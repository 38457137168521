import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';

import { NaceBelEntity, NacebelSearchEntity } from '../interfaces';
import { actions, selectors } from '../nacebel.store';

import { NacebelApiService } from './nacebel.api.service';

@Injectable()
export class NacebelService extends StoreService {
	/**
	 * Array of nacebel codes
	 */
	public nacebelCodes$: ObservableArray<NaceBelEntity> = this.selectFromStore(selectors.nacebelCodes);

	public nacebelSearchResults$: ObservableArray<NacebelSearchEntity> = this.selectFromStore(
		selectors.nacebelSearchResults
	);

	/**
	 * Loading for the branches
	 */
	public nacebelCodesLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.nacebelCodes);

	/**
	 * Loading for the branches
	 */
	public nacebelCodesSearchLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.nacebelSearchResults);

	/**
	 * Error for the branches
	 */
	public nacebelCodesError$: ObservableBoolean = this.selectErrorFromStore(selectors.nacebelCodes);

	constructor(public readonly store: Store, private readonly apiService: NacebelApiService) {
		super(store);
	}

	public getNacebelCodes(): ObservableArray<NaceBelEntity> {
		return dispatchDataToStore(actions.nacebelCodes, this.apiService.getNacebelCodes(), this.store, 'set');
	}

	public searchNacebelCodes(searchValue: string): ObservableArray<NacebelSearchEntity> {
		return dispatchDataToStore(
			actions.nacebelSearchResults,
			this.apiService.searchNacebelCodes(searchValue),
			this.store,
			'set'
		);
	}
}
