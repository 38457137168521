import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { map, tap } from 'rxjs';

import { AbstractModalDialogService, ExitWizardModalAbstractComponent, defaultModalOptions } from '../abstracts';
import { ExitModalOptions, i18nKeysEntity } from '../interfaces';

@Injectable()
export class ExitWizardService {
	constructor(private readonly modalService: AbstractModalDialogService) {}

	public handleModal<ComponentClass extends ExitWizardModalAbstractComponent>(
		i18nValues: i18nKeysEntity,
		component: ComponentType<ComponentClass>
	): ObservableBoolean {
		// Wouter: open modal and set keys
		const modal = this.modalService.openModal(component, defaultModalOptions).component;
		modal.i18nKeys = i18nValues;

		return modal.closeModal.pipe(
			map((option) => option === ExitModalOptions.Exit),
			tap(() => this.modalService.closeModal())
		);
	}
}
