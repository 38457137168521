import { Directive, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as deepEqual from 'deepequal';
import { distinctUntilChanged, filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs';

import { OnDestroyComponent } from '../on-destroy.component';

@Directive()
export abstract class AbstractFilterComponent<FiltersType extends Object> extends OnDestroyComponent implements OnInit {
	/**
	 * The control used to handle the filters
	 */
	public readonly filtersControl: FormControl<Partial<FiltersType>> = new FormControl<Partial<FiltersType>>(
		this.initialValue()
	);

	constructor(protected readonly router: Router, protected readonly route: ActivatedRoute) {
		super();
	}

	/**
	 * The value we wish to use as the initial value of the form and the initial value of the routing when none is provided
	 */
	public abstract initialValue(): FiltersType | undefined;

	public ngOnInit(): void {
		// Iben: Listen to the control changes and route accordingly
		this.filtersControl.valueChanges
			.pipe(
				distinctUntilChanged(),
				filter(Boolean),
				// Iben: Fetch the latest route params and parse them to a control based format
				withLatestFrom(
					this.route.queryParams.pipe(
						map((value) => {
							if (!value) {
								return;
							}

							return this.convertForRouter(value, 'parse');
						})
					)
				),
				tap(([controlValue, routeValue]) => {
					// Iben: If the control and the route have the same value, we early exit to prevent endless loops
					if (deepEqual(controlValue, routeValue)) {
						return;
					}

					// Iben: Navigate to the new route to reflect the changes of the form in the route
					this.router.navigate([], {
						queryParams: this.convertForRouter(controlValue, 'stringify'),
						relativeTo: this.route
					});
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Iben: Listen to the query param changes once to handle the intitial routing
		this.route.queryParams
			.pipe(
				take(1),
				tap((value) => {
					// Iben: If the control and the route have the same value, we early exit to prevent endless loops
					if (deepEqual(this.filtersControl.value, value)) {
						return;
					}

					// Iben: If no values were provided, we use the initial value
					const controlValue = Object.keys(value).length === 0 ? this.initialValue() : value;

					// Iben: Update the filters based on the provided route
					this.filtersControl.setValue(this.convertForRouter(controlValue, 'parse'));
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * Convert an object for the router or the control
	 *
	 * @param {*} data - The data
	 * @param  convertTo - Whether we want to convert the properties of an object to a string or parse them
	 */
	private convertForRouter(data: any, convertTo: 'parse' | 'stringify') {
		return Object.entries(data).reduce((previous, [key, value]) => {
			return {
				...previous,
				[key]: JSON[convertTo](value as any)
			};
		}, {});
	}
}
