import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxTourAction, NgxTourService, NgxTourStep } from '@studiohyperdrive/ngx-tour';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { sharedI18nKeys } from '@vlaio/shared/i18n';
import { MediaQueryMin } from '@vlaio/shared/types';
import { VlaioFaIconComponent } from '@vlaio/shared/ui/common';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';

@Component({
	selector: 'vlaio-tour-button',
	standalone: true,
	templateUrl: './tour-button.component.html',
	styleUrl: './tour-button.component.scss',
	imports: [MediaQueryDirective, VlaioFaIconComponent, FocusClickDirective, TranslateModule, CypressTagDirective]
})
export class VlaioTourButtonComponent {
	private tourData: NgxTourStep[] = [];

	/**
	 * The mediaquery to use in the template.
	 */
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;

	public readonly i18nKeys: typeof sharedI18nKeys = sharedI18nKeys;

	/**
	 * A tooltip we wish to display
	 */
	@Input() public toolTip: string;

	@Input() public onClose: NgxTourAction;

	@Input({ required: true }) public set tour(tour: NgxTourStep[]) {
		this.tourData = [];

		tour.forEach((item) => {
			this.tourData.push({
				...item,
				title: this.translateService.instant(item.title),
				content: this.translateService.instant(item.content)
			});
		});
	}

	constructor(private readonly ngxTourService: NgxTourService, private readonly translateService: TranslateService) {}

	public startTour(): void {
		this.ngxTourService.startTour(this.tourData, this.onClose).subscribe();
	}
}
