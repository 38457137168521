import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';

import { Notification } from '../interfaces/notification.interface';
import { actions, selectors } from '../notifications.store';

import { NotificationsApiService } from './notifications.api.service';

@Injectable()
export class NotificationsService extends StoreService {
	/**
	 * An array of notifications
	 */
	public readonly notifications$: ObservableArray<Notification> = this.selectFromStore<Notification[]>(selectors);
	constructor(private readonly apiService: NotificationsApiService, public readonly store: Store) {
		super(store);
	}

	/**
	 * Get the notifications from the api and dispatch them to the store
	 */
	public getNotifications(): ObservableArray<Notification> {
		return dispatchDataToStore(actions, this.apiService.getNotifications(), this.store);
	}
}
