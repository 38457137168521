<div>
	<div class="c-modal__header">
		<h3 class="c-modal__title">{{ translations.title || 'titel' }}</h3>

		<button class="c-modal__close" (focusClick)="handleClose.emit()">
			<fa-icon icon="fa-close" />
		</button>
	</div>

	@if (hasCookieConsent) {
	<div class="c-modal__body">
		<div class="c-iframe">
			<iframe
				style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0"
				allowfullscreen
				scrolling="no"
				allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
				[src]="videoData.video"
			></iframe>
		</div>
	</div>
	} @else {

	<div class="c-modal__body image-body">
		<img class="u-margin-bottom-sm" alt="" [src]="videoData.thumbnail" />

		<vlaio-disclaimer>
			<div [outerHTML]="translations.videoDescription"></div>

			<a
				class="cc-link u-link__underline u-outline"
				role="button"
				tabindex="0"
				href="/nl/pagina?page=privacyverklaring"
				rel="noopener noreferrer nofollow"
				target="_blank"
				[title]="translations.readMoreLabel"
			>
				{{ translations.readMoreLabel || 'Read more' }}
			</a>
		</vlaio-disclaimer>
	</div>

	} @if (!hasCookieConsent) {
	<div class="c-modal__footer">
		<vlaio-button class="c-modal__action" [title]="translations.buttonTitle" (handleClick)="cookieAccepted.emit()">
			{{ translations.buttonLabel }}
		</vlaio-button>
	</div>
	}
</div>
