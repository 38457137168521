//
// Interface: GTag
// ---
//

// Interface Definition
export interface GTagConfig {
	trackingCode: string;
}

export enum GtagType {
	PWA = 'Mobiele app',
	Filter = 'Filteren',
	Mandates = 'Mandaten',
	Dashboard = 'Dashboard',
	Status = 'Applicatiestatus'
}

export enum GTagCategory {
	InstallPWA = 'Mobiele app installeren',
	InstallPWAPage = 'Mobiele app pagina bekeken',
	Cases = 'Lopende aanvragen',
	MandatesNewMandate = 'Nieuw mandaat',
	MandatesEditMandate = 'Bewerk mandaat',
	MandatesStopMandate = 'Stop mandaat',
	MandatesSearchMandatesProduct = 'Zoek mandaat op product',
	MandatesSearchMandatesCompany = 'Zoek mandaat op onderneming',
	MandatesFilterRequireApproval = 'Filter mandaten op goedkeuren',
	MandatesApproveMandate = 'Keur mandaat goed',
	MandatesDeclineMandate = 'Keur mandaat af',
	MandatesNewExecutor = 'Nieuwe uitvoerder',
	MandatesAddExecutor = 'Voeg uitvoerder toe aan mandaat',
	MandatesStopExecutor = 'Stop uitvoerder',
	MandatesSearchExecutor = 'Zoek uitvoerder',
	MandatesFilterActiveExecutor = 'Filter actieve uitvoerders',
	MandatesFilterApplicationsByStatus = 'Filter mandaataanvragen op status',
	MandatesFilterApplicationsByExecutor = 'Filter mandaataanvragen op uitvoerder',
	MandatesOverview = 'Mandaatbeheer overzicht',
	MandatesTaker = 'Mandaatnemer overzicht',
	MandatesGiver = 'Mandaatgever overzicht',
	MandatesExecutor = 'Mandaatuivoerder overzicht',
	MandatesApplications = 'Mandaataanvragen overzicht',
	MandatesDashboardCTA = 'Goed te keuren mandaten (Dashboard)',
	MandatesAddToExecutor = 'Mandaten toewijzen aan uitvoerder',
	MandatesApproveApplication = 'Mandaataanvraag goedkeuren',
	MandatesDeclineApplication = 'Mandaataanvraag afkeuren',
	DashboardDefaultConfig = 'Standaard dashboard configuratie',
	DashboardCustomConfig = 'Gepersonaliseerde dashboard configuratie',
	DashboardDynamicInfo = 'Dynamische info dashboard',
	DashboardLinks = 'Dashboard links',
	DashboardInfoBlocksLink = 'Dashboard info blok',
	DashboardHelp = 'Help dashboard',
	StatusError = 'Applicatiestatus fout'
}

export enum GTagLabel {
	MandateGiver = 'Mandaatgever',
	MandateTaker = 'Mandaatnemer'
}

export type GTagCategoryKey = keyof typeof GTagCategory;
export type GtagTypeKey = keyof typeof GtagType;
