<div class="c-tag--button">
	<span class="c-tag__label--button">
		{{ i18nKeys.Facets.Actions.ClearAll | translate }}
	</span>

	<button
		class="c-tag__trigger u-outline"
		cypressTag="Filters.RemoveAllFilters"
		[title]="i18nKeys.Facets.Actions.ClearAll | translate"
		(click)="cleared.emit()"
	>
		@if (displayCloseIcon) {
		<span class="c-tag__trigger-icon icon-close"></span>
		}
	</button>
</div>
