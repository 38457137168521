import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'vlaio-indicator',
	template: ` <span class="indicator" [class]="indicatorClass">{{ amount }}</span> `,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class IndicatorComponent {
	@Input() public amount: number;
	@Input() indicatorClass: string;
}
