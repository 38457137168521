<div class="c-search">
	<div class="c-search__input js-input-search" [ngClass]="{ 'is-empty': form.value !== '' }">
		<div class="c-input c-input--with-icon" [title]="title || i18nKeys.Forms.Actions.Title | translate">
			<span class="icon-search"></span>
			@if (mask) {

			<input
				class="c-input--as-textfield input-search u-outline"
				name="input-search"
				id="input-search"
				role="search"
				type="text"
				[placeholder]="placeholder"
				[cypressTag]="inputCypressTag || 'General.Input.SearchInput'"
				[formControl]="searchField"
				[mask]="mask"
				[showMaskTyped]="true"
				(keydown.enter)="!autoSearch && changed(searchField.value)"
			/>

			} @else {

			<input
				class="c-input--as-textfield input-search u-outline"
				name="input-search"
				id="input-search"
				role="search"
				type="text"
				[placeholder]="placeholder"
				[cypressTag]="inputCypressTag || 'General.Input.SearchInput'"
				[formControl]="searchField"
				(keydown.enter)="!autoSearch && changed(searchField.value)"
			/>

			} @if (searchField.value) {
			<button
				class="c-button c-button__close c-button--rounded c-button--grey has-icon u-outline"
				cypressTag="General.Input.DeleteSearchButton"
				[title]="i18nKeys.Forms.Actions.ClearSearch | translate"
				(click)="reset()"
			>
				<span class="icon-close"></span>
			</button>
			}
		</div>
	</div>

	@if (showButton) {
	<div class="c-search__cta" cypressTag="General.Input.SearchButton">
		<button
			class="c-button c-button--search"
			[class.c-button--search--small]="hasSmallButton"
			[cypressTag]="buttonCypressTag"
			[title]="buttonLabel || (i18nKeys.Forms.Actions.Search | translate)"
			[disabled]="searchField.disabled || disableSearchButton"
			(click)="changed(searchField.value)"
		>
			{{ buttonLabel || (i18nKeys.Forms.Actions.Search | translate) }}
		</button>
	</div>
	}
</div>
