import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { I18nKeys } from '../../i18n';
import { VlaioFaIconComponent } from '../fa-icon/fa-icon.component';

import { DisclaimerTypes } from './disclaimer.enums';

/**
 * A disclaimer component that can be used to display information, warnings or errors.
 *
 */
@Component({
	selector: 'vlaio-disclaimer',
	templateUrl: './disclaimer.component.html',
	styleUrl: './disclaimer.component.scss',
	standalone: true,
	imports: [VlaioFaIconComponent, NgClass, NgxI18nModule],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlaioDisclaimerComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	/**
	 * The type of the disclaimer
	 */
	@Input() public type: `${DisclaimerTypes}` = 'warning';

	/**
	 * Whether the disclaimer should take in the full width of the container
	 */
	@Input() public fullWidth: boolean = true;

	/**
	 * Whether the disclaimer should have vertical margin (top, bottom)
	 */
	@Input() public verticalMargin: boolean = true;
}
