<div class="l-grid l-grid--flex l-grid--stretch">
	<div class="l-grid__col l-grid__col--7@viewport-9 l-grid__col--12@viewport-7">
		<div class="c-cta-card__content" [class.u-flex--wrap]="bodyText">
			@if (image) {
			<vlaio-img class="c-cta-card__image" [src]="image"></vlaio-img>
			} @if (icon && !image) {
			<fa-icon class="c-cta-card__icon primary-color" [icon]="icon"></fa-icon>
			}

			<div class="c-cta-card__title-container">
				<h2 class="c-cta-card__title" [cypressTag]="cypressTitleTag" [innerHTML]="title"></h2>
				@if (displayInfo) {
				<vlaio-info [description]="infoText" />
				}
			</div>
			@if (bodyText) {
			<p class="c-cta-card__body" [innerHTML]="bodyText"></p>
			}
		</div>
	</div>

	<div class="l-grid__col l-grid__col--5@viewport-9 l-grid__col--12@viewport-7">
		<div class="c-cta-card__meta-container" [class]="counterPosition">
			<div class="u-flex--justify-center u-flex--align-center u-flex">
				<vlaio-content [loading]="loading">
					@if (!showCTA) {
					<span class="c-cta-card__count" [cypressTag]="cypressCountTag">
						{{ count }}
					</span>
					} @else {

					<ng-content> </ng-content>

					}
				</vlaio-content>

				@if (!showCTA) {
				<a class="c-cta-card__link">
					<span class="c-cta-card__icon icon-arrow-big-right"></span>
				</a>
				}
			</div>
		</div>
	</div>
</div>
