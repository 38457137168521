import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ModalDialogService } from './services';

@NgModule({
	imports: [MatDialogModule],
	providers: [ModalDialogService]
})
export class ModalDataModule {}
