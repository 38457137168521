import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { LanguageService } from '@vlaio/shared/i18n';
import { AppRoutePaths, ELoketRoutePaths } from '@vlaio/shared/route-paths';
import { VlaioAddressComponent, VlaioTranslateWithRouterLinksPipe } from '@vlaio/shared/ui/common';

import { CompanyEntity } from '../../../data';
import { sharedCompanyI18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-company-info',
	templateUrl: './info.component.html',
	styleUrl: './info.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CypressTagDirective,
		VlaioAddressComponent,
		DatePipe,
		TranslateModule,
		SafeHtmlPipe,
		VlaioTranslateWithRouterLinksPipe
	]
})
export class CompanyInfoComponent {
	public readonly i18nKeys = sharedCompanyI18nKeys.Companies.Info;

	/**
	 * The link to the cases page.
	 */
	public caseLink: string = `${this.languageService.currentLanguage}/${AppRoutePaths.ELoket}/${ELoketRoutePaths.Cases}`;

	/**
	 * Whether the component should be full width.
	 *
	 * Default value is `false`.
	 */
	@HostBinding('class.is-full-width') @Input() fullWidth: boolean = false;

	/**
	 * Whether the user is the owner of the company.
	 *
	 * Default value is `false`.
	 */
	@Input() public isUserCompany: boolean = false;
	/**
	 * The company data.
	 */
	@Input() public company: CompanyEntity | Partial<CompanyEntity>;
	/**
	 * Whether the public data text has to be hidden.
	 *
	 * Default value is `false`.
	 */
	@Input() public hideText: boolean = false;
	/**
	 * The title to display.
	 *
	 * Default value is translation string.
	 */
	@Input() public title: string = this.i18nKeys.Public;
	/**
	 * Whether the title should be displayed.
	 *
	 * Default value is `true`.
	 */
	@Input() public showTitle: boolean = true;
	/**
	 * Whether the essentials of the company details should be shown.
	 *
	 * Default value is `false`.
	 */
	@Input() public showMinimalInfo: boolean = false;

	constructor(private readonly languageService: LanguageService) {}
}
