import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';

import { VlaioIconComponent } from '../../icon/icon.component';

@Component({
	selector: 'vlaio-close-button',
	template: `
		<button class="u-outline" [title]="title" [cypressTag]="buttonCypressTag" (click)="handleClick.emit()">
			<vlaio-icon icon="fa-xmark" />
		</button>
	`,
	styleUrl: './close-button.component.scss',
	standalone: true,
	imports: [CypressTagDirective, VlaioIconComponent]
})
export class VlaioCloseButtonComponent {
	/**
	 * The title of the button. This will be the tooltip on hover.
	 */
	@Input({ required: true }) public title: string;
	/**
	 * A Cypress tag to identify the button.
	 */
	@Input() public buttonCypressTag: CypressTagsPaths;

	/**
	 * Emits when the button has been pressed.
	 */
	@Output() public handleClick: EventEmitter<void> = new EventEmitter<void>();
}
