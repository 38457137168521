import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, ElementRef, HostBinding, Input, TemplateRef, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';
import { MediaQueryMax, MediaQueryMin } from '@vlaio/shared/types';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'vlaio-filter-container',
	templateUrl: './filter-container.component.html',
	styleUrls: ['./filter-container.component.scss'],
	standalone: true,
	imports: [CypressTagDirective, TranslateModule, MediaQueryDirective, NgTemplateOutlet]
})
export class VlaioFilterContainerComponent {
	/**
	 * The translation keys
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * The media queries to be used in the template
	 */
	public readonly mediaQueries = { MediaQueryMin, MediaQueryMax };

	/**
	 * The details element in the template
	 */
	@ViewChild('details') public details: ElementRef;

	/**
	 * The template reference to the content
	 */
	@ContentChild('content') public contentTmpl: TemplateRef<any>;

	/**
	 * Whether a border should be shown around the filters
	 */
	@HostBinding('class.has-border') @Input() public hasBorder: boolean = true;

	/**
	 * The cypress tag we wish to attach to the filters
	 */
	@Input() public cypressTag: CypressTagsPaths;

	/**
	 * The title of the filters
	 */
	@Input({ required: true }) public title: string;

	/**
	 * Whether we want the filters to be opened
	 */
	@Input() public set filtersOpen(value: { isOpen: boolean }) {
		// Iben: If no details was found, early exit
		if (!this.details || !value) {
			return;
		}

		// Iben: Open or close the filters based on the provided isOpen
		value.isOpen
			? this.details.nativeElement.setAttribute('open', true)
			: this.details.nativeElement.removeAttribute('open');
	}
}
