import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { dispatchDataToStore, StoreService } from '@studiohyperdrive/ngx-store';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { map, Observable } from 'rxjs';

import { PartnerLevelEnum, PartnersByLevelEntity } from '../interfaces';
import { actions, selectors } from '../partners.store';

import { PartnersApiService } from './partners.api.service';

@Injectable()
export class PartnersService extends StoreService {
	/**
	 * Array of partners
	 */
	public partners$: Observable<PartnersByLevelEntity> = this.selectFromStore(selectors.partners);

	/**
	 * Loading for the partners
	 */
	public loading$: ObservableBoolean = this.selectLoadingFromStore(selectors.partners);

	/**
	 * Error for the partners
	 */
	public error$: ObservableBoolean = this.selectErrorFromStore(selectors.partners);

	constructor(private readonly apiService: PartnersApiService, public readonly store: Store) {
		super(store);
	}

	/**
	 * Fetches and stores the partners in the store
	 */
	public getPartners(): Observable<PartnersByLevelEntity> {
		return dispatchDataToStore(actions.partners, this.apiService.getPartners(), this.store);
	}

	/**
	 * Get an object requesterd governing bodies with their partners
	 *
	 * @param labels An array of PartnerLevelEnum values.
	 */
	public getPartnersByLevel(labels: PartnerLevelEnum[]): Observable<PartnersByLevelEntity> {
		return this.partners$.pipe(
			map((partners) => {
				return labels.reduce(
					(acc, curr) => ({
						...acc,
						[curr]: partners[curr] || []
					}),
					{} as PartnersByLevelEntity
				);
			})
		);
	}
}
