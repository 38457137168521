import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { FacetEntity, FacetFilter } from '@vlaio/shared/types';
import { VlaioEmptySearchResultComponent } from '@vlaio/shared/ui/common';

import { FacetFilterComponent } from '../facet-filter/facet-filter.component';
import { SelectedFacetsComponent } from '../selected-facets/selected-facets.component';

@Component({
	selector: 'vlaio-facet-container',
	templateUrl: './facet-container.component.html',
	styleUrls: ['./facet-container.component.scss'],
	standalone: true,
	imports: [FacetFilterComponent, SelectedFacetsComponent, VlaioEmptySearchResultComponent]
})
export class FacetContainerComponent {
	@HostBinding('class') private readonly rootClass = 'u-margin-top';

	/**
	 * Whether or not we're currently in a desktop view, by default this value is false
	 */
	@Input() public isDesktop = false;

	/**
	 * Whether or not the clear button needs to be shown, by default this value is false
	 */
	@Input() public enableClear: boolean = false;

	/**
	 * A list of facets we want to show in the filter list
	 */
	@Input({ required: true }) public facets: FacetEntity[] = [];

	/**
	 * The loading state of the facets
	 */
	@Input() public loading: boolean;

	/**
	 * An optional search query
	 */
	@Input() public searchQuery: string;

	/**
	 * An optional set of selected facets
	 */
	@Input() public selectedFacets: FacetFilter;

	/**
	 * A record of facet names with as key the id of the facet
	 */
	@Input() public facetSubjectNames: Record<string, string> = {};

	/**
	 * Whether or not we want to show the currently selectedFacets
	 */
	@Input() public showSelectedFacets: boolean = false;

	/**
	 * Whether a page-blocking error has occurred.
	 */
	@Input() @HostBinding('class.has-error') public error: boolean = false;

	/**
	 * An output for when the clear all button is clicked
	 */
	@Output() public readonly clearClicked = new EventEmitter<void>();

	/**
	 * An output for when any of the filters have changed
	 */
	@Output() public readonly filtersChanged = new EventEmitter<FacetFilter>();

	/**
	 * An output for when a facet is clicked
	 */
	@Output() public readonly facetClicked = new EventEmitter<string>();

	/**
	 * An output for when the search query is cleared
	 */
	@Output() public readonly searchQueryClearClicked: EventEmitter<void> = new EventEmitter<void>();
}
