<button title="Previous" (click)="move('left')">
	<vlaio-icon icon="fa-chevron-left" />
</button>

<drag-scroll #carrousel [scrollbar-hidden]="true">
	@for (item of data; track item) {
		<div class="c-carrousel-item" drag-scroll-item>
			<ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
			</ng-template>
		</div>
	}
</drag-scroll>

<button title="Next" (click)="move('right')">
	<vlaio-icon icon="fa-chevron-right" />
</button>
