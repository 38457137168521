@if (product) {
<div class="c-product" cypressTag="Offers.Product.Block" [class.is-recommended]="product.tags?.length > 0">
	@for (tag of product.tags || []; track tag) {
	<vlaio-product-tag [description]="tag.description">
		{{ tag.name }}
	</vlaio-product-tag>
	}

	<div class="c-product-content" [class.u-margin]="!borderless">
		<h2 class="c-product-title" cypressTag="Offers.Product.Title" [class.u-margin-top-sm]="showCTAs">
			{{ product.name }}
		</h2>

		<p class="c-product-meta" [class.u-margin-top]="(product.partners || []).length !== 0">
			{{
				(recommendationRating ? i18nKeys.Products.Detail.RecommendedBy : i18nKeys.Products.Detail.Via)
					| translate
			}}
			@for (partner of product.partners || []; track partner) { @if (partner.url) {
			<vlaio-external-link cypressTag="Offers.Product.Partner" [link]="partner.url" [title]="partner.name">
				{{ partner.name }}
			</vlaio-external-link>
			} @else {

			<span cypressTag="Offers.Product.Partner">
				{{ partner.name }}
			</span>

			} }
		</p>

		<p
			class="c-product-description u-wysiwyg"
			cypressTag="Offers.Product.Description"
			[class.u-margin-top]="product.description"
			[innerHtml]="product.description"
		></p>

		<div class="c-product-links u-margin-top">
			<vlaio-external-link
				cypressTag="Offers.Product.MoreInfo"
				[link]="product.link"
				[title]="i18nKeys.Actions.MoreInfo | translate"
			>
				{{ i18nKeys.Actions.MoreInfo | translate }}
			</vlaio-external-link>

			@for (link of product.infoLinks || []; track link) {

			<i class="c-product-separator">&#124;</i>

			<vlaio-external-link [link]="link.link" [title]="link.name">
				{{ link.name }}
			</vlaio-external-link>

			}
		</div>
	</div>

	@if ( showCTAs && (product?.actions.length > 0 || recommendationRating || (product.annotations &&
	product.annotations.length > 0)) ) {
	<div class="c-product-buttons u-margin">
		@for (annotation of product.annotations || []; track annotation) {
		<vlaio-product-annotation [annotation]="annotation" />
		} @for (action of product.actions || []; track action) {
		<vlaio-product-cta [callToAction]="action" [productId]="product.id" (ctaClicked)="handleCTAClicked(action)" />
		} @if (recommendationRating) {
		<vlaio-product-rating
			[recommendationRating]="recommendationRating"
			(recommendationRated)="recommendationRated.emit($event)"
		/>
		}
	</div>
	}
</div>
}
