import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { ButtonClasses } from '@vlaio/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-desktop-only',
	templateUrl: './desktop-only.component.html',
	styleUrls: ['./desktop-only.component.scss'],
	standalone: true,
	imports: [CypressTagDirective, RouterLink, TranslateModule]
})
export class DesktopOnlyComponent {
	/**
	 * The translation keys used in the template
	 */
	public readonly label = RedirectI18nKeys.DesktopOnly.Text;
	public readonly title = RedirectI18nKeys.DesktopOnly.Title;
	public readonly action = RedirectI18nKeys.DesktopOnly.Button;

	/**
	 * The button classes used in the template
	 */
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
}
