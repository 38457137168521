@if (currentLanguage$ | async; as currentLanguage) {
	<button class="language-main-button" [cdkMenuTriggerFor]="languagesTmpl">
		{{ currentLanguage | uppercase }}
	</button>

	<ng-template #languagesTmpl>
		<ul class="language-menu" cdkMenu>
			@for (language of languages; track language) {
				@if (language !== currentLanguage) {
					<li>
						<button cdkMenuItem (click)="setLanguage(language)">
							{{ language | uppercase }}
						</button>
					</li>
				}
			}
		</ul>
	</ng-template>
}
