<aside class="c-aside">
	<!-- Mobile, Tablet -->
	<details
		*mediaQuery="mediaQueryMax.Desktop"
		class="u-outline"
		#details
		tabindex="0"
		(focusClick)="handleClick(details, $event)"
	>
		<summary class="font-bold" tabindex="-1">
			<h5>{{ summary }}</h5>
			<vlaio-icon [icon]="isDetailOpen ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'" />
		</summary>

		<ng-container *ngTemplateOutlet="listTmpl"></ng-container>
	</details>

	<!-- Desktop -->
	<ng-container *mediaQuery="mediaQueryMin.Desktop">
		<div>
			<h5>{{ summary }}</h5>
			<ng-container *ngTemplateOutlet="listTmpl"></ng-container>
		</div>
	</ng-container>
</aside>

<ng-template #listTmpl>
	<ul>
		@for (item of items; track item) {
			<li cypressTag="Shared.AsideMenu.Link" [class.has-submenu]="item.items?.length">
				<ng-template [ngTemplateOutlet]="itemTmpl" [ngTemplateOutletContext]="{ $implicit: item }">
				</ng-template>

				@if (item.items?.length > 0) {
					<ul class="submenu" cypressTag="Shared.AsideMenu.SubMenu">
						@for (child of item.items; track child) {
							<li cypressTag="Shared.AsideMenu.SubMenuLink">
								<ng-template
									[ngTemplateOutlet]="itemTmpl"
									[ngTemplateOutletContext]="{ $implicit: child }"
								>
								</ng-template>
							</li>
						}
					</ul>
				}
			</li>
		}
	</ul>
</ng-template>

<ng-template #itemTmpl let-item>
	<vlaio-internal-link linkStyle="plain" [link]="item.routerLink" [linkQueryParams]="item.queryParams">
		{{ item.label }}
	</vlaio-internal-link>
</ng-template>
