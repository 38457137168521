<vlaio-icon icon="fa-triangle-exclamation" iconType="fa-solid" />

<div
	class="projection-content"
	[class]="'disclaimer-' + type"
	[ngClass]="{ 'full-width': fullWidth, 'vertical-margin': verticalMargin }"
>
	<ng-content>
		{{ i18nKeys.Disclaimer.Fallback | translate }}
	</ng-content>
</div>
