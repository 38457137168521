<div class="c-detail-content">
	<h3 class="c-detail-content-title" [cypressTag]="cypressTitleTag">
		<ng-content select="vlaio-icon"></ng-content>

		<ng-content select="header">
			@if (title) {
				{{ title }}
			}
		</ng-content>
	</h3>

	<div class="c-detail-content-detail">
		<ng-content select="section"></ng-content>
	</div>
</div>

<div class="c-detail-content-aside">
	<ng-content select="aside"></ng-content>
</div>
