import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';
import * as clean from 'obj-clean';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { FacetFilter } from '@vlaio/shared/types';
import { I18nKeys } from '@vlaio/shared/ui/forms';

import { ClearSearchComponent } from '../clear-search-button/clear-search.component';

@Component({
	selector: 'vlaio-selected-facets',
	templateUrl: './selected-facets.component.html',
	styleUrls: ['../../../../../../ui/styles/block.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgxI18nModule, ClearSearchComponent, FocusClickDirective, CypressTagDirective]
})
export class SelectedFacetsComponent {
	public facetIds: string[] = [];
	public facets: FacetFilter;
	public readonly i18nKeys = I18nKeys;

	@HostBinding('class.c-facet-buttons') private readonly hasFacetButtonsClass: boolean = true;

	/**
	 * Whether or not we wish to show the delete button
	 */
	@Input() public hideDelete: boolean = false;

	/**
	 * An optional search query we wish to display
	 */
	@Input() public searchQuery: string;

	/**
	 * A record of selected filters by id
	 */
	@Input() public set filters(filters: FacetFilter) {
		// Iben: Set the facet ids in a separate array for performant access in template
		this.facetIds = Array.from(Object.keys(filters || {}));
		this.facets = filters || {};
	}

	/**
	 * A record of facet names with as key the id of the facet
	 */
	@Input() public facetSubjectNames: Record<string, string> = {};

	/**
	 * Output handling the clear search query action
	 */
	@Output() public readonly searchQueryCleared = new EventEmitter<void>();

	/**
	 * Output handling changes in the selected filters
	 */
	@Output() public readonly filtersChanged = new EventEmitter<FacetFilter>();

	/**
	 * Whether or not we're currently in a desktop view, by default this value is false
	 */
	@Output() public readonly clearAll = new EventEmitter<void>();

	/**
	 * Removes a facet from the selected facets
	 *
	 * @param facetId - The id of the facet
	 * @param subjectId - The id of the subject
	 */
	public filterCleared(facetId: string, subjectId: string): void {
		this.filtersChanged.emit(
			clean(
				{
					...this.facets,
					[facetId]: [...this.facets[facetId]].filter((item) => item !== subjectId)
				},
				{ preserveArrays: false }
			)
		);
	}
}
