<div class="c-dropdown-search">
	<div class="c-search">
		<div class="c-search__input js-input-search" [ngClass]="{ 'is-empty': searchField.value !== '' }">
			<div class="c-input c-input--with-icon" [title]="title || i18nKeys.Forms.Actions.Title | translate">
				<span class="icon-search"></span>
				<input
					class="c-input--as-textfield input-search"
					name="input-search"
					id="input-search"
					role="search"
					#input
					type="text"
					cypressTag="General.Input.DropdownSearch"
					[ngClass]="{ 'u-outline': outline }"
					[placeholder]="placeholder"
					[formControl]="searchField"
				/>
				@if (showClearButton && input.value) {
				<button
					class="c-button c-button__close c-button--rounded c-button--grey has-icon u-outline"
					[title]="i18nKeys.Forms.Actions.ClearSearch | translate"
					(click)="clearSearchInput()"
				>
					<span class="icon-close"></span>
				</button>
				}
			</div>
		</div>
	</div>

	@if (searchField.value && hasSearched) {
	<div
		class="c-dropdown-search__flyout"
		[class.c-dropdown-search__flyout--active]="loading || error || searchedItems"
	>
		<ul>
			@if (!loading) { @if (!error) { @if (searchedItems?.length > 0) { @for (item of searchedItems; track item) {
			<li tabindex="0" cypressTag="General.Input.SearchResult" (click)="selectItem(item)">
				@if (listItemTemplate) {
				<ng-template
					[ngTemplateOutlet]="listItemTemplate"
					[ngTemplateOutletContext]="{
						$implicit: item
					}"
				>
				</ng-template>
				} @else { }
			</li>
			} } @else {

			<li class="is-non-clickable" cypressTag="General.Input.EmptySearchResult">
				{{ emptyLabel }}
			</li>

			} } @else {

			<li class="is-non-clickable">
				{{ errorLabel }}
			</li>

			} } @else {

			<li class="is-non-clickable">
				<div class="c-loader-container" cypressTag="General.Loading">
					<div class="c-loader">
						<span></span>
					</div>
				</div>
			</li>

			}
		</ul>
	</div>
	}
</div>
