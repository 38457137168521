import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { createAccessorProviders, FormAccessor } from '@studiohyperdrive/ngx-forms';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-radio',
	providers: [createAccessorProviders(VlaioRadioButtonComponent)],
	templateUrl: './radio.component.html',
	styleUrls: ['./radio.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ReactiveFormsModule, TranslateModule]
})
export class VlaioRadioButtonComponent extends FormAccessor {
	/**
	 * Translation keys
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * The unique id of the checkbox.
	 */
	@Input({ required: true }) public id: string;
	/**
	 * The name of the group to which this input belongs.
	 */
	@Input({ required: true }) public name: string;
	/**
	 * The value assigned to the radio button.
	 */
	@Input({ required: true }) public value: unknown;
	/**
	 * The title of the checkbox.
	 * This property is superior to `label`.
	 *
	 * No default value. Fallback to `label` input.
	 */
	@Input() public title: string;
	/**
	 * The value that gets inserted in the title translation.
	 * This property is inferior to `title`.
	 *
	 * No default value.
	 */
	@Input({ required: true }) public label: string;

	// Abstract methods
	// ==============================
	public initForm(): FormControl<unknown> {
		return new FormControl<unknown>(null);
	}
}
