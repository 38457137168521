import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LanguageService } from '@vlaio/shared/i18n';

import { bpostHost, sasEndPoint } from './end-points';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
	constructor(private readonly languageService: LanguageService) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Denis: do not set these headers on the Bpost API requests. They cause CORS errors.
		if (req.url === sasEndPoint || req.url.indexOf(bpostHost)) {
			return next.handle(req);
		}

		const defaultHeadersReq = req.clone({
			headers: req.headers
				.set('Accept-Language', this.languageService.currentLanguage)
				.set('Content-Type', 'application/json')
				.set('User-UUID', '')
		});

		return next.handle(defaultHeadersReq);
	}
}
