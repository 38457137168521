import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxCookieService } from '@studiohyperdrive/ngx-cookies';
import { take, tap } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { VlaioIconComponent } from '@vlaio/shared/ui/common';

import { ModalDialogService } from '../../../data';
import { VlaioVideoModalComponent } from '../video-modal/video-modal.component';

import { VlaioCookieConsentTranslationsEntity, VlaioVideoDataEntity } from './video-button.types';

function getImageUrl(videoId: string): VlaioVideoDataEntity {
	return {
		videoId,
		thumbnail: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
		video: `https://www.youtube.com/embed/${videoId}`
	};
}

@Component({
	selector: 'vlaio-video-button',
	templateUrl: './video-button.component.html',
	styleUrl: './video-button.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioIconComponent, CypressTagDirective]
})
export class VlaioVideoButtonComponent {
	/**
	 * The translations of the cookie consent disclaimer.
	 */
	@Input({ required: true }) public translations: VlaioCookieConsentTranslationsEntity;
	/**
	 * The YouTube video.
	 */
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input({ required: true, transform: getImageUrl, alias: 'videoId' }) public videoData: VlaioVideoDataEntity;
	/**
	 * Whether the modal should open by default.
	 */
	@Input() public set openModal(shouldOpen: boolean) {
		if (!shouldOpen) {
			return;
		}
		// Wouter: Open the modal;
		this.handleButtonClick();
		// Wouter: Emit the modal has programmatically been opened.
		this.modalOpenedOnInit.emit();
	}

	/**
	 * Emits when the modal has been opened programmatically.
	 */
	@Output() public readonly modalOpenedOnInit: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private readonly modalService: ModalDialogService,
		private readonly cookieService: NgxCookieService,
		private readonly sanitizer: DomSanitizer
	) {}

	/**
	 * Handle the opening of the modal, along with intercepting its output events.
	 */
	public handleButtonClick() {
		this.modalService.closeModal();

		const component = this.modalService.openModal<VlaioVideoModalComponent>(VlaioVideoModalComponent, {
			disableClose: false
		}).component;

		// Iben: Check if the marketing cookies are accepted
		this.cookieService
			.hasAcceptedCategory('marketing')
			.pipe(
				take(1),
				tap((hasAccepted) => {
					component.hasCookieConsent = hasAccepted;
				})
			)
			.subscribe();

		component.videoData = {
			...this.videoData,
			video: this.sanitizer.bypassSecurityTrustResourceUrl(this.videoData.video as string)
		};
		component.translations = this.translations;

		// Wouter: handle the cookie accepting
		component.cookieAccepted
			.pipe(
				take(1),
				tap(() => {
					this.cookieService.acceptCategory('marketing');
					this.handleButtonClick();
				})
			)
			.subscribe();

		// Wouter: Handle the closing of the modal on button press.
		component.handleClose
			.pipe(
				take(1),
				tap(() => {
					this.modalService.closeModal();
				})
			)
			.subscribe();
	}
}
