import { StoreService } from '@studiohyperdrive/ngx-store';
import { ObservableRecord } from '@studiohyperdrive/rxjs-utils';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

export abstract class AbstractFetchByIdService<
	DataType,
	DataId extends string | number | symbol = string
> extends StoreService {
	/**
	 * The data record in which we will store the items locally after fetching it from the backend
	 */
	protected abstract get dataRecord$(): ObservableRecord<DataType, DataId>;
	/**
	 * A method to fetch the data from the API in case it isn't available in the record yet
	 *
	 * @param id - The id of the item we want to store in the record
	 */
	protected abstract fetchForDataRecord(id: DataId): Observable<DataType>;

	/**
	 * A method to update the data record
	 *
	 * @param  dataRecord - The updated data record after fetching
	 * @memberof AbstractFetchByIdService
	 */
	protected abstract updateDataRecord(dataRecord: Record<DataId, DataType>): void;

	/**
	 * Fetches an item by id, checking if it's available in the existing record and fetching it from the api in case it isn't
	 *
	 * @param id - The id of the item
	 */
	public fetchById(id: DataId): Observable<DataType> {
		// Iben: Fetch the data record
		return this.dataRecord$.pipe(
			take(1),
			switchMap((dataRecord) => {
				const record = (dataRecord || {}) as Record<DataId, DataType>;
				// Iben: If the item already exists in the record, we return the item
				return record[id]
					? of(record[id])
					: // Iben: If the item doesn't exist in the record, we fetch it from the API
						this.fetchForDataRecord(id).pipe(
							tap((result) => {
								// Iben: We update the record and send it to the data record for updating
								this.updateDataRecord({
									...record,
									[id]: result
								});
							})
						);
			})
		);
	}
}
