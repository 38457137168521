<vlaio-content [loading]="loading">
	@if (products?.length > 0) {

	<ul class="u-reset-list">
		@for (product of products; track product) {
		<li class="c-measures__list-item">
			<vlaio-product-result
				#product
				[product]="product"
				[recommendationRating]="recommendations[product.id]"
				(recommendationRated)="recommendationRated.emit({ productId: product.id, rating: $event })"
				(ctaClicked)="ctaClicked.emit($event)"
			>
			</vlaio-product-result>
		</li>
		}
	</ul>

	@if (hasNextPage) {

	<vlaio-content [loading]="moreLoading" [label]="i18nKeys.Products.Loading.Products | translate"></vlaio-content>
	<vlaio-button
		class="more-button"
		[title]="i18nKeys.Products.Actions.LoadMore | translate"
		(handleClick)="loadMoreClicked.emit()"
	>
		{{ i18nKeys.Products.Actions.LoadMore | translate }}
	</vlaio-button>

	} } @else {

	<div class="c-search-results__emptystate">
		@if (!isEmptyAfterFilteredSearch) {

		{{ i18nKeys.Products.Empty | translate }}

		} @else {

		<p>
			{{ i18nKeys.Products.EmptyAfterFilteredSearch | translate }}
		</p>
		<a
			class="u-link__underline"
			cypressTag="Offers.Products.SearchWithoutFilters"
			(click)="searchWithoutFiltersClicked.emit()"
		>
			{{ i18nKeys.Products.Actions.SearchWithoutFilters | translate }}
		</a>

		}
	</div>

	}
</vlaio-content>
