import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { RecommendationRating } from '@vlaio/shared/types';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-product-rating',
	templateUrl: './product-rating.component.html',
	styleUrl: './product-rating.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule]
})
export class ProductRatingComponent {
	private rating: RecommendationRating;

	@HostBinding('class') private readonly rootClass = 'c-measure__feedback c-feedback';

	@Input() public set recommendationRating(rating: RecommendationRating) {
		this.rating = rating;
		this.isNegativeRating = rating === RecommendationRating.NEGATIVE;
		this.isPositiveRating = rating === RecommendationRating.POSITIVE;
	}

	@Output() public recommendationRated = new EventEmitter<RecommendationRating>();

	public isPositiveRating: boolean = false;
	public isNegativeRating: boolean = false;
	public i18nKeys = I18nKeys.Recommendations.Rating;

	public givePositiveRating() {
		this.rateRecommendation(RecommendationRating.POSITIVE);
	}

	public giveNegativeRating() {
		this.rateRecommendation(RecommendationRating.NEGATIVE);
	}

	private rateRecommendation(newRating: RecommendationRating): void {
		if (this.rating === newRating) {
			this.recommendationRated.emit(RecommendationRating.NEUTRAL);

			return;
		}

		this.recommendationRated.emit(newRating);
	}
}
