<vlaio-table-wrapper [data]="permits || []">
	<ng-template #tableTmpl>
		<ngx-table
			highlightKey="isNew"
			cypressTag="NgxTable.Table.General"
			[class.is-minimal]="view === 'minimal'"
			[columns]="columns"
			[data]="permits"
			[showSelectedOpenRow]="true"
		>
			<ngx-table-cell column="isRenewable" [cypressDataTags]="{ cell: cypressTags.NgxTable.Table.Cells.Warning }">
				<ng-template #headerTmpl></ng-template>
				<ng-template #cellTmpl let-isRenewable>
					@if(isRenewable) {
					<vlaio-icon icon="icon-alert" [toolTip]="i18nKeys.Permits.Table.isRenewable | translate">
					</vlaio-icon>
					}
				</ng-template>
			</ngx-table-cell>

			<ngx-table-cell column="title" [cypressDataTags]="{ cell: cypressTags.NgxTable.Table.Cells.PermitName }">
				<ng-template #headerTmpl>
					{{ i18nKeys.Permits.Table.FileName | translate }}
				</ng-template>

				<ng-template #cellTmpl let-item>
					@if(view === 'all') {
					{{ item }}
					} @else {
					<span class="minimal-title" matTooltipClass="c-mat-tooltip" [matTooltip]="item">{{ item }}</span>
					}
				</ng-template>
			</ngx-table-cell>

			<ngx-table-cell
				column="referenceNumber"
				[cypressDataTags]="{ cell: cypressTags.NgxTable.Table.Cells.PermitNumber }"
			>
				<ng-template #headerTmpl>
					{{ i18nKeys.Permits.Table.FileNumber | translate }}
				</ng-template>
				<ng-template #cellTmpl let-item let-permit="row">
					@if(permit.referenceNumber) {
					{{ item }}
					} @else {
					<ng-template [ngTemplateOutlet]="noInfoTmpl"> </ng-template>
					}
				</ng-template>
			</ngx-table-cell>

			<ngx-date-table-cell
				column="recentChangeDate"
				rowKey="status.recentChangeDate"
				[cypressDataTags]="{ cell: cypressTags.NgxTable.Table.Cells.LastChange }"
				[emptyLabel]="i18nKeys.Common.Empty.NoData | translate"
			>
				<ng-template #headerTmpl>
					{{ i18nKeys.Permits.Table.RecentChange | translate }}
				</ng-template>
			</ngx-date-table-cell>

			<ngx-table-cell
				column="visibility"
				[cypressDataTags]="{ cell: cypressTags.NgxTable.Table.Cells.Visibility }"
			>
				<ng-template #headerTmpl>
					{{ i18nKeys.Permits.Table.Visibility.Title | translate }}
				</ng-template>
				<ng-template #cellTmpl let-row="row">
					<span
						class="c-is-public"
						matTooltipPosition="right"
						[matTooltip]="
							i18nKeys.Permits.Table.Visibility[row.isPublic ? 'Public' : 'Private'] | translate
						"
					>
						{{ i18nKeys.Permits.Table[row.isPublic ? 'Public' : 'Private'] | translate }}
					</span>
				</ng-template>
			</ngx-table-cell>

			<vlaio-organization-number-table-cell
				column="assignedTo"
				[cypressDataTags]="{ cell: cypressTags.NgxTable.Table.Cells.CompanyNumber }"
			>
				<ng-template #cellTmpl let-item let-permit="row">
					@if(permit.assignedTo) {
					{{ item }}
					} @else {
					<ng-template [ngTemplateOutlet]="noInfoTmpl"> </ng-template>
					}
				</ng-template>
			</vlaio-organization-number-table-cell>

			<ng-template #noInfoTmpl>
				<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>
			</ng-template>

			<ng-template #openRowStateTmpl let-open>
				<vlaio-toggle-open-table-cell [isRowOpen]="open" />
			</ng-template>

			@if(view === 'all') {
			<ng-template #detailRowTmpl let-item>
				<ng-template [ngTemplateOutlet]="permitDetailTmpl" [ngTemplateOutletContext]="{ $implicit: item }">
				</ng-template>
			</ng-template>
			}
		</ngx-table>
	</ng-template>

	<ng-template #listItemTmpl let-item>
		<ng-template [ngTemplateOutlet]="permitDetailTmpl" [ngTemplateOutletContext]="{ $implicit: item }">
		</ng-template>
	</ng-template>
</vlaio-table-wrapper>

<ng-template #permitDetailTmpl let-permit>
	<permits-detail
		[view]="view"
		[readOnly]="readOnly"
		[addresses]="addresses"
		[permit]="permit"
		(caseButtonClicked)="caseButtonClicked.emit($event)"
		(permitButtonClicked)="permitButtonClicked.emit($event)"
	/>
</ng-template>
