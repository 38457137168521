import {
	Directive,
	ContentChildren,
	QueryList,
	ContentChild,
	OnDestroy,
	ChangeDetectorRef,
	AfterContentChecked
} from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { fromEvent, Subject, merge } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { CheckBoxComponent } from '../components';

@Directive({
	selector: '[checkboxGroup]',
	standalone: true
})

/**
 * Directive to allow for a "check all" checkbox spanning multiple checkboxes
 */
export class CheckboxGroupDirective implements AfterContentChecked, OnDestroy {
	private destroy$ = new Subject();

	/**
	 * List of all controls rendered in the container on which we put the directive
	 * These all need to be rendered (if you wish to hide them use [hidden]) for the directive to work properly
	 */
	@ContentChildren(NgControl, { descendants: true }) public controls: QueryList<NgControl>;
	/**
	 * List of all individual checkbox components attached to a form control
	 */
	@ContentChildren('individualCheckBox', { descendants: true }) public checkBoxes: QueryList<CheckBoxComponent>;
	/**
	 * The overarching checkbox that will handle the "check all" functionality
	 */
	@ContentChild('groupCheckbox', { static: false }) public groupCheckbox: CheckBoxComponent;

	private groupCheckboxChanges$: ObservableBoolean;

	constructor(private readonly cdRef: ChangeDetectorRef) {}

	/**
	 * Sets up listener based on the change event of the group checkbox as it doesn't have a formControl
	 */
	private setGroupCheckBoxChangesListener() {
		this.groupCheckboxChanges$ = fromEvent(this.groupCheckbox.input.nativeElement, 'change').pipe(
			map((event: { target: { checked: boolean } }) => event.target.checked),
			takeUntil(this.destroy$)
		);
	}

	/**
	 * Update the partialChecked and value of the group checkbox
	 *
	 * @param controls - A list of all the controls influencing the group checkbox
	 */
	private updateGroupCheckbox(controls: AbstractControl[]): void {
		// Iben: Check if the controls are all or partially checked
		const allChecked = controls.every((control) => control.value);
		const isPartialChecked = controls.some((control) => control.value);

		// Iben: Set the group checkbox to partially checked for individual styling
		this.groupCheckbox.isPartialChecked = !allChecked && isPartialChecked;

		// Iben: Set the value of the group checkbox
		this.groupCheckbox.onChange(allChecked);
		this.groupCheckbox.markForCheck();
	}

	public ngAfterContentChecked(): void {
		const controls = this.controls.toArray().map(({ control }) => control);

		// Iben: Reset the updateGroupCheckBox in case a new set of controls have been generated
		if (controls.length > 0) {
			// Iben: Check if the controls are all or partially checked
			const allChecked = controls.every((control) => control.value);
			const isPartialChecked = controls.some((control) => control.value);

			this.groupCheckbox.isPartialChecked = !allChecked && isPartialChecked;

			// Iben: If the controls value is different from the grouped, update the grouped
			if (allChecked !== this.groupCheckbox.isChecked) {
				this.groupCheckbox.onChange(allChecked);
				this.groupCheckbox.markForCheck();
			}
		}

		// Iben: If the groupcheckBoxChanges is already setup or the controls array is empty, return
		if (controls.length === 0 || Boolean(this.groupCheckboxChanges$)) {
			return;
		}

		// Iben: Set up the change listener for the group checkbox
		this.setGroupCheckBoxChangesListener();

		if (controls.length === 0) {
			return;
		}

		// Iben: Set the initial value of the group checkbox
		this.updateGroupCheckbox(controls);

		// Iben: Listen to the groupCheckbox changes and patch the controls accordingly
		this.groupCheckboxChanges$
			.pipe(
				tap((checked) => {
					for (const [index, control] of controls.entries()) {
						// Iben: Skip the control if disabled
						if (control.disabled) {
							continue;
						}

						// Iben: Check if the checkboxes have their own specific value or are just true/false
						const value = this.checkBoxes.toArray()[index].value;
						const controlValue = value ? (checked ? value : '') : checked;

						// Iben: Update the individual checkboxes
						control.patchValue(controlValue, { emitEvent: false });
						control.markAsDirty();
					}
				})
			)
			.subscribe();

		// Iben:  Update the groupCheckbox when all controls are checked
		const controlChanges = controls.map((control) => control.valueChanges);

		merge(...controlChanges)
			.pipe(
				tap(() => {
					this.updateGroupCheckbox(controls);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
