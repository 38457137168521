import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxTooltipDirective, NgxTooltipPosition } from '@studiohyperdrive/ngx-inform';

import { VlaioIconType } from './icon.const';

@Component({
	selector: 'vlaio-icon',
	templateUrl: './icon.component.html',
	standalone: true,
	imports: [NgxTooltipDirective],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlaioIconComponent {
	/**
	 * The string of the icon to be displayed.
	 */
	@Input({ required: true }) public icon: string;
	/**
	 * Whether the icon is solid or regular.
	 *
	 * Default value is `solid`
	 */
	@Input() public iconType: `${VlaioIconType}` = VlaioIconType.Solid;

	/**
	 * The text to be displayed in the tooltip.
	 */
	@Input() public toolTip: string;
	/**
	 * The position of the tooltip towards the icon.
	 *
	 * Default value is `above`
	 */
	@Input() public toolTipPosition: NgxTooltipPosition = 'above';
}
