import { SlicePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { FocusClickDirective, LimitToPipe } from '@studiohyperdrive/ngx-utils';
import * as clean from 'obj-clean';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { BrowserService } from '@vlaio/shared/core';
import {
	FacetEntity,
	FacetFilter,
	FacetFilterForm,
	NonRefinableFacetSubjectEntity,
	RefinableFacetSubjectEntity
} from '@vlaio/shared/types';
import { VlaioButtonComponent, VlaioContentComponent } from '@vlaio/shared/ui/common';
import { VlaioCheckBoxComponent, CheckboxGroupDirective, I18nKeys } from '@vlaio/shared/ui/forms';
import { filterFacetsOnAmountChecked } from '@vlaio/shared/utils';

@Component({
	selector: 'vlaio-facet-filter',
	templateUrl: './facet-filter.component.html',
	styleUrls: ['./facet-filter.component.scss'],
	standalone: true,
	imports: [
		NgxI18nModule,
		SlicePipe,
		VlaioButtonComponent,
		ReactiveFormsModule,
		VlaioCheckBoxComponent,
		CypressTagDirective,
		VlaioContentComponent,
		LimitToPipe,
		FocusClickDirective,
		CheckboxGroupDirective
	]
})
// Iben: IMPORTANT DISCLAIMER:
// In the template there are two instances where [hidden] is used instead of *ngIf. It is important for the functionality
// of the grouped checkboxes. A regular *ngIf will not work
export class FacetFilterComponent implements OnDestroy {
	/**
	 * The root class of the component
	 */
	@HostBinding('class') private readonly rootClass = 'c-filter';

	/**
	 * Whether or not the filters are open
	 */
	@HostBinding('class.is-open') public isOpen: boolean = false;

	/**
	 * Whether or not we are on desktop or on mobile, by default false
	 */
	@Input() public isDesktop = false;

	/**
	 * Whether or not we wish to be able to clear all facets, by default false
	 */
	@Input() public enableClear: boolean = false;

	/**
	 * An array of facets
	 */
	@Input() public set facets(facets: FacetEntity[]) {
		// Iben: Set the facets in a property we can still access later
		this.facetsArray = filterFacetsOnAmountChecked(facets || []);
		this.formArrayMap = {};
		this.form = this.formBuilder.group({});
		this.form.enable({ emitEvent: false });
		const hasAtLeastOneFacetWithItems = this.facetsArray.some((item) => item.items.length > 0);
		this.isEmpty = false;

		// Iben: If there are no facets, we create an empty group
		if (!this.facetsArray || this.facetsArray.length === 0 || !hasAtLeastOneFacetWithItems) {
			this.isEmpty = true;

			return;
		}

		// Iben: Loop over each facet to make a checkbox block
		for (const facet of this.facetsArray) {
			this.generateFormControlsForFacets(
				facet.id,
				// Iben: If the facet is refinable, we need to get all the items from the refinements
				!facet.isRefinable
					? facet.items
					: (this.convertRefinementsToSubjects(facet.items as any, facet.id) as any)
			);

			// Iben: If a facet is non refinable, we setup a record for the showMore and set the limit for all of the items
			if (!facet.isRefinable) {
				// Iben: Update record for the showAllFilters. Use the previous value when possible, so the filters don't close when checking a filter
				this.showAllFilters[facet.id] =
					this.showAllFilters[facet.id] || facet.items.length <= this.showMoreLimit;

				// Iben: Calculate the amount of check items so we know how many items we need to show.
				const limit = facet.items.reduce((result, item) => result + (item['checked'] ? 1 : 0), 0);
				this.filterLimitByFacet[facet.id] = this.showMoreLimit > limit ? this.showMoreLimit : limit;
			}
		}
	}

	/**
	 * The loading state of the facets
	 */
	@Input() public set loading(value: boolean) {
		this.isLoading = value;

		// Iben: Disable the form when loading, as filters affect each other
		value ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });
	}

	/**
	 * Whether the clear all was clicked
	 */
	@Output() private readonly clearClicked = new EventEmitter<void>();

	/**
	 * Whether or not the filters were changed
	 */
	@Output() private readonly filtersChanged = new EventEmitter<FacetFilter>();

	/**
	 * The click on an individual facet, often used for tracking
	 */
	@Output() private readonly facetClicked = new EventEmitter<string>();

	/**
	 * Overarching form
	 */
	public form: FormGroup<FacetFilterForm> = this.formBuilder.group({});
	/**
	 * Map for easy template access of the form arrays by facet id
	 */
	public formArrayMap: Record<string, FormArray<FormControl<string>>> = {};
	/**
	 * Map for easy template access of which form controls of the form array are part of a specific sub-facet, saved by facet and sub-facet id
	 */
	public refinementIndexMap: Record<string, Record<string, { start: number; end: number }>> = {};
	/**
	 * Map for easy template access to see if all subjects of a refinement should be shown, saved by facet and sub-facet id
	 */
	public showAllRefinements: Record<string, Record<string, boolean>> = {};
	/**
	 * Map for easy template access to see if all subjects of a filter should be shown, saved by facet id
	 */
	public showAllFilters: Record<string, boolean> = {};
	/**
	 * Map for easy template access to see if all to how many items we should limit a filter
	 */
	public filterLimitByFacet: Record<string, number> = {};
	/**
	 * Map for easy template access to get the sorted refinement subjects by facet and sub-facet id
	 */
	public sortedRefinements: Record<string, Record<string, NonRefinableFacetSubjectEntity[]>> = {};
	/**
	 * Map for easy template access to get the show state of the refinements by facet and sub-facet id
	 */
	public refinementsOfSubFacetsShown: Record<string, Record<string, boolean>> = {};
	/**
	 * Map for easy template access to get the all selected state of the refinements by facet and sub-facet id
	 */
	public allRefinementsOfSubFacetSelected: Record<string, Record<string, boolean>> = {};
	/**
	 * Array of all the facets
	 */
	public facetsArray: FacetEntity[] = [];
	/**
	 * How many elements the template can show if it isn't set to showAll
	 */
	public isLoading: boolean = false;
	public isEmpty: boolean = false;
	public readonly showMoreLimit: number = 5;
	public readonly i18nKeys = I18nKeys;

	constructor(
		private readonly formBuilder: FormBuilder,
		public readonly elementRef: ElementRef,
		private readonly browserService: BrowserService
	) {}

	/**
	 * Emit the changes in the selected filters
	 */
	public changed(facetLabel: string, checked: boolean) {
		// Iben: Use setTimeout to get the values of the form value from the next tick, needed for the grouped checkbox
		setTimeout(() => this.filtersChanged.emit(clean(this.form.value, { preserveArrays: false })), 0);

		// Iben: When a user clicks the facet, we emit the label for GA tracking
		if (checked) {
			this.facetClicked.emit(facetLabel);
		}
	}

	/**
	 * Toggle the open state of the filter. A param can be added to focus an specific element after the toggle.
	 * Depending on the state of the toggle and wether the component gets rendered in desktop mode or not, an overflow
	 * class is either added or removed to/from the body.
	 *
	 * @param element - The template variable that needs focus when closing or opening the facet filters *- Optional*.
	 */
	public toggleIsOpen(): void {
		this.isOpen = !this.isOpen;

		const className = this.isDesktop ? 'u-overflow-y--hidden--vp12' : 'u-overflow-y--hidden';
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.body.classList.toggle(className, this.isOpen);
		});
	}

	/**
	 * Show all the hidden refinements for a sub-facet
	 *
	 * @param facetId - Id of the facet
	 * @param subFacetId - Id of the sub-facet
	 */
	public showAllForSubFacet(facetId: string, subFacetId: string): void {
		this.showAllRefinements[facetId][subFacetId] = !this.showAllRefinements[facetId][subFacetId];
	}

	/**
	 * Show all the hidden filters for a facet
	 *
	 * @param facetId - Id of the facet
	 */
	public showAllForFacet(facetId: string): void {
		this.showAllFilters[facetId] = !this.showAllFilters[facetId];
	}

	/**
	 * Show/hide all the refinements of a sub facet
	 *
	 * @param facetId - Id of the facet
	 * @param subFacetId - Id of the sub-facet
	 */
	public toggleRefinementsOfSubFacet(facetId: string, subFacetId: string): void {
		this.refinementsOfSubFacetsShown[facetId][subFacetId] = !this.refinementsOfSubFacetsShown[facetId][subFacetId];
	}

	/**
	 * Emit a cleared event to the parent
	 */
	public clearFilters(): void {
		this.clearClicked.emit();
	}

	/**
	 * Generates a form control based upon the facet subject
	 *
	 * @param subject - Subject for which we make a form control
	 */
	private createItem(subject: NonRefinableFacetSubjectEntity): FormControl<string> {
		return this.formBuilder.control(subject.checked ? subject.id : '');
	}

	/**
	 * Generate and adds the form controls for each facet to the form
	 *
	 * @param id - The id of the facet
	 * @param subjects - The selectable subjects of a facet
	 */
	private generateFormControlsForFacets(id: string, subjects: NonRefinableFacetSubjectEntity[]): void {
		const formArray = this.formBuilder.array(
			subjects.map((facetSubject) => this.createItem(facetSubject as NonRefinableFacetSubjectEntity))
		);
		// Iben: Add a control to the formGroup to add all checkboxes
		this.form.addControl(id, formArray);

		// Iben: Add the formArray to the formArrayMap for optimized access in template
		this.formArrayMap[id] = formArray;
	}

	/**
	 * Converts the refinable subjects to an array of their refined subjects
	 *
	 * @param items - An array of refinements
	 * @param facetId - The id of the parent facet
	 */
	private convertRefinementsToSubjects(
		items: RefinableFacetSubjectEntity[],
		facetId: string
	): NonRefinableFacetSubjectEntity[] {
		// Iben: Set the start values
		let start = 0;
		const result = [];

		// Iben: Loop over all refinable subjects
		for (const { id, refinements } of items) {
			// Iben: Check if some of the items are checked
			const someRefinementsAreSelected: boolean = refinements.some((item) => item['checked']);

			// Iben: Setup the show all refinements map
			this.showAllRefinements[facetId] = {
				...(this.showAllRefinements[facetId] || {}),
				[id]: this.showAllRefinements[facetId] ? Boolean(this.showAllRefinements[facetId][id]) : false
			};

			// Iben: setup the refinements of SubFacet map
			this.refinementsOfSubFacetsShown[facetId] = {
				...(this.refinementsOfSubFacetsShown[facetId] || {}),
				[id]: someRefinementsAreSelected
			};

			// Iben: setup the selectedState of SubFacet map
			const allRefinementsAreSelected: boolean = refinements.every((item) => item['checked']);
			this.allRefinementsOfSubFacetSelected[facetId] = {
				...(this.allRefinementsOfSubFacetSelected[facetId] || {}),
				[id]: allRefinementsAreSelected
			};

			// Iben: Sort the refined items by checked status
			const sortedRefinements = [...refinements].sort((a, b) => {
				// Iben: If either of the option does not exist, return 0
				if (!b || !a) {
					return 0;
				}

				// Iben: If both items are checked, keep the current order
				if (a['checked'] == b['checked']) {
					return 0;
				}

				// Iben: Sort by checked state
				return a['checked'] ? -1 : 1;
			});

			// Iben: Store the sorted refinements in a map for easy access in the template
			this.sortedRefinements[facetId] = {
				...(this.sortedRefinements[facetId] || {}),
				[id]: sortedRefinements as NonRefinableFacetSubjectEntity[]
			};

			// Iben: Generate an index map so we can correctly get the controls in the template
			const end = start + refinements.length;

			this.refinementIndexMap[facetId] = {
				...(this.refinementIndexMap[facetId] || {}),
				[id]: {
					start,
					end
				}
			};

			start = end;

			// Iben: Push the refinements to the result
			result.push(...sortedRefinements);
		}

		return result;
	}

	public ngOnDestroy(): void {
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Wouter: both overflow styles need to be removed in case user detroys component by scaling
			browserDocument.body.classList.remove('u-overflow-y--hidden', 'u-overflow-y--hidden--vp12');
		});
	}
}
