// Iben: Types to type all paths of an object

// See: https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object/58436959#58436959
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

type Join<K, P> = K extends string | number
	? P extends string | number
		? `${K}${'' extends P ? '' : '.'}${P}`
		: never
	: never;

type Paths<T, D extends number = 10> = [D] extends [never]
	? never
	: T extends object
	? {
			[K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
	  }[keyof T]
	: '';

const HomeTags = {
	Home: {
		Nav: {
			Home: 'home-nav-home',
			Offers: 'home-nav-offers',
			AboutUs: 'home-nav-about-us',
			Partners: 'home-nav-partners',
			ELoket: 'home-nav-e-loket',
			Mandates: 'home-nav-mandates'
		},
		Hero: {
			Block: 'home-hero-block',
			Login: 'home-hero-button-login',
			Offers: 'home-hero-button-offers',
			ELoket: 'home-hero-button-e-loket'
		},
		Carousel: {
			Block: 'home-carousel-block'
		},
		Cases: {
			Block: 'home-cases-block'
		},
		Company: {
			Block: 'home-company-block'
		},
		Recommendations: {
			Block: 'home-recommendations-block',
			ELoket: 'home-recommendations-e-loket',
			Login: 'home-recommendations-login'
		},
		PreFooter: {
			Block: 'home-pre-footer-block'
		},
		Footer: {
			Block: 'home-footer-block',
			AboutUs: 'home-footer-about-us',
			Link: 'home-footer-link',
			Cookies: 'home-footer-cookies'
		},
		Notification: 'home-notification'
	},
	Partners: {
		Banner: 'partners-banner',
		Content: {
			Block: 'partners-content-block',
			CardList: {
				Block: 'partners-cardlist-block',
				Card: 'partners-cardlist-card'
			},
			Card: {
				Name: 'partners-card-name',
				Link: 'partners-card-link',
				Tag: 'partners-card-tag'
			},
			Button: 'partners-content-button',
			Description: 'partners-content-description'
		}
	},
	AboutUs: {
		Banner: 'about-us-banner',
		Content: 'about-us-content',
		Menu: {
			Block: 'about-us-menu-block',
			Link: 'about-us-menu-link',
			Submenu: {
				Block: 'about-us-menu-submenu-block',
				Link: 'about-us-menu-submenu-link'
			}
		}
	},
	Breadcrumbs: {
		Block: 'breadcrumbs-block',
		Breadcrumb: 'breadcrumbs-breadcrumb'
	}
};

const Offers = {
	Facets: {
		Block: 'offers-facets-block',
		RefinableFacet: 'offers-facets-refinable-facet',
		UnRefinableFacet: 'offers-facets-un-refinable-facet',
		RefinedFacet: 'offers-facets-refined-facet',
		RefinedFacetsBlock: 'offers-facets-refined-facets-block',
		Toggle: 'offers-facets-toggle',
		ShowMore: 'offers-facets-show-more',
		ShowResults: 'offers-facets-show-results',
		OpenFilter: 'offers-facet-open-filters'
	},
	Sorting: {
		Block: 'offers-sorting-block',
		Select: 'offers-sorting-select'
	},
	Products: {
		Block: 'offers-products-block',
		SearchWithoutFilters: 'offers-products-search-without-filters'
	},
	Search: {
		Block: 'offers-search-block',
		Input: 'offers-search-input',
		Button: 'offers-search-button',
		SearchQueryTag: 'offers-search-search-query-tag'
	},
	Buttons: {
		Block: 'offers-buttons-block',
		RemoveAll: 'offers-buttons-remove-all'
	},
	Product: {
		Title: 'offers-product-title',
		Partner: 'offers-product-partner',
		Description: 'offers-product-description',
		MoreInfo: 'offers-product-more-info',
		Login: 'offers-product-login',
		Block: 'offers-product-block',
		Info: 'offers-product-info',
		CTA: 'offers-product-cta'
	},
	Header: {
		Description: 'offers-header-description'
	}
};

const Status = {
	Title: 'status-title',
	Description: 'status-description',
	Button: 'status-button'
};

const Dashboard = {
	Hero: {
		Title: 'dashboard-hero-title',
		Description: 'dashboard-hero-description'
	},
	CTA: {
		EBox: {
			Title: 'dashboard-cta-e-box-title',
			Button: 'dashboard-cta-e-box-button',
			GoToButton: 'dashboard-cta-e-box-go-to-button'
		}
	},
	Cases: {
		Title: 'dashboard-cases-title',
		Button: 'dashboard-cases-button',
		Empty: 'dashboard-cases-empty'
	},
	Navigation: {
		Block: 'dashboard-navigation-block',
		Link: {
			Dashboard: 'dashboard-navigation-link-dashboard',
			Cases: 'dashboard-navigation-link-cases',
			Measures: 'dashboard-navigation-link-measures',
			Company: 'dashboard-navigation-link-company',
			Sanctions: 'dashboard-navigation-link-sanctions',
			Search: 'dashboard-navigation-link-search',
			Mandates: 'dashboard-navigation-link-mandates',
			Permits: 'dashboard-navigation-link-permits',
			Inbox: 'dashboard-navigation-link-inbox'
		}
	},
	Info: {
		Company: {
			Block: 'dashboard-info-company-block',
			Link: 'dashboard-info-company-link',
			Content: 'dashboard-inf-company-content'
		},
		Permits: {
			Block: 'dashboard-info-permits-block',
			Link: 'dashboard-info-permits-link',
			Content: 'dashboard-info-permits-content'
		},
		Cases: {
			Block: 'dashboard-info-cases-block',
			Link: 'dashboard-info-cases-link',
			Content: 'dashboard-info-cases-content'
		}
	},
	Links: {
		Products: 'dashboard-links-products',
		Cases: 'dashboard-links-cases',
		Inbox: 'dashboard-links-inbox',
		EBox: 'dashboard-links-ebox',
		RecommendedProducts: 'dashboard-links-recommended-products',
		RenewablePermits: 'dashboard-renewable-permits',
		NewPermits: 'dashboard-new-permits',
		GiverMandates: 'dashboard-giver-mandates',
		TakerMandates: 'dashboard-taker-mandates'
	},
	Dynamic: {
		Block: 'dashboard-dynamic-block',
		Title: 'dashboard-dynamic-title',
		Content: 'dashboard-dynamic-content',
		Stop: 'dashboard-dynamic-stop',
		Action: 'dashboard-dynamic-action'
	},
	Tour: {
		Start: 'dashboard-tour-start',
		Links: 'dashboard-tour-links',
		Info: 'dashboard-tour-info',
		Edit: 'dashboard-tour-edit',
		Link: 'dashboard-tour-link',
		Save: 'dashboard-tour-save'
	},
	Actions: {
		Configure: 'dashboard-actions-configure',
		Default: 'dashboard-actions-default',
		Custom: 'dashboard-actions-custom',
		Save: 'dashboard-actions-save',
		Cancel: 'dashboard-actions-cancel'
	}
};

const Cases = {
	Detail: {
		Title: 'cases-detail-title',
		Address: 'cases-detail-address',
		Product: 'cases-detail-product',
		Partner: {
			Name: 'cases-detail-partner-name',
			Contact: 'cases-detail-partner-contact'
		},
		Button: 'cases-detail-button',
		History: {
			Info: 'cases-detail-history-info',
			TimelineButton: 'cases-detail-history-timeline-button',
			Date: 'cases-detail-history-date'
		}
	},
	Table: {
		Block: 'cases-table-block',
		Row: 'cases-table-row'
	},
	Filters: {
		Block: 'cases-filters-block',
		Facet: 'cases-filters-facet',
		Search: 'cases-filters-search',
		Button: 'cases-filters-button',
		FacetSelect: 'cases-filters-facet-select'
	}
};

const Companies = {
	Search: {
		Table: 'companies-search-table',
		NameInput: 'companies-search-name-input',
		ZipCodeInput: 'companies-search-zip-codes',
		ZipCode: 'companies-search-zip-code',
		Button: 'companies-search-button'
	},
	Detail: {
		Link: 'companies-detail-link',
		Public: 'companies-detail-public',
		Activities: {
			Main: 'companies-detail-activities-main',
			Branch: 'companies-detail-activities-branch'
		},
		Branches: 'companies-detail-branches'
	}
};

const Redirects = {
	AuthenticationFailed: {
		Button: 'authentication-failed-button'
	}
};

const General = {
	Loading: 'general-loading',
	Toast: 'general-toast',
	Input: {
		Checkbox: 'general-input-checkbox',
		SearchResult: 'general-input-search-result',
		EmptySearchResult: 'general-input-empty-search-result',
		SearchInput: 'general-input-search-input',
		DropdownSearch: 'general-input-dropdown-search',
		SearchButton: 'general-input-search-button',
		DeleteSearchButton: 'general-input-delete-search-button',
		Radio: 'general-input-radio',
		RadioLabel: 'general-input-radio-label'
	},
	Table: {
		Empty: 'general-table-empty'
	},
	Toggle: 'general-toggle',
	FormError: 'general-form-error'
};

const Survey = {
	Question: 'survey-question',
	Answer: 'survey-answer',
	Save: 'survey-save',
	Cancel: 'survey-cancel'
};

const Mandates = {
	Landing: {
		Option: 'mandates-landing-block',
		Title: 'mandates-landing-title',
		Video: 'mandates-landing-vodeo',
		Description: 'mandates-landing-description',
		CTA: 'mandates-landing-cta'
	},
	Overview: {
		Item: 'mandates-overview-item',
		ItemName: 'mandates-overview-item-name',
		Rights: {
			AccessLevel: 'mandates-overview-rights-access-level'
		},
		Executors: {
			Title: 'mandates-overview-executors-title',
			Executor: 'mandates-overview-executors-executor',
			Empty: 'mandates-overview-executors-empty'
		},
		Date: {
			Item: 'mandates-overview-date',
			Start: 'mandates-overview-start',
			End: 'mandates-overview-end'
		},
		Status: 'mandates-overview-status',
		StatusDate: 'mandates-overview-status-date',
		Buttons: {
			EditButton: 'mandates-overview-buttons-edit-button',
			StopButton: 'mandates-overview-buttons-stop-button'
		},
		Timeline: {
			TimelineStep: 'mandates-overview-timeline-timelinestep'
		},
		Confirm: {
			Text: 'mandates-overview-confirm-text',
			ApproveButton: 'mandates-overview-confirm-approve-button',
			DeclineButton: 'mandates-overview-confirm-decline-button'
		},
		AddExecutors: 'mandates-overview-add-executors'
	},
	Wizard: {
		Buttons: {
			NewMandateButton: 'mandates-wizard-new-mandate-button',
			NextButton: 'mandates-wizard-next-button',
			PreviousButton: 'mandates-wizard-previous-button'
		},
		AddCompany: {
			Switch: {
				Giver: {
					Input: 'mandates-wizard-add-company-switch-giver-input',
					Label: 'mandates-wizard-add-company-switch-giver-label'
				},
				Taker: {
					Input: 'mandates-wizard-add-company-switch-taker-input',
					Label: 'mandates-wizard-add-company-switch-taker-label'
				}
			},
			CompanyDetails: 'mandates-wizard-add-company-company-details'
		},
		AddProducts: {
			Table: 'mandates-wizard-add-products-table',
			Search: 'mandates-wizard-add-products-search',
			SelectTable: 'mandates-wizard-add-products-select-table',
			SelectedTable: 'mandates-wizard-add-products-selected-table',
			DeleteButton: 'mandates-wizard-add-products-delete-button',
			Filter: {
				Block: 'mandates-wizard-add-products-filter-block',
				Facet: 'mandates-wizard-add-products-filter-facet'
			}
		},
		AddDuration: {
			GeneralDuration: 'mandates-wizard-add-duration-general-duration',
			AddAllButton: 'mandates-wizard-add-duration-add-all-button',
			Table: 'mandates-wizard-add-duration-table',
			TableError: 'mandates-wizard-add-duration-table-error',
			Validating: 'mandates-wizard-add-duration-validating'
		},
		SendMandates: {
			StartDate: 'mandates-wizard-send-mandates-start-date',
			EndDate: 'mandates-wizard-send-mandates-end-date',
			Reasoning: 'mandates-wizard-send-mandates-reasoning',
			DeleteProduct: 'mandates-wizard-send-mandates-delete-product',
			NoContent: {
				Block: 'mandates-wizard-send-mandates-no-content-block',
				Button: 'mandates-wizard-send-mandates-no-content-button'
			}
		},
		RiskLegend: 'mandates-wizard-risk-legend',
		CurrentMandateOverview: 'mandates-wizard-current-mandate-overview',
		Result: {
			SuccessText: 'mandates-wizard-result-success-text',
			NoSuccessText: 'mandates-wizard-result-no-success-text'
		}
	},
	Search: {
		Search: 'mandates-overview-search-search',
		SearchButton: 'mandates-overview-search-search-button',
		ZipCode: 'mandates-overview-search-zip-code'
	},
	Duration: {
		StartInput: 'mandates-duration-start-input',
		EndInput: 'mandates-duration-end-input',
		StartInputError: 'mandates-duration-start-input-error',
		EndInputError: 'mandates-duration-end-input-error',
		ChronologicalError: 'mandates-duration-chronological-error'
	},
	NoContent: 'mandates-no-content'
};

const Executors = {
	Overview: {
		Status: 'executors-overview-status',
		Executor: 'executors-overview-executor',
		SortExecutor: 'executors-overview-executor-sort',
		ActiveMandates: 'executors-overview-active-mandates',
		InactiveMandates: 'executors-overview-inactive-mandates',
		AllMandates: 'executors-overview-all-mandates',
		Add: 'executors-overview-add',
		Stop: 'executors-overview-stop',
		Toggle: 'executors-overview-toggle',
		Filter: {
			Field: 'executors-overview-filter-field',
			Button: 'executors-overview-filter-button'
		},
		Detail: {
			Status: 'executors-overview-detail-status',
			Product: 'executors-overview-detail-product',
			Giver: 'executors-overview-detail-giver',
			StartMandate: 'executors-overview-detail-start-mandate',
			EndMandate: 'executors-overview-detail-end-mandate',
			StartExecutor: 'executors-overview-detail-start-executor',
			EndExecutor: 'executors-overview-detail-end-executor',
			StopExecutor: 'executors-overview-detail-stop-executor'
		}
	},
	Wizard: {
		AddExecutors: {
			SearchField: {
				Input: 'executors-wizard-add-executors-searchfield-input',
				Button: 'executors-wizard-add-executors-searchfield-button'
			},
			Tag: 'executors-wizard-add-executors-tag',
			Toggle: {
				Alphabetic: 'executors-wizard-add-executors-toggle-alphabetical',
				Mandates: 'executors-wizard-add-executors-toggle-mandates'
			},
			SelectedList: 'executors-wizard-add-executors-selected-list',
			SelectList: 'executors-wizard-add-executors-select-list'
		},
		SelectMandates: {
			Filter: 'executors-wizard-select-mandates-filter',
			Table: {
				Product: 'executors-wizard-select-mandates-table-product',
				Giver: 'executors-wizard-select-mandates-table-giver',
				ValidFrom: 'executors-wizard-select-mandates-table-valid-from',
				ValidTo: 'executors-wizard-select-mandates-table-valid-to'
			}
		},
		SetDuration: {
			UseMandateDuration: 'executors-wizard-set-duration-use-mandate-duration',
			UseProvidedDuration: 'executors-wizard-set-duration-use-provided-duration'
		},
		SendRequest: {
			Product: 'executors-wizard-send-request-product',
			Giver: 'executors-wizard-send-request-table-giver',
			ValidFrom: 'executors-wizard-send-request-table-valid-from',
			ValidTo: 'executors-wizard-send-request-table-valid-to',
			StartExecutor: 'executors-wizard-send-request-start-executor',
			EndExecutor: 'executors-wizard-send-request-end-executor',
			RemoveMandate: 'executors-wizard-send-request-remove-mandate',
			Executor: 'executors-wizard-send-request-executor',
			BackToStep: 'executors-wizard-send-request-back-to-step'
		},
		ConfirmRemove: 'executors-wizard-confirm-remove'
	}
};

const NgxTable = {
	Table: {
		General: 'ngx-table',
		// Only used in depricated tests, not needed anymore
		// -- START --
		Row: 'ngx-table-row',
		DetailRow: 'ngx-table-detail-row',
		ColumnHeader: 'ngx-table-column-header',
		ColumnHeaderSortable: 'ngx-table-column-header-sortable',
		// -- END --
		Headers: {
			CheckboxSelect: 'ngx-table-headers-checkbox-select'
		},
		Cells: {
			CompanyName: 'ngx-table-cells-company-name',
			CompanyAddress: 'ngx-table-cells-company-address',
			CompanyNumber: 'ngx-table-cells-company-number',
			RadioSelect: 'ngx-table-cells-radio-select',
			CheckboxSelect: 'ngx-table-cells-checkbox-select',
			ProductName: 'ngx-table-cells-product-name',
			Partner: 'ngx-table-cells-partner',
			RiskProfile: 'ngx-table-cells-risk-profile',
			StartInput: 'ngx-table-cells-start-input',
			EndInput: 'ngx-table-cells-end-input',
			StartDate: 'ngx-table-cells-start-date',
			EndDate: 'ngx-table-cells-end-date',
			LastChange: 'ngx-table-cells-last-change',
			OpenRow: 'ngx-table-cells-open-row',
			Trash: 'ngx-table-cells-trash',
			Edit: 'ngx-table-cells-edit',
			Warning: 'ngx-table-cells-warning',
			Visibility: 'ngx-table-cells-visibility',
			PermitName: 'ngx-table-cells-permit-name',
			PermitNumber: 'ngx-table-cells-permit-number',
			Status: 'ngx-table-cells-status',
			Giver: 'ngx-table-cells-giver',
			Taker: 'ngx-table-cells-taker',
			AccessLevel: 'ngx-table-cells-access-level',
			RiskLevel: 'ngx-table-cells-risk-level'
		}
	}
};

const Sanctions = {
	Table: {
		Number: 'sanctions-table-number',
		Case: 'sanctions-table-case',
		Authority: 'sanctions-table-authority',
		Organisation: 'sanctions-table-organisation',
		Date: 'sanctions-table-date'
	},
	Detail: {
		Indictment: {
			LegalGrounds: 'sanctions-detail-indictment-legal-grounds',
			Location: 'sanctions-detail-indictment-location',
			Period: 'sanctions-detail-indictment-period',
			Date: 'sanctions-detail-indictment-date'
		},
		Decision: {
			Decision: 'sanctions-detail-decision-decision',
			Offenses: 'sanctions-detail-decision-offenses',
			Sanction: 'sanctions-detail-decision-sanction',
			ExclusionOfEntitleMent: 'sanctions-detail-decision-exclusion-of-entitlement',
			Forfeit: 'sanctions-detail-decision-forfeit',
			Other: 'sanctions-detail-decision-other',
			Suspension: 'sanctions-detail-decision-suspension',
			Dropped: 'sanctions-detail-decision-dropped'
		},
		Info: {
			Case: 'sanctions-detail-info-case',
			Data: 'sanctions-detail-info-data',
			Document: 'sanctions-detail-info-document'
		}
	}
};

const Disclaimer = {
	Content: 'disclaimer-content'
};

const ProgressMeter = {
	Popup: {
		Title: 'progressmeter-popup-title',
		Subtitle: 'progressmeter-popup-subtitle'
	}
};

const Header = {
	Header: 'acm-header'
};

const Products = {
	Detail: {
		Heading: 'products-detail-heading',
		Sidebar: 'products-detail-sidebar',
		SidebarInfo: 'products-detail-sidebar-info',
		SidebarUserAction: 'products-detail-sidebar-user-action',
		MoreInfo: 'products-detail-more-info',
		Wysiwyg: 'products-detail-wysiwyg',
		ExtraCta: 'products-detail-extra-cta'
	}
};

const Shared = {
	AsideMenu: {
		Link: 'shared-aside-menu-link',
		SubMenuLink: 'shared-aside-menu-sub-menu-link',
		SubMenu: 'shared-aside-menu-sub-menu'
	},
	Ui: {
		InfoWrapper: {
			Title: 'shared-ui-info-wrapper-title',
			Count: 'shared-ui-info-wrapper-count'
		},
		LinkButton: {
			Count: 'shared-ui-link-button-count',
			Label: 'shared-ui-link-button-label'
		}
	},
	Tour: {
		Start: 'shared-tour-start',
		Title: 'shared-tour-title',
		Content: 'shared-tour-content',
		Next: 'shared-tour-next',
		Previous: 'shared-tour-previous',
		Close: 'shared-tour-close',
		Progress: 'shared-tour-progress'
	}
};

const UserStatus = {
	Anonymous: 'user-status-anonymous',
	User: {
		Name: 'user-status-user-name',
		FirstName: 'user-status-user-first-name',
		Type: 'user-status-user-type',
		Language: 'user-status-user-language',
		Code: 'user-status-user-code',
		Browser: 'user-status-user-browser'
	},
	Company: {
		Name: 'user-status-company-name',
		LegalRepresentative: 'user-status-company-legal-representative',
		MandateAdmin: 'user-status-company-mandate-admin',
		Sanctions: 'user-status-company-sanctions'
	},
	Meta: {
		Start: 'user-status-meta-start',
		End: 'user-status-meta-end',
		Duration: 'user-status-meta-duration',
		PreviousLogin: 'user-status-meta-previous-login',
		LastUsed: 'user-status-meta-last-used'
	},
	Mandate: {
		TakerName: 'user-status-mandate-taker-name',
		TakerNumber: 'user-status-mandate-taker-number',
		MandateList: 'user-status-meta-end'
	}
};

const Filters = {
	Container: 'filters-container',
	Block: 'filters-block',
	Facets: {
		List: 'filters-facets-list',
		ListBlock: 'filters-facets-list-block',
		ListItem: 'filters-facets-list-item',
		UnrefinableFacet: 'filters-facets-unrefinable-facet',
		RefinableFacet: 'filters-facets-refinable-facet',
		Toggle: 'filters.facets.toggle'
	},
	ShowMore: 'filters-show-more',
	RemoveAllFilters: 'filters-remove-all-filters'
};

const Permits = {
	Address: 'permits-address',
	Actions: {
		File: 'permits-actions-file',
		Permit: 'permits-actions-permit'
	},
	AssignedBy: 'permits-addigned-by',
	Dates: {
		End: 'permits-dates-end',
		Renewal: 'permits-dates-renewal',
		Start: 'permits-dates-start'
	},
	Description: 'permits-description',
	Product: 'permits-product',
	Title: 'permits-title'
};

const Modals = {
	Mandates: {
		StopMandate: {
			Reason: 'modals-mandates-stop-mandate-reason',
			CancelButton: 'modals-mandates-stop-mandate-cancel-button',
			StopButton: 'modals-mandates-stop-mandate-stop-button'
		}
	}
};

export const CypressTags = {
	...HomeTags,
	...Header,
	Cases,
	Companies,
	Dashboard,
	Disclaimer,
	Executors,
	Filters,
	General,
	Mandates,
	NgxTable,
	Offers,
	Permits,
	Products,
	ProgressMeter,
	Redirects,
	Shared,
	Status,
	Survey,
	UserStatus,
	Sanctions,
	Modals
};

export type CypressTagsPaths = Paths<typeof CypressTags>;
