import { Platform } from '@angular/cdk/platform';
import { Injectable, OnDestroy } from '@angular/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { PlatformEnum, PlatformKeys } from '../interfaces';

import { BrowserService } from './browser.service';

/**
 * Service to handle Progressive Web App (PWA) installation prompts and platform-specific code execution.
 */
@Injectable({ providedIn: 'root' })
export class PwaService implements OnDestroy {
	/**
	 * Subject to keep the current online status in
	 */
	private readonly isOnlineSubject$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	/**
	 * Destroyed state of the service to cancel subscriptions
	 */
	private readonly destroyed$ = new Subject();
	/**
	 * Record with platform status
	 */
	private readonly platformRecord: Record<PlatformKeys, boolean> = {
		IOS: this.platform.IOS,
		ANDROID: this.platform.ANDROID,
		DESKTOP: this.platform.isBrowser && this.deviceService.isDesktop()
	};
	/**
	 * Prompt handler for PWA installation prompt on Android
	 */
	private pwaInstallPrompt: any;

	/**
	 * Whether or not the application is online
	 */
	public isOnline$: ObservableBoolean = this.isOnlineSubject$.asObservable();

	constructor(
		private readonly platform: Platform,
		private readonly browserService: BrowserService,
		private deviceService: DeviceDetectorService
	) {
		this.browserService.runInBrowser(({ browserWindow }) => {
			// Iben: Handle the on and offline status of the application
			fromEvent(browserWindow, 'online')
				.pipe(
					tap(() => {
						this.isOnlineSubject$.next(true);
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();

			fromEvent(browserWindow, 'offline')
				.pipe(
					tap(() => {
						this.isOnlineSubject$.next(false);
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		});
	}

	ngOnDestroy(): void {
		// Iben: Handle the onDestroy flow to cancel the subscription
		this.destroyed$.next(undefined);
		this.destroyed$.complete();
	}

	/**
	 * Returns the current platform of the application
	 */
	public getCurrentPlatform(): PlatformEnum {
		// Iben: Returns the enum value of the current platform by reducing the record to the only true value
		return PlatformEnum[
			Object.entries(this.platformRecord)
				.filter(([, isPlatform]) => isPlatform)
				.map(([key]) => key)[0]
		];
	}

	/**
	 * Returns whether the provided platform matches the current platform
	 *
	 * @param key - The provided platform
	 */
	public isPlatform(key: PlatformKeys): boolean {
		return this.platformRecord[key];
	}

	/**
	 * Captures the beforeinstallprompt event from a PWA and allows us to use it later to prompt an install window on Android
	 *
	 * @memberof PwaService
	 */
	public capturePwaInstallPromptEvent() {
		// Iben: Catch the prompt event and set the event in the property so we can call it later
		this.browserService.runInBrowser(({ browserWindow }) => {
			browserWindow.addEventListener('beforeinstallprompt', (event: Event) => {
				// Iben: Prevent the default behavior of the prompt
				event.preventDefault();

				this.pwaInstallPrompt = event;
			});
		});
	}

	/**
	 * Prompt the user with a "install pwa" prompt on Android
	 */
	public promptPwaInstall() {
		// Iben: Early exit in case the prompt even was not intercepted
		if (!this.pwaInstallPrompt) {
			return;
		}

		// Iben: Return the prompt event as observable
		this.pwaInstallPrompt.prompt();
	}

	/**
	 * Run a specific piece of code only on a specific platform
	 *
	 * @param platform - The platform on which we want to execute the callback
	 * @param callBack - Callback we want to execute in case we are on the correct platform
	 */
	public runOnPlatform(platform: PlatformKeys, callBack: () => void) {
		// Iben: Early exit in case we are not on the correct platform
		if (this.isPlatform(platform)) {
			return;
		}

		// Iben: Execute callback
		callBack();
	}

	/**
	 * Whether or not a PWA functionality is provided by the browser
	 */
	public get hasPwaInstallFunctionality(): boolean {
		return this.isPlatform('ANDROID') && this.deviceService.browser === 'Chrome';
	}
}
