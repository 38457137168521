import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HasObserversPipe } from '@studiohyperdrive/ngx-utils';

import { CypressTags } from '@vlaio/cypress/shared';
import { clickOnTranslationLink } from '@vlaio/shared/utils';

import { I18nKeys } from '../../i18n';
import { EmptySearchResultTextEntity } from '../../types';
import { VlaioIconComponent } from '../vlaio-icon/vlaio-icon.component';

@Component({
	selector: 'vlaio-empty-search-result',
	templateUrl: './empty-search-result.component.html',
	styleUrls: ['./empty-search-result.component.scss'],
	standalone: true,
	imports: [VlaioIconComponent, TranslateModule, HasObserversPipe]
})
export class EmptySearchResultComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys.EmptySearch = I18nKeys.EmptySearch;

	/**
	 * Whether the empty state is due to an empty data set or an error.
	 *
	 * Default value is 'no-results'.
	 */
	@HostBinding('class') @Input() public type: 'no-results' | 'error' = 'no-results';
	/**
	 * Bind the cypressTag to the host.
	 */
	@HostBinding('attr.data-cy') private readonly cypressTag: string = CypressTags.General.Table.Empty;

	/**
	 * A setup of custom text for the empty result
	 */
	@Input() public customText: EmptySearchResultTextEntity;

	/**
	 * Emits whenever the recommended option to search again without filters is clicked.
	 */
	@Output() public readonly searchWithoutFiltersClicked: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Navigate to the products page
	 *
	 * @param event - the click event for the util
	 */
	public emitSearchWithoutFilters(event: Event) {
		clickOnTranslationLink(event, () => {
			this.searchWithoutFiltersClicked.emit();
		});
	}
}
