/**
 * The possible mandate roles
 */
type MandateRoleType = 'mandaatgever' | 'mandaatnemer';

/**
 * Get the other mandate role. If you pass 'mandaatgever', you will get 'mandaatnemer' and vice versa.
 *
 * @param type - The mandate role to get the other role from
 * @returns The other mandate role
 */
export const getOtherMandateRole = (type: MandateRoleType): MandateRoleType =>
	type === 'mandaatgever' ? 'mandaatnemer' : 'mandaatgever';

// ---------------------------------
// :: UTILS
// ---------------------------------
const date = new Date();
date.setDate(date.getDate() - 1);
const YESTERDAY = date.toISOString();

/**
 * Get a mandate mock
 *
 * @param responseElements - The elements to include in the mock
 * @returns The mandate mock object
 */
export const getMandatesMock = (responseElements: Object[]): Object => {
	return {
		'@type': 'Pagina',
		inhoud: {
			'@type': 'MandaatrelatieVerzameling',
			elementen: responseElements
		}
	};
};

// ---------------------------------
// :: MOCKS
// ---------------------------------

export const EmptyMandatesMock: Object = getMandatesMock([]);

export const ActiveMandateMock: Object = getMandatesMock([
	{
		'@type': 'Mandaatrelatie',
		id: 239,
		actieveVersieId: 241,
		mandaatnemer: {
			'@type': 'Mandaatnemer',
			ondernemingsnummer: {
				'@type': 'Ondernemingsnummer',
				nummer: '0123456789'
			},
			naam: 'Voorbeeldonderneming'
		},
		mandaatgever: {
			'@type': 'Mandaatgever',
			ondernemingsnummer: {
				'@type': 'Ondernemingsnummer',
				nummer: '0987654321'
			},
			naam: 'ANDERE ONDERNEMING'
		},
		product: {
			'@type': 'Mandaatproduct',
			'@id': 'https://cms.vlaio-dev.district01.be/nl/api/aangeboden-producten/detail/subsidievooreenprospectiereisbuitendeeuijslandliechtensteinennoorwegen',
			id: 'subsidievooreenprospectiereisbuitendeeuijslandliechtensteinennoorwegen',
			naam: 'Subsidie voor een prospectiereis buiten de EU, IJsland, Liechtenstein en Noorwegen'
		},
		status: 'Actief',
		maximaalToegangsniveau: 'Maximaal',
		aangevraagdeWijziging: null,
		geldigVan: YESTERDAY,
		geldigTot: null,
		tijdslijn: [
			{
				'@type': 'MandaatstatusAangemaakt',
				status: 'Aangemaakt',
				aangemaakt: YESTERDAY,
				initiatiefnemer: {
					'@type': 'Mandaatgever',
					ondernemingsnummer: {
						'@type': 'Ondernemingsnummer',
						nummer: '0123456789'
					},
					naam: 'Voorbeeldonderneming'
				}
			},
			{
				'@type': 'MandaatstatusVersie',
				aangemaakt: YESTERDAY,
				status: 'Goedgekeurd',
				geldigVan: YESTERDAY,
				geldigTot: null,
				toegangsniveau: null,
				initiatiefnemer: {
					'@type': 'Mandaatgever',
					ondernemingsnummer: {
						'@type': 'Ondernemingsnummer',
						nummer: '0123456789'
					},
					naam: 'Voorbeeldonderneming'
				},
				reviewer: {
					'@type': 'Mandaatnemer',
					ondernemingsnummer: {
						'@type': 'Ondernemingsnummer',
						nummer: '0987654321'
					},
					naam: 'ANDERE ONDERNEMING'
				},
				revisie: [
					{
						'@type': 'MandaatrelatieRevisie',
						persoon: '00000000000',
						type: 'Goedkeuren',
						commentaar: '',
						datum: YESTERDAY
					}
				]
			},
			{
				'@type': 'MandaatstatusHuidig',
				aangemaakt: YESTERDAY,
				status: 'Actief',
				geldigVan: YESTERDAY,
				geldigTot: null,
				toegangsniveau: null,
				initiatiefnemer: {
					'@type': 'Mandaatgever',
					ondernemingsnummer: {
						'@type': 'Ondernemingsnummer',
						nummer: '0123456789'
					},
					naam: 'Voorbeeldonderneming'
				},
				reviewer: {
					'@type': 'Mandaatnemer',
					ondernemingsnummer: {
						'@type': 'Ondernemingsnummer',
						nummer: '0987654321'
					},
					naam: 'ANDERE ONDERNEMING'
				},
				revisie: [
					{
						'@type': 'MandaatrelatieRevisie',
						persoon: '00000000000',
						type: 'Goedkeuren',
						commentaar: '',
						datum: YESTERDAY
					}
				]
			}
		],
		commentaar: 'Cypress automated mocked tests'
	}
]);

export const ExpiredMandateMock: Object = getMandatesMock([
	{
		'@type': 'Mandaatrelatie',
		id: 239,
		actieveVersieId: 241,
		mandaatnemer: {
			'@type': 'Mandaatnemer',
			ondernemingsnummer: {
				'@type': 'Ondernemingsnummer',
				nummer: '0123456789'
			},
			naam: 'Voorbeeldonderneming'
		},
		mandaatgever: {
			'@type': 'Mandaatgever',
			ondernemingsnummer: {
				'@type': 'Ondernemingsnummer',
				nummer: '0987654321'
			},
			naam: 'ANDERE ONDERNEMING'
		},
		product: {
			'@type': 'Mandaatproduct',
			'@id': 'https://cms.vlaio-dev.district01.be/nl/api/aangeboden-producten/detail/subsidievooreenprospectiereisbuitendeeuijslandliechtensteinennoorwegen',
			id: 'subsidievooreenprospectiereisbuitendeeuijslandliechtensteinennoorwegen',
			naam: 'Subsidie voor een prospectiereis buiten de EU, IJsland, Liechtenstein en Noorwegen'
		},
		status: 'Verlopen',
		maximaalToegangsniveau: 'Maximaal',
		aangevraagdeWijziging: null,
		geldigVan: YESTERDAY,
		geldigTot: YESTERDAY,
		tijdslijn: [
			{
				'@type': 'MandaatstatusAangemaakt',
				status: 'Aangemaakt',
				aangemaakt: YESTERDAY,
				initiatiefnemer: {
					'@type': 'Mandaatgever',
					ondernemingsnummer: {
						'@type': 'Ondernemingsnummer',
						nummer: '0987654321'
					},
					naam: 'ANDERE ONDERNEMING'
				}
			},
			{
				'@type': 'MandaatstatusHuidig',
				aangemaakt: YESTERDAY,
				status: 'Verlopen',
				geldigVan: YESTERDAY,
				geldigTot: YESTERDAY,
				toegangsniveau: null,
				initiatiefnemer: {
					'@type': 'Mandaatgever',
					ondernemingsnummer: {
						'@type': 'Ondernemingsnummer',
						nummer: '0987654321'
					},
					naam: 'ANDERE ONDERNEMING'
				},
				reviewer: {
					'@type': 'Mandaatnemer',
					ondernemingsnummer: {
						'@type': 'Ondernemingsnummer',
						nummer: '0123456789'
					},
					naam: 'Voorbeeldonderneming'
				},
				revisie: [
					{
						'@type': 'MandaatrelatieRevisie',
						persoon: '00000000000',
						type: 'Goedkeuren',
						commentaar: '',
						datum: YESTERDAY
					}
				]
			}
		],
		commentaar: 'Cypress automated mocked tests'
	}
]);

export const getMandateToApproveMock = (role: MandateRoleType): Object =>
	getMandatesMock([
		{
			'@type': 'Mandaatrelatie',
			id: 212,
			actieveVersieId: 213,
			mandaatnemer: {
				'@type': 'Mandaatnemer',
				ondernemingsnummer: {
					'@type': 'Ondernemingsnummer',
					nummer: '0123456789'
				},
				naam: 'Voorbeeld onderneming'
			},
			mandaatgever: {
				'@type': 'Mandaatgever',
				ondernemingsnummer: {
					'@type': 'Ondernemingsnummer',
					nummer: '0987654321'
				},
				naam: 'ANDERE ONDERNEMING'
			},
			product: {
				'@type': 'Mandaatproduct',
				'@id': 'https://cms.vlaio-dev.district01.be/nl/api/aangeboden-producten/detail/ontwikkelingsprojecten',
				id: 'ontwikkelingsprojecten',
				naam: 'Ontwikkelingsprojecten'
			},
			status: 'Nieuw',
			maximaalToegangsniveau: 'Maximaal',
			aangevraagdeWijziging: {
				'@type': 'MandaatrelatieVersie',
				versieId: 213,
				status: 'Wachten op goedkeuring',
				toegangsniveau: null,
				geldigVan: YESTERDAY,
				geldigTot: null,
				initiatiefnemer: 'Mandaatgever',
				revisies: {
					[role]: [],
					[getOtherMandateRole(role)]: [
						{
							'@type': 'MandaatrelatieRevisie',
							persoon: null,
							type: 'Goedkeuren',
							commentaar: '',
							datum: YESTERDAY
						}
					]
				}
			},
			geldigVan: YESTERDAY,
			geldigTot: null,
			tijdslijn: [
				{
					'@type': 'MandaatstatusAangemaakt',
					status: 'Aangemaakt',
					aangemaakt: YESTERDAY,
					initiatiefnemer: {
						'@type': 'Mandaatgever',
						ondernemingsnummer: {
							'@type': 'Ondernemingsnummer',
							nummer: '0987654321'
						},
						naam: 'ANDERE ONDERNEMING'
					}
				},
				{
					'@type': 'MandaatstatusHuidig',
					aangemaakt: YESTERDAY,
					status: 'Wachten op goedkeuring',
					geldigVan: YESTERDAY,
					geldigTot: null,
					toegangsniveau: null,
					initiatiefnemer: {
						'@type': 'Mandaatgever',
						ondernemingsnummer: {
							'@type': 'Ondernemingsnummer',
							nummer: '0987654321'
						},
						naam: 'ANDERE ONDERNEMING'
					},
					reviewer: {
						'@type': 'Mandaatnemer',
						ondernemingsnummer: {
							'@type': 'Ondernemingsnummer',
							nummer: '0123456789'
						},
						naam: 'Voorbeeldonderneming'
					},
					revisie: []
				},
				{
					'@type': 'MandaatstatusToekomst',
					status: 'Actief'
				}
			],
			commentaar: 'Cypress automated mocked tests'
		}
	]);
