import { EntityStoreAssets, createEntityAdapterStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';

import { VlaioZipCodeEntity } from './interfaces';

type FormsStoreType = {
	zipCodes: EntityStoreAssets<VlaioZipCodeEntity>;
};

export const { selectors, actions, reducers } = createStoreAssets<FormsStoreType>(StoreSlice.Forms, [
	{
		subSlice: 'zipCodes',
		generator: createEntityAdapterStoreAssets<VlaioZipCodeEntity>
	}
]);
