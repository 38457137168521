import { EntityStoreAssets, createEntityAdapterStoreAssets, createStoreAssets } from '@studiohyperdrive/ngx-store';

import { StoreSlice } from '@vlaio/shared/store';

import { ZipCodeEntity } from './interfaces';

type FormsStoreType = {
	zipCodes: EntityStoreAssets<ZipCodeEntity>;
};

export const { selectors, actions, reducers } = createStoreAssets<FormsStoreType>(StoreSlice.Forms, [
	{
		subSlice: 'zipCodes',
		generator: createEntityAdapterStoreAssets<ZipCodeEntity>
	}
]);
