import { NgClass } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { BackdropComponent } from '../backdrop/backdrop.component';

@Component({
	selector: 'vlaio-modal',
	templateUrl: 'modal.component.html',
	standalone: true,
	imports: [BackdropComponent, NgClass]
})
export class ModalComponent {
	@Input() public hideHeader = false;
	@Input() public hideFooter = false;
	@Input() public hideCloseButton = false;
	@Input() public isOpen: boolean;
	@Input() public small: boolean;
	@Input() public title: string;
	@Output() public handleClose = new EventEmitter();

	public handleClickClose() {
		this.handleClose.emit();
	}
}
