import { HttpBackend } from '@angular/common/http';
import { MultiTranslationHttpLoader } from '@studiohyperdrive/ngx-i18n';

export function ProductsTranslationLoader(http: HttpBackend) {
	return new MultiTranslationHttpLoader(http, [
		'./assets/i18n/shared/products/',
		'./assets/i18n/shared/ui/forms/',
		'./assets/i18n/shared/i18n/',
		'./assets/i18n/shared/ui/common/'
	]);
}
