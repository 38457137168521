import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { Observable, map } from 'rxjs';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { VlaioResult } from '@vlaio/shared/types';
import { convertVlaioResult } from '@vlaio/shared/utils';

import {
	PermitsEntity,
	PermitsResultEntity,
	PermitsSummaryEntity,
	PermitsSummaryResultEntity,
	RenewablePermitsEntity,
	RenewablePermitsResultEntity
} from '../interfaces';
import { convertPermit, convertPermitSummary, convertRenewablePermit } from '../utils';

@Injectable()
export class PermitsApiService {
	constructor(private readonly httpClient: VlaioHttpClientService) {}

	/**
	 * Fetches the permits from the backend.
	 */
	public getPermits(): ObservableArray<PermitsEntity> {
		return this.httpClient
			.get<
				VlaioResult<PermitsResultEntity, 'ToelatingenVerzameling'>
			>(ELoketEndpoints.Permits.GetPermits(), {}, true)
			.pipe(convertVlaioResult<'ToelatingenVerzameling', PermitsResultEntity, PermitsEntity>(convertPermit));
	}

	/**
	 * Fetches the summary of the permits (for the dashboard).
	 */
	public getPermitsSummary(): Observable<PermitsSummaryEntity> {
		return this.httpClient
			.get<PermitsSummaryResultEntity>(ELoketEndpoints.Permits.Summary(), {}, true)
			.pipe(map(convertPermitSummary));
	}

	/**
	 * Fetches the permits that require a renewal.
	 */
	public getRenewablePermits(): ObservableArray<RenewablePermitsEntity> {
		return this.httpClient
			.get<
				VlaioResult<RenewablePermitsResultEntity, 'ToelatingenVerzameling'>
			>(ELoketEndpoints.Permits.RenewablePermits(), {}, true)
			.pipe(
				convertVlaioResult<'ToelatingenVerzameling', RenewablePermitsResultEntity, RenewablePermitsEntity>(
					convertRenewablePermit
				)
			);
	}
}
