import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'vlaio-hamburger',
	templateUrl: 'hamburger.component.html',
	styleUrl: 'hamburger.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule, NgClass]
})
export class HamburgerComponent {
	/**
	 * The translation keys
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * Whether the menu is open
	 */
	@Input() public readonly isMenuOpen: boolean;
}
