<h1 class="u-margin-bottom">
	@for (title of titles; track title) {
	<span>
		<ng-template
			[ngTemplateOutlet]="title.canNavigate ? linkTmpl : defaultTmpl"
			[ngTemplateOutletContext]="{ $implicit: title }"
		></ng-template>
	</span>
	}

	<ng-content select="[help]"></ng-content>
</h1>

<ng-template #defaultTmpl let-item>
	{{ item.label }}
</ng-template>

<ng-template #linkTmpl let-item>
	<a [routerLink]="item.route" [queryParams]="item.queryParams || {}" [attr.title]="item.title">{{ item.label }}</a>
</ng-template>
