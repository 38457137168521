import { defaultHeadersInterceptor } from './default-headers.interceptor';
import { defaultQueryParamsInterceptor } from './default-query-params.interceptor';
import { sessionErrorInterceptor } from './session-error.interceptor';
import { xcsrfInterceptor } from './xcsrf.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptors = [
	defaultHeadersInterceptor,
	defaultQueryParamsInterceptor,
	sessionErrorInterceptor,
	xcsrfInterceptor
];
