@if (breadcrumbs$ | async; as breadcrumbs) { @if (breadcrumbs.length > 0) {
<ul class="c-page-overview">
	@for (route of breadcrumbs; track route; let last = $last) {
	<li class="c-page-overview__item">
		@if (route.allowNav) {
		<a
			class="c-page-overview__link u-outline font-bold"
			cypressTag="Breadcrumbs.Breadcrumb"
			[class.is-active]="last"
			[routerLink]="route.routePath"
			[queryParams]="route.queryParams || {}"
			[title]="route.title | translate"
		>
			{{ route.title | translate }}
		</a>
		} @if (!route.allowNav) {
		<a class="c-page-overview__link u-outline font-bold" [class.is-active]="last" [title]="route.title | translate">
			{{ route.title | translate }}
		</a>
		}
	</li>
	}
</ul>
} }
