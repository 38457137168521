import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QrCodeModule } from 'ng-qrcode';

import { PwaService } from '@vlaio/shared/core';
import { GtagDirectives } from '@vlaio/shared/gtag';
import { Language } from '@vlaio/shared/types';
import { VlaioButtonComponent } from '@vlaio/shared/ui/common';
import { environment } from 'environments';

import { SharedPWAi18nKeys } from '../../../i18n';
import { PlatformDirective } from '../../directives';

@Component({
	selector: 'vlaio-pwa-cta',
	templateUrl: './pwa-cta.component.html',
	styleUrl: './pwa-cta.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [PlatformDirective, QrCodeModule, GtagDirectives, TranslateModule, VlaioButtonComponent]
})
export class PwaCtaComponent {
	/**
	 * The root class of the component.
	 */
	@HostBinding('class') private readonly rootClass = 'c-carousel';

	/**
	 * The translation keys.
	 */
	public readonly i18nKeys = SharedPWAi18nKeys.PWA;

	/**
	 * The page link.
	 */
	public readonly pageLink: string[] = ['pwa'];

	/**
	 * Whether the PWA install functionality is available.
	 */
	public readonly hasPwaInstallFunctionality: boolean = this.pwaService.hasPwaInstallFunctionality;

	/**
	 * The URL of the QR code.
	 */
	public qrCodeUrl: string;

	/**
	 * The language of the page. This is needed to generate the correct QR code.
	 */
	@Input() set language(language: Language) {
		this.qrCodeUrl = `${environment.domain}/${language}/pwa`;
	}

	constructor(private readonly pwaService: PwaService) {}

	/**
	 * Installs the PWA.
	 */
	public installApp(): void {
		this.pwaService.promptPwaInstall();
	}
}
