export enum ExitModalOptions {
	Exit = 'exit',
	Cancel = 'cancel'
}

export interface i18nKeysEntity {
	title: string;
	description: string;
	cancel: string;
	exit: string;
}
