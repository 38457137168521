import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ModalOptionsEntity, ModalEntity } from '@vlaio/shared/core';

const defaultModalOptions: ModalOptionsEntity = {
	disableClose: true,
	closeOnNavigation: false,
	hasBackdrop: true
};

@Injectable()
export class ModalDialogService {
	constructor(private readonly matDialog: MatDialog) {}

	/**
	 * Opens and returns the modal instance
	 *
	 * @template ComponentClass
	 * @param component - The class of the component we want to render in the modal
	 * @param options - Options to disable the close, close on navigation and show a backdrop
	 */
	public openModal<ComponentClass>(
		component: ComponentType<ComponentClass>,
		options?: ModalOptionsEntity
	): ModalEntity<ComponentClass> {
		// Iben: Open the modal with matDialog
		const modal = this.matDialog.open(component, { ...defaultModalOptions, ...options });

		// Iben: Return the instance of the component in the modal and the close function
		return {
			component: modal.componentInstance
		};
	}

	public closeModal(): void {
		this.matDialog.closeAll();
	}
}
