@if (address) {
<address [class]="addressClass">
	@if (format === 'one-line') {

	{{ address.street }}
	{{ address.number }}@if (!address.box && (address.street || address.number)) { , } @if (address.box) {
	{{ ' bus ' + address.box }}, }
	{{ address.zip }}
	{{ address.municipality }}

	} @else { @if (address.street || address.number) {

	{{ (address.street || '') + ' ' + (address.number || '') }}
	} @if (address.box) {
	{{ ' bus ' + address.box }}
	} @if (address.street || address.number || address.box) {
	<br />
	} @if (address.zip || address.municipality) {

	{{ (address.zip || '') + ' ' + (address.municipality || '') }}

	} }
</address>
} @else {

<i>
	{{ label | translate }}
</i>

}
