import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { NgxTooltipDirective } from '@studiohyperdrive/ngx-inform';

import { HasFeaturePipe } from '@vlaio/shared/features';

@Component({
	selector: 'vlaio-product-tag',
	template: `<span
		ngxTooltipPosition="above"
		[ngxTooltip]="description"
		[ngxTooltipDisabled]="!('RecommendationReason' | hasFeature) || !description"
	>
		<ng-content></ng-content>
	</span>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HasFeaturePipe, NgxTooltipDirective]
})
export class ProductTagComponent {
	@HostBinding('class') private readonly rootClass = 'c-product-tag';

	@Input() public description: string;
}
