import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '@studiohyperdrive/ngx-utils';
import { BehaviorSubject, Observable, distinctUntilChanged, filter, from, tap } from 'rxjs';

import { Language } from '@vlaio/shared/types';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {
	/**
	 * Subject to hold the current language of the application
	 */
	private readonly languageSubject: BehaviorSubject<Language> = new BehaviorSubject(undefined);

	/**
	 * The current language of the application
	 */
	public readonly currentLanguage$: Observable<Language> = this.languageSubject
		.asObservable()
		.pipe(distinctUntilChanged(), filter(Boolean));

	// Iben: Use the WindowService here directly, as using the BrowserService causes a (incorrect?) circular dependency
	constructor(private readonly router: Router, private readonly windowService: WindowService) {}

	/**
	 * Change the language of the application
	 *
	 * @param language - The language of the application
	 */
	public changeLanguage(language: Language): Observable<unknown> {
		// Iben: Setup the currentRoute, but only when we are in the browser
		let currentRoute: string[] = [];

		this.windowService.runInBrowser(({ browserWindow }) => {
			currentRoute = browserWindow.location.pathname.split('/').slice(2);
		});

		// Iben: Navigate to the new language paramater
		return from(this.router.navigate(['/', language, ...currentRoute], { queryParamsHandling: 'preserve' })).pipe(
			tap(() => {
				// Iben: Set the language
				this.setLanguage(language);

				// Iben: Do a full reload so all API calls are done again
				this.windowService.runInBrowser(() => {
					location.reload();
				});
			})
		);
	}

	/**
	 * Set the language of the application
	 *
	 * @param language - The language of the application
	 */
	public setLanguage(language: Language | 'undefined'): void {
		// Iben: Early exit if no language was passed
		if (!language || language === 'undefined') {
			return;
		}

		// Iben: Update the subject
		this.languageSubject.next(language);

		// Iben: Update the localStorage and the language of the browser document
		this.windowService.runInBrowser(({ browserDocument }) => {
			localStorage.setItem('vlaio-language', language);

			browserDocument.documentElement.lang = language;
		});
	}

	/**
	 * Returns the current language
	 */
	public get currentLanguage(): Language {
		return this.languageSubject.getValue();
	}
}
