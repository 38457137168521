@for (tag of product.tags || []; track tag) {
	<vlaio-product-tag [description]="tag.description">
		{{ tag.name }}
	</vlaio-product-tag>
}

<vlaio-detail-content
	cypressTitleTag="Offers.Product.Title"
	[class.has-product-tag]="product.tags?.length > 0"
	[title]="product.name"
	[highlightTitle]="highlightTitle"
>
	<section [class.c-has-tag]="product.tags?.length > 0">
		<h4>
			{{
				(recommendationRating ? i18nKeys.Products.Detail.RecommendedBy : i18nKeys.Products.Detail.Via)
					| translate
			}}
			@for (partner of product.partners || []; track partner) {
				@if (partner.url) {
					<vlaio-external-link
						cypressTag="Offers.Product.Partner"
						[link]="partner.url"
						[title]="partner.name"
					>
						{{ partner.name }}
					</vlaio-external-link>
				} @else {
					<span cypressTag="Offers.Product.Partner">
						{{ partner.name }}
					</span>
				}
			}
		</h4>

		<p class="c-product-description" cypressTag="Offers.Product.Description" [innerHTML]="product.description"></p>

		@if (product.link || product.infoLinks?.length > 0) {
			<div>
				<vlaio-external-link
					cypressTag="Offers.Product.MoreInfo"
					[link]="product.link"
					[title]="i18nKeys.Actions.MoreInfo | translate"
				>
					{{ i18nKeys.Actions.MoreInfo | translate }}
				</vlaio-external-link>

				@for (link of product.infoLinks || []; track link) {
					<i class="c-product-separator">&#124;</i>

					<vlaio-external-link [link]="link.link" [title]="link.name">
						{{ link.name }}
					</vlaio-external-link>
				}
			</div>
		}
	</section>

	@if (showCTA) {
		<aside>
			@for (annotation of product.annotations || []; track annotation) {
				<vlaio-product-annotation [annotation]="annotation" />
			}
			@for (action of product.actions || []; track action) {
				<vlaio-product-cta
					[callToAction]="action"
					[productId]="product.id"
					(ctaClicked)="handleCTAClicked(action)"
				/>
			}
			@if (recommendationRating) {
				<vlaio-product-rating
					[recommendationRating]="recommendationRating"
					(recommendationRated)="recommendationRated.emit($event)"
				/>
			}
		</aside>
	}
</vlaio-detail-content>
