@if (!error) {

<vlaio-facet-filter
	[loading]="loading"
	[facets]="facets"
	[isDesktop]="isDesktop"
	[enableClear]="enableClear"
	(clearClicked)="clearClicked.emit()"
	(facetClicked)="facetClicked.emit($event)"
	(filtersChanged)="filtersChanged.emit($event)"
/>

<div class="vlaio-facet-filters">
	<ng-content select="[filters]"></ng-content>

	@if (showSelectedFacets) {
	<vlaio-selected-facets
		class="c-products__filter-list"
		[searchQuery]="searchQuery"
		[filters]="selectedFacets"
		[facetSubjectNames]="facetSubjectNames"
		(filtersChanged)="filtersChanged.emit($event)"
		(searchQueryCleared)="searchQueryClearClicked.emit()"
		(clearAll)="clearClicked.emit()"
	/>
	}
</div>

<div class="vlaio-facet-content">
	<ng-content select="[content]"></ng-content>
</div>

} @else {

<vlaio-empty-search-result type="error" />

}
