<nav *mediaQuery="mediaQueries.MediaQueryMax.Desktop" aria-describedby="main-navigation">
	<vlaio-hamburger
		#hamburger
		[cdkMenuTriggerFor]="menuTmpl"
		[isMenuOpen]="isMenuOpen()"
		(cdkMenuOpened)="handleMenuChange(true)"
		(cdkMenuClosed)="handleMenuChange(false)"
	/>
</nav>

<ng-template #menuTmpl>
	<ul class="unauthenticated-menu" cdkMenu>
		@for (item of menuItems; track item) {

		<li
			[cypressTag]="item.cypressTag"
			[routerLinkActive]="['is-active']"
			[routerLinkActiveOptions]="{ exact: !!item.exact }"
		>
			<a
				class="u-outline"
				cdkMenuItem
				[routerLink]="item.routerLink"
				[routerLinkActive]="['is-active']"
				[routerLinkActiveOptions]="{ exact: !!item.exact }"
			>
				@if (itemTemplate) {

				<ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item.id }">
				</ng-template>

				} @else {

				{{ item.id }}

				}
			</a>
		</li>

		}
	</ul>
</ng-template>

<nav *mediaQuery="mediaQueries.MediaQueryMin.Desktop">
	<ul class="desktop-menu">
		@for (item of menuItems; track item) {
		<li
			[cypressTag]="item.cypressTag"
			[routerLinkActive]="['is-active']"
			[routerLinkActiveOptions]="{ exact: !!item.exact }"
		>
			<a
				class="u-outline"
				[routerLink]="item.routerLink"
				[routerLinkActive]="['is-active']"
				[routerLinkActiveOptions]="{ exact: !!item.exact }"
			>
				@if (itemTemplate) {

				<ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item.id }">
				</ng-template>

				} @else {

				{{ item.id }}

				}
			</a>
		</li>
		}
	</ul>
</nav>
