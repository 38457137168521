import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { BrowserService, StatusService } from '@vlaio/shared/core';
import { VlaioButtonComponent } from '@vlaio/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-maintenance-page',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss'],
	standalone: true,
	imports: [CypressTagDirective, VlaioButtonComponent, TranslateModule]
})
export class MaintenancePageComponent {
	public readonly label = RedirectI18nKeys.Maintenance.MaintenanceMode;
	public readonly title = RedirectI18nKeys.Maintenance.Title;
	public readonly action = RedirectI18nKeys.Actions.TryAgain;
	public readonly message = this.statusService.statusMessage;

	constructor(
		private readonly browserService: BrowserService,
		private readonly statusService: StatusService
	) {}

	/**
	 * Reload the document
	 */
	retryStatus() {
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.location.reload();
		});
	}
}
