import { Component } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { BrowserService } from '@vlaio/shared/core';
import { LanguageService } from '@vlaio/shared/i18n';
import { Language } from '@vlaio/shared/types';
import { VlaioButtonComponent } from '@vlaio/shared/ui/common';

@Component({
	selector: 'vlaio-no-resources',
	templateUrl: './no-resources.component.html',
	styleUrls: ['./no-resources.component.scss'],
	standalone: true,
	imports: [CypressTagDirective, VlaioButtonComponent]
})
export class NoResourcesComponent {
	public readonly language: Language = this.languageService.currentLanguage;

	/**
	 * The translation record of the title.
	 */
	public readonly titleTranslations: Record<Language, string> = {
		nl: 'Er is iets misgelopen',
		en: 'Something went wrong',
		fr: `Quelque chose s'est mal passé`
	};

	/**
	 * The translation record of the description.
	 */
	public readonly descriptionTranslations: Record<Language, string> = {
		nl: 'Er zijn bepaalde belangrijke elementen die niet geladen konden worden. Gelieve opnieuw te proberen.',
		en: 'Some key features could not be loaded. Please try again below.',
		fr: `Certaines fonctionnalités clés n'ont pas pu être chargées. Veuillez réessayer ci-dessous.`
	};

	/**
	 * The translation record of the button title.
	 */
	public readonly buttonTitleTranslations: Record<Language, string> = {
		nl: 'Klik hier om de belangrijke diensten opnieuw te laden.',
		en: 'Click here to reload the important features by refreshing the page.',
		fr: 'Cliquez ici pour recharger les fonctionnalités importantes en actualisant la page.'
	};

	/**
	 * The translation record of the button text.
	 */
	public readonly buttonTextTranslations: Record<Language, string> = {
		nl: 'Opnieuw laden',
		en: 'Reload',
		fr: 'Recharger'
	};

	constructor(
		private readonly languageService: LanguageService,
		private readonly browserService: BrowserService
	) {}

	/**
	 * Reload the document
	 */
	public retryStatus(): void {
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.location.reload();
		});
	}
}
