import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

import { ToastEntity } from '@vlaio/shared/types';

import { I18nKeys } from '../../i18n';
import { VlaioIconComponent } from '../vlaio-icon/vlaio-icon.component';

@Component({
	selector: 'vlaio-toast',
	templateUrl: './toast.component.html',
	styleUrl: './toast.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioIconComponent, NgClass, TranslateModule]
})
export class ToastComponent {
	/**
	 * Handle the click on the component to dismiss the snackbar.
	 */
	@HostListener('click') private onClick(): void {
		this.snackBarRef.dismiss();
	}

	/**
	 * The translation keys
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	/**
	 * The reference to the snackbar itself.
	 */
	public readonly snackBarRef = inject(MatSnackBarRef);
	/**
	 * The icon to be displayed in the template.
	 */
	public icon: string;

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ToastEntity) {
		// Wouter: Match the correct icon
		if (data.showIcon) {
			switch (data.type) {
				case 'Positive':
					this.icon = 'fa-solid fa-circle-check';
					break;
				case 'Negative':
					this.icon = 'fa-solid fa-circle-xmark';
					break;
				case 'Info':
					this.icon = 'fa-solid fa-circle-info';
					break;
				case 'Warning':
					this.icon = 'fa-solid fa-triangle-exclamation';
					break;
			}
		}
	}
}
