<button
	[disabled]="isDisabled || loading"
	[className]="classes"
	[ngClass]="{ 'is-disabled': isDisabled, 'c-button--dark-text': darkText, 'is-loading': loading }"
	[title]="title"
	[attr.type]="type ? type : null"
	(click)="handleButtonClick()"
>
	@if (!loading) { @if (icon) {
	<span [className]="overrideIconClass ? icon : 'c-button__icon-before icon-' + icon"> </span>
	}

	<ng-content></ng-content>

	} @else {

	<div class="c-loader">
		<span></span>
	</div>

	}
</button>
