import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'vlaio-cta-block',
	templateUrl: './cta-block.component.html',
	styleUrls: ['./cta-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class CTABlockComponent {
	@Input() public title: string;
	@Input() public description: string;
}
