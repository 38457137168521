@if (!disablePopup) {
<div class="c-progress-meter__popup">
	@if (meterItems[currentIndex$ | async]; as item) {
	<div class="c-progress-meter__popup-content" [cypressTag]="detailCypressTag">
		<h3 class="c-progress-meter__popup-title" [cypressTag]="'ProgressMeter.Popup.Title'">
			<ng-template [ngTemplateOutlet]="titleTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
			</ng-template>
		</h3>

		<h5 class="c-progress-meter__popup-subtitle" [cypressTag]="'ProgressMeter.Popup.Subtitle'">
			<ng-template [ngTemplateOutlet]="subTitleTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
			</ng-template>
		</h5>

		<small class="c-progress-meter__popup__date" [cypressTag]="dateCypressTag">
			{{ item.date | date : dateFormat }}
		</small>

		<p class="c-progress-meter__popup-text">
			<ng-template [ngTemplateOutlet]="actionTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
			</ng-template>
		</p>
	</div>
	}
	<div class="c-progress-meter__arrow" [style.opacity]="0"></div>
</div>
}

<ul class="c-progress-meter__line">
	@for (item of meterItems; track item; let index = $index; let last = $last) {

	<li
		class="c-progress-meter__part"
		[class.is-active]="item.isActive && !item.isCurrent"
		[class.is-current]="item.isCurrent"
	>
		<input
			class="c-progress-meter__input u-is-invisible"
			type="radio"
			tabindex="-1"
			[attr.name]="progressMeterId"
			[attr.id]="progressMeterId + index"
			[formControl]="currentIndexControl"
			[value]="progressMeterId + index"
			[disabled]="disableClickEvent"
		/>
		<label
			class="c-progress-meter__dot"
			matTooltipClass="c-mat-tooltip"
			matTooltipPosition="below"
			[matTooltip]="item.toolTip | translate"
			[class.c-progress-meter__no-popup]="disablePopup"
			[cypressTag]="progressButtonCypressTag"
			[class.is-selected]="progressMeterId + index === currentIndexControl.value"
			[class.is-last]="last"
			[attr.for]="progressMeterId + index"
		>
		</label>
	</li>

	}
</ul>
