import { JsonPipe, NgClass, NgForOf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, Type } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTooltipDirective } from '@studiohyperdrive/ngx-inform';
import { ArraySignal, FocusClickDirective, WritableBooleanSignal } from '@studiohyperdrive/ngx-utils';
import { BehaviorSubject, combineLatest, map, of, switchMap, take, tap } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { FeatureService, HasFeatureDirective } from '@vlaio/shared/features';
import { MediaQueryMax, MediaQueryMin } from '@vlaio/shared/types';
import { VlaioIconComponent } from '@vlaio/shared/ui/common';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';
import { HasCompanyRoleDirective, HasRoleDirective, UserService } from '@vlaio/shared/user';

import { I18nKeys } from '../../../i18n';
import { ClickAnywhereDirective } from '../../directives';
import { VlaioNavigationTooltipComponent } from '../navigation-tooltip/navigation-tooltip.component';

import { VlaioNavigationItemEntity } from './navigation-v2.types';

@Component({
	selector: 'vlaio-navigation-v2',
	templateUrl: './navigation-v2.component.html',
	styleUrls: [
		'./navigation-v2.shared.component.scss',
		'./navigation-v2.mobile.component.scss',
		'./navigation-v2.desktop.component.scss'
	],
	standalone: true,
	imports: [
		JsonPipe,
		NgForOf,
		VlaioIconComponent,
		RouterLink,
		RouterLinkActive,
		FocusClickDirective,
		NgClass,
		HasFeatureDirective,
		CypressTagDirective,
		HasRoleDirective,
		HasCompanyRoleDirective,
		MediaQueryDirective,
		NgTemplateOutlet,
		ClickAnywhereDirective,
		TranslateModule,
		NgxTooltipDirective
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlaioNavigationV2Component {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	/**
	 * The minimum width for the desktop navigation to be displayed
	 */
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;

	/**
	 * The maximum width for the mobile navigation to be displayed
	 */
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;

	/**
	 * The tooltip for the component
	 */
	public readonly toolTipComponent: Type<VlaioNavigationTooltipComponent> = VlaioNavigationTooltipComponent;

	/**
	 * Whether the mobile flyout is expanded
	 */
	public flyoutIsOpen: WritableBooleanSignal = signal(false);

	/**
	 * The subject to store the navigation items in.
	 */
	public readonly itemsSubject: BehaviorSubject<VlaioNavigationItemEntity[]> = new BehaviorSubject<
		VlaioNavigationItemEntity[]
	>([]);

	/**
	 * The items to display in the navigation, filtered by the user's roles, features and company roles
	 */
	public readonly navItems: ArraySignal<VlaioNavigationItemEntity> = toSignal(
		this.itemsSubject.pipe(
			switchMap((items) =>
				combineLatest(
					items.map((item) => {
						// Wouter: skip checks if item needs to be hidden
						if (item.hideItem) {
							return of(null);
						}

						// Wouter: Check feature
						return this.featureService.hasFeature(item.feature).pipe(
							map((hasFeature) => (item.featureAction === 'hideOnFeature' ? !hasFeature : hasFeature)),
							// Wouter: Check company role
							switchMap((hasFeature: boolean) => {
								// Wouter: If the feature is not present, no further checks are needed
								if (!hasFeature) {
									return of(false);
								}

								return this.userService.userHasCompanyProperties(
									Array.isArray(item.companyRole)
										? item.companyRole
										: [item.companyRole].filter(Boolean),
									item.shouldHaveAllCompanyRoles ?? false
								);
							}),
							// Wouter: Check role
							switchMap((hasCompanyRoles) => {
								// Wouter: If the company roles are not present, no further checks are needed
								if (!hasCompanyRoles) {
									return of(false);
								}

								return this.userService.hasRole(
									Array.isArray(item.role) ? item.role : [item.role].filter(Boolean)
								);
							}),
							// Wouter: If all is good, return the item
							map((hasRoles) => {
								if (!hasRoles) {
									return null;
								}
								return item;
							})
						);
					})
				)
			),
			// Wouter: Filter out the null values
			map((items) => items.filter(Boolean)),
			takeUntilDestroyed()
		)
	);

	/**
	 * Whether the desktop navigation is expanded
	 */
	@Input({ required: true }) public navIsOpen: boolean;

	/**
	 * The navigation items to display
	 */
	@Input({ required: false }) public set items(items: VlaioNavigationItemEntity[]) {
		if (!items || !items?.length) {
			return;
		}

		this.itemsSubject.next(items);
	}

	/**
	 * Emits when the button to open or close the desktop navigation is clicked
	 */
	@Output() public readonly handleNavIsOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private readonly featureService: FeatureService,
		private readonly userService: UserService,
		private readonly router: Router
	) {}

	/**
	 * Toggles the side bar.
	 */
	public toggleSideBar(): void {
		this.handleNavIsOpenChange.emit();
	}

	/**
	 * Toggles the mobile flyout bar.
	 */
	public toggleFlyout(): void {
		this.flyoutIsOpen.update((isOpen) => !isOpen);
	}

	/**
	 * When the mobile flyout is open, this method will close it when the user clicks anywhere outside of the menu.
	 *
	 * @param event The event that is triggered when the user clicks anywhere.
	 */
	public handleMobileClickAnywhere(isMenuToggle: boolean): void {
		// Wouter: The toggleFlyout method will handle the closing of the flyout when the menu toggle was targeted
		if (isMenuToggle) {
			return;
		}

		if (this.flyoutIsOpen()) {
			this.flyoutIsOpen.update(() => false);
		}
	}

	/**
	 * Logs the user out.
	 */
	public logout(): void {
		this.userService
			.logOut()
			.pipe(
				take(1),
				tap(() => {
					this.router.navigate(['/']);
				})
			)
			.subscribe();
	}
}
