<ul class="u-reset-list u-flex u-wrap u-inline-wrap">
	@if (searchQuery) {
	<li>
		<div class="c-tag--button">
			<span class="c-tag__label--button">
				{{ searchQuery }}
			</span>

			<button
				class="c-tag__trigger u-outline"
				cypressTag="Offers.Search.SearchQueryTag"
				(focusClick)="searchQueryCleared.emit()"
			>
				<span class="c-tag__trigger-icon icon-close"></span>
			</button>
		</div>
	</li>
	} @for (facetId of facetIds; track facetId) { @for (item of facets[facetId]; track item) { @if
	(facetSubjectNames[item]) {

	<li>
		<div class="c-tag--button">
			<span class="c-tag__label--button">
				{{ facetSubjectNames[item] }}
			</span>

			<button class="c-tag__trigger" (focusClick)="filterCleared(facetId, item)">
				<span class="c-tag__trigger-icon icon-close"></span>
			</button>
		</div>
	</li>
	} } } @if (!hideDelete && facetIds.length > 0) {
	<li>
		<vlaio-clear-search [displayCloseIcon]="true" (cleared)="clearAll.emit()" />
	</li>
	}
</ul>
