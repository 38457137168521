import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-clear-search',
	templateUrl: './clear-search.component.html',
	standalone: true,
	imports: [CypressTagDirective, TranslateModule]
})
export class VlaioClearSearchComponent {
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * If set to `true`, the close icon of the button is shown
	 *
	 * Default value is set to `true`
	 */
	@Input() public displayCloseIcon: boolean = true;

	/**
	 * When the button is pressed, the event is emitted.
	 *
	 * The event is `null`
	 */
	@Output() public readonly cleared: EventEmitter<void> = new EventEmitter<void>();
}
