import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, LOCALE_ID, ErrorHandler, importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxFormsErrorsConfigurationToken } from '@studiohyperdrive/ngx-forms';
import { I18nService, NgxI18nModule } from '@studiohyperdrive/ngx-i18n';
import { NgxTableConfigToken } from '@studiohyperdrive/ngx-table';
import { provideNgxTourConfiguration } from '@studiohyperdrive/ngx-tour';
import { WITH_ROUTER_LINKS_CONFIG, WithRouterLinksConfig } from '@studiohyperdrive/ngx-utils';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { ELoketCoreModule, VlaioFormErrorComponent } from '@vlaio/e-loket/core';
import { NotificationsDataModule } from '@vlaio/e-loket/notifications';
import { PartnersDataModule } from '@vlaio/e-loket/partners/shared';
import { AuthDataModule } from '@vlaio/shared/authentication/auth';
import { CompanyDataModule } from '@vlaio/shared/company';
import { ErrorService, AbstractModalDialogService, SharedCoreModule } from '@vlaio/shared/core';
import { sharedI18nKeys } from '@vlaio/shared/i18n';
import { NacebelDataModule } from '@vlaio/shared/nacebel';
import { PagesDataModule } from '@vlaio/shared/page';
import { ProductDataModule } from '@vlaio/shared/products';
import { TrackingDataModule } from '@vlaio/shared/tracking';
import { AbstractI18nService } from '@vlaio/shared/types';
import { BreadcrumbModule } from '@vlaio/shared/ui/breadcrumb';
import { SharedUiFormsDataModule } from '@vlaio/shared/ui/forms';
import { ModalDialogService, ModalUiModule } from '@vlaio/shared/ui/modal';
import { SharedUiNavigationModule } from '@vlaio/shared/ui/navigation';
import { VlaioTourStepComponent } from '@vlaio/shared/ui/tour';
import { UserDataModule } from '@vlaio/shared/user';
import { SharedUtilsModule } from '@vlaio/shared/utils';
import { environment } from '~environment';

import { AppComponent } from './app/app.component';
import { routing } from './app/app.routing';
import { ELoketTranslationLoader } from './app/translation.loader';

import './polyfills';

if (environment.production) {
	enableProdMode();
	window.console.log = () => null;
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			ELoketCoreModule,
			NacebelDataModule,
			NotificationsDataModule,
			SharedUtilsModule,
			SharedCoreModule,
			ReactiveFormsModule,
			routing,
			BreadcrumbModule,
			PagesDataModule,
			TrackingDataModule,
			ModalUiModule,
			CompanyDataModule,
			SharedUiNavigationModule,
			MatSnackBarModule,
			SharedUiFormsDataModule,
			NgxI18nModule.forRoot(
				{
					defaultLanguage: 'nl',
					availableLanguages: environment.languages,
					defaultAssetPaths: ['./assets/i18n/shared/i18n/']
				},
				ELoketTranslationLoader
			),
			AuthDataModule,
			UserDataModule,
			StoreModule.forRoot([]),
			StoreDevtoolsModule.instrument({
				maxAge: 25
			}),
			ProductDataModule,
			PartnersDataModule,
			ServiceWorkerModule.register('ngsw-worker.js', {
				enabled: environment.hasServiceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 10 seconds (whichever comes first).
				registrationStrategy: 'registerWhenStable:10000'
			})
		),
		{
			provide: LOCALE_ID,
			useValue: 'nl-BE'
		},
		{
			provide: ErrorHandler,
			useClass: ErrorService
		},
		{
			provide: DATE_PIPE_DEFAULT_OPTIONS,
			useValue: { dateFormat: 'dd/MM/yyyy' }
		},
		{
			provide: AbstractModalDialogService,
			useClass: ModalDialogService
		},
		{
			provide: AbstractI18nService,
			useClass: I18nService
		},
		{
			provide: NgxFormsErrorsConfigurationToken,
			useValue: {
				showWhen: 'touched',
				errors: {
					invalidINSZ: sharedI18nKeys.FormErrors.InvalidInsz,
					required: sharedI18nKeys.FormErrors.Required,
					incorrectChronologicalDates: sharedI18nKeys.FormErrors.ChronologicalDates,
					incorrectChronologicalDate: sharedI18nKeys.FormErrors.ChronologicalDates,
					invalidMandateExecutorRuntime: sharedI18nKeys.FormErrors.MandateExecutorRuntime,
					minlength: sharedI18nKeys.FormErrors.MinLength,
					invalidMinimumDate: sharedI18nKeys.FormErrors.MinDate
				},
				component: VlaioFormErrorComponent
			}
		},
		{
			provide: NgxTableConfigToken,
			useValue: {
				showOpenRowState: true,
				ngxTableClass: 'vlaio-table',
				showDetailRow: 'on-single-item',
				emitValueOnSingleItem: true
			}
		},
		{
			provide: WITH_ROUTER_LINKS_CONFIG,
			useValue: {
				replaceElementSelector: 'vlaio-translated-link',
				linkAttributeName: 'link',
				dataLinkIdAttributeName: 'data-link-id'
			} as WithRouterLinksConfig
		},
		provideEnvironmentNgxMask(),
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
		provideNgxTourConfiguration(VlaioTourStepComponent)
	]
}).catch((err) => console.error(err));
