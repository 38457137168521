import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormAccessor } from '@studiohyperdrive/ngx-forms';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { finalize } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { ProductEntity } from '@vlaio/shared/types';
import { VlaioDropdownSearchComponent } from '@vlaio/shared/ui/forms';

import { ProductService } from '../../../data';
import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'products-dropdown-search',
	templateUrl: './products-dropdown-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ProductsDropdownSearchComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => ProductsDropdownSearchComponent),
			multi: true
		}
	],
	standalone: true,
	imports: [VlaioDropdownSearchComponent, ReactiveFormsModule, CypressTagDirective, AsyncPipe, TranslateModule]
})
export class ProductsDropdownSearchComponent
	extends FormAccessor<ProductEntity, FormControl<ProductEntity>>
	implements OnInit
{
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public loading = false;

	public readonly products$: ObservableArray<ProductEntity> = this.productsService.offeredProducts$;
	public readonly loading$: ObservableBoolean = this.productsService.offeredProductsLoading$;
	public readonly error$: ObservableBoolean = this.productsService.offeredProductsError$;

	constructor(private readonly productsService: ProductService) {
		super();
	}

	/**
	 * Search for the products based on the searchQuery
	 *
	 * @param searchQuery - The searchQuery we want to search on
	 */
	public searchProducts(searchQuery: string): void {
		// Iben: If we don't have a searchQuery we reset the form and early exit
		// Iben: For some reason, we have to check for the string version of null here as the Angular form control passes that to us
		if (searchQuery === '' || searchQuery === null || searchQuery === 'null') {
			this.form.reset();
			this.productsService.clearOfferedProducts();

			return;
		}

		// Iben: Setup filters
		const filters = {
			searchQuery
		};

		// Iben: Search for a company
		this.productsService
			.getOfferedProducts(filters)
			.pipe(
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe();
	}

	public initForm(): FormControl<ProductEntity> {
		return new FormControl<ProductEntity>(null);
	}

	/**
	 * Maps the product to a human readable name
	 *
	 * @param product - Get the name of the product
	 */
	public selectedProductMapper(product: ProductEntity): string {
		return product?.name;
	}

	/**
	 * Sets the initial loading on true
	 */
	public setInitialLoading(): void {
		this.loading = true;
	}
}
