@if (breadcrumbs$ | async; as breadcrumbs) {
	@if (breadcrumbs.length > 0) {
		<ul class="c-page-overview">
			@for (route of breadcrumbs; track route; let last = $last) {
				<li class="c-page-overview__item">
					@if (route.allowNav) {
						<vlaio-internal-link
							cypressTag="Breadcrumbs.Breadcrumb"
							linkStyle="plain"
							[title]="route.title | translate"
							[link]="route.routePath"
							[linkQueryParams]="route.queryParams || {}"
						>
							{{ route.title | translate }}
						</vlaio-internal-link>
					}
					@if (!route.allowNav) {
						<vlaio-event-link>
							{{ route.title | translate }}
						</vlaio-event-link>
					}
				</li>
			}
		</ul>
	}
}
