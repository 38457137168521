import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'vlaio-detail-content',
	templateUrl: './detail-content.component.html',
	styleUrl: './detail-content.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class VlaioDetailContentComponent {
	/**
	 * The title of the detail
	 */
	@Input({ required: true }) public title: string;
}
