import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTourStepComponent } from '@studiohyperdrive/ngx-tour';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';

import { sharedI18nKeys } from '@vlaio/shared/i18n';
import { VlaioIconComponent } from '@vlaio/shared/ui/common';

@Component({
	selector: 'vlaio-introduction-step-component',
	templateUrl: './introduction-step.component.html',
	styleUrl: './introduction-step.component.scss',
	standalone: true,
	imports: [TranslateModule, VlaioIconComponent, FocusClickDirective]
})
export class VlaioIntroductionStepComponent extends NgxTourStepComponent {
	public readonly i18nKeys: typeof sharedI18nKeys = sharedI18nKeys;
}
