import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BlockTypeKeys, BlockTypes } from './block.types';

@Component({
	selector: 'vlaio-block',
	templateUrl: './block.component.html',
	styleUrls: ['./block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class BlockComponent {
	@Input() public type: BlockTypeKeys = 'Default';

	public readonly blockTypes = BlockTypes;
}
