import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { VlaioContentTitleEntity } from '../../types';

@Component({
	selector: 'vlaio-content-title',
	templateUrl: './content-title.component.html',
	styleUrl: './content-title.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet, RouterLink]
})
export class VlaioContentTitleComponent {
	/**
	 * The breadcrumb title we wish to show
	 */
	@Input({ required: true }) titles: VlaioContentTitleEntity[] = [];
}
