<div class="c-search__input" [ngClass]="{ 'is-empty': form.value !== '' }">
	<div class="c-input c-input--with-icon" [title]="title || i18nKeys.Forms.Actions.Title | translate">
		<span class="icon-search"></span>
		@if (mask) {
			<input
				class="c-input--as-textfield input-search u-outline"
				name="input-search"
				id="input-search"
				role="search"
				type="text"
				[placeholder]="placeholder"
				[cypressTag]="inputCypressTag || 'General.Input.SearchInput'"
				[formControl]="searchField"
				[mask]="mask"
				[showMaskTyped]="true"
				(keydown.enter)="!autoSearch && changed(searchField.value)"
			/>
		} @else {
			<input
				class="c-input--as-textfield input-search u-outline"
				name="input-search"
				id="input-search"
				role="search"
				type="text"
				[placeholder]="placeholder"
				[cypressTag]="inputCypressTag || 'General.Input.SearchInput'"
				[formControl]="searchField"
				(keydown.enter)="!autoSearch && changed(searchField.value)"
			/>
		}
		@if (searchField.value) {
			<vlaio-close-button
				class="clear-button"
				cypressTag="General.Input.DeleteSearchButton"
				[title]="i18nKeys.Forms.Actions.ClearSearch | translate"
				(handleClick)="reset()"
			/>
		}
	</div>
</div>

@if (showButton) {
	<vlaio-button
		cypressTag="General.Input.SearchButton"
		buttonType="Regular"
		[title]="buttonLabel || (i18nKeys.Forms.Actions.Search | translate)"
		[fullWidth]="true"
		(handleClick)="changed(searchField.value)"
	>
		{{ buttonLabel || (i18nKeys.Forms.Actions.Search | translate) }}
	</vlaio-button>
}
