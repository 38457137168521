<!-- Wouter: The error is checked first to prevent further loading and content rendering in case of an error -->
@if (!error) { @if (!data.length && !loading && !error) { @if (emptyTemplate) {

<ng-template [ngTemplateOutlet]="emptyTemplate"></ng-template>

} @else {

<vlaio-empty-search-result (searchWithoutFiltersClicked)="resetFilters.emit()" />

} } @if ((data.length || loading) && tableTemplate) {

<ng-template
	*mediaQuery="mediaQueryOverride ? mediaQueryMin[mediaQueryOverride] : mediaQueryMin.Desktop"
	[ngTemplateOutlet]="tableTemplate"
	[ngTemplateOutletContext]="{ $implicit: data, loading: loading, error: error }"
>
</ng-template>

} @if ((data.length || loading) && listItemTemplate) {

<vlaio-content
	*mediaQuery="mediaQueryOverride ? mediaQueryMax[mediaQueryOverride] : mediaQueryMax.Desktop"
	[loading]="loading"
	[error]="error"
>
	@for (item of data; track item; let index = $index) { @if (item) {
	<div class="c-vlaio-table-item u-margin-top" tabindex="0">
		<ng-template [ngTemplateOutlet]="listItemTemplate" [ngTemplateOutletContext]="{ $implicit: item, index }">
		</ng-template>
	</div>
	} }
</vlaio-content>

} } @else {

<vlaio-empty-search-result type="error" (searchWithoutFiltersClicked)="resetFilters.emit()" />

}
