import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { BrowserService } from '@vlaio/shared/core';
import { VlaioButtonComponent } from '@vlaio/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-desktop-only',
	templateUrl: './desktop-only.component.html',
	styleUrls: ['./desktop-only.component.scss'],
	standalone: true,
	imports: [CypressTagDirective, RouterLink, TranslateModule, VlaioButtonComponent]
})
export class DesktopOnlyComponent {
	/**
	 * The translation keys used in the template
	 */
	public readonly label = RedirectI18nKeys.DesktopOnly.Text;
	public readonly title = RedirectI18nKeys.DesktopOnly.Title;
	public readonly action = RedirectI18nKeys.DesktopOnly.Button;

	constructor(
		private readonly browserService: BrowserService,
		private readonly router: Router
	) {}

	/**
	 * Navigates to the home page
	 */
	public navigateToLink(): void {
		this.browserService.runInBrowser(() => {
			this.router.navigate(['/']);
		});
	}
}
