/**
 * Handles the click event on a translation with a link
 *
 * @param event - The click event
 * @param action - The action we wish to perform
 */
export const clickOnTranslationLink = (event: Event, action: () => void) => {
	// Iben: If we didn't click the link or if no class is present, early exit
	if (
		!Boolean((event.target as HTMLElement)?.className) ||
		!(event.target as HTMLElement).className.includes('u-link')
	) {
		return;
	}

	// Iben: If we clicked on the link, we perform the action
	action();
};
