import { ComponentType } from '@angular/cdk/portal';

import { ModalEntity, ModalOptionsEntity } from '../interfaces';

export const defaultModalOptions: ModalOptionsEntity = {
	disableClose: true,
	closeOnNavigation: false,
	hasBackdrop: true
};

/**
 * Provides a a modal dialog opener to the application
 */
export abstract class AbstractModalDialogService {
	/**
	 * Opens and returns the modal instance
	 *
	 * @template ComponentClass
	 * @param component - The class of the component we want to render in the modal
	 * @param options - Options to disable the close, close on navigation and show a backdrop
	 */
	public abstract openModal<ComponentClass>(
		component: ComponentType<ComponentClass>,
		options: ModalOptionsEntity
	): ModalEntity<ComponentClass>;

	public abstract closeModal(): void;
}
