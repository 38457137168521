@for (annotation of action.annotations; track annotation) {
	<vlaio-product-annotation [annotation]="annotation" />
}
@switch (buttonActionType) {
	<!-- Login -->

	@case (ButtonActionTypes.Login) {
		<vlaio-button
			buttonType="NeutralOutline"
			vlaioUserEIDLogin
			cypressTag="Offers.Product.Login"
			icon="fa-chalkboard-user"
			[productId]="productId"
			[title]="action.name"
			[fullWidth]="true"
		>
			{{ action.name }}
		</vlaio-button>
	}

	<!-- Request access eloket -->

	@case (ButtonActionTypes.RequestAccessEloket) {
		<vlaio-button
			buttonType="Action"
			icon="fa-key"
			[title]="action.name"
			[fullWidth]="true"
			(handleClick)="ctaClicked.emit()"
		>
			{{ action.name }}
		</vlaio-button>
	}

	<!-- Request access -->

	@case (ButtonActionTypes.RequestAccess) {
		<vlaio-button
			buttonType="Action"
			icon="fa-key"
			[title]="action.name"
			[fullWidth]="true"
			(handleClick)="ctaClicked.emit()"
		>
			{{ action.name }}
		</vlaio-button>
	}

	<!-- More info -->

	@case (ButtonActionTypes.MoreInfo) {
		<vlaio-button
			buttonType="Neutral"
			icon="fa-up-right-from-square"
			[title]="action.name"
			[fullWidth]="true"
			(handleClick)="ctaClicked.emit()"
		>
			{{ action.name }}
		</vlaio-button>
	}

	<!-- Fallback (Start case) -->

	@default {
		<vlaio-button
			buttonType="Regular"
			icon="fa-up-right-from-square"
			[title]="action.name"
			[fullWidth]="true"
			(handleClick)="ctaClicked.emit()"
		>
			{{ action.name }}
		</vlaio-button>
	}
}
