@if (asidePosition === AsidePosition.left) {
<aside [class]="'c-vlaio-container__aside' + ' ' + classesAside">
	<ng-content select="[asideLeft]"></ng-content>
</aside>
}
<section [class]="'c-vlaio-container__main' + ' ' + classesMain">
	<ng-content select="[main]"></ng-content>
</section>
@if (asidePosition === AsidePosition.right) {
<aside [class]="'c-vlaio-container__aside' + ' ' + classesAside">
	<ng-content select="[asideRight]"></ng-content>
</aside>
}
