import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { VlaioTranslateWithRouterLinksPipe } from '../../pipes';
import { VlaioFaIconComponent } from '../fa-icon/fa-icon.component';

@Component({
	selector: 'vlaio-empty-page-result',
	templateUrl: './empty-page-result.component.html',
	styleUrls: ['./empty-page-result.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioFaIconComponent, VlaioTranslateWithRouterLinksPipe]
})
export class EmptyPageResultComponent {
	/**
	 * The logo to display above the empty label text.
	 *
	 * Default value is `fa-file-circle-xmark`.
	 */
	@Input() public icon: string = 'fa-file-circle-xmark';

	/**
	 * The text to display.
	 */
	@Input({ required: true }) public emptyLabel: string;

	/**
	 * Whether the label contains HTML.
	 */
	@Input() public hasHtmlLabel = false;

	/**
	 * The router link of the item that needs to be substituted by the `withRouterLink` pipe.
	 */
	@Input() public substituteRouterLink: string | string[];

	/**
	 * The id of the item that needs to be substituted by the `withRouterLink` pipe.
	 */
	@Input() public substituteLinkId: string;

	/**
	 * The optional type of icon.
	 */
	@Input() public iconType: 'fa-regular' | 'fa-solid' = 'fa-solid';
}
