import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ExitModalOptions, ExitWizardModalAbstractComponent, i18nKeysEntity } from '@vlaio/shared/core';

import { VlaioButtonComponent } from '../buttons';

@Component({
	selector: 'vlaio-exit-wizard-modal',
	templateUrl: './exit-wizard-modal.component.html',
	standalone: true,
	imports: [VlaioButtonComponent]
})
export class VlaioExitWizardModalComponent extends ExitWizardModalAbstractComponent {
	public readonly modalOptions: typeof ExitModalOptions = ExitModalOptions;

	@Input() public i18nKeys: i18nKeysEntity;

	@Output() public closeModal = new EventEmitter<ExitModalOptions>();
}
