@if (data.showIcon) {
<vlaio-icon
	[icon]="icon"
	[ngClass]="{
		'ui-color-red': data.type === 'Negative',
		'ui-color-blue': data.type === 'Info',
		'ui-color-orange': data.type === 'Warning',
		'primary-color': data.type === 'Positive'
	}"
/>
}

<div class="text-wrapper">
	<p [innerHTML]="data.text"></p>

	<p class="remove-text">{{ i18nKeys.Notification.ClickToDelete | translate }}</p>
</div>
