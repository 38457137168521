import { Component, ContentChild, TemplateRef, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxAbstractTableCellDirective } from '@studiohyperdrive/ngx-table';

import { I18nKeys } from '../../../i18n';
import { VlaioTooltipTextComponent } from '../../tooltip-text/tooltip-text.component';

@Component({
	selector: 'vlaio-organization-number-table-cell',
	providers: [
		{
			provide: NgxAbstractTableCellDirective,
			useExisting: VlaioOrganizationNumberTableCellComponent
		}
	],
	templateUrl: './organization-number-cell.component.html',
	standalone: true,
	imports: [VlaioTooltipTextComponent, TranslateModule]
})
export class VlaioOrganizationNumberTableCellComponent extends NgxAbstractTableCellDirective {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * A template for the header of the cell
	 */
	@ViewChild('headerTmpl', { static: false })
	public headerTemplate: TemplateRef<any>;

	/**
	 * A template for the cell itself
	 */
	@ContentChild('cellTmpl', { static: false })
	public cellTemplate: TemplateRef<any>;

	/**
	 * A template for the footer of the cell
	 */
	@ContentChild('footerTmpl', { static: false })
	public footerTemplate: TemplateRef<any>;
}
