import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormAccessor, createAccessorProviders } from '@studiohyperdrive/ngx-forms';
import { merge } from '@studiohyperdrive/utils';

import { FacetFilter, SortingEntity } from '@vlaio/shared/types';
import { VlaioSearchComponent, VlaioSelectComponent } from '@vlaio/shared/ui/forms';

import { ProductFiltersEntity } from '../../../data';
import { I18nKeys } from '../../../i18n';

interface ProductSearchFormComponentForm {
	sorting: FormControl<SortingEntity>;
	filters: FormControl<FacetFilter>;
	searchQuery: FormControl<string>;
	index: FormControl<number>;
}

interface ProductFiltersEntityForm extends Omit<ProductFiltersEntity, 'sorting'> {
	sorting?: string;
}

@Component({
	selector: 'vlaio-product-search',
	templateUrl: './product-search.component.html',
	styleUrl: './product-search.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [createAccessorProviders(ProductSearchComponent)],
	standalone: true,
	imports: [ReactiveFormsModule, VlaioSearchComponent, VlaioSelectComponent, TranslateModule]
})
export class ProductSearchComponent extends FormAccessor<
	ProductFiltersEntity,
	FormGroup<ProductSearchFormComponentForm>,
	ProductFiltersEntityForm
> {
	/**
	 * An array of sorting options needed for the select
	 */
	@Input({ required: true }) sortingOptions: SortingEntity[] = [];

	public readonly i18nKeys = I18nKeys;

	public onWriteValueMapper(value: ProductFiltersEntity): ProductFiltersEntityForm {
		// Iben: If no value is provided, return an empty object
		if (!value) {
			return {};
		}

		// Iben: Get the sorting and map it to a string
		const { sorting, ...rest } = value;

		return merge<ProductFiltersEntityForm>(rest, ['sorting', sorting?.id]);
	}

	public onChangeMapper(value: Partial<ProductFiltersEntityForm>): ProductFiltersEntity {
		if (!value) {
			return {};
		}
		// Iben: Get the sorting and map it to a object
		const { sorting, ...rest } = value;

		return merge<ProductFiltersEntity>(rest, [
			'sorting',
			this.sortingOptions.find((option) => option.id === sorting)
		]);
	}

	public initForm(): FormGroup<ProductSearchFormComponentForm> {
		return new FormGroup({
			filters: new FormControl<FacetFilter>(undefined),
			searchQuery: new FormControl<string>(undefined),
			index: new FormControl<number>(undefined),
			sorting: new FormControl<SortingEntity>(undefined)
		});
	}
}
