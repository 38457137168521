import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { UserEIDLoginDirective } from '@vlaio/shared/authentication/auth';
import { CallToActionEntity, CallToActionEnum } from '@vlaio/shared/types';
import { ButtonComponent, VlaioIconComponent } from '@vlaio/shared/ui/common';

import { ProductAnnotationComponent } from '../product-annotation/product-annotation.component';
@Component({
	selector: 'vlaio-product-cta',
	templateUrl: './product-cta.component.html',
	styleUrls: ['./product-cta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ProductAnnotationComponent,
		ButtonComponent,
		UserEIDLoginDirective,
		CypressTagDirective,
		VlaioIconComponent
	]
})
export class ProductCTAComponent {
	/**
	 * The possible button types.
	 */
	public readonly ButtonActionTypes: typeof CallToActionEnum = CallToActionEnum;
	/**
	 * The action that defines the button.
	 */
	public action: CallToActionEntity;
	/**
	 * The type of the button itself.
	 */
	public buttonActionType: CallToActionEnum;

	/**
	 * The configuration that defines the button.
	 */
	@Input({ required: true }) public set callToAction(action: CallToActionEntity) {
		this.action = action;
		this.buttonActionType = action.action;
	}
	/**
	 * The id of the product, used for the `login` template.
	 */
	@Input() public productId: string;

	/**
	 * Emits when the action button is pressed.
	 */
	@Output() public ctaClicked: EventEmitter<void> = new EventEmitter<void>();
}
