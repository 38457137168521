import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { PageEntity, PageNavDataEntity, PageNavItemEntity, Pages } from '@vlaio/shared/types';

import { actions, selectors } from '../page.store';

import { PageApiService } from './page.api.service';

@Injectable()
export class PageService extends StoreService {
	/**
	 * The page navigation instance.
	 */
	private readonly navigation$: ObservableArray<PageNavDataEntity> = this.selectFromStore<PageNavDataEntity[]>(
		selectors.navigation
	);
	/**
	 * The fetched pages from the backend.
	 */
	private readonly pages$: ObservableArray<PageEntity> = this.selectFromStore<PageEntity[]>(selectors.pages);

	/**
	 * Whether the current page is being fetched.
	 */
	public readonly pageLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.pages);
	/**
	 * Whether an error occurred while fetching the page content.
	 */
	public readonly pageError$: ObservableBoolean = this.selectErrorFromStore(selectors.pages);
	/**
	 * Whether an error occurred during navigation.
	 */
	public readonly navigationError$: ObservableBoolean = this.selectErrorFromStore(selectors.navigation);
	/**
	 * Whether the navigation is loading
	 */
	public readonly navigationLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.navigation);

	constructor(private readonly apiService: PageApiService, public readonly store: Store) {
		super(store);
	}

	/**
	 * Get a specific page
	 *
	 * @param pageId - The id of the parent page
	 * @param pageTitle - The url to the actual page
	 */
	public getPage(pageId: Pages, pageTitle: string): Observable<PageEntity> {
		return combineLatest([this.pages$, this.getNavigationItems(pageId)]).pipe(
			switchMap(([pages, navigation]) => {
				// Iben: Find the navigationItem that matches this page
				const navigationItem = navigation.find(({ title, id }) => {
					// Iben: Check whether the title or the id matches
					const titleMatch = title.toLowerCase() === pageTitle.toLowerCase();
					const idMatch = id.split('/').pop() === pageTitle;

					return idMatch || titleMatch;
				});

				// Wouter: Check if at least one of the children's id matches the requested page.
				const navigationChild: PageNavItemEntity = navigation
					.map(({ items }) => {
						return (items || []).find(({ title, id }) => {
							const titleMatch = title.toLowerCase() === pageTitle.toLowerCase();
							const idMatch = id.split('/').pop() === pageTitle;

							return idMatch || titleMatch;
						});
					})
					.filter(Boolean)
					.pop();

				// Iben: If the navigation item does not exist throw an error
				if (!navigationItem && !navigationChild) {
					return throwError(new Error('Navigation item could not be found'));
				}

				// Iben: Find the page and its corresponding resource id
				const resourceId = navigationItem?.id || navigationChild?.id;
				const parent = navigationChild?.parent;

				// Iben: Remove the params from the id
				const page = (pages || []).find((item) => item.id.split('?')[0] === resourceId);

				// Iben: If the page does not exist we fetch it from the API
				if (!page) {
					return dispatchDataToStore(
						actions.pages,
						this.apiService.getPage(resourceId, pageId, parent),
						this.store,
						'add'
					);
				}

				// Iben: If the page already exists we return the version from the store
				return of(page);
			})
		);
	}

	/**
	 * Get the content of a standalone page.
	 */
	public getSinglePage(pageId: string): Observable<any> {
		return this.pages$.pipe(
			first(),
			map((pages) => {
				return (pages || []).find((page) => page.id.split('?')[0] === pageId);
			}),
			switchMap((page) => {
				if (!page || !Object.keys(page).length) {
					return dispatchDataToStore(actions.pages, this.apiService.getSinglePage(pageId), this.store, 'add');
				}

				return of(page);
			})
		);
	}

	/**
	 * Get the navigation items of a parent page
	 *
	 * @param pageId - The id of the parent page
	 */
	public getNavigationItems(pageId: Pages): ObservableArray<PageNavItemEntity> {
		return this.navigation$.pipe(
			switchMap((navigation) => {
				// Iben: Check  if the overview for that navigation already exists in the store
				const overview = (navigation || []).find(({ id }) => id === pageId);

				return overview
					? of(overview.navigation)
					: // Iben: If it does not exist in the store we add it
					  dispatchDataToStore<PageNavDataEntity>(
							actions.navigation,
							this.apiService.getPageNavItems(pageId).pipe(
								map((result) => {
									return {
										id: pageId,
										navigation: result
									};
								})
							),
							this.store,
							'add'
					  ).pipe(map((result) => result.navigation));
			})
		);
	}
}
