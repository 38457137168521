<ng-container [formGroup]="form">
	<vlaio-search
		formControlName="searchQuery"
		placeholder="{{ i18nKeys.Products.Actions.Search | translate }}"
		buttonCypressTag="Offers.Search.Button"
		inputCypressTag="Offers.Search.Input"
		[clearOnSearch]="true"
		[hasSmallButton]="true"
	/>

	<vlaio-select
		class="c-products__search-sorting__sort-select"
		inputCypressTag="Offers.Sorting.Select"
		formControlName="sorting"
		[items]="sortingOptions || []"
		[horizontal]="true"
		[inputName]="i18nKeys.Products.Select.InputName | translate"
	>
		{{ i18nKeys.Products.Actions.Sort | translate }}

		<ng-template #itemTmpl let-item>
			{{ item.name }}
		</ng-template>
	</vlaio-select>
</ng-container>
