import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';

import { IconType } from './fa-icon.const';

@Component({
	selector: 'fa-icon',
	standalone: true,
	imports: [MatTooltipModule],
	template: `<i
		matTooltipClass="c-mat-tooltip"
		[matTooltipPosition]="toolTipPosition"
		[class]="iconType + ' ' + icon"
		[matTooltipDisabled]="!toolTip"
		[matTooltip]="toolTip"
	></i>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VlaioFaIconComponent {
	/**
	 * The string of the icon to be displayed.
	 */
	@Input({ required: true }) public icon: string;
	/**
	 * Whether the icon is solid or regular.
	 *
	 * Default value is `solid`
	 */
	@Input() public iconType: `${IconType}` = IconType.solid;

	/**
	 * The text to be displayed in the tooltip.
	 */
	@Input() public toolTip: string;
	/**
	 * The position of the tooltip towards the icon.
	 *
	 * Default value is `above`
	 */
	@Input() public toolTipPosition: TooltipPosition = 'above';
}
