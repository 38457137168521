export enum LayoutType {
	fullWidth = 'full-width',
	splitEqual = 'split-equal',
	splitContentRight = 'split-content-right',
	splitContentLeft = 'split-content-left'
}

export enum AsidePosition {
	left = 'left',
	right = 'right'
}

export enum AlignItems {
	start = 'start',
	center = 'center',
	end = 'end'
}

export enum PreventSpacingAt {
	top = 'top',
	bottom = 'bottom',
	left = 'left',
	right = 'right',
	all = 'all',
	none = 'none'
}
