import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { BrowserService } from '@vlaio/shared/core';
import { ButtonComponent } from '@vlaio/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-offline-page',
	templateUrl: './offline.component.html',
	styleUrls: ['./offline.component.scss'],
	standalone: true,
	imports: [CypressTagDirective, ButtonComponent, TranslateModule]
})
export class OfflinePageComponent {
	public readonly label = RedirectI18nKeys.Offline;
	public readonly title = RedirectI18nKeys.Maintenance.Title;
	public readonly action = RedirectI18nKeys.Actions.TryAgain;

	constructor(private readonly browserService: BrowserService) {}

	/**
	 * Reload the document
	 */
	retryStatus() {
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.location.reload();
		});
	}
}
