import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDateTableCellComponent, NgxTableCellDirective, NgxTableComponent } from '@studiohyperdrive/ngx-table';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTags } from '@vlaio/cypress/shared';
import { CompanyAddressRecord } from '@vlaio/shared/company';
import {
	VlaioTableWrapperComponent,
	VlaioIconComponent,
	VlaioOrganizationNumberTableCellComponent,
	VlaioToggleOpenCellComponent
} from '@vlaio/shared/ui/common';

import { PermitsEntity } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { PermitsDetailComponent } from '../permits-detail/permits-detail.component';

@Component({
	selector: 'permits-table',
	templateUrl: './permits-table.component.html',
	styleUrl: './permits-table.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		VlaioTableWrapperComponent,
		NgxTableComponent,
		NgxTableCellDirective,
		NgxDateTableCellComponent,
		CypressTagDirective,
		VlaioIconComponent,
		MatTooltip,
		NgTemplateOutlet,
		VlaioOrganizationNumberTableCellComponent,
		VlaioToggleOpenCellComponent,
		PermitsDetailComponent,
		TranslateModule
	]
})
export class PermitsTableComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	/**
	 * The cypress tags.
	 */
	public readonly cypressTags: typeof CypressTags = CypressTags;

	/**
	 * The permits to display in the table.
	 */
	@Input({ required: true }) public permits: PermitsEntity[];
	/**
	 * A record of the branchNumber and its address.
	 */
	@Input() public addresses: CompanyAddressRecord = {};

	/**
	 * The columns we wish to show
	 */
	@Input() public columns: string[] = ['visibility', 'title', 'referenceNumber', 'recentChangeDate', 'assignedTo'];

	/**
	 * Whether the table is read-only, by default false. Setting this to true will hide the buttons in the detail view
	 */
	@Input() public readOnly: boolean = false;

	/**
	 * Whether we wish to show all information or only the minimal amount. By default this is 'all'
	 */
	@Input() public view: 'minimal' | 'all' = 'all';

	/**
	 * Emits the url of the permit whose button was pressed.
	 */
	@Output() public readonly permitButtonClicked: EventEmitter<string> = new EventEmitter();
	/**
	 * Emits the fileNumber of the permit whose button was pressed.
	 */
	@Output() public readonly caseButtonClicked: EventEmitter<string> = new EventEmitter();
}
