<div class="c-measure__feedback-title">
	<p>
		<strong>
			{{ i18nKeys.IsThisRelevant | translate }}
		</strong>
	</p>
</div>

<div class="c-rating">
	<button class="c-rating__button" [class.is-active]="isPositiveRating" (click)="givePositiveRating()">
		<div>
			<span class="c-rating__icon icon-thumb-up"></span>

			<span class="c-rating__icon-checked icon-thumb-up-filled"></span>
		</div>

		<span class="c-rating__label">
			{{ i18nKeys.Relevant | translate }}
		</span>
	</button>

	<button class="c-rating__button" [class.is-active]="isNegativeRating" (click)="giveNegativeRating()">
		<div>
			<span class="c-rating__icon icon-thumb-down"></span>

			<span class="c-rating__icon-checked icon-thumb-down-filled"></span>
		</div>

		<span class="c-rating__label">
			{{ i18nKeys.Irrelevant | translate }}
		</span>
	</button>
</div>
