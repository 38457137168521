import { AfterContentInit, Component, ContentChild, ElementRef, HostBinding, Input } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { map, startWith, takeUntil } from 'rxjs';

import { OnDestroyComponent } from '@vlaio/shared/types';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-input-wrapper',
	templateUrl: './input-wrapper.component.html',
	styleUrls: ['./input-wrapper.component.scss'],
	standalone: true,
	imports: [TranslateModule]
})
export class InputWrapperComponent extends OnDestroyComponent implements AfterContentInit {
	@ContentChild('input') public wrappedInput: ElementRef;
	@ContentChild(NgControl) public controlWrapper: NgControl;

	@HostBinding('class.c-input') private readonly hasInputClass: boolean = true;
	@HostBinding('class.c-input--is-disabled') public isDisabled: boolean = false;
	/**
	 * HOSTBINDING
	 *
	 * The withIcon input attribute can be used to set the c-input--with-icon class.
	 *
	 * Default value is `false`
	 */
	@HostBinding('class.c-input--with-icon')
	@Input()
	public withIcon: boolean = false;
	/**
	 * The hasSuccess input attribute can be used to set the is-success class.
	 *
	 * Default value is `false`
	 */
	@HostBinding('class.is-success')
	@Input()
	public hasSuccess: boolean = false;
	/**
	 * HOSTBINDING
	 *
	 * The hasWarning input attribute can be used to set the has-warning class.
	 *
	 * Default value is `false`
	 */
	@HostBinding('class.is-warning')
	@Input()
	public hasWarning: boolean = false;
	/**
	 * HOSTBINDING
	 *
	 * The hasError input attribute can be used to set the has-error class.
	 *
	 * Note: the input-wrapper will handle this automatically for the wrapped control.
	 * Only use this to provide a specific overwrite.
	 *
	 * Default value is `false`
	 */
	@HostBinding('class.is-error')
	@Input()
	public hasError: boolean = false;
	/**
	 * The label input attribute is used to provide a label for the input.
	 */
	@Input() public label: string;
	/**
	 * The hint input attribute is used to show a hint for the current input.
	 */
	@Input() public hint: string;
	/**
	 * The id input attribute will set both the 'for' attribute on the label,
	 * and the id on the input field.
	 */
	@Input() public id: string;
	/**
	 * The isTextfield input attribute will add the 'c-input--as-textfield' class
	 * to the input to present it as a textfield.
	 *
	 * default is set to true.
	 */
	@Input() public isTextfield: boolean = true;

	/**
	 * Whether or we want the errors to be visible when touched or when dirty
	 *
	 * default is set to dirty.
	 */
	@Input() public showErrorOn: 'touched' | 'dirty' = 'dirty';

	/**
	 * The isOptional attribute will show the optional marker in the label.
	 *
	 * default is set to false.
	 */
	public isOptional: boolean = false;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	public ngAfterContentInit(): void {
		// Denis: Handle the wrapped input
		this.handleWrappedInput();

		// Iben: Setup the subscriptions for the control
		this.handleWrappedControl();
	}

	/**
	 * Handles the classes and ids of the native element
	 */
	private handleWrappedInput(): void {
		if (!this.wrappedInput) {
			return;
		}

		if (this.isTextfield) {
			this.wrappedInput.nativeElement.className = 'c-input--as-textfield';
		}

		this.wrappedInput.nativeElement.id = this.id;
	}

	/**
	 * Handles the subscriptions for the wrapped control
	 */
	private handleWrappedControl(): void {
		// Iben: If no control was provided, early exit
		if (!this.controlWrapper || !this.controlWrapper.control) {
			return;
		}

		// Iben: Set the disabled and error state based on the control state
		this.controlWrapper.control.statusChanges
			.pipe(
				startWith(() => this.controlWrapper.control.status),
				map(() => {
					this.isDisabled = this.controlWrapper.disabled;
					this.hasError =
						this.controlWrapper.control.invalid && this.controlWrapper.control[this.showErrorOn];
					this.isOptional = !this.controlWrapper.control.hasValidator(Validators.required);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
