import { ChangeDetectorRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
	LinkReference,
	WITH_ROUTER_LINKS_CONFIG,
	WithRouterLinkPipe,
	WithRouterLinksConfig
} from '@studiohyperdrive/ngx-utils';

/**
 * A custom Angular pipe that combines translation and router link substitution in a single operation.
 * It first translates the given text using the `TranslatePipe` from `@ngx-translate/core` and then
 * processes any router links within the translated text using the `WithRouterLinkPipe` from
 * `@studiohyperdrive/ngx-utils`. This pipe is designed to be used in Angular templates to simplify
 * the handling of text that includes both translations and router links.
 *
 * @example
 * ```html
 * <div [innerHTML]="'TRANSLATION_KEY' | translateWithRouterLinks: linkReferences"></div>
 * ```
 * Where `TRANSLATION_KEY` is the key for the translation string and `linkReferences` is an array
 * of objects that define the router links to be substituted in the translated text.
 *
 * @param {string} value - The translation key to be translated.
 * @param {LinkReference[]} linkReferences - An optional array of `LinkReference` objects that define
 * the router links to be substituted in the translated text.
 *
 * @returns The translated text with router links substituted.
 */
@Pipe({
	name: 'translateWithRouterLinks',
	standalone: true
})
export class VlaioTranslateWithRouterLinksPipe implements PipeTransform {
	/**
	 * The pipe that substitutes the router links.
	 */
	private withRouterLinksPipe: WithRouterLinkPipe;

	/**
	 * The pipe that translates the text.
	 */
	private translatePipe: TranslatePipe;

	constructor(
		@Inject(WITH_ROUTER_LINKS_CONFIG) private readonly config: WithRouterLinksConfig,
		private readonly sanitizer: DomSanitizer,
		private readonly translate: TranslateService,
		private readonly cdRef: ChangeDetectorRef
	) {
		this.withRouterLinksPipe = new WithRouterLinkPipe(config, sanitizer);
		this.translatePipe = new TranslatePipe(translate, cdRef);
	}

	public transform(value: string, linkReferences: LinkReference[] = []): any {
		return this.withRouterLinksPipe.transform(this.translatePipe.transform(value), linkReferences);
	}
}
