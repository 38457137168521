import { NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	TemplateRef,
	ContentChild,
	Input,
	Optional,
	Output,
	EventEmitter,
	HostBinding
} from '@angular/core';

import { ScreenSize } from '@vlaio/cypress/shared';
import { MediaQueryMin, MediaQueryMax } from '@vlaio/shared/types';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';

import { EmptySearchResultComponent } from '../empty-search-result/empty-search-result.component';
import { VlaioContentComponent } from '../vlaio-content/vlaio-content.component';

@Component({
	selector: 'vlaio-table-wrapper',
	templateUrl: './vlaio-table-wrapper.component.html',
	styleUrls: ['./vlaio-table-wrapper.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet, EmptySearchResultComponent, MediaQueryDirective, VlaioContentComponent]
})
export class VlaioTableWrapperComponent {
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;

	@ContentChild('tableTmpl') public tableTemplate: TemplateRef<unknown>;
	@ContentChild('listItemTmpl') public listItemTemplate: TemplateRef<unknown>;
	@ContentChild('emptyTmpl') public emptyTemplate: TemplateRef<unknown>;

	/**
	 * Whether we want no styling on the detail items. By default this is false.
	 */
	@HostBinding('class.no-item-styling') @Input() public noItemStyling: boolean = false;

	/**
	 * The data to display in the table.
	 */
	@Input() public data: unknown[] = [];

	/**
	 * Whether the data is being fetched or not.
	 */
	@Input() public loading: boolean = false;

	/**
	 * Whether the fetching of the data resulted in an error or not.
	 */
	@Input() public error: boolean = false;

	/**
	 * If needed, the width at which the table turns into a container with cards
	 * can be overridden.
	 *
	 * **Note:** Only change if absolutely necessary. This will create an uneven feel
	 * in the application.
	 */
	@Input() @Optional() public mediaQueryOverride: keyof typeof ScreenSize;

	/**
	 * Emits whenever the recommended option to search again without filters is clicked.
	 */
	@Output() public readonly resetFilters: EventEmitter<void> = new EventEmitter<void>();
}
