<label class="font-bold" [for]="inputId">
	<p>
		{{ label }}
		@if (required) {
			<span class="ui-color-red font-bold">&ast;</span>
		}
	</p>
</label>

<div class="input-wrapper" [class.has-icon]="icon">
	@if (icon) {
		<vlaio-icon [icon]="icon" />
	}
	<input [id]="inputId" [type]="inputType" [placeholder]="placeholder ?? undefined" [formControl]="form" />
</div>
