@if (company) {
	@if (showTitle) {
		<h2>{{ title | translate }}</h2>
	}
	@if (!hideText) {
		<div class="u-padding-bottom">
			<p [innerHtml]="i18nKeys.CheckYourData | translate: { number: company.number } | safeHtml"></p>
			@if (isUserCompany) {
				<p [innerHtml]="i18nKeys.ChangeYourData | translate"></p>
			}
		</div>
		<div class="u-padding-bottom">
			@if (isUserCompany) {
				<p
					[innerHtml]="
						i18nKeys.ChangeYourCase
							| translateWithRouterLinks
								: [{ id: 'cases', elementId: 'internalLink', data: { link: caseLink } }]
					"
				></p>
			}
		</div>
	}

	<ul cypressTag="Companies.Detail.Public">
		@if (company.names.public) {
			<li>
				<p>{{ i18nKeys.RegisteredName | translate }}</p>
				<p>{{ company.names.public }}</p>
			</li>
		}
		@if (!showMinimalInfo && company.form) {
			<li>
				<p>{{ i18nKeys.Form | translate }}</p>
				<p>{{ company.form }}</p>
			</li>
		}
		@if (!showMinimalInfo && company.legalStatus) {
			<li>
				<p>{{ i18nKeys.LegalStatus | translate }}</p>
				<p>{{ company.legalStatus }}</p>
			</li>
		}
		@if (!showMinimalInfo && company.startDate) {
			<li>
				<p>{{ i18nKeys.StartDate | translate }}</p>
				<p>{{ company.startDate | date: 'dd/MM/yyyy' }}</p>
			</li>
		}
		@if (company.registeredOffice) {
			<li>
				<p>{{ i18nKeys.RegisteredOffice | translate }}</p>
				<p>
					<vlaio-address format="one-line" [address]="company.registeredOffice" />
				</p>
			</li>
		}
		@if (company.number) {
			<li>
				<p>{{ i18nKeys.Number | translate }}</p>
				<p>{{ company.number }}</p>
			</li>
		}
	</ul>
}
