import { Component, Input } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { ContactItemsEntity } from '@vlaio/shared/types';

import { VlaioAddressComponent } from '../address/address.component';
import { VlaioContactLinkComponent, VlaioExternalLinkComponent } from '../links';

@Component({
	selector: 'vlaio-partner-detail',
	standalone: true,
	templateUrl: './partner-detail.component.html',
	styleUrl: './partner-detail.component.scss',
	imports: [VlaioExternalLinkComponent, VlaioContactLinkComponent, VlaioAddressComponent, CypressTagDirective]
})
export class VlaioPartnerDetailComponent {
	/**
	 * The name of the partner
	 */
	@Input({ required: true }) public partner: string;

	/**
	 * A list of contact items
	 */
	@Input() public contactItems: ContactItemsEntity = {};

	/**
	 * The mail data we wish to provide to e-mail addresses in the contact items
	 */
	@Input() mailData: string;
}
