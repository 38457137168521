import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { pluck, ObservableBoolean, ObservableString } from '@studiohyperdrive/rxjs-utils';
import { Observable, filter, tap } from 'rxjs';

import { PageComponent, PageService } from '@vlaio/shared/page';
import { AbstractPageComponent, PageEntity } from '@vlaio/shared/types';
import { BreadcrumbComponent, BreadcrumbService } from '@vlaio/shared/ui/breadcrumb';
import { BannerComponent, VlaioContentComponent } from '@vlaio/shared/ui/common';

@Component({
	selector: 'vlaio-single-page',
	templateUrl: './single-page.component.html',
	styleUrls: ['./single-page.component.scss'],
	standalone: true,
	imports: [BannerComponent, BreadcrumbComponent, NgTemplateOutlet, VlaioContentComponent, PageComponent, AsyncPipe]
})
export class SinglePageComponent extends AbstractPageComponent implements OnInit {
	/**
	 * Whether the page content is being fetched;
	 */
	public readonly loading$: ObservableBoolean = this.pageService.pageLoading$;

	/**
	 * Set the breadcrumbs of the page.
	 */
	public readonly breadcrumbs$: Observable<PageEntity> = this.page$.pipe(
		filter<PageEntity>(Boolean),
		tap((page) => {
			this.breadcrumbsService.setBreadcrumbs([
				{
					routePath: [],
					title: page.title,
					allowNav: false
				}
			]);
		}),
		takeUntilDestroyed()
	);

	constructor(
		public readonly route: ActivatedRoute,
		private readonly pageService: PageService,
		private readonly breadcrumbsService: BreadcrumbService
	) {
		super(route);
	}

	// Lifecycle methods
	// ==============================
	public ngOnInit(): void {
		// Iben: Set breadCrumb path

		this.breadcrumbs$.subscribe();
	}

	// Abstract methods
	// ==============================
	protected getPageId(): ObservableString {
		return this.route.queryParams.pipe(filter(Boolean), pluck('page'));
	}

	protected getPage(page: string): Observable<PageEntity> {
		return this.pageService.getSinglePage(page);
	}
}
