@if (toolTip) {
	<i
		[ngxTooltip]="toolTip"
		[ngxTooltipPosition]="toolTipPosition"
		[ngxTooltipDisabled]="!toolTip"
		[class]="iconType + ' ' + icon"
	></i>
} @else {
	<i [class]="iconType + ' ' + icon"></i>
}
