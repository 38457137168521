import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTableCellDirective, NgxTableComponent } from '@studiohyperdrive/ngx-table';

import { VlaioValueTileComponent, VlaioTableWrapperComponent } from '@vlaio/shared/ui/common';

import { CompanyActivityEntity } from '../../../data';
import { sharedCompanyI18nKeys } from '../../../i18n';
import { ActivityGroupPipe } from '../../pipes/activity-group.pipe';

@Component({
	selector: 'activity-table',
	templateUrl: './activity-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		VlaioTableWrapperComponent,
		NgxTableComponent,
		NgxTableCellDirective,
		VlaioValueTileComponent,
		TranslateModule,
		ActivityGroupPipe
	]
})
export class ActivityTableComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof sharedCompanyI18nKeys.Companies = sharedCompanyI18nKeys.Companies;
	/**
	 * The data to display in the table
	 */
	@Input({ required: true }) public readonly data: CompanyActivityEntity[];
}
