import { Component, Input } from '@angular/core';
import { Params, QueryParamsHandling, RouterLink } from '@angular/router';

/**
 * A component that renders an internal link. The navigation is done using the Angular Router.
 */
@Component({
	selector: 'vlaio-internal-link',
	template: ` <a
		class="u-outline u-link__underline font-bold"
		[routerLink]="link"
		[queryParams]="queryParams"
		[queryParamsHandling]="queryParamsHandling"
	>
		<ng-content></ng-content>
	</a>`,
	standalone: true,
	imports: [RouterLink]
})
export class VlaioInternalLinkComponent {
	/**
	 * The link to navigate to. This path may be either a string or an array of segments.
	 */
	@Input({ required: true }) readonly link: string | string[];

	/**
	 * The fragments (parameters) to be added to the URL query.
	 */
	@Input() readonly queryParams: Params;

	/**
	 * How to handle query parameters in the URL.
	 *
	 * Default value is `merge`.
	 */
	@Input() readonly queryParamsHandling: QueryParamsHandling = 'merge';
}
