import { Injectable } from '@angular/core';
import Cookies from 'js-cookie';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { BrowserService } from '@vlaio/shared/core';
import { UserEntity, UserService } from '@vlaio/shared/user';
import { environment } from 'environments';

import { GTagCategory, GTagCategoryKey, GtagType, GtagTypeKey } from '../interfaces';

enum GoogleAnalyticsDimension {
	dimension1 = 'Maatregelnaam',
	dimension3 = 'Product',
	dimension4 = 'SearchQuery',
	dimension5 = 'Ondernemingsidentificatie',
	dimension6 = 'Hoedanigheid',
	dimension7 = 'AangemeldeGebruikers'
}

// Decorators
// -------------------------------------------------------------------------- /
@Injectable({
	providedIn: 'root'
})

// Class Definition
// -------------------------------------------------------------------------- /
export class GTagService {
	// Private properties
	// ------------------------------------------------------------------------ /
	private trackingActive: boolean;
	private gtagManager;
	private readonly destroy$ = new Subject();
	private readonly gTagTrackingCode: string = environment.gtag.trackingCode;

	// Public properties
	// ------------------------------------------------------------------------ /
	constructor(private readonly userService: UserService, private readonly browserService: BrowserService) {
		this.trackingActive = false;
	}

	// Track a page event
	public trackPage(title: string, location: string, path: string) {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('config', this.gTagTrackingCode, {
				type: 'page',
				page_title: title,
				page_location: location,
				page_path: path,
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.companyNumberHash
			});
		});
	}

	// Track an event
	public trackEvent(type: string, category: string, label: string) {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('event', type, {
				event_category: category,
				event_label: label,
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.companyNumberHash
			});
		});
	}

	//TODO: Iben: At one point we need to replace the regular track event with this one, so we know exactly what is tracked throughout the application
	/**
	 * Track an event in GTAG with predefined tags
	 *
	 * @param type - The type of the event
	 * @param category - The subcategory of the type
	 * @param label - A free label to add to the event, by default it's 'Klik'
	 * @memberof GTagService
	 */
	public trackListedEvent(type: GtagTypeKey, category: GTagCategoryKey, label?: string) {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('event', GtagType[type], {
				event_category: GTagCategory[category],
				// Iben: We don't use a prefilled value in the function here, as it can be passed down as undefined by the directives
				event_label: label || 'Klik',
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.companyNumberHash
			});
		});
	}

	/**
	 * Track a case link referral
	 *
	 * @param type - The type of case
	 * @param product - The product of the case
	 * @memberof GTagService
	 */
	public trackCase(type: string, product: string) {
		this.trackEvent(type, 'Lopende aanvragen', product);
	}

	/**
	 * Track the target of the user
	 *
	 * @param targetCode - Code of the target of the user
	 */
	public trackTarget(targetCode: string) {
		this.handleTracking((mananger) => {
			mananger('config', this.gTagTrackingCode, {
				custom_map: {
					dimension6: GoogleAnalyticsDimension.dimension6,
					dimension7: GoogleAnalyticsDimension.dimension7
				},
				anonymize_ip: true
			});

			mananger('event', 'doelGroep', {
				[GoogleAnalyticsDimension.dimension6]: targetCode,
				[GoogleAnalyticsDimension.dimension7]: '1',
				anonymize_ip: true
			});
		});
	}

	// Track a click on the ebox button.
	public trackEboxButton(type: string, label: 'Naar EBox' | 'Activeer EBox' = 'Naar EBox') {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('event', type, {
				event_category: 'ebox',
				event_label: label,
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.companyNumberHash
			});
		});
	}

	/**
	 * Track a button click
	 *
	 * @param type - Type of item
	 * @param label - Label of the event
	 * @memberof GTagService
	 */
	public trackButton(type: string, label: string) {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true
			});

			manager('event', type, {
				event_category: 'Button click',
				event_label: label,
				anonymize_ip: true,
				[GoogleAnalyticsDimension.dimension5]: this.userService.companyNumberHash
			});
		});
	}

	/**
	 * Track a filter click
	 *
	 * @param type - Type of item
	 * @param label - Label of the event
	 * @memberof GTagService
	 */
	public trackFilterClick(type: string, label: string) {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				anonymize_ip: true
			});

			manager('event', type, {
				event_category: 'Filter Click',
				event_label: label,
				anonymize_ip: true
			});
		});
	}

	// Track an event
	public trackMeasure(type: string, category: string, label: string, measureTitle: string) {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension1: GoogleAnalyticsDimension.dimension1,
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true,
				transport_type: 'beacon'
			});

			manager('event', type, {
				event_category: category,
				event_label: label,
				[GoogleAnalyticsDimension.dimension1]: measureTitle,
				[GoogleAnalyticsDimension.dimension5]: this.userService.companyNumberHash,
				anonymize_ip: true
			});
		});
	}

	// Track an event
	public trackDirectLink(type: string, category: string, label: string, products: string[]) {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension3: GoogleAnalyticsDimension.dimension3,
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true,
				transport_type: 'beacon'
			});

			manager('event', type, {
				event_category: category,
				event_label: label,
				[GoogleAnalyticsDimension.dimension3]: products.join(','),
				[GoogleAnalyticsDimension.dimension5]: this.userService.companyNumberHash,
				anonymize_ip: true
			});
		});
	}

	// Track an event
	public trackMeasureSearch(query: string) {
		this.handleTracking((manager) => {
			manager('config', this.gTagTrackingCode, {
				custom_map: {
					dimension4: GoogleAnalyticsDimension.dimension4,
					dimension5: GoogleAnalyticsDimension.dimension5
				},
				anonymize_ip: true,
				transport_type: 'beacon'
			});

			manager('event', 'zoek_maatregelen', {
				event_category: 'maatregelen',
				event_label: 'Zoek maatregelen',
				[GoogleAnalyticsDimension.dimension4]: query,
				[GoogleAnalyticsDimension.dimension5]: this.userService.companyNumberHash,
				anonymize_ip: true
			});
		});
	}

	// Tracking was activated.
	public init() {
		this.browserService.runInBrowser(({ browserWindow }) => {
			this.trackingActive = true;

			this.gtagManager = browserWindow['gtag'];

			if (!this.gtagManager) {
				console.error('No GTagManager was found');

				return;
			}

			this.gtagManager('js', new Date());
			this.gtagManager('config', this.gTagTrackingCode, {
				anonymize_ip: true,
				transport_type: 'beacon'
			});

			this.userService.user$
				.pipe(
					filter<UserEntity>(Boolean),
					tap(({ targetCode }) => {
						this.trackTarget(targetCode);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe();
		});
	}

	/**
	 * Removes all the necessary Google Analytics data
	 *
	 * @memberof GTagService
	 */
	public removeGA() {
		this.browserService.runInBrowser(({ browserDocument }) => {
			// Iben: Set the tracking to inactive
			this.trackingActive = false;

			// Iben: Removes the script tag
			browserDocument.getElementById('GTAG-SCRIPT')?.remove();
			browserDocument.getElementById('GTAG-INIT-SCRIPT')?.remove();

			// Iben: Loop over all cookies and remove the Google Analytics ones
			const cookies = browserDocument.cookie?.split('; ') || [];

			cookies.forEach((cookie) => {
				// Iben: If it's not a GA cookie, we early exit
				if (!cookie.includes('_ga')) {
					return;
				}

				// Iben: Remove the cookie
				Cookies.remove(cookie.split('=')[0], { path: '', domain: environment.cookieDomain });
			});
		});
	}

	/**
	 * Wraps the tracking into a handler that will only apply the tracking when we're in the browser and when the tracking is activated
	 *
	 * @param action - The tracking function we wish to perform
	 */
	private handleTracking(action: (manager) => void) {
		// Iben: Run the tracking only in the browser
		this.browserService.runInBrowser(({ browserWindow }) => {
			const manager = browserWindow['gtag'];

			// Iben: Run the tracking only when there's a manager and when tracking is active
			if (Boolean(manager) && this.gtagManager && this.trackingActive) {
				action(this.gtagManager);
			}
		});
	}
}
