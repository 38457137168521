import { Injectable } from '@angular/core';
import { ObservableString } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SessionService, VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationApiService {
	constructor(
		private readonly httpClient: VlaioHttpClientService,
		private readonly sessionService: SessionService
	) {}

	logOut(): ObservableString {
		return this.httpClient
			.get<{ doorstuurLocatie: string }>(ELoketEndpoints.Authentication.Logout(), {}, true)
			.pipe(map(({ doorstuurLocatie }) => doorstuurLocatie));
	}

	logOutWithEid(callback: string = ''): Observable<void> {
		return this.httpClient.directGet(
			`${this.sessionService.getACMIDMDomain()}${ELoketEndpoints.Authentication.LogoutWitEId()}${callback}`,
			{},
			true
		);
	}
}
