import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@ngspot/ngx-errors';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { VlaioDisclaimerComponent } from '@vlaio/shared/ui/common';

import { BaseComponent, VlaioFormErrorComponent } from './components';
import { providerServices, SpotlightService } from './services';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		NgxErrorsModule,
		NgxI18nModule.forChild(),
		VlaioDisclaimerComponent,
		BaseComponent,
		VlaioFormErrorComponent
	],
	providers: [...providerServices, SpotlightService],
	exports: [BaseComponent, VlaioFormErrorComponent]
})
export class ELoketCoreModule {}
