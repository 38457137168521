<vlaio-backdrop>
	<div class="c-modal" [ngClass]="{ 'c-modal--small': small }">
		@if (small && !hideCloseButton) {
		<div class="c-modal__close">
			<button class="c-button-secondary icon-only" (click)="handleClickClose()">
				<span class="icon-close"></span>
			</button>
		</div>
		} @if (!hideHeader) {
		<div class="c-modal__header">
			<h3 class="c-modal__title">{{ title }}</h3>

			@if (!small) {
			<button class="c-button-secondary icon-only" (click)="handleClickClose()">
				<span class="icon-close"></span>
			</button>
			}
		</div>
		}

		<div class="c-modal__body">
			<ng-content select="[modalBody]"></ng-content>
		</div>

		@if (!hideFooter) {
		<div class="c-modal__footer">
			<ng-content select="[modalFooter]"></ng-content>
		</div>
		}
	</div>
</vlaio-backdrop>
