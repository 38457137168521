<div>
	<div class="c-modal__header">
		<h3 class="c-modal__title">{{ title }}</h3>
	</div>

	<div class="c-modal__body">
		<p>
			{{ text }}
		</p>
	</div>

	<div class="c-modal__footer">
		<vlaio-button class="c-modal__action" [title]="confirmLabel" (click)="buttonClicked.emit('confirm')">
			{{ confirmLabel }}
		</vlaio-button>

		<vlaio-button
			class="c-modal__action"
			buttonType="RegularOutline"
			[title]="cancelLabel"
			(click)="buttonClicked.emit('cancel')"
		>
			{{ cancelLabel }}
		</vlaio-button>
	</div>
</div>
