import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { I18nKeys } from '@vlaio/shared/ui/forms';

@Component({
	selector: 'vlaio-clear-search',
	templateUrl: './clear-search.component.html',
	standalone: true,
	imports: [NgxI18nModule, CypressTagDirective]
})
export class VlaioClearSearchComponent {
	public readonly i18nKeys = I18nKeys;

	/**
	 * If set to `true`, the close icon of the button is shown
	 *
	 * Default value is set to `true`
	 */
	@Input() public displayCloseIcon: boolean = true;

	/**
	 * When the button is pressed, the event is emitted.
	 */
	@Output() public cleared = new EventEmitter<void>();
}
