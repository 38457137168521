<div class="c-preloader">
	@if (imageSrc) {

	<img class="c-preloader__logo-image" priority [ngSrc]="imageSrc" />
	<span class="c-preloader__info">{{ loadingText }}</span>

	} @else {

	<span class="icon-flanders c-preloader__logo"></span>
	<span class="c-preloader__info">{{ loadingText }}</span>

	}
</div>
