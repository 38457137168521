import { Component, Input } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';

/**
 * Use content projection to display the [title], [link] and [content].
 * The component works either way, but works best if and when all three content
 * projections are provided.
 */
@Component({
	selector: 'info-wrapper',
	templateUrl: './info-wrapper.component.html',
	styleUrl: './info-wrapper.component.scss',
	standalone: true,
	imports: [CypressTagDirective]
})
export class InfoWrapperComponent {
	/**
	 * If provided, a number will be shown next to the title.
	 */
	@Input() public count: string;
}
