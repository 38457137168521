import { TranslationLoaderGuard, provideWithTranslations } from '@studiohyperdrive/ngx-i18n';

import { AppRoutePaths, OffersRoutePaths, VlaioRoutes } from '@vlaio/shared/route-paths';

import { ProductsTranslationLoader } from './translation.loader';

export const ProductRoutes: VlaioRoutes = [
	provideWithTranslations(
		{
			path: AppRoutePaths.Empty,
			loadComponent: () => import('./pages').then((m) => m.ProductsPageComponent),
			canActivate: [TranslationLoaderGuard]
		},
		ProductsTranslationLoader
	),
	provideWithTranslations(
		{
			path: `${OffersRoutePaths.OffersDetail}/:id`,
			canActivate: [TranslationLoaderGuard],
			loadComponent: () => import('./pages').then((m) => m.ProductPageComponent)
		},
		ProductsTranslationLoader
	)
];
