@if (company) {
	@if (showTitle) {
		<h2>
			{{ i18nKeys.Activities.Activities | translate }}
		</h2>
	}
	@if (showEnterpriseActivities) {
		<div class="u-padding-bottom">
			<vlaio-company-activity
				cypressTag="Companies.Detail.Activities.Main"
				[title]="(i18nKeys.Info.RegisteredOffice | translate) + ' (' + (company.number | btw) + ')'"
				[defaultIsOpenState]="enterpriseOpen"
				[activities]="company.activities"
				[address]="company.registeredOffice"
				[isEnterprise]="true"
			/>
		</div>

		@if (showBranchActivities) {
			@for (branch of company.branches; track branch; let index = $index) {
				<div [class.u-padding-bottom]="index !== company.branches.length - 1">
					<vlaio-company-activity
						cypressTag="Companies.Detail.Activities.Branch"
						[title]="
							(i18nKeys.Branches.Branch | translate) +
							' (' +
							((branch.number | btw) || branch.names.commercial) +
							')'
						"
						[activities]="activitiesByBranch[company.number + '-' + branch.number]"
						[address]="branch.address"
						[loading]="loading"
						[error]="error"
						(activitiesRequested)="activitiesRequestedForBranch.emit(branch.number)"
					/>
				</div>
			}
		}
	}
}
