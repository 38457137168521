export enum ButtonClasses {
	Regular = 'c-button',
	RegularDecline = 'c-button c-button--red',
	RegularFullWidth = 'c-button c-button--full-width',
	Outline = 'c-button c-button--outline',
	OutlineFullWidth = 'c-button c-button--outline c-button--full-width',
	Link = 'c-button c-button--link',
	LinkNoDecoration = 'c-button c-button--link c-button--link--no-decoration',
	LinkButtonOutline = 'c-button c-button--blue-outline',
	LinkButtonOutlineSolid = 'c-button c-button--blue-outline-solid',
	IconLeft = 'c-button--icon-left',
	IconRight = 'c-button--icon-right',
	Action = 'c-button--action'
}
