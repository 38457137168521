import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { i18nKeysEntity, ExitModalOptions } from '../interfaces';

@Directive()
export abstract class ExitWizardModalAbstractComponent {
	@Input() public i18nKeys: i18nKeysEntity;

	@Output() public closeModal = new EventEmitter<ExitModalOptions>();
}
