import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

import { OnDestroyComponent } from '@vlaio/shared/types';
import { VlaioTreeItemComponent } from '@vlaio/shared/ui/common';

import { NaceBelEntity, SelectedNacebelEntity } from '../../../data';
import { isNacebelOpenPipe } from '../../pipes/is-nacebel-open.pipe';
import { createNaceBelForm } from '../../utils';
import { NacebelTitleComponent } from '../nacebel-title/nacebel-title.component';
import { NacebelTreeItemComponent } from '../nacebel-tree-item/nacebel-tree-item.component';

const INPUT_CONTROL_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => NacebelTreeComponent),
	multi: true
};
@Component({
	selector: 'vlaio-nacebel-tree',
	templateUrl: './nacebel-tree.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [INPUT_CONTROL_ACCESSOR],
	standalone: true,
	imports: [VlaioTreeItemComponent, NacebelTitleComponent, NacebelTreeItemComponent, isNacebelOpenPipe]
})
export class NacebelTreeComponent extends OnDestroyComponent implements ControlValueAccessor {
	@Input() set nacebelCodes(codes: NaceBelEntity[]) {
		// Iben: Set the current codes for the rendering in the template
		this.codes = codes;

		// Iben: If there are no codes, the codes are empty or we already have a form, early exit
		if (!codes || codes.length === 0 || this.form) {
			return;
		}

		// Iben: Create a form based on the provided codes
		this.form = createNaceBelForm(codes);

		// Iben: Subscribe to the form changes
		this.form.valueChanges
			.pipe(
				distinctUntilChanged(),
				tap(() => {
					this.onTouch();
					this.onModelChange(this.form.value);
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	@Input() set selectedActivities(activities: SelectedNacebelEntity[]) {
		// Iben: Create a new isOpenSet
		this.isOpenSet = new Set();

		// Iben: Loop over the activities and patch the values
		(activities || []).forEach(({ path, ...activity }) => {
			// Iben: We want an emit event as the form is supposed to be dirty after this
			this.form.get(path).patchValue(activity);

			// Iben: Add the item to the isOpenSet so we know which items should be opened
			path.split('.').forEach((item) => this.isOpenSet.add(item));
		});
	}

	@Input() searchResults: Set<string>;
	@Input() filterNacebelCodes: boolean;

	public form: FormGroup;
	public codes: NaceBelEntity[] = [];
	public isDisabled: boolean = false;
	public isOpenSet: Set<string> = new Set();

	private onTouch: Function = () => {};
	private onModelChange: Function = (_: any) => {};

	writeValue(value: any): void {
		// Iben: Early exit to avoid issues when the form isn't created yet
		if (!this.form) {
			return;
		}

		// Iben: patch the incoming value
		this.form.patchValue(value, { emitEvent: false });
	}

	registerOnChange(fn: any): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
}
