@if (title || content) {
<article>
	@if (showTitle) {
	<h1>
		{{ title }}
	</h1>
	}
	<div #pageContent [innerHtml]="content"></div>
</article>
}
