<h1>
	{{ title }}

	<vlaio-icon
		icon="fa-times"
		[attr.title]="i18nKeys.Tour.Close.Title | translate"
		(focusClick)="handleInteraction.emit('close')"
	/>
</h1>

<p>
	{{ content }}
</p>

<button [title]="i18nKeys.Tour.Start.Title | translate" (click)="handleInteraction.emit('next')">
	{{ i18nKeys.Tour.Start.Text | translate }}
</button>
