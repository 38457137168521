import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'vlaio-value-tile',
	templateUrl: './value-tile.component.html',
	styleUrls: ['./value-tile.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class ValueTileComponent {}
