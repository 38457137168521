import { Injectable } from '@angular/core';
import { NgxCookieService } from '@studiohyperdrive/ngx-cookies';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { authenticationCookie, savedKBOCookie, spotlightProductsCallbackQueryParam } from '@vlaio/shared/cookies';
import { BrowserService, SessionService } from '@vlaio/shared/core';
import { LanguageService } from '@vlaio/shared/i18n';
import { AppRoutePaths } from '@vlaio/shared/route-paths';
import { ACMTargetGroups } from '@vlaio/shared/types';
import { isValidHint } from '@vlaio/shared/utils';
import { environment, EnvironmentType } from 'environments';

import { AuthenticationFailedTypes } from '../enums';
import { LoginAsEconomicActorOptionsEntity, LoginOptionsEntity } from '../interfaces';

import { AuthenticationApiService } from './authentication.api.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	private readonly authenticationFailedTypeSubject$ = new BehaviorSubject<AuthenticationFailedTypes | undefined>(
		undefined
	);
	private API_BASE: string = this.sessionService.api;

	public readonly isAuthenticated$: ObservableBoolean = this.cookieService
		.getCookieObservable<boolean>(authenticationCookie)
		.pipe(map(Boolean));
	public readonly authenticationFailed$: Observable<AuthenticationFailedTypes | undefined> =
		this.authenticationFailedTypeSubject$.asObservable();

	constructor(
		private readonly apiService: AuthenticationApiService,
		private readonly sessionService: SessionService,
		private readonly languageService: LanguageService,
		private readonly cookieService: NgxCookieService,
		private readonly browserService: BrowserService
	) {}

	/**
	 * Get whether the user is authenticated
	 */
	get authenticated() {
		return this.cookieService.getCookie(authenticationCookie);
	}

	/**
	 * Remove all authentication cookies
	 */
	public dropAuthentication() {
		// Iben: Remove the cookies
		this.cookieService.removeCookie(authenticationCookie);
		this.cookieService.removeCookie(savedKBOCookie);
	}

	public logout() {
		// Clear the cookies before requesting logout on the server
		this.dropAuthentication();

		// Kaat: Reset the authenticated failed state
		this.authenticationFailedTypeSubject$.next(undefined);

		return this.apiService.logOut();
	}

	/**
	 * Login the user using the ACM login flow
	 *
	 * @param options - Options we wish to use to log in the user
	 */
	public login(options: LoginOptionsEntity) {
		// Iben: Split the options into separate variables
		const { spotlightProducts, customCallBack, capHint } = options;

		// Brecht: protocol + host
		const baseUrl: string = `${environment.acmidm.protocol}://${environment.acmidm.hostname}`;
		// Brecht: loginPath
		const loginPathUrl: string = `/${environment.acmidm.loginPath}`;
		// Brecht: specify all the query parameters
		let queryParams;

		// Iben: allow to add a custom callback
		let callback = customCallBack || `/${this.languageService.currentLanguage}/${AppRoutePaths.Loket}`;

		// Iben: Add the auth success param
		callback += '?remoteAuthSuccess=true';

		// Iben: If spotlight products were passed, we add them to the callback
		if (spotlightProducts) {
			callback = `${callback}&${spotlightProductsCallbackQueryParam}=${spotlightProducts}`;
		}

		// Brecht: encode callback value
		queryParams = `callback=${encodeURIComponent(callback)}`;

		// Iben: If we are in the local application, we attach the redirect param
		queryParams += this.getLocalRedirectUrl();

		// Iben: If there's a code hint, we add it to the queryParams
		if (capHint) {
			const loginHint = JSON.stringify(
				// TODO: Iben: Find better way to type this to prevent the cast
				capHint === ACMTargetGroups.BUR
					? { cap_hint: capHint }
					: { cap_hint: capHint, code_hint: (options as LoginAsEconomicActorOptionsEntity).codeHint }
			);

			if (isValidHint(btoa(loginHint))) {
				// Brecht: base64 encode loginHint
				queryParams += `&login_hint=${btoa(loginHint)}`;
			}
		}

		// Iben: Login the user when the user is not logged in, else log-out the user and login again with the correct queryParams as directed-switch does not exist
		return this.isAuthenticated$.pipe(
			take(1),
			switchMap((isAuthenticated) => {
				return isAuthenticated ? this.logout() : of(isAuthenticated);
			}),
			tap(() => {
				this.browserService.runInBrowser(({ browserWindow }) => {
					browserWindow.location.href = `${baseUrl}${loginPathUrl}?${queryParams}`;
				});
			})
		);
	}

	/**
	 * Log out with EID
	 */
	public logoutEID() {
		// Iben: Drop authentication cookies
		this.dropAuthentication();

		// Kaat: Reset the authenticated failed state
		this.authenticationFailedTypeSubject$.next(undefined);

		return this.apiService.logOutWithEid(this.getLocalRedirectUrl());
	}

	/**
	 * Set authentication cookie for the user
	 *
	 * @param value - Whether or not the user is authenticated, by default this is true
	 */
	public setAuthenticationCookie(value: string = 'true') {
		// Iben: Set a boolean cookie that expires when the Drupal Session cookie expires
		this.cookieService.setCookie({ name: authenticationCookie, value });
	}

	/**
	 * Returns the authenticated cookie
	 */
	public getAuthorizationCookie(): string {
		return this.cookieService.getCookie(authenticationCookie);
	}

	/**
	 * Indicate that the authentication process has failed
	 */
	public setAuthenticationFailed(failType: AuthenticationFailedTypes): void {
		this.authenticationFailedTypeSubject$.next(failType);
	}

	/**
	 * Returns the redirect url if needed
	 */
	getLocalRedirectUrl(connector: '&' | '?' = '&'): string {
		// Iben: To connect with the dev environment, we pass the domain when we are in the local version of the app
		return environment.environment === EnvironmentType.LOCAL
			? `${connector}redirect_url=${environment.domain}`
			: '';
	}
}
