import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';

import { VlaioIconComponent } from '../icon/icon.component';

@Component({
	selector: 'vlaio-carrousel',
	templateUrl: './carrousel.component.html',
	styleUrl: './carrousel.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioIconComponent, DragScrollComponent, DragScrollItemDirective, NgTemplateOutlet]
})
export class VlaioCarrouselComponent {
	/**
	 * The carrousel component
	 */
	@ViewChild('carrousel', { read: DragScrollComponent }) private readonly dragScroll: DragScrollComponent;

	/**
	 * The template for the items in the carrousel
	 */
	@ContentChild('itemTmpl', { static: false }) public itemTemplate: TemplateRef<unknown>;

	/**
	 * The data we wish to show in the carrousel
	 */
	@Input({ required: true }) public data: unknown[];

	/**
	 * Move the carrousel in the proposed direction
	 *
	 * @param direction - The direction, left or right
	 */
	public move(direction: 'left' | 'right'): void {
		direction === 'left' ? this.dragScroll.moveLeft() : this.dragScroll.moveRight();
	}
}
