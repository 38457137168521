<details [open]="defaultIsOpenState" (toggle)="toggleDetail()">
	<summary tabindex="0" [title]="i18nKeys.Activities.Title | translate : { title: displayTitle }">
		<span class="font-bold primary-color">{{ displayTitle }}</span>
		<fa-icon icon="fa-chevron-down" />
	</summary>
	<div class="content">
		<vlaio-content [loading]="loading && !activities" [error]="error">
			@if (address && !isEnterprise) {
			<div class="content-item">
				<h5 class="font-bold content-item-title">
					{{ i18nKeys.Branches.Address | translate }}
				</h5>

				<vlaio-address format="multi-line" [address]="address" />
			</div>
			}

			<div class="content-item table-wrapper">
				<h5 class="font-bold content-item-title">
					{{ i18nKeys.Activities.Activities | translate }}
				</h5>

				@if (activities?.length > 0) {
				<activity-table [data]="activities" />
				} @else {

				<p class="content-item">{{ i18nKeys.Activities.NoActivities | translate }}</p>

				}
			</div>
		</vlaio-content>
	</div>
</details>
