// Angular Imports
// ------------------------------------------------------------------------- /
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { I18nService } from '@studiohyperdrive/ngx-i18n';

import { BrowserService } from '@vlaio/shared/core';

// Module Imports
// ------------------------------------------------------------------------- /
import { GTagService } from '../services';

/**
 * Track the page event in Google Analytics
 *
 * @param route - Activated route
 * @param state - Current state
 */
export const GTagGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
	// Iben: Fetch all injectables
	const gtagService: GTagService = inject(GTagService);
	const i18nService: I18nService = inject(I18nService);
	const browserService: BrowserService = inject(BrowserService);

	// Iben: Get the path and the gtagData
	const path = state.url;
	const routeGtagData = route?.data?.gtag || undefined;

	// Iben: If there's tracking data, we track the page
	browserService.runInBrowser(({ browserWindow }) => {
		if (routeGtagData && routeGtagData.title) {
			gtagService.trackPage(
				`${i18nService.getTranslation(routeGtagData.title || '')} - e-loketondernemers.be`,
				`${browserWindow.location.origin}/path`,
				path
			);
		}
	});

	// Iben: Default return to true to always go to the page we intended to route to
	return true;
};
