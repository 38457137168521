import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'vlaio-results',
	templateUrl: './results.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule]
})
export class ResultsComponent {
	/**
	 * Number of results
	 */
	@Input() public amount: number = 0;
	/**
	 * Whether or not we should show a "No results" label in case the amount is 0; default true
	 */
	@Input() public showNoResultsLabel: boolean = true;

	public i18nKey = I18nKeys.Results;
}
