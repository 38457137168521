import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject } from 'rxjs';

import { LanguageService } from '@vlaio/shared/i18n';

import { Breadcrumb } from '../interfaces/breadcrumb.interface';

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {
	private readonly breadcrumbs: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject([]);
	private readonly homeBreadcrumb: Breadcrumb = {
		title: 'Home',
		routePath: ['/', this.languageService.currentLanguage],
		allowNav: true
	};

	/**
	 * An array of breadcrumbs for the entire application
	 */
	public readonly breadcrumbs$: ObservableArray<Breadcrumb> = this.breadcrumbs.asObservable();

	constructor(private readonly languageService: LanguageService) {}

	/**
	 * Set the breadcrumbs that need to be displayed in the breadcrumb component
	 *
	 * @param breadcrumbs - A series of breadcrumbs we wish to display in the breadcrumb component
	 * @param prefixHome - Whether or not we want the breadcrumbs to be prefixed with a Home crumb. By default true.
	 */
	public setBreadcrumbs(breadcrumbs: Breadcrumb[], prefixHome = true): void {
		this.breadcrumbs.next([
			...(prefixHome ? [this.homeBreadcrumb] : []),
			...[...breadcrumbs].map((breadcrumb) => {
				// Iben: If we don't want to add the language to the routePath we return the breadcrumb
				if (breadcrumb.withoutLanguage) {
					return breadcrumb;
				}

				// Iben: Add the current language to the route path
				return {
					...breadcrumb,
					routePath: ['/', this.languageService.currentLanguage, ...breadcrumb.routePath]
				};
			})
		]);
	}

	/**
	 * Empties the breadcrumbs for the breadcrumb component
	 */
	public clearBreadcrumbs(): void {
		this.breadcrumbs.next([]);
	}
}
