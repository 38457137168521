/**
 * All the available button colors.
 *
 * Be cautious when using these directly, as they are
 * already used in the {@link ButtonVariants} and will cause
 * overwrites and thus inconsistencies in the design.
 */
export const ButtonColors = {
	Primary: 'button-color__primary',
	Orange: 'button-color__orange',
	Red: 'button-color__red',
	Grey: 'button-color__grey'
} as const;

/**
 * All the available button styles.
 */
export const ButtonVariants = {
	Regular: `c-button ${ButtonColors.Primary}`,
	RegularOutline: `c-button ${ButtonColors.Primary}-outline`,
	Neutral: `c-button ${ButtonColors.Grey}`,
	NeutralOutline: `c-button ${ButtonColors.Grey}-outline`,
	Decline: `c-button ${ButtonColors.Red}`,
	Action: `c-button ${ButtonColors.Orange}`,
	Link: 'link-button'
} as const;
