<div class="c-detail-content">
	<h3 class="c-detail-content-title">
		<ng-content select="fa-icon"></ng-content>

		{{ title }}
	</h3>

	<div class="c-detail-content-detail">
		<ng-content select="section"></ng-content>
	</div>
</div>

<div class="c-detail-content-aside">
	<ng-content select="aside"></ng-content>
</div>
