import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';

import { VlaioEventLinkComponent, VlaioInternalLinkComponent } from '@vlaio/shared/ui/common';

import { Breadcrumb, BreadcrumbService } from '../../../data';
@Component({
	selector: 'vlaio-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['breadcrumb.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterLink, AsyncPipe, TranslateModule, VlaioInternalLinkComponent, VlaioEventLinkComponent]
})
export class VlaioBreadcrumbComponent {
	/**
	 * The breadcrumbs that currently need displaying.
	 */
	public readonly breadcrumbs$: ObservableArray<Breadcrumb> = this.breadcrumbService.breadcrumbs$;

	constructor(private readonly breadcrumbService: BreadcrumbService) {}
}
