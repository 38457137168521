import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { ButtonClasses } from './button.enums';

@Component({
	selector: 'vlaio-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass]
})
export class ButtonComponent {
	/**
	 * Disable the tabbing of the host itself, only the internal button should be highlightable.
	 */
	@HostBinding('attr.tabindex') private readonly tabindex: number = -1;
	/**
	 * Whether or not the button should be full width.
	 */
	@HostBinding('class.c-button--full-width') @Input() public fullWidth: boolean = false;
	/**
	 * The tooltip of the button. This should not be the same as the text.
	 *
	 * This is required since WCAG AA-label dictates it.
	 */
	@Input({ required: true }) public title: string = '';

	/**
	 * Whether the button should show a loading state
	 */
	@Input() public loading: boolean = false;

	/**
	 * The styling to apply to the button.
	 *
	 * Default value is `Regular`
	 */
	@Input() public classes: ButtonClasses | string = ButtonClasses.Regular;
	/**
	 * The class of the icon we wish to display
	 */
	@Input() public icon: string;
	/**
	 * Whether or not the default icon class should be overridden.
	 *
	 * Default value is `false`
	 */
	@Input() public overrideIconClass: boolean = false;
	/**
	 * Whether or not the text should be dark.
	 *
	 * Default value is `false`
	 */
	@Input() public darkText: boolean = false;
	/**
	 * Whether or not the button should be disabled.
	 *
	 * Default value is `false`
	 */
	@Input() public isDisabled: boolean = false;
	/**
	 * Provide a 'type' for the button.
	 *
	 * This property follows the options and behavior of the spec:
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
	 */
	@Input() public type: 'submit' | 'reset' | 'button' = null;

	/**
	 * Emits when the button has been pressed.
	 */
	@Output() public handleClick: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * If the button is not disabled, emit the click.
	 */
	public handleButtonClick(): void {
		if (!this.isDisabled) {
			this.handleClick.emit();
		}
	}
}
