import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { environment } from '~environment';

import { LanguageService } from '../../services';

export const LanguageGuard: CanActivateFn = (): false => {
	const router: Router = inject(Router);

	const localStorageLanguage = localStorage.getItem('vlaio-language');

	const language = environment.languages.includes(localStorageLanguage) ? localStorageLanguage : 'nl';

	router.navigate([language]);

	return false;
};

export const SetLanguageGuard: CanActivateFn = (route: ActivatedRouteSnapshot): true => {
	const languageService: LanguageService = inject(LanguageService);

	if (
		!route.params.language ||
		(languageService.currentLanguage && languageService.currentLanguage === route.params.language)
	) {
		return true;
	}

	languageService.setLanguage(route.params.language);

	return true;
};
