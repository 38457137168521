import { ChangeDetectionStrategy, Component, HostBinding, Input, booleanAttribute } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { createAccessorProviders, FormAccessor } from '@studiohyperdrive/ngx-forms';

import { VlaioIconComponent } from '../icon/icon.component';

import { HTMLInputTypes } from './pre-labeled-input.types';

@Component({
	selector: 'vlaio-pre-labeled-input',
	templateUrl: './pre-labeled-input.component.html',
	styleUrls: ['./pre-labeled-input.component.scss'],
	standalone: true,
	providers: createAccessorProviders(VlaioPreLabeledInputComponent),
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VlaioIconComponent, ReactiveFormsModule]
})
export class VlaioPreLabeledInputComponent extends FormAccessor<string, FormControl<string>> {
	/**
	 * Whether the component should be full-width.
	 *
	 * Default value is `true`.
	 */
	@HostBinding('class.is-full-width') @Input({ transform: booleanAttribute }) public fullWidth: boolean;
	/**
	 * Whether the input field is required.
	 */
	@Input({ transform: booleanAttribute }) public required: boolean;
	/**
	 * The label of the input field.
	 */
	@Input({ required: true }) public label: string;
	/**
	 * The id of the input field.
	 */
	@Input() public inputId: string;
	/**
	 * The possible input types for the input field.
	 */
	@Input() public inputType: HTMLInputTypes = 'text';
	/**
	 * A placeholder to display.
	 */
	@Input() public placeholder: string = '';
	/**
	 * The icon to display in the input field.
	 *
	 * If no icon is provided, no icon will be rendered.
	 */
	@Input() public icon: string = '';

	// Lifecycle methods
	// ==============================
	public initForm(): FormControl<string> {
		return new FormControl<string>(null);
	}
}
