import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, Input, Output, TemplateRef } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormAccessor, createAccessorProviders } from '@studiohyperdrive/ngx-forms';
import { debounce, distinctUntilChanged, filter, map, tap, timer } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { I18nKeys } from '../../../i18n';
@Component({
	selector: 'vlaio-dropdown-search',
	templateUrl: './dropdown-search.component.html',
	styleUrls: ['./dropdown-search.component.scss'],
	providers: [createAccessorProviders(DropdownSearchComponent)],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, ReactiveFormsModule, CypressTagDirective, NgTemplateOutlet, TranslateModule]
})
export class DropdownSearchComponent extends FormAccessor<unknown, FormControl<unknown>> {
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly searchField: FormControl<string> = new FormControl<string>(null);
	public searchedItems: unknown[] = [];
	public hasSearched: boolean = false;

	/**
	 * The items the user can select from
	 */
	@Input({ required: true }) public set items(items: unknown[]) {
		this.searchedItems = items || [];
	}

	/**
	 * The outline input attribute can be used to set the outline class on the input.
	 *
	 * Default value is `true`
	 */
	@Input() public outline: boolean = true;

	/**
	 * Placeholder for the search input
	 */
	@Input({ required: true }) public placeholder: string = '';
	/**
	 * Loading state of the search
	 */
	@Input({ required: true }) public loading: boolean;
	/**
	 * Error state of the search
	 */
	@Input({ required: true }) public error: boolean;
	/**
	 * Label when the search has failed
	 */
	@Input({ required: true }) public errorLabel: string;
	/**
	 * Whether the search results need to be cleared after selecting an item
	 *
	 * Default value is `true`
	 */
	@Input() public autoClearSearched: boolean = true;
	/**
	 * WCAG title for the input
	 *
	 * Fallback value is `Forms.Actions.Title`
	 */
	@Input() public title: string;
	/**
	 * Whether the search input needs to take up the full width
	 *
	 * Default value is `true`
	 */
	@Input() public fullWidth: boolean = true;
	/**
	 * A mapper to map the selected value as a human readable string
	 */
	@Input() public selectedMapper: (item: unknown) => string;
	/**
	 * Whether or not we want to show the selected value in the search input
	 *
	 * Default value is `false`
	 */
	@Input() public showSelected: boolean = false;

	/**
	 * A label for when there are no matches
	 */
	@Input({ required: true }) public emptyLabel: string;

	/**
	 * Whether or not we want to show the clear button
	 *
	 * Default value is `false`
	 */
	@Input() public showClearButton: boolean = true;

	/**
	 * Listen to the searched value of the user, debounced for 2000ms
	 */
	@Output() public readonly searchValueChanged = this.searchField.valueChanges.pipe(
		distinctUntilChanged(),
		tap(() => {
			// Iben: Mark that the user has started searching
			this.hasSearched = true;
		}),
		debounce((value) => {
			// Iben: If there's no value, it means the input was cleared so we instantly emit it upwards
			if (value === '' || value === null) {
				return timer(0);
			}

			// Iben: We debounce valueChange so that the user has time to type
			return timer(2000);
		}),
		map(() => this.searchField.value)
	);
	/**
	 * Listen to when the user starts typing in the search bar
	 */
	@Output() public readonly searchInitiated = this.searchField.valueChanges.pipe(
		distinctUntilChanged(),
		filter((value) => !(value === '' || value === null))
	);

	@ContentChild('listItemTmpl', { static: false })
	public listItemTemplate: TemplateRef<any>;

	public initForm(): FormControl<unknown> {
		return new FormControl();
	}

	public writeValue(value: unknown): void {
		super.writeValue(value);

		// Iben: Set the search field value if needed
		this.setSearchFieldValue(value);
	}

	/**
	 * Select the item from the list and patch it in the form
	 *
	 * @param item - The selected item
	 */
	public selectItem(item: unknown) {
		// Iben: Reset the searched value
		this.hasSearched = false;

		// Iben: Update form value
		this.form.setValue(item);

		// Iben: Set the search field value if needed
		this.setSearchFieldValue(item);

		// Iben: Clear the searched item if needed
		if (this.autoClearSearched) {
			this.searchedItems = [];
		}
	}

	/**
	 * Clears the search input
	 */
	public clearSearchInput() {
		// Iben: Clear the form value
		this.searchField.setValue(null);

		// Iben: Reset the searched value
		this.hasSearched = false;
	}

	/**
	 * Sets the search field value once an item in the list is selected
	 *
	 * @param item - The selected item
	 */
	private setSearchFieldValue(item: unknown) {
		// Iben: If we don't want to show the selected item, early exit
		if (!this.showSelected) {
			return;
		}

		// Iben: Set the search field depending on the mapper
		this.searchField.setValue(this.selectedMapper ? this.selectedMapper(item) : (item as string), {
			emitEvent: false
		});

		// Iben: Detect changes so that the clear button is always shown correctly
		this.cdRef.detectChanges();
	}
}
