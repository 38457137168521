import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	QueryList,
	ViewChildren
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { filter, takeUntil, tap } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CallToActionClickedEvent, OnDestroyComponent, ProductEntity, RecommendationRating } from '@vlaio/shared/types';
import { VlaioContentComponent, ButtonComponent } from '@vlaio/shared/ui/common';

import { I18nKeys } from '../../../i18n';
import { ProductResultComponent } from '../product-result/product-result.component';

@Component({
	selector: 'vlaio-product-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioContentComponent, ProductResultComponent, ButtonComponent, CypressTagDirective, TranslateModule]
})
export class ProductListComponent extends OnDestroyComponent implements AfterViewInit {
	@HostBinding('class') private readonly rootClass = ' c-measures__list';
	@ViewChildren('product', { read: ProductResultComponent })
	private readonly productResults: QueryList<ProductResultComponent>;
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	@Input() public products: ProductEntity[] = [];
	@Input() public loading: boolean = false;
	@Input() public error: boolean = false;
	@Input() public hasNextPage: boolean = false;
	@Input() public moreLoading: boolean = false;
	@Input() public recommendations: Record<string, RecommendationRating> = {};
	@Input() public isEmptyAfterFilteredSearch: boolean = false;
	@Input() public focussedOn: number;

	@Output() public loadMoreClicked = new EventEmitter<void>();
	@Output() public searchWithoutFiltersClicked = new EventEmitter<void>();
	@Output() public recommendationRated = new EventEmitter<{ productId: string; rating: RecommendationRating }>();
	/**
	 * Emits the action config when the Call To Action of a product is clicked.
	 */
	@Output() public ctaClicked: EventEmitter<CallToActionClickedEvent> = new EventEmitter<CallToActionClickedEvent>();

	constructor(private readonly cdRef: ChangeDetectorRef) {
		super();
	}
	// Lifecycle methods
	// ==============================
	public ngAfterViewInit(): void {
		// Iben: Subscribe on the product results visible in the DOM so we can focus on the correct item after loading more items
		this.productResults.changes
			.pipe(
				// Iben: If there are no results or no element to focus on, we skip
				filter((results) => results?.length > 0 && this.focussedOn !== undefined),
				tap((results) => {
					// Iben: Get the required element
					const element = results.toArray()[this.focussedOn]?.elementRef?.nativeElement;

					// Iben: Early exit if the element is not found
					if (!element) {
						return;
					}

					// Iben: Get first focusable element and focus on that one
					element.querySelectorAll('button, [href], input, [tabindex="0"]')[0].focus();

					// Iben: Call a change detection cycle
					this.cdRef.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
