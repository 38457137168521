import {
	NotificationSeverity,
	NotificationResultEntity,
	Notification,
	CompanyNotificationKeyValueResultEntity,
	NotificationResultKeys,
	CompanyNotificationResultEntity,
	CompanyNotificationEntity,
	CompanyNotificationKeyValueEntity
} from '../interfaces';

export const NotificationEntityMock: Notification = {
	title: 'test title',
	message: 'test message',
	severity: NotificationSeverity.Info,
	id: 'test id'
};

export const NotificationResultEntityMock: NotificationResultEntity = {
	naam: 'test title',
	bericht: 'test message',
	ernst: NotificationSeverity.Info,
	id: 'test id'
};

/**
 * The entity used in a CompanyNotificationResultEntity.
 */
export const CompanyNotificationsKeyValuesResultMock: CompanyNotificationKeyValueResultEntity[] = [
	{ sleutel: 'ExterneLinkNaam' as NotificationResultKeys.ExterneLinkNaam, waarde: 'waarde1' },
	{ sleutel: 'NotificatieDatum' as NotificationResultKeys.NotificatieDatum, waarde: 'waarde2' }
];

/**
 * The mapped key-value of the company notification result.
 */
export const CompanyNotificationsKeyValuesMock: Partial<CompanyNotificationKeyValueEntity> = {
	[NotificationResultKeys.ExterneLinkNaam]: 'waarde1',
	[NotificationResultKeys.NotificatieDatum]: 'waarde2'
};

/**
 * A mock of the company notification result.
 */
export const CompanyNotificationResultMock: CompanyNotificationResultEntity = {
	afzender: {
		korteNaam: 'tst-afzndr',
		naam: 'test-afzender'
	},
	bestemmeling: '0123456789',
	datum: '01-01-2000',
	id: 'id-001',
	isOngelezen: true,
	sleutelWaardeParen: {
		elementen: CompanyNotificationsKeyValuesResultMock
	},
	titel: 'test-titel'
};

/**
 * A mock of the company notification entity.
 */
export const CompanyNotificationMock: CompanyNotificationEntity = {
	provider: {
		short: 'tst-afzndr',
		name: 'test-afzender'
	},
	organizationNumber: '0123456789',
	fullDate: '01-01-2000',
	id: 'id-001',
	isUnread: true,
	title: 'test-titel',
	...CompanyNotificationsKeyValuesMock
};
