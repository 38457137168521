import { merge } from '@studiohyperdrive/utils';

import {
	CompanyNotificationEntity,
	CompanyNotificationResultEntity,
	NotificationResultKeys,
	CompanyNotificationKeyValueResultEntity,
	CompanyNotificationKeyValueEntity,
	NotificationProviderResultEntity,
	NotificationProviderEntity
} from '../interfaces';

export function convertCompanyNotification(notification: CompanyNotificationResultEntity): CompanyNotificationEntity {
	if (!Boolean(notification) || !Object.keys(notification).length) {
		return;
	}

	return {
		id: notification.id,
		title: notification.titel,
		provider: mapProviderDetails(notification.afzender),
		fullDate: notification.datum,
		organizationNumber: notification.bestemmeling,
		isUnread: notification.isOngelezen,
		...mapNotificationContent(notification.sleutelWaardeParen.elementen)
	};
}

/**
 * Map the `SleutelWaarde` property to a useable structure.
 *
 * @param values The SleutelWaarde property of the API response.
 * @returns A frontend-friendly object format.
 */
function mapNotificationContent(values: CompanyNotificationKeyValueResultEntity[]): CompanyNotificationKeyValueEntity {
	if (!Boolean(values) || !values.length) {
		return;
	}

	return values.reduce<CompanyNotificationKeyValueEntity>(
		(acc, curr) => ({
			...acc,
			[NotificationResultKeys[curr.sleutel]]: curr.waarde
		}),
		{} as CompanyNotificationKeyValueEntity
	);
}

/**
 * Maps the provider details to a frontend-friendly interface.
 *
 * @param provider The API response.
 */
function mapProviderDetails(provider: NotificationProviderResultEntity): NotificationProviderEntity {
	if (!Boolean(provider) || !Object.keys(provider).length) {
		return;
	}

	return merge<NotificationProviderEntity>(
		{
			name: provider.naam,
			short: provider.korteNaam
		},
		['phone', provider?.telefoonNummer],
		['url', provider?.website]
	);
}

export const exportForTesting = {
	mapNotificationContent,
	mapProviderDetails
};
