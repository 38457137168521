import { HttpRequest, HttpEvent, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'environments';

import { AuthenticationService } from '../services/authentication.service';

export function sessionErrorInterceptor(
	request: HttpRequest<any>,
	next: HttpHandlerFn
): Observable<HttpEvent<any>> | Observable<any> {
	// Wouter: Inject services
	const authService = inject(Injector).get(AuthenticationService);

	const tokenError = 'refresh_token_exception';

	// Iben: Check if the request is a call to our API, if not early exit
	if (!request.url.includes(environment.api.fullPath)) {
		return next(request);
	}

	// Iben: If the error returns a 401, we check if we need to logout the user
	return next(request).pipe(
		catchError((response: HttpErrorResponse) => {
			// Iben: If the errorCode matches the token, we logout the user
			if (response.status === 401 && response.error?.errorCode === tokenError) {
				// Iben: Logout and refresh
				return authService.logout().pipe(
					tap(() => {
						location.reload();
					})
				);
			}

			// Iben: Return the error in regular cases
			return throwError(() => response);
		})
	);
}
