import { Component, OnInit, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTourStepComponent } from '@studiohyperdrive/ngx-tour';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { sharedI18nKeys } from '@vlaio/shared/i18n';
import { VlaioIconComponent } from '@vlaio/shared/ui/common';

import { TourStepDataEntity } from '../../interfaces';

@Component({
	selector: 'vlaio-tour-step',
	templateUrl: './tour-step.component.html',
	styleUrl: './tour-step.component.scss',
	standalone: true,
	imports: [TranslateModule, VlaioIconComponent, FocusClickDirective, CypressTagDirective],
	hostDirectives: [CypressTagDirective]
})
export class VlaioTourStepComponent extends NgxTourStepComponent<TourStepDataEntity> implements OnInit {
	/**
	 * The host cypress tag directive
	 */
	private readonly cypressTagDirective: CypressTagDirective = inject(CypressTagDirective);

	/**
	 * The translation keys
	 */
	public readonly i18nKeys: typeof sharedI18nKeys = sharedI18nKeys;

	ngOnInit(): void {
		// Iben: Set the Cypress tag.
		// Given that a step is rendered and never changed, we can do this during the ngOnInit
		this.cypressTagDirective.cypressTag = this.data?.cypressTag;
	}
}
