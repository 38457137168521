@if (!isExternalLink) {
	<a
		class="u-outline"
		[routerLink]="link"
		[queryParams]="queryParams"
		[queryParamsHandling]="queryParamsHandling"
		[class]="buttonType"
	>
		<ng-template [ngTemplateOutlet]="contentTmpl"></ng-template>
	</a>
} @else {
	<a class="u-outline" target="_blank" [href]="link" [class]="buttonType">
		<ng-template [ngTemplateOutlet]="contentTmpl"></ng-template>
	</a>
}

<ng-template #contentTmpl>
	<span class="vlaio-link-label" cypressTag="Shared.Ui.LinkButton.Label">
		{{ label }}
	</span>

	@if (count) {
		<span class="vlaio-link-count" cypressTag="Shared.Ui.LinkButton.Count">
			{{ count }}
		</span>
	}

	<vlaio-icon class="vlaio-link-icon" icon="fa-chevron-right" />
</ng-template>
