import { HttpRequest, HttpEvent, HttpClient, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ObservableString } from '@studiohyperdrive/rxjs-utils';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { environment } from 'environments';

import { AuthenticationService } from '../services/authentication.service';

import { sasEndPoint, xcsrfEndPoint } from './endpoints';

/**
 * Interceptor that adds the X-CSRF-Token to the headers of the eligible request.
 *
 * @param req The request to intercept
 * @param next The next interceptor in the chain
 *
 * @returns The request with the X-CSRF-Token added to the headers
 */
export function xcsrfInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
	// Wouter: Inject services
	const authService = inject(AuthenticationService);
	const httpClient = inject(HttpClient);

	// Wouter: If the request is not eligible for interception, return the request as is
	if (!requestIsEligibleForIntercept(req, authService.authenticated)) {
		return next(req);
	}

	// Get a X-CSRF-Token for authentication when using POST requests
	return getXcsrfToken(httpClient).pipe(
		switchMap((token) => {
			const xcsrfReq = req.clone({
				headers: req.headers.set('X-CSRF-Token', token),
				// Iben: Set the withCredentials on true when an anonymous call is made when logged in
				withCredentials: authService.authenticated && req.method === 'GET' ? true : req.withCredentials
			});

			return next(xcsrfReq);
		})
	);
}

/**
 * Get the X-CSRF-Token for authentication.
 *
 * @param httpClient The HttpClient used to make the request
 *
 * @returns Either the X-CSRF-Token or an error
 */
const getXcsrfToken = (httpClient: HttpClient): ObservableString => {
	return httpClient.get<string>(xcsrfEndPoint, { withCredentials: true }).pipe(
		catchError((err) => {
			return of(err);
		})
	);
};

/**
 * Check if the request is eligible for interception.
 *
 * @param req The request to check
 * @param isAuthenticated Whether the user is authenticated
 * @returns Whether the request is eligible for interception
 */
const requestIsEligibleForIntercept = (req: HttpRequest<any>, isAuthenticated: boolean): boolean => {
	if (!isAuthenticated) {
		return false;
	}

	return (
		(req.url !== xcsrfEndPoint &&
			req.url !== sasEndPoint &&
			['DELETE', 'POST', 'PUT', 'PATCH'].includes(req.method)) ||
		// Iben: Include calls that can be made anonymously
		(req.method === 'GET' && !req.withCredentials && req.url.startsWith(environment.api.fullPath))
	);
};
