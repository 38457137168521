<vlaio-dropdown-search
	cypressTag="Companies.Search.ZipCodeInput"
	[items]="zipCodes$ | async"
	[loading]="isSearchInitiated || (loading$ | async)"
	[error]="error$ | async"
	[errorLabel]="errorLabel"
	[placeholder]="i18nKeys.Forms.Placeholders.ZipCode | translate"
	[title]="title"
	[emptyLabel]="emptyLabel"
	[showSelected]="true"
	[formControl]="form"
	[showClearButton]="showClearButton"
	[selectedMapper]="selectedMapper || selectedZipCodeMapper"
	[outline]="outline"
	(searchValueChanged)="searchZipCode($event)"
	(searchInitiated)="searchInitiated()"
>
	<ng-template #listItemTmpl let-item>
		@if (itemTemplate) {
			<ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }">
			</ng-template>
		} @else {
			<span>{{ item.municipality }} ({{ item.code }})</span>
		}
	</ng-template>
</vlaio-dropdown-search>
