// IMPORTS
import { trigger, state, style, transition, animate } from '@angular/animations'; // Animation Framework
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterLinkActive, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';
import { takeUntil, tap } from 'rxjs/operators';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { HasFeatureDirective } from '@vlaio/shared/features';
import { GtagClickDirective, GtagDirectives } from '@vlaio/shared/gtag';
import { sharedI18nKeys } from '@vlaio/shared/i18n';
import { MandatesRoutePaths, CompanyRoutePaths, ELoketRoutePaths } from '@vlaio/shared/route-paths';
import { MediaQueryMax, MediaQueryMin, OnDestroyComponent } from '@vlaio/shared/types';
import { IndicatorComponent, VlaioIconComponent, VlaioFaIconComponent } from '@vlaio/shared/ui/common';
import { HasCompanyRoleDirective, HasRoleDirective } from '@vlaio/shared/user';

import { ClickAnywhereDirective } from '../../directives';

@Component({
	selector: 'vlaio-navigation',
	templateUrl: './navigation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		// Animations for profile popout
		trigger('moreMenuState', [
			state(
				'closed',
				style({
					'z-index': -1,
					opacity: '0',
					transform: 'scale3d(0, 0, 1) translate3d(0, 100px, 0)'
				})
			),
			state(
				'open',
				style({
					'z-index': 'inherit',
					opacity: '1',
					transform: 'scale3d(1,1,1) translate3d(0, 0, 0)'
				})
			),
			transition('closed => open', animate('165ms ease-in-out')),
			transition('open => closed', animate('165ms ease-in-out'))
		])
	],
	standalone: true,
	imports: [
		ClickAnywhereDirective,
		CypressTagDirective,
		NgClass,
		RouterLinkActive,
		RouterLink,
		HasFeatureDirective,
		IndicatorComponent,
		HasCompanyRoleDirective,
		VlaioIconComponent,
		HasRoleDirective,
		VlaioFaIconComponent,
		GtagDirectives,
		GtagClickDirective,
		TranslateModule,
		FocusClickDirective
	]
})
export class NavigationComponent extends OnDestroyComponent implements OnInit {
	// HOSTBINDING
	@HostBinding('class.c-eloket-nav__container') private readonly rootClass = true;
	@HostBinding('class.c-eloket-nav--show-more') showMore = false;

	// INPUT
	@Input() public eBoxVisible: boolean = false;
	@Input() public amountOfCurrentCases: number = 0;
	@Input() public isCompany: boolean = false;
	@Input() public navOpen?: boolean = true;

	// OUTPUT
	@Output() public toggleNavigationBar = new EventEmitter<void>();

	// PROPERTIES
	public readonly paths = { ...ELoketRoutePaths, ...CompanyRoutePaths, ...MandatesRoutePaths };
	public readonly i18nKeys = sharedI18nKeys.PageTitles.ELoket;
	public readonly mediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax = MediaQueryMax;
	public readonly moreLabel = sharedI18nKeys.Actions.More;
	public navHover: boolean = false;
	public moreMenuState = 'closed';

	// CONSTRUCTOR
	constructor(private readonly router: Router) {
		super();
	}

	// LIFECYCLE HOOKS
	public ngOnInit() {
		this.router.events
			.pipe(
				tap((event) => {
					if (event instanceof NavigationEnd) {
						this.onClickAnywhere(true);
					}
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	// METHODS
	onMouseEnter() {
		this.navHover = true;
	}

	onMouseLeave() {
		this.navHover = false;
	}

	onClickAnywhere(shouldClose: boolean) {
		if (shouldClose) {
			this.setActiveClass(!shouldClose);
			this.moreMenuState = 'closed';
		}
	}

	handleToggle(event: Event) {
		// Prevent default link behaviour
		event.preventDefault();
		// Prevent default link behaviour on children
		event.stopImmediatePropagation();

		const shouldOpen: boolean = this.moreMenuState === 'closed';
		this.moreMenuState = shouldOpen ? 'open' : 'closed';

		this.setActiveClass(shouldOpen);
	}

	private setActiveClass = (setShowMoreClass: boolean) => {
		this.showMore = setShowMoreClass;
	};
}
