import { HttpRequest, HttpEvent, HttpHandlerFn } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Interceptor that adds default query parameters to the headers of the eligible request.
 *
 * @param req The request to intercept
 * @param next The next interceptor in the chain
 *
 * @returns The request with the default query parameters added to the headers
 */
export function defaultQueryParamsInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
	if (!requestIsEligibleForIntercept(req)) {
		return next(req);
	}

	const defaultQueryParamsReq = req.clone({
		params: req.params.set('_format', 'json')
	});

	return next(defaultQueryParamsReq);
}

/**
 * Check if the request is eligible for interception.
 *
 * @param req The request to check
 *
 * @returns Whether the request is eligible for interception
 */
const requestIsEligibleForIntercept = (req: HttpRequest<any>): boolean => {
	return req.method === 'GET' && req.url[0] !== '/'; // Skip local paths
};
