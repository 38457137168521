import { VlaioZipCodeEntity, VlaioZipCodeResultEntity } from '../interfaces';

export const ZipCodeEntityMock: VlaioZipCodeEntity = {
	municipality: 'Ghent',
	code: 9000,
	id: 'Ghent-9000'
};

export const ZipCodeResultEntityMock: VlaioZipCodeResultEntity = {
	postcode: 9000,
	gemeente: 'Ghent'
};
