import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnInit,
	ViewChild,
	forwardRef
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormAccessor } from '@studiohyperdrive/ngx-forms';
import { NgxMaskDirective } from 'ngx-mask';
import { debounceTime, takeUntil, tap } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';

import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-search',
	templateUrl: './search.component.html',
	styleUrl: './search.component.scss',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VlaioSearchComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VlaioSearchComponent),
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, ReactiveFormsModule, CypressTagDirective, TranslateModule, NgxMaskDirective]
})
export class VlaioSearchComponent extends FormAccessor<string, FormControl<string>> implements OnInit {
	/**
	 * A control that is used to handle the visual search field input.
	 *
	 * This approach is used so the search can be visually cleared after searching, but the component can still function as a ControlValueAccessor
	 */
	public readonly searchField: FormControl<string> = new FormControl('');

	/**
	 * The translation keys
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/** Whether the search component has to take up the full width.
	 *
	 * By default this is `false`.
	 */
	@HostBinding('class.full-width') @Input() fullWidth: boolean = false;

	/**
	 * Reference to the actual input element
	 */
	@ViewChild('input', { static: false }) public readonly searchInput: ElementRef;

	/**
	 * Whether the search component has a small button.
	 *
	 * By default this is `false`.
	 */
	@Input() public hasSmallButton: boolean = false;

	/**
	 * Whether the search component should clear on search.
	 *
	 * By default this is `false`.
	 */
	@Input() public clearOnSearch: boolean = false;

	/**
	 * Whether we want to show the search button.
	 *
	 * By default this is `true`.
	 */
	@Input() public showButton: boolean = true;

	/**
	 * The title we wish to use for WCAG
	 */
	@Input() public title: string;

	/**
	 * The cypress tag for the search button
	 */
	@Input() public buttonCypressTag: CypressTagsPaths;

	/**
	 * The cypress tag for the search input
	 */
	@Input() public inputCypressTag: CypressTagsPaths;

	/**
	 * Whether we wish to automatically search when typing into the search input
	 */
	@Input() public set autoSearch(autoSearch: boolean) {
		// Iben: If we auto search, we don't show the search button
		this.showButton = !autoSearch;

		// Iben: Listen to the valueChanges of the control
		if (autoSearch) {
			this.searchField.valueChanges
				.pipe(
					debounceTime(300),
					tap(() => {
						this.changed(this.searchField.value);
					}),
					takeUntil(this.destroy$)
				)
				.subscribe();
		}
	}

	/**
	 * A mask we wish to use on the search-input
	 */
	@Input() public mask: string;

	/**
	 * The label we wish to use for the button
	 */
	@Input() public buttonLabel: string;

	/**
	 * Whether we wish to disable the search button
	 */
	@Input() disableSearchButton: boolean = false;

	/**
	 * A placeholder for the search input
	 */
	@Input() public placeholder: string;

	public initForm(): FormControl<string> {
		return new FormControl<string>('');
	}

	public onWriteValueMapper(value: string): string {
		// Iben: If the search isn't cleared, we also update the searchField
		if (!this.clearOnSearch) {
			this.searchField.setValue(value, { emitEvent: false });
		}

		return value;
	}
	public setDisabledState(isDisabled: boolean): void {
		// Iben: Handle the internal disable state
		super.setDisabledState(isDisabled);

		// Iben: Disable the searchfield
		isDisabled ? this.searchField.disable({ emitEvent: false }) : this.searchField.enable({ emitEvent: false });

		// Iben: Detect changes for  a visual update
		this.cdRef.detectChanges();
	}

	/**
	 * Resets both the form and the visual input field
	 *
	 * @memberof VlaioSearchComponent
	 */
	public reset() {
		this.searchField.reset();
		this.form.reset();
	}

	/**
	 * Handles the changes of the search field
	 *
	 * @param value - The value of the search field
	 */
	public changed(value: string) {
		// Iben: Update the internal form
		this.form.setValue(value);

		// Iben: Clear the search field visually if needed
		if (this.clearOnSearch) {
			this.searchField.reset('', { emitEvent: false });
		}

		this.onTouch();
	}
}
