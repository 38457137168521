<ng-template #headerTmpl>
	@if (headerClicked | hasObservers) {

	<button
		[ngClass]="['vlaio-action-cell-header', iconStyle]"
		[tourItem]="headerTourItem"
		[cypressTag]="headerCypressTag"
		[disabled]="headerDisabled"
		[title]="headerTitle"
		(click)="headerClicked.emit()"
	>
		<fa-icon [icon]="headerIcon || icon" />
	</button>

	}
</ng-template>

<ng-template #defaultCellTmpl let-data="row" let-index="index">
	@if (customCellTemplate) {

	<ng-template [ngTemplateOutlet]="customCellTemplate" [ngTemplateOutletContext]="{ $implicit: data, index: index }">
	</ng-template>

	} @else {

	<button
		[ngClass]="[iconStyle]"
		[disabled]="cellDisabled ? cellDisabled[data[cellDisabledKey]] : false"
		[tourItem]="cellTourItem"
		[cypressTag]="cellCypressTag"
		[title]="cellTitle"
		(click)="clickCell({data, index}, $event)"
	>
		<fa-icon [icon]="icon" />
	</button>

	}
</ng-template>
