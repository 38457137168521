import { NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	EventEmitter,
	forwardRef,
	HostBinding,
	Input,
	OnInit,
	Output,
	TemplateRef
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';
import { OnDestroyComponent } from '@vlaio/shared/types';

@Component({
	selector: 'vlaio-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VlaioSelectComponent), // tslint:disable-line:no-use-before-declare
			multi: true
		}
	],
	standalone: true,
	imports: [ReactiveFormsModule, CypressTagDirective, NgTemplateOutlet]
})
export class VlaioSelectComponent extends OnDestroyComponent implements ControlValueAccessor, OnInit {
	private onTouch: Function = () => {}; // tslint:disable-line:no-empty
	private onModelChange: Function = (_: any) => {}; // tslint:disable-line:no-empty

	public readonly control = new FormControl<string>('');

	/**
	 * **Hostbinding**
	 *
	 * Whether or not the select should be horizontal.
	 */
	@HostBinding('class.c-select__horizontal') @Input({ required: true }) private horizontal: boolean;

	/**
	 * The name of the input
	 */
	@Input() public inputName = '';
	/**
	 * The items to display in the select.
	 */
	@Input({ required: true }) public items: unknown[] = [];
	/**
	 * The key that needs to match in the options.
	 *
	 * Default value is `id`
	 */
	@Input() public matchingKey: string = 'id';
	/**
	 * The placeholder for the select.
	 */
	@Input() public placeholder: string;
	/**
	 * Whether ot not an empty value should be added.
	 */
	@Input() public allowEmpty: boolean = false;
	/**
	 * The label to show if there are no items.
	 */
	@Input() public emptyLabel: string;

	/**
	 * When no label was provided
	 */
	@HostBinding('class.has-no-label') @Input() public hasNoLabel: boolean = false;
	/**
	 * The cypress tag for the input.
	 */
	@Input() public inputCypressTag: CypressTagsPaths;

	/**
	 * Emits when the select has changed.
	 */
	@Output() public readonly selectChanged = new EventEmitter();

	/**
	 * Optional template to style the items in the list.
	 */
	@ContentChild('itemTmpl', { static: true }) public itemTemplate: TemplateRef<any>;

	constructor(private readonly cdRef: ChangeDetectorRef) {
		super();
	}

	public writeValue(value: any): void {
		this.control.patchValue(value, { emitEvent: false });
		this.cdRef.detectChanges();
	}

	public registerOnChange(fn: any): void {
		this.onModelChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		!isDisabled ? this.control.enable({ emitEvent: false }) : this.control.disable({ emitEvent: false });
	}

	public ngOnInit(): void {
		this.control.valueChanges
			.pipe(
				map((value) => {
					// Iben: Convert null string value to null
					return value === 'null' ? null : value;
				}),
				tap((value) => {
					this.onModelChange(value);
					this.onTouch();
					this.selectChanged.emit();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
