import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QrCodeModule } from 'ng-qrcode';

import { GtagDirectives } from '@vlaio/shared/gtag';
import { Language } from '@vlaio/shared/types';
import { environment } from 'environments';

import { PwaService } from '../../../data';
import { SharedPWAi18nKeys } from '../../../i18n';
import { PlatformDirective } from '../../directives/platform/platform.directive';

@Component({
	selector: 'vlaio-pwa-cta',
	templateUrl: './pwa-cta.component.html',
	styleUrls: ['../../../../../../ui/styles/block.component.scss', './pwa-cta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [PlatformDirective, QrCodeModule, GtagDirectives, TranslateModule]
})
export class PwaCtaComponent {
	@HostBinding('class') private readonly rootClass = 'c-carousel';
	@Input() set language(language: Language) {
		this.qrCodeUrl = `${environment.domain}/${language}/pwa`;
	}

	public readonly i18nKeys = SharedPWAi18nKeys.PWA;
	public readonly pageLink: string[] = ['pwa'];
	public readonly hasPwaInstallFunctionality: boolean = this.pwaService.hasPwaInstallFunctionality;
	public qrCodeUrl: string;

	constructor(private readonly pwaService: PwaService) {}

	public installApp() {
		this.pwaService.promptPwaInstall();
	}
}
