<p (focusClick)="startTour()">
	<ng-content>
		{{ i18nKeys.Actions.HelpTour | translate }}
	</ng-content>
</p>

<vlaio-icon
	*mediaQuery="mediaQueryMin.Desktop"
	class="u-outline"
	cypressTag="Shared.Tour.Start"
	toolTipPosition="right"
	icon="fa-circle-question"
	[toolTip]="toolTip"
	(focusClick)="startTour()"
/>
