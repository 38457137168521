import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { ProductEntity, CallToActionEntity, CallToActionClickedEvent } from '@vlaio/shared/types';
import { VlaioDetailContentComponent, VlaioExternalLinkComponent } from '@vlaio/shared/ui/common';

import { I18nKeys } from '../../../i18n';
import { ProductAnnotationComponent } from '../product-annotation/product-annotation.component';
import { ProductCTAComponent } from '../product-cta/product-cta.component';

@Component({
	selector: 'vlaio-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrl: './product-detail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		VlaioDetailContentComponent,
		VlaioExternalLinkComponent,
		CypressTagDirective,
		ProductAnnotationComponent,
		ProductCTAComponent,
		TranslateModule
	]
})
export class ProductDetailComponent {
	/**
	 * The translation keys
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * The product to display.
	 */
	@Input() public product: ProductEntity;

	/**
	 * Emits the action and the product whose CTA has been clicked.
	 */
	@Output() public ctaClicked: EventEmitter<CallToActionClickedEvent> = new EventEmitter<CallToActionClickedEvent>();

	/**
	 * Generate a click event for a CTA
	 *
	 * @param action - The action we clicked on
	 */
	public handleCTAClicked(action: CallToActionEntity) {
		// Iben: Emit the correct CTA data to the parent
		this.ctaClicked.emit({
			action,
			product: this.product
		});
	}
}
