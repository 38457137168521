import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTooltipDirective, NgxTooltipPosition } from '@studiohyperdrive/ngx-inform';

import { VlaioIconComponent } from '../../icon/icon.component';
import { VlaioLinkAbstractComponent } from '../shared';

/**
 * A component that renders an external link. The navigation is done by opening the link in a new tab.
 * It always adds an icon to the link.
 */
@Component({
	selector: 'vlaio-external-link',
	templateUrl: './external-link.component.html',
	styleUrls: ['../shared/shared-link.style.scss', './external-link.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule, VlaioIconComponent, NgxTooltipDirective, NgClass]
})
export class VlaioExternalLinkComponent extends VlaioLinkAbstractComponent {
	/**
	 * If provided, this will be shown as a tooltip on hover.
	 */
	@Input() public tooltip: string = '';

	/**
	 * The position of the tooltip.
	 *
	 * Default value is 'right'
	 */
	@Input() public tooltipPosition: NgxTooltipPosition = 'right';
}
