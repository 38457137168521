<div class="c-filters__form-row-item c-input">
	@if (!hasNoLabel) {
	<label>
		<span>
			<ng-content></ng-content>
		</span>
	</label>
	}

	<div>
		<select
			[cypressTag]="inputCypressTag"
			[attr.placeholder]="placeholder"
			[formControl]="control"
			[value]="control.value"
			[name]="inputName"
			[title]="inputName"
			[class.is-empty]="control.value === null || control.value === 'null'"
		>
			@if (allowEmpty) {
			<option [value]="null">
				<ng-template
					[ngTemplateOutlet]="itemTemplate ? itemTemplate : defaultTmpl"
					[ngTemplateOutletContext]="{ $implicit: '' }"
				>
				</ng-template>
			</option>
			} @for (item of items; track item) {
			<option [value]="matchingKey ? item[matchingKey] : item">
				<ng-template
					[ngTemplateOutlet]="itemTemplate ? itemTemplate : defaultTmpl"
					[ngTemplateOutletContext]="{ $implicit: item }"
				>
				</ng-template>
			</option>
			}
		</select>

		<span class="icon-arrow-down"></span>
	</div>
</div>

<ng-template #defaultTmpl let-item>
	{{ item || emptyLabel }}
</ng-template>
