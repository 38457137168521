import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output
} from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';

import { I18nKeys } from '../../i18n';
import { CounterPosition } from '../../types';
import { VlaioFaIconComponent } from '../fa-icon/fa-icon.component';
import { ImageComponent } from '../image/image.component';
import { InfoComponent } from '../info/info.component';
import { VlaioContentComponent } from '../vlaio-content/vlaio-content.component';

@Component({
	selector: 'vlaio-cta-card',
	templateUrl: './cta-card.component.html',
	styleUrls: ['./cta-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ImageComponent, VlaioFaIconComponent, CypressTagDirective, InfoComponent, VlaioContentComponent]
})
export class VlaioCTACardComponent {
	@HostBinding('tabindex') private readonly tabindex = 0;
	@HostBinding('class') private readonly rootClass = 'c-cta-card';
	@HostBinding('attr.role') private readonly role = 'link';

	/**
	 * The translation keys.
	 */
	public readonly I18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * **Hostbinding**
	 *
	 * Whether the title is allowed to span multiple lines.
	 *
	 * Default value is `false`.
	 */
	@HostBinding('class.c-multiline-title') @Input() public multiLineTitle: boolean = false;

	/**
	 * The onClick listener set to the host if is is not disabled by
	 * the `overrideListener` and `showCTA` properties.
	 */
	@HostListener('click') public onClick(): void {
		if (!this.overrideListener && this.showCTA) {
			return;
		}

		this.clicked.emit();
	}

	@HostListener('keydown.enter') public onKeyDown() {
		if (!this.overrideListener && this.showCTA) {
			return;
		}

		this.clicked.emit();
	}

	/**
	 * The title to display in the CTA.
	 */
	@Input({ required: true }) public title: string;
	/**
	 * An image to precede the title.
	 * If an `icon` is also provided, only the image will be displayed.
	 */
	@Input() public image: string;
	/**
	 * An icon to precede the title.
	 * If an `image` is also provided, only the image will be displayed.
	 */
	@Input() public icon: string;
	/**
	 * The count to display on the right/bottom side.
	 */
	@Input() public count: number = 0;
	/**
	 * Whether the data is being fetched.
	 */
	@Input() public loading: boolean = false;
	/**
	 * If set to `true`, the ng-content is rendered.
	 *
	 * Default value is `false`.
	 */
	@Input() public showCTA: boolean = false;
	/**
	 * When set to `true` in combination with `[showCTA]="true"`,
	 * the `click` behaviour is set to the host (the card itself).
	 *
	 * Default value is `false`.
	 */
	@Input() public overrideListener: boolean = false;
	/**
	 * The cypress tag for the title of the card.
	 */
	@Input() public cypressTitleTag: CypressTagsPaths;
	/**
	 * The cypress tag for the counter of the card.
	 */
	@Input() public cypressCountTag: CypressTagsPaths;
	/**
	 * The position of the counter (number).
	 *
	 * Default value is `center`.
	 */
	@Input() public counterPosition: CounterPosition = CounterPosition.Center;
	/**
	 * The text of the card.
	 */
	@Input() public bodyText: string;
	/**
	 * Whether or not the tooltip should be displayed.
	 *
	 * Default value is `false`.
	 */
	@Input() public displayInfo: boolean = false;
	/**
	 * The text to display if `displayInfo` is set to `true`.
	 */
	@Input() public infoText: string;

	/**
	 * Emits when the card is clicked.
	 */
	@Output() public clicked = new EventEmitter<void>();
}
