import { Injectable } from '@angular/core';
import { combineBooleans, ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureKeys, Features } from '../features';

@Injectable({
	providedIn: 'root'
})
export class FeatureService {
	/**
	 * Subject to store the features. Because we don't want to initialize this in a module, we use a subject instead of a store
	 */
	private readonly featuresSubject$: BehaviorSubject<Features[]> = new BehaviorSubject([]);
	/**
	 * Subject to store  whether the features are set. Because we don't want to initialize this in a module, we use a subject instead of a store
	 */
	private readonly featureSetSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	/**
	 * An observable that will let us know whether or not the features have been set
	 */
	public readonly featuresSet$: ObservableBoolean = this.featureSetSubject$.asObservable();

	/**
	 * Return whether or not the environment has the provided feature
	 *
	 * @param feature - The provided feature
	 */
	public hasFeature(feature: FeatureKeys | FeatureKeys[] | undefined): ObservableBoolean {
		// Wouter: If this service is used without any features, we allow the user to pass
		if (!feature) {
			return of(true);
		}

		return Array.isArray(feature)
			? combineBooleans([...feature].map((key) => this.isFeatureEnabled(key)))
			: this.isFeatureEnabled(feature);
	}

	/**
	 * Store the features in the feature service
	 *
	 * @param features - The features we wish to store
	 */
	public setFeatures(features: Features[]) {
		this.featuresSubject$.next(features);
		this.featureSetSubject$.next(true);
	}

	/**
	 * Get all the currently active features.
	 */
	public getAllFeatures(): ObservableArray<Features> {
		return this.featuresSubject$.asObservable();
	}

	/**
	 * Check if the provided feature is currently active.
	 */
	private isFeatureEnabled(feature: FeatureKeys): ObservableBoolean {
		return this.featuresSubject$.pipe(
			map((features) => {
				return new Set(features).has(Features[feature]);
			})
		);
	}
}
