import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	private readonly navigationHistorySubject$ = new BehaviorSubject<string[]>([]);

	/**
	 * The navigation history of the application
	 */
	public readonly navigationHistory$: ObservableArray<string> = this.navigationHistorySubject$.asObservable();

	constructor(private readonly router: Router, private readonly location: Location) {
		// Iben: Track the history of the navigation
		this.router.events
			.pipe(
				tap((event) => {
					// Iben: Only dispatch the end events
					if (event instanceof NavigationEnd) {
						this.navigationHistorySubject$.next([
							...this.navigationHistorySubject$.getValue(),
							event.urlAfterRedirects
						]);
					}
				})
			)
			.subscribe();
	}

	/**
	 * Navigate back into the navigation history
	 *
	 * @param amountOfSteps - The amount of steps we wish to return, default one.
	 */
	back(amountOfSteps: number = 1): void {
		// Iben: Get the current history minus the current page
		const history = this.navigationHistorySubject$.getValue();
		history.pop();

		// Iben: If we have no history we use the browser history
		if (history.length === 0) {
			this.location.back();
		} else {
			// Iben: Navigate back in the navigation history of the application
			this.router.navigateByUrl(history[history.length - amountOfSteps]);
		}
	}
}
