enum ELoketBaseUrl {
	Cases = 'lopende-dossiers',
	Company = 'onderneming',
	EBox = 'ebox',
	Notifications = 'notificaties',
	ContentPages = 'paginas',
	ContentTable = 'inhoudstafels',
	Partners = 'deelnemende-partners',
	Products = 'aangeboden-producten',
	Facets = 'facets',
	Spotlight = 'producten-in-de-kijker',
	Recommendations = 'uitgelichte-producten',
	RecommendationRatings = 'aanbevelingsbeoordelingen',
	Survey = 'vragenlijst',
	UserContext = 'gebruikerscontext',
	Tracking = 'gebeurtenis',
	UserSession = 'gebruikerssessie',
	Status = 'status',
	Authentication = 'logout',
	User = 'gebruiker',
	Sanctions = 'sanctieregister',
	Permits = 'toelatingen',
	Dashboard = 'dashboard',
	DynamicBlocks = 'dynamische-blokken'
}

export const ELoketEndpoints: Partial<Record<keyof typeof ELoketBaseUrl, Record<string, (...args: any) => string>>> = {
	Status: {
		Status: () => ELoketBaseUrl.Status
	},
	Authentication: {
		LogoutWitEId: () => 'logout',
		Logout: () => 'gebruiker/afmelden'
	},
	Cases: {
		Summary: () => `${ELoketBaseUrl.Cases}/samenvatting`,
		UnRead: () => `${ELoketBaseUrl.Cases}/ongelezen`,
		Facets: () => `${ELoketBaseUrl.Cases}/${ELoketBaseUrl.Facets}`,
		GetCases: () => 'lopende-dossiers-pagina',
		GetCase: (id: string) => `${ELoketBaseUrl.Cases}/${id}`,
		GetExpropriationDetails: (id: string) => `innemingsdossier/${id}`,
		DownloadExpropriationDocument: (id: string) => `innemingsdossier/download/${id}`
	},
	Company: {
		GetCompany: (companyNumber: string) => `${ELoketBaseUrl.Company}/${companyNumber}`,
		GetCompanyBranchActivities: (companyNumber: string, branch: string) =>
			`${ELoketBaseUrl.Company}/${companyNumber}/activiteiten/${branch}`,
		ZipCodes: () => 'postcodes',
		Search: () => `${ELoketBaseUrl.Company}/zoeken`
	},
	EBox: {
		GetEBoxData: () => ELoketBaseUrl.EBox
	},
	Notifications: {
		GetNotifications: () => ELoketBaseUrl.Notifications,
		GetCompanyNotifications: () => `${ELoketBaseUrl.Notifications}/${ELoketBaseUrl.Company}`
	},
	ContentPages: {
		GetPageNavItems: (page: string) => `${ELoketBaseUrl.ContentTable}/${page}`,
		Pages: () => ELoketBaseUrl.ContentPages,
		SinglePage: (pageId: string) => `${ELoketBaseUrl.ContentPages}/${pageId}`
	},
	Partners: {
		GetPartners: () => ELoketBaseUrl.Partners
	},
	Products: {
		Facets: () => `${ELoketBaseUrl.Products}/${ELoketBaseUrl.Facets}`,
		Spotlight: () => ELoketBaseUrl.Spotlight,
		Detail: (id: string) => `${ELoketBaseUrl.Products}/detail/${id}`,
		Sorting: () => `${ELoketBaseUrl.Products}/sorteeropties`,
		GetProducts: () => 'aangeboden-producten-pagina'
	},
	Recommendations: {
		GetRecommendedProducts: () => ELoketBaseUrl.Recommendations,
		RecommendationRatings: () => ELoketBaseUrl.RecommendationRatings,
		ProductRecommendationRating: (product: string) => `producten/${product}/aanbevelingsbeoordeling`
	},
	Survey: {
		GetSurvey: () => `${ELoketBaseUrl.Recommendations}/${ELoketBaseUrl.Survey}`
	},
	User: {
		UserContext: (id: string) => `${ELoketBaseUrl.UserContext}/${id}`,
		UserSession: () => ELoketBaseUrl.UserSession
	},
	Tracking: {
		TrackEvent: () => ELoketBaseUrl.Tracking
	},
	Sanctions: {
		GetSanctions: () => `${ELoketBaseUrl.Sanctions}/dossiers`,
		DownloadDocument: (uuid: string) => `${ELoketBaseUrl.Sanctions}/download/${uuid}`
	},
	Permits: {
		GetPermits: () => `${ELoketBaseUrl.Permits}/mijn-toelatingen`,
		Search: () => `${ELoketBaseUrl.Permits}/zoeken`,
		RenewablePermits: () => `${ELoketBaseUrl.Permits}/mijn-te-hernieuwen-toelatingen`,
		Summary: () => `${ELoketBaseUrl.Permits}/mijn-samenvatting`
	},
	Dashboard: {
		GetDynamicBlocks: () => ELoketBaseUrl.DynamicBlocks
	}
};

export type EndpointFunctionEntity = (...args: any) => string;
