import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@vlaio/shared/i18n';

export const I18nKeys = {
	...sharedI18nKeys,
	Results: {
		SingleResult: marker('results.single-result'),
		MultipleResults: marker('results.multiple-results'),
		NoResults: marker('results.no-results'),
		Of: marker('results.of')
	},
	Hamburger: {
		Title: marker('hamburger.title')
	},
	Link: {
		Extern: marker('link.extern')
	},
	SkipLinks: {
		MainContent: marker('skip-links.main-content'),
		Footer: marker('skip-links.footer')
	},
	ProgressMeter: {
		Step: marker('progress-meter.step')
	},
	EmptySearch: {
		NoResults: {
			Title: marker('empty-search.no-results.title'),
			Subtitle: marker('empty-search.no-results.subtitle'),
			List: {
				Item1: marker('empty-search.no-results.list.item-1'),
				Item2: marker('empty-search.no-results.list.item-2'),
				Item3: marker('empty-search.no-results.list.item-3'),
				Item4: marker('empty-search.no-results.list.item-4')
			}
		},
		Error: {
			Title: marker('empty-search.error.title'),
			Subtitle: marker('empty-search.error.subtitle'),
			List: {
				Item1: marker('empty-search.error.list.item-1'),
				Item2: marker('empty-search.error.list.item-2'),
				Item3: marker('empty-search.error.list.item-3')
			}
		}
	},
	Notification: {
		ClickToDelete: marker('notification.click-to-delete')
	},
	Disclaimer: {
		Fallback: marker('disclaimer.fallback')
	}
};
