import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HasObserversPipe } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTags } from '@vlaio/cypress/shared';

import { I18nKeys } from '../../i18n';
import { VlaioIconComponent } from '../icon/icon.component';

import { VlaioEmptySearchResultTextEntity } from './empty-search-result.types';

@Component({
	selector: 'vlaio-empty-search-result',
	templateUrl: './empty-search-result.component.html',
	styleUrls: ['./empty-search-result.component.scss'],
	standalone: true,
	imports: [VlaioIconComponent, TranslateModule, HasObserversPipe, CypressTagDirective]
})
export class VlaioEmptySearchResultComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys.EmptySearch = I18nKeys.EmptySearch;

	/**
	 * Whether the empty state is due to an empty data set or an error.
	 *
	 * Default value is 'no-results'.
	 */
	@HostBinding('class') @Input() public type: 'no-results' | 'error' = 'no-results';
	/**
	 * Bind the cypressTag to the host.
	 */
	@HostBinding('attr.data-cy') private readonly cypressTag: string = CypressTags.General.Table.Empty;

	/**
	 * A setup of custom text for the empty result
	 */
	@Input() public customText: VlaioEmptySearchResultTextEntity;

	/**
	 * Emits whenever the recommended option to search again without filters is clicked.
	 */
	@Output() public readonly searchWithoutFiltersClicked: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Navigate to the products page
	 *
	 * @param event - the click event for the util
	 */
	public emitSearchWithoutFilters(event: Event) {
		//Iben: To avoid overcomplicating the implementation, this is the only place where we keep using the original translationLinkUtil behavior
		// Iben: If we didn't click the link or if no class is present, early exit
		if (
			!Boolean((event.target as HTMLElement)?.className) ||
			!(event.target as HTMLElement).className.includes('u-translation-link')
		) {
			return;
		}

		this.searchWithoutFiltersClicked.emit();
	}
}
