import { Component } from '@angular/core';
import { NgxTooltipAbstractComponent } from '@studiohyperdrive/ngx-inform';

@Component({
	selector: 'vlaio-navigation-tooltip',
	template: `<p>{{ text }}</p>`,
	styleUrl: './navigation-tooltip.component.scss',
	standalone: true
})
export class VlaioNavigationTooltipComponent extends NgxTooltipAbstractComponent {}
