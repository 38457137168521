<div class="c-search-fields" [formGroup]="form">
	<fieldset class="c-company-search__inputs">
		<div class="c-search__input js-input-search">
			<div class="c-input c-input--with-icon">
				<span class="icon-search"></span>
				<input
					class="c-input--as-textfield input-search c-company-search__name u-outline"
					name="input-search"
					id="input-search"
					cypressTag="Companies.Search.NameInput"
					type="text"
					formControlName="searchQuery"
					[title]="i18nKeys.Companies.SearchCompany.Placeholder | translate"
					[placeholder]="i18nKeys.Companies.SearchCompany.Placeholder | translate"
					(keydown.enter)="searchClicked.emit(form.value)"
				/>
			</div>
		</div>

		<vlaio-zip-code-input formControlName="zipCode" [showClearButton]="false" />
	</fieldset>

	@if (form.get('searchQuery').value || form.get('zipCode').value) {
		<vlaio-close-button
			class="clear-button u-margin-right"
			[title]="i18nKeys.Companies.SearchCompany.ClearButton | translate"
			(handleClick)="clearField()"
		/>
	}
</div>

<vlaio-button
	cypressTag="Companies.Search.Button"
	[fullWidth]="true"
	[title]="i18nKeys.Companies.SearchCompany.SearchButton.Title | translate"
	(handleClick)="searched()"
>
	{{ i18nKeys.Companies.SearchCompany.SearchButton.Text | translate }}
</vlaio-button>

@if (hasSearched && this.form.get('searchQuery').touched && !this.form.get('searchQuery').value) {
	<ng-container ngxErrors="searchQuery">
		<vlaio-disclaimer type="warning" ngxError="required" showWhen="touched">
			<p>{{ i18nKeys.Companies.SearchCompany.Error | translate }}</p>
		</vlaio-disclaimer>
	</ng-container>
}
