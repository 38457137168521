import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxI18nModule } from '@studiohyperdrive/ngx-i18n';

import { VlaioBreadcrumbComponent } from './components';

@NgModule({
	imports: [CommonModule, RouterModule, NgxI18nModule.forChild(), VlaioBreadcrumbComponent],
	exports: [VlaioBreadcrumbComponent]
})
export class BreadcrumbModule {}
