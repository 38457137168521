import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { CypressTags } from '@vlaio/cypress/shared';

import { VlaioTranslateWithRouterLinksPipe } from '../../pipes';
import { VlaioIconComponent } from '../icon/icon.component';

/**
 * Display this component when the content of a page is empty.
 */
@Component({
	selector: 'vlaio-empty-page',
	templateUrl: './empty-page.component.html',
	styleUrls: ['./empty-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioIconComponent, VlaioTranslateWithRouterLinksPipe]
})
export class VlaioEmptyPageComponent {
	/**
	 * Set the cypressTag to the empty-page block.
	 * This can be a hostbinding, since it would be pointless to provide this property each time.
	 */
	@HostBinding('attr.data-cy') private readonly cypressTag: string = CypressTags.General.EmptyPage;

	/**
	 * The logo to display above the empty label text.
	 *
	 * Default value is `fa-file-circle-xmark`.
	 */
	@Input() public icon: string = 'fa-file-circle-xmark';

	/**
	 * The text to display.
	 */
	@Input({ required: true }) public emptyLabel: string;

	/**
	 * Whether the label contains HTML.
	 */
	@Input() public hasHtmlLabel = false;

	/**
	 * The router link of the item that needs to be substituted by the `withRouterLink` pipe.
	 */
	@Input() public substituteRouterLink: string;

	/**
	 * The id of the item that needs to be substituted by the `withRouterLink` pipe.
	 */
	@Input() public substituteLinkId: string;

	/**
	 * The optional type of icon.
	 */
	@Input() public iconType: 'fa-regular' | 'fa-solid' = 'fa-solid';
}
