import { I18nKeys } from '../../i18n';
import { VlaioNavigationItemEntity } from '../components';

const translatePath = I18nKeys.PageTitles.ELoket;

/**
 * Create the menu items for the navigation. The order in this array is the order in which the items will be displayed.
 *
 * @param isCompany - Whether the user is signed in as a company.
 * @param amountOfCurrentCases - The amount of active cases the user has.
 * @returns The menu config array.
 */
export const createMenuItems = (isCompany: boolean, amountOfCurrentCases: number): VlaioNavigationItemEntity[] => [
	{
		routerLink: ['dashboard'],
		label: translatePath.Dashboard,
		icon: 'fa-house',
		routerLinkActiveOptions: { exact: false },
		cypressTag: 'Dashboard.Navigation.Link.Dashboard'
	},
	{
		routerLink: ['aanvragen'],
		label: translatePath.Cases,
		icon: 'fa-server',
		routerLinkActiveOptions: { exact: false, queryParams: 'ignored' },
		feature: 'Cases',
		role: ['ECONOMIC_ACTOR'],
		amount: amountOfCurrentCases,
		cypressTag: 'Dashboard.Navigation.Link.Cases'
	},
	{
		routerLink: ['aanvraag'],
		label: translatePath.Products,
		icon: 'fa-file',
		routerLinkActiveOptions: { exact: false, queryParams: 'ignored' },
		feature: 'Products',
		cypressTag: 'Dashboard.Navigation.Link.Products'
	},
	{
		routerLink: ['onderneming', 'eigen'],
		label: translatePath.Company,
		icon: 'fa-building',
		routerLinkActiveOptions: { exact: true },
		feature: 'MyOrganisation',
		hideItem: !isCompany,
		cypressTag: 'Dashboard.Navigation.Link.Company'
	},
	{
		routerLink: ['sanctieregister'],
		label: translatePath.Sanctions,
		icon: 'fa-file-signature',
		routerLinkActiveOptions: { exact: true },
		feature: 'Sanctions',
		cypressTag: 'Dashboard.Navigation.Link.Sanctions',
		hideItem: !isCompany,
		companyRole: ['isLegalRepresentative', 'hasSanctionsAccess']
	},
	{
		routerLink: ['toelatingen'],
		label: translatePath.Permits,
		icon: 'fa-check-to-slot',
		routerLinkActiveOptions: { exact: true },
		feature: 'Permits',
		role: ['ECONOMIC_ACTOR'],
		cypressTag: 'Dashboard.Navigation.Link.Permits'
	},
	{
		routerLink: ['subsidieregister'],
		label: translatePath.Subsidies,
		icon: 'fa-landmark',
		routerLinkActiveOptions: { exact: true, queryParams: 'ignored' },
		feature: 'Subsidies',
		hideItem: !isCompany,
		companyRole: ['isLegalRepresentative'],
		cypressTag: 'Dashboard.Navigation.Link.subsidies'
	},
	{
		routerLink: ['onderneming', 'zoeken'],
		label: translatePath.Search,
		icon: 'fa-magnifying-glass',
		routerLinkActiveOptions: { exact: false, queryParams: 'ignored' },
		feature: 'SearchOrganisations',
		cypressTag: 'Dashboard.Navigation.Link.Search'
	},
	{
		routerLink: ['inbox'],
		label: translatePath.Inbox,
		icon: 'fa-bell',
		routerLinkActiveOptions: { exact: true },
		role: 'ECONOMIC_ACTOR',
		feature: 'Notifications',
		companyRole: ['isLegalRepresentative', 'isMandateAdministrator', 'isMandateExecutor'],
		cypressTag: 'Dashboard.Navigation.Link.Inbox',
		shouldHaveAllCompanyRoles: false
	},
	{
		routerLink: ['mandaten'],
		label: translatePath.Mandates,
		icon: 'fa-layer-group',
		feature: 'Mandates',
		routerLinkActiveOptions: { exact: false, queryParams: 'ignored' },
		companyRole: ['isMandateAdministrator', 'isLegalRepresentative'],
		hideItem: !isCompany,
		cypressTag: 'Dashboard.Navigation.Link.Mandates'
	}
];
