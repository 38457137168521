import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BrowserService } from '@vlaio/shared/core';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'vlaio-skip-links',
	templateUrl: './skip-links.component.html',
	styleUrls: ['./skip-links.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UpperCasePipe, TranslateModule]
})
export class SkipLinksComponent {
	public readonly i18nKeys = I18nKeys;

	constructor(private readonly browserService: BrowserService) {}

	public scrollToMain(event: Event) {
		event.preventDefault();
		this.browserService.scrollToId('main-content');
	}

	public scrollToFooter(event: Event) {
		event.preventDefault();
		this.browserService.scrollToId('footer');
	}
}
