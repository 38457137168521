<vlaio-icon [icon]="icon" [iconType]="iconType" />

@if (hasHtmlLabel) {
	<p
		class="u-margin-top-sm"
		[innerHTML]="
			emptyLabel
				| translateWithRouterLinks
					: [
							{
								id: substituteLinkId,
								elementId: 'internalLink',
								data: {
									link: substituteRouterLink
								}
							}
					  ]
		"
	></p>
} @else {
	<p class="u-margin-top-sm">{{ emptyLabel }}</p>
}

<ng-content></ng-content>
