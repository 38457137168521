import { Pipe, PipeTransform } from '@angular/core';

/**
 * Formats a string to include anchor tags for URLs.
 *
 * @param value - The string to format
 */
@Pipe({
	standalone: true,
	name: 'anchor'
})
export class AnchorPipe implements PipeTransform {
	transform(value: string): string {
		if (typeof value !== 'string') {
			return '';
		}

		const regex = /https:\/\/?[\w\/\-%.]+\.[\w\/\-&?=%]+/gm;
		return value.replace(regex, (match) => `<a href="${match}" class="u-outline" target="_blank">${match}</a>`);
	}
}
