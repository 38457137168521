import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableString } from '@studiohyperdrive/rxjs-utils';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { environment } from 'environments';

import { AuthenticationService } from '../services';

import { sasEndPoint, xcsrfEndPoint } from './end-points';

@Injectable()
export class XCSRFInterceptor implements HttpInterceptor {
	constructor(private authService: AuthenticationService, private httpClient: HttpClient) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.requestIsEligibleForIntercept(req)) {
			return next.handle(req);
		}

		return this.getXCSRFToken().pipe(
			switchMap((token) => {
				const xcsrfReq = req.clone({
					headers: req.headers.set('X-CSRF-Token', token),
					// Iben: Set the withCredentials on true when an anonymous call is made when logged in
					withCredentials: this.authService.authenticated && req.method === 'GET' ? true : req.withCredentials
				});

				return next.handle(xcsrfReq);
			})
		);
	}

	private requestIsEligibleForIntercept(req: HttpRequest<any>): boolean {
		return (
			(this.authService.authenticated &&
				req.url !== xcsrfEndPoint &&
				req.url !== sasEndPoint &&
				['DELETE', 'POST', 'PUT', 'PATCH'].includes(req.method)) ||
			// Iben: Include calls that can be made anonymously
			(this.authService.authenticated &&
				req.method === 'GET' &&
				!req.withCredentials &&
				req.url.startsWith(environment.api.fullPath))
		);
	}

	// Get a X-CSRF-Token for authentication when using POST requests
	private getXCSRFToken = (): ObservableString => {
		return this.httpClient.get<string>(xcsrfEndPoint, { withCredentials: true }).pipe(
			catchError((err) => {
				return of(err);
			})
		);
	};
}
