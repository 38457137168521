import { NgTemplateOutlet } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { Params, QueryParamsHandling, RouterLink } from '@angular/router';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { VlaioIconComponent } from '../icon/icon.component';

@Component({
	selector: 'vlaio-link-button',
	templateUrl: './link-button.component.html',
	styleUrl: './link-button.component.scss',
	standalone: true,
	imports: [RouterLink, NgTemplateOutlet, VlaioIconComponent, CypressTagDirective]
})
export class VlaioLinkButtonComponent {
	/**
	 * The title attribute of the link button
	 */
	@HostBinding('attr.title')
	@Input({ required: true })
	public title: string;

	@Input()
	public buttonType: 'primary' | 'secondary' = 'primary';

	/**
	 * The label displayed on the link
	 */
	@Input({ required: true }) public label: string;

	/**
	 * An optional count displayed on the link
	 */
	@Input() public count: number;

	// Iben: The following input properties and their types are based on the RouterLink directive

	/**
	 * The provided routerLink
	 */
	@Input({ required: true }) public link: any[] | string;

	/**
	 * Optional queryParams to be used with the provided routerLink
	 */
	@Input() public queryParams: Params;

	/**
	 * Optional handling parameter for the queryParams
	 */
	@Input() public queryParamsHandling: QueryParamsHandling;

	/**
	 * Whether the provided link is an external link
	 */
	@Input() public isExternalLink: boolean = false;
}
