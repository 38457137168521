/**
 * The entity of the data passed to the toast component.
 */
export interface VlaioToastEntity {
	showIcon?: boolean;
	text: string;
	type?: keyof typeof VlaioToastEnum;
	duration?: number;
}

/**
 * The types of the toasts.
 */
export enum VlaioToastEnum {
	Positive = 'positive',
	Negative = 'negative',
	Info = 'info',
	Warning = 'warning'
}
