<div>
	<h3 cypressTag="Permits.Title">
		{{ permit.title }}

		@if (!readOnly && permit.isRenewable) {

		<small class="u-block u-margin-top">
			<vlaio-icon icon="icon-alert"> </vlaio-icon>

			<strong>
				{{ i18nKeys.Permits.Table.isRenewable | translate }}
			</strong>
		</small>

		}
	</h3>

	<vlaio-value-tile cypressTag="Permits.Description">
		<ng-container content>
			@if (permit.description) {

			{{ permit.description }}

			} @else {

			<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>

			}
		</ng-container>
	</vlaio-value-tile>

	<hr />

	@if (view === 'all') {

	<div class="date-containers">
		<vlaio-value-tile cypressTag="Permits.Dates.Start">
			<ng-container title>{{ i18nKeys.Permits.Card.Dates.Start | translate }}</ng-container>
			<ng-container content>
				@if (permit.status.startDate) {

				{{ permit.status.startDate | date }}

				} @else {

				<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>

				}
			</ng-container>
		</vlaio-value-tile>

		<vlaio-value-tile cypressTag="Permits.Dates.End">
			<ng-container title>{{ i18nKeys.Permits.Card.Dates.End | translate }}</ng-container>
			<ng-container content>
				@if (permit.status.endDate) {

				{{ permit.status.endDate | date }}

				} @else {

				<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>

				}
			</ng-container>
		</vlaio-value-tile>

		@if (!readOnly) {
		<vlaio-value-tile cypressTag="Permits.Dates.Renewal">
			<ng-container title>{{ i18nKeys.Permits.Card.Dates.Renewal | translate }}</ng-container>
			<ng-container content>
				@if (permit.status.renewalDate) {
				{{ permit.status.renewalDate | date }}
				} @else {

				<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>

				}
			</ng-container>
		</vlaio-value-tile>
		}
	</div>

	<hr />

	@if (permit.assignedBy) {

	<p class="font-bold primary-color assigned-by" cypressTag="Permits.AssignedBy">
		{{ permit.assignedBy.name || permit.assignedBy.organizationCode }}
	</p>

	}
	<hr class="card-only" />

	} @else {

	<vlaio-value-tile>
		<ng-container title>
			{{ i18nKeys.Permits.Table.FileNumber | translate }}
		</ng-container>

		<ng-container content>
			@if (permit.referenceNumber) {

			{{ permit.referenceNumber }}

			} @else {

			<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>

			}
		</ng-container>
	</vlaio-value-tile>

	<vlaio-value-tile class="u-margin-top">
		<ng-container title>
			{{ i18nKeys.Permits.Table.RecentChange | translate }}
		</ng-container>

		<ng-container content>
			@if (permit.status.recentChangeDate) {

			{{ permit.status.recentChangeDate | date }}

			} @else {

			<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>

			}
		</ng-container>
	</vlaio-value-tile>

	}
</div>

@if (view === 'all') {
<aside>
	<div class="value-tiles-wrapper">
		<vlaio-value-tile cypressTag="Permits.Product">
			<ng-container title>{{ i18nKeys.Permits.Card.Product | translate }}</ng-container>
			<ng-container content>
				@if (permit.product) {

				{{ permit.product }}

				} @else {

				<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>

				}
			</ng-container>
		</vlaio-value-tile>

		<vlaio-value-tile class="u-margin-top" cypressTag="Permits.Address">
			<ng-container title>{{ i18nKeys.Permits.Card.Address | translate }}</ng-container>
			<ng-container content>
				@if (addresses) {

				<vlaio-address format="multi-line" [address]="addresses[permit.assignedTo]" />

				} @else {

				<i>{{ i18nKeys.Common.Empty.NoData | translate }}</i>

				}
			</ng-container>
		</vlaio-value-tile>
	</div>

	@if (!readOnly) {
	<div class="button-wrapper u-margin-top">
		<vlaio-button
			cypressTag="Permits.Actions.Permit"
			icon="open-externally"
			[fullWidth]="true"
			[title]="
				(this.permit.referralUrl
					? i18nKeys.Permits.Card.PermitButton.Title
					: i18nKeys.Permits.Card.PermitButton.Disabled
				) | translate
			"
			[isDisabled]="!this.permit.referralUrl"
			(click)="handlePermitButtonClicked()"
		>
			{{ i18nKeys.Permits.Card.PermitButton.Text | translate }}
		</vlaio-button>

		<vlaio-button
			*hasFeature="'Cases'"
			class="u-margin-top-sm"
			cypressTag="Permits.Actions.File"
			icon="open-externally"
			[isDisabled]="!this.permit.status.fileNumber"
			[fullWidth]="true"
			[title]="
				(this.permit.status.fileNumber
					? i18nKeys.Permits.Card.CaseButton.Title
					: i18nKeys.Permits.Card.CaseButton.Disabled
				) | translate
			"
			(click)="handleCaseButtonClicked()"
		>
			{{ i18nKeys.Permits.Card.CaseButton.Text | translate }}
		</vlaio-button>
	</div>
	}
</aside>
}
