import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'environments';

import { AuthenticationService } from '../..';

@Injectable()
export class SessionErrorInterceptor implements HttpInterceptor {
	private readonly tokenError = 'refresh_token_exception';

	constructor(private readonly authService: AuthenticationService) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<any> {
		// Iben: Check if the request is a call to our API, if not early exit
		if (!request.url.includes(environment.api.fullPath)) {
			return next.handle(request);
		}

		// Iben: If the error returns a 401, we check if we need to logout the user
		return next.handle(request).pipe(
			catchError((response) => {
				// Iben: If the errorCode matches the token, we logout the user
				if (response.status === 401 && response.error?.errorCode === this.tokenError) {
					// Iben: Logout and refresh
					return this.authService.logout().pipe(
						tap(() => {
							location.reload();
						})
					);
				}

				// Iben: Return the error in regular cases
				return throwError(response);
			})
		);
	}
}
