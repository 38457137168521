import { inject } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { CanActivateFn, Router } from '@angular/router';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { Observable, combineLatest, filter, map, tap } from 'rxjs';

import { LanguageService } from '@vlaio/shared/i18n';
import { AppRoutePaths } from '@vlaio/shared/route-paths';
import { ToastService } from '@vlaio/shared/ui/toast';

import { UserService } from '../../data';
import { I18nKeys } from '../../i18n';

/**
 * This guard will never succeed. It allows us to check the store for a user. If one exists, we navigate to the dashboard page.
 * If one does not exist, (ie. the end-user is anonymous) we navigate to the home component.
 *
 * We navigate using the router, since this will activate the `HOME_PATH` route along with its guards and data attributes.
 * The component passed in this route (`FALLBACK_PATH`) will never be loaded, since this guard will always redirect and
 * return `false`.
 */

export const FallbackRedirectGuard: CanActivateFn = (): Observable<false> => {
	const userService: UserService = inject(UserService);
	const router: Router = inject(Router);
	const i18nService: I18nService = inject(I18nService);
	const languageService: LanguageService = inject(LanguageService);
	const toastService: ToastService = inject(ToastService);

	// Wouter: Initiate the toast message instance.
	const toastMessage = (): MatSnackBarRef<unknown> =>
		toastService.openFromComponent({
			type: 'Negative',
			text: i18nService.getTranslation(I18nKeys.User.Fallback.Notification)
		});

	return combineLatest([
		userService.user$,
		// Iben: We only allow this to emit once the refreshUser call is ready
		userService.loading$.pipe(filter((loading) => !loading))
	]).pipe(
		tap(([user]) => {
			// Wouter: If a user exists, navigate to the dashboard page.
			if (Boolean(user)) {
				router.navigate([languageService.currentLanguage, AppRoutePaths.Loket]).then(toastMessage);
				return;
			}

			// Wouter: user is not signed in, so we navigate to `home`.
			router.navigate([languageService.currentLanguage]).then(toastMessage);
		}),
		// Wouter: Always return false, but this will have no effect due to previous navigation.
		map(() => false)
	);
};
