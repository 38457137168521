import { transition, trigger, useAnimation } from '@angular/animations';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { Component, OnDestroy, HostBinding, OnInit, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { interval } from 'rxjs';
import { map, mergeMap, tap, timeInterval } from 'rxjs/operators';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { BrowserService } from '@vlaio/shared/core';
import { OnDestroyComponent, MediaQueryMax, MediaQueryMin } from '@vlaio/shared/types';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';

import { NotificationSeverity, NotificationsService, Notification } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { fadeInAnimation, fadeOutAnimation } from '../../animations/fade';

@Component({
	selector: 'vlaio-notifications-list',
	templateUrl: './notifications-list.component.html',
	styleUrls: ['./notifications-list.component.scss'],
	animations: [
		trigger('fadeInOutAnimation', [
			transition(':enter', [useAnimation(fadeInAnimation())]),
			transition(':leave', [useAnimation(fadeOutAnimation())])
		])
	],
	standalone: true,
	imports: [MediaQueryDirective, NgTemplateOutlet, CypressTagDirective, AsyncPipe, TranslateModule]
})
export class NotificationsListComponent extends OnDestroyComponent implements OnDestroy, OnInit {
	@HostBinding('class.has-errors') private hasErrorClass: boolean;
	@HostBinding('class.is-open') public isOpen: boolean;

	@HostBinding('class.c-notifications-mobile-ui') @Input() public useMobileUi: boolean = false;

	public readonly i18nKeys = I18nKeys;
	public readonly mediaQueryMin = MediaQueryMin;
	public readonly mediaQueryMax = MediaQueryMax;
	public readonly notifications$ = this.notificationsService.notifications$.pipe(
		map((items: Notification[]) =>
			items.map((item: Notification) => {
				return {
					...item,
					hasError: item.severity === NotificationSeverity.Error
				};
			})
		)
	);

	public readonly listHasError$: ObservableBoolean = this.notifications$.pipe(
		map((notifications) => notifications && notifications.some((item) => item.hasError)),
		tap((value) => (this.hasErrorClass = value))
	);

	constructor(
		private readonly notificationsService: NotificationsService,
		private readonly browserService: BrowserService
	) {
		super();
	}

	/**
	 * Remove overflow on body
	 */
	public onClose(): void {
		this.isOpen = false;
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.body.classList.remove('u-overflow-y--hidden--vp9');
		});
	}

	/**
	 * Toggle overflow on body when overlay is active
	 */
	public toggleNotifications() {
		this.isOpen = !this.isOpen;
		this.browserService.runInBrowser(({ browserDocument }) => {
			browserDocument.body.classList.toggle('u-overflow-y--hidden--vp9', this.isOpen);
		});
	}

	public trackById(_: number, item: Notification) {
		return item.id;
	}

	// Lifecycle methods
	// ------------------------------------------------------------------------- /
	public ngOnInit(): void {
		// Iben: Fetch the initial notifications
		this.notificationsService.getNotifications().subscribe();

		// Iben: Fetch the notifications every 30 seconds
		interval(30000)
			.pipe(
				timeInterval(),
				mergeMap(() => this.notificationsService.getNotifications())
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.onClose();
		super.ngOnDestroy();
	}
}
