import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { I18nKeys } from '../../i18n';

/**
 * A component that renders an external link. The navigation is done by opening the link in a new tab.
 */
@Component({
	selector: 'vlaio-external-link',
	template: ` <a
		class="u-link__external u-link--bold u-outline"
		target="_blank"
		rel="noreferrer"
		[href]="link"
		[title]="i18nKeys.Link.Extern | translate : { label: title }"
	>
		<span>
			<ng-content></ng-content>
		</span>
	</a>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule]
})
export class VlaioExternalLinkComponent {
	/**
	 * An external link
	 */
	@Input() public link: string = '';

	/**
	 * The title of the link
	 */
	@Input() public title: string = '';

	/**
	 * The translation keys
	 */
	public readonly i18nKeys = I18nKeys;
}
