import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import * as clean from 'obj-clean';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { PermitsEntity, PermitsResultEntity, convertPermit } from '@vlaio/shared/permits';
import { VlaioResult } from '@vlaio/shared/types';
import { convertVlaioResult } from '@vlaio/shared/utils';

import {
	CompanyActivityEntity,
	CompanyActivityResultEntity,
	CompanyEntity,
	CompanyResultEntity,
	PartialCompanySearchEntity
} from '../../data/interfaces';
import { convertCompanyActivityResultToCompanyActivity, convertCompanyResultToCompany } from '../utils';

@Injectable()
export class CompanyApiService {
	constructor(private readonly httpClient: VlaioHttpClientService) {}

	/**
	 * Get the data of a company
	 *
	 * @param number - Number of the company
	 */
	public getCompany(number: string): Observable<CompanyEntity> {
		return this.httpClient
			.get<CompanyResultEntity>(ELoketEndpoints.Company.GetCompany(number), {}, true)
			.pipe(map((result) => convertCompanyResultToCompany(result)));
	}

	/**
	 * Get the activities of a company branch
	 *
	 * @param number - Number of the company
	 * @param branch - Number of the branch
	 */
	public getCompanyBranchActivities(number: string, branch: string): ObservableArray<CompanyActivityEntity> {
		return this.httpClient
			.get<
				VlaioResult<CompanyActivityResultEntity, 'Activiteiten'>
			>(ELoketEndpoints.Company.GetCompanyBranchActivities(number, branch), {}, true)
			.pipe(map(({ elementen }) => elementen.map((item) => convertCompanyActivityResultToCompanyActivity(item))));
	}

	/**
	 * Get a list of companies based on the provided filters
	 *
	 * @param filters - The provided filters (searchQuery and zipCode)
	 */
	public searchCompanies({ searchQuery, zipCode }: PartialCompanySearchEntity): ObservableArray<CompanyEntity> {
		const filters = {
			trefwoord: searchQuery,
			postcode: zipCode ? Number(zipCode.code) : ''
		};

		return this.httpClient
			.post<VlaioResult<CompanyResultEntity, 'Ondernemingen'>>(ELoketEndpoints.Company.Search(), clean(filters))
			.pipe(
				convertVlaioResult<'Ondernemingen', CompanyResultEntity, CompanyEntity>(convertCompanyResultToCompany)
			);
	}

	/**
	 * Fetches the permits of a specific company
	 *
	 * @param company - The company number
	 */
	public getCompanyPermits(company: string): ObservableArray<PermitsEntity> {
		// Iben: Setup the params
		const params = new HttpParams({
			fromObject: {
				'ondernemingsnummers[]': [company]
			}
		});

		// Iben: Fetch the permits
		return this.httpClient
			.get<
				VlaioResult<PermitsResultEntity, 'ToelatingenVerzameling'>
			>(ELoketEndpoints.Permits.Search(), params, true)
			.pipe(convertVlaioResult<'ToelatingenVerzameling', PermitsResultEntity, PermitsEntity>(convertPermit));
	}
}
