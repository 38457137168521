import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CompanyAddressRecord } from '@vlaio/shared/company';
import { HasFeatureDirective } from '@vlaio/shared/features';
import { VlaioIconComponent, ValueTileComponent, AddressComponent, ButtonComponent } from '@vlaio/shared/ui/common';
import { UserEntity } from '@vlaio/shared/user';

import { PermitsEntity } from '../../../data';
import { I18nKeys } from '../../../i18n';

@Component({
	selector: 'permits-detail',
	templateUrl: './permits-detail.component.html',
	styleUrls: ['./permits-detail.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CypressTagDirective,
		VlaioIconComponent,
		ValueTileComponent,
		AddressComponent,
		ButtonComponent,
		HasFeatureDirective,
		DatePipe,
		TranslateModule
	]
})
export class PermitsDetailComponent {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * The permit to render in the detail view.
	 */
	@Input({ required: true }) public permit: PermitsEntity;
	/**
	 * The permit to render in the detail view.
	 */
	@Input({ required: false }) public company: UserEntity;
	/**
	 * A record of the branchNumber and its address.
	 */
	@Input({ required: true }) public addresses: CompanyAddressRecord;

	/**
	 * Whether or not the table is read-only, by default false. Setting this to true will hide the buttons
	 */
	@Input() public readOnly: boolean = false;

	/**
	 * Whether we wish to show all information or only the minimal amount. By default this is 'all'
	 */
	@Input() public view: 'minimal' | 'all' = 'all';

	/**
	 * Emits the url of the permit whose button was pressed.
	 */
	@Output() public readonly permitButtonClicked: EventEmitter<string> = new EventEmitter();
	/**
	 * Emits the fileNumber of the permit whose button was pressed.
	 */
	@Output() public readonly caseButtonClicked: EventEmitter<string> = new EventEmitter();

	// Component methods
	// ==============================
	/**
	 * When the user clicks on the `permit` button, a new tab is opened with the
	 * deeplink that is passed.
	 */
	public handlePermitButtonClicked(): void {
		if (!this.permit || !this.permit.referralUrl) {
			return;
		}

		this.permitButtonClicked.emit(this.permit.referralUrl);
	}

	/**
	 * When the user clicks on the `case` button, a new tab is openend with the case opened
	 * on the `cases` page.
	 */
	public handleCaseButtonClicked(): void {
		if (!this.permit.status || !this.permit.status.fileNumber) {
			return;
		}

		this.caseButtonClicked.emit(this.permit.status.fileNumber);
	}
}
