import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';

import { VlaioFaIconComponent, VlaioDisclaimerComponent, ButtonComponent } from '@vlaio/shared/ui/common';

import { CookieConsentTranslationsEntity, VideoDataEntity } from '../video-button/video-button.types';

@Component({
	selector: 'video-modal',
	templateUrl: './video-modal.component.html',
	styleUrl: './video-modal.component.scss',
	standalone: true,
	imports: [VlaioFaIconComponent, VlaioDisclaimerComponent, ButtonComponent, FocusClickDirective]
})
export class VideoModalComponent {
	/**
	 * Whether the video cookie is currently accepted.
	 */
	@Input({ required: true }) public hasCookieConsent: boolean;
	/**
	 * The text to display in the read more link.
	 */
	@Input({ required: true }) public videoData: VideoDataEntity;
	/**
	 * The translations used in the template.
	 */
	@Input({ required: true }) public translations: CookieConsentTranslationsEntity;

	/**
	 * Emits when the button to accept the cookie has been pressed.
	 */
	@Output() public readonly cookieAccepted: EventEmitter<void> = new EventEmitter<void>();
	/**
	 * Emits when the modal is closed by the user.
	 */
	@Output() public readonly handleClose: EventEmitter<void> = new EventEmitter<void>();
}
