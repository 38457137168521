import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { createAccessorProviders, FormAccessor } from '@studiohyperdrive/ngx-forms';

@Component({
	selector: 'vlaio-date-input',
	templateUrl: './date-input.component.html',
	styleUrl: './date-input.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [createAccessorProviders(VlaioDateInputComponent)],
	standalone: true,
	imports: [ReactiveFormsModule]
})
export class VlaioDateInputComponent extends FormAccessor<string, FormControl<string>> {
	@Input() public label: string = 'INPUT';

	@Input({ required: true }) inputId: string;

	public initForm(): FormControl<string> {
		return new FormControl<string>('');
	}
}
