import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
	selector: 'vlaio-tag-group',
	templateUrl: './tag-group.component.html',
	styleUrls: ['./tag-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class TagGroupComponent {
	@HostBinding('class.c-vlaio-tag-group') private readonly hasTagGroupClass: boolean = true;
}
