import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonComponent } from '@vlaio/shared/ui/common';

@Component({
	selector: 'vlaio-action-modal',
	templateUrl: './action-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonComponent]
})
export class ActionModalComponent {
	@Input() public title: string;
	@Input() public text: string;
	@Input() public confirmLabel: string;
	@Input() public cancelLabel: string;

	@Output() public buttonClicked = new EventEmitter<'confirm' | 'cancel'>();
}
