@if (codesForm && codes) { @for (code of codes; track code) { @if ((filterNacebelCodes && searchResults.has('' +
code.id)) || !filterNacebelCodes) { @if (code.subcodes && code.subcodes.length > 0) {
<vlaio-tree-item [open]="'' + code.id | isNacebelOpen : isOpenSet">
	<vlaio-nacebel-title label [formGroup]="$any(codesForm.get('' + code.id))" [code]="code">
		@if (code.selectable) {
		<vlaio-checkbox [value]="code" [formControlName]="'' + code.id"> </vlaio-checkbox>
		}
	</vlaio-nacebel-title>

	@if (code.subcodes && code.subcodes.length > 0) {
	<vlaio-nacebel-tree-item
		content
		[isOpenSet]="isOpenSet"
		[codes]="code.subcodes"
		[filterNacebelCodes]="filterNacebelCodes"
		[searchResults]="searchResults"
		[codesForm]="$any(codesForm.get('' + code.id))"
	>
	</vlaio-nacebel-tree-item>
	}
</vlaio-tree-item>
} @else {

<vlaio-nacebel-title class="nacebel-tree-leaf" label [formGroup]="$any(codesForm.get('' + code.id))" [code]="code">
	@if (code.selectable) {
	<vlaio-checkbox [value]="code" [formControl]="$any(codesForm.get('' + code.id))"> </vlaio-checkbox>
	}
</vlaio-nacebel-title>

} } } }
