import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslationLoaderGuard } from '@studiohyperdrive/ngx-i18n';

import { BaseComponent } from '@vlaio/e-loket/core';
import { FooterPageComponent } from '@vlaio/e-loket/footer';
import { SinglePageComponent } from '@vlaio/e-loket/single-page';
import { LoginHintGuard } from '@vlaio/shared/authentication/auth';
import { MetaGuard } from '@vlaio/shared/core';
import { HasFeatureGuard } from '@vlaio/shared/features';
import { GTagGuard, IndexGuard } from '@vlaio/shared/gtag';
import { LanguageGuard, SetLanguageGuard } from '@vlaio/shared/i18n';
import { AppRoutePaths, VlaioRoute, VlaioRoutes } from '@vlaio/shared/route-paths';
import { DirectedLoginGuard, FallbackRedirectGuard, IsAuthenticatedGuard } from '@vlaio/shared/user';

import { I18nKeys } from './markers';

// Iben: Do not add the GtagGuard to this path, or it will track home with every page
const HOME_PATH: VlaioRoute = {
	path: AppRoutePaths.Empty,
	loadChildren: () => import('@vlaio/e-loket/home').then((m) => m.HomeRoutes),
	canActivate: [IndexGuard, MetaGuard],
	data: {
		meta: {
			title: I18nKeys.PageTitles.Home,
			description: I18nKeys.PageDescriptions.Home
		},
		gtag: {
			title: I18nKeys.PageTitles.Home
		},
		companyContext: false,
		preventIndex: {
			routes: ['?products']
		}
	}
};

const ELOKET_PATH: VlaioRoute = {
	path: AppRoutePaths.Loket,
	loadChildren: () => import('@vlaio/e-loket/core-authenticated').then((m) => m.ELoketRoutes),
	canActivate: [IndexGuard, IsAuthenticatedGuard],
	data: {
		isMenuHidden: true,
		preventIndex: {
			preventAll: true
		}
	}
};

const ABOUT_PATH: VlaioRoute = {
	path: AppRoutePaths.About,
	loadChildren: () => import('@vlaio/e-loket/about').then((m) => m.AboutRoutes),
	canActivate: [IndexGuard, HasFeatureGuard, MetaGuard],
	canActivateChild: [GTagGuard],
	data: {
		meta: {
			title: I18nKeys.PageTitles.AboutUs,
			description: I18nKeys.PageDescriptions.AboutUs
		},
		gtag: {
			title: I18nKeys.PageTitles.AboutUs
		},
		feature: 'About'
	}
};

const PARTNERS_PATH: VlaioRoute = {
	path: AppRoutePaths.Partners,
	loadChildren: () => import('@vlaio/e-loket/partners/home').then((m) => m.HomePartnersRoutes),
	canActivate: [IndexGuard, HasFeatureGuard, MetaGuard],
	canActivateChild: [GTagGuard],
	data: {
		meta: {
			title: I18nKeys.PageTitles.Partners,
			description: I18nKeys.PageDescriptions.Partners
		},
		gtag: {
			title: I18nKeys.PageTitles.Partners
		},
		feature: 'Partners'
	}
};

const FAQ_PATH: VlaioRoute = {
	path: AppRoutePaths.Faq,
	loadChildren: () => import('@vlaio/e-loket/faq').then((m) => m.FaqRoutes),
	canActivate: [IndexGuard, MetaGuard],
	canActivateChild: [GTagGuard],
	data: {
		meta: {
			title: I18nKeys.PageTitles.Faq,
			description: I18nKeys.PageDescriptions.Faq
		},
		gtag: {
			title: I18nKeys.PageTitles.Faq
		}
	}
};

const PRODUCT_PATH: VlaioRoute = {
	path: AppRoutePaths.Offers,
	loadChildren: () => import('@vlaio/e-loket/products/home').then((m) => m.ProductsHomeRoutes),
	canActivate: [IndexGuard, HasFeatureGuard, MetaGuard],
	data: {
		feature: 'Products',
		gtag: {
			title: I18nKeys.PageTitles.Offers
		},
		meta: {
			title: I18nKeys.PageTitles.Offers,
			description: I18nKeys.PageDescriptions.Offers
		}
	}
};

const PAGE_PATH: VlaioRoute = {
	path: AppRoutePaths.Page,
	component: FooterPageComponent,
	canActivate: [IndexGuard, TranslationLoaderGuard]
};

const SINGLE_PAGE_PATH: VlaioRoute = {
	path: AppRoutePaths.SinglePage,
	component: SinglePageComponent,
	canActivate: [IndexGuard, TranslationLoaderGuard]
};

const REDIRECT_PATH: VlaioRoute = {
	path: AppRoutePaths.Redirects,
	loadChildren: () => import('@vlaio/shared/redirects').then((m) => m.RedirectRoutes),
	canActivate: [IndexGuard, MetaGuard],
	data: {
		meta: {
			title: I18nKeys.PageTitles.ELoket.Redirect,
			description: I18nKeys.PageDescriptions.ELoket.Redirect
		},
		gtag: {
			title: I18nKeys.PageTitles.ELoket.Redirect
		}
	}
};

const PWA_PATH: VlaioRoute = {
	path: AppRoutePaths.PWA,
	loadChildren: () => import('@vlaio/e-loket/pwa').then((m) => m.PWARoutes),
	canActivate: [IndexGuard, MetaGuard],
	data: {
		meta: {
			title: I18nKeys.PageTitles.ELoket.Pwa,
			description: I18nKeys.PageDescriptions.ELoket.Pwa
		},
		gtag: {
			title: I18nKeys.PageTitles.ELoket.Pwa
		}
	}
};

const INFO_PATH: VlaioRoute = {
	path: AppRoutePaths.Info,
	loadChildren: () => import('@vlaio/e-loket/info').then((m) => m.InfoRoutes)
};

const USER_STATUS_PATH: VlaioRoute = {
	path: AppRoutePaths.UserStatus,
	loadChildren: () => import('@vlaio/shared/user-status').then((m) => m.UserStatusRoutes),
	canActivate: [IndexGuard],
	data: {
		preventIndex: {
			preventAll: true
		}
	}
};

const FALLBACK_PATH: VlaioRoute = {
	path: AppRoutePaths.Fallback,
	canActivate: [FallbackRedirectGuard],
	// Wouter: This is a dummy component. The FallbackRedirectGuard will always return `false`
	loadChildren: () => import('@vlaio/e-loket/home').then((m) => m.HomeRoutes)
};

// Iben: We provide this fallback path so that previous URLS keep working
const E_LOKET_FALLBACK_PATH: VlaioRoute = {
	path: AppRoutePaths.ELoket,
	redirectTo: AppRoutePaths.Loket
};

// See https://github.com/angular/angular/issues/18605. Until ng 9, use a
// dummy component.
const DIRECTED_LOGIN_PATH: VlaioRoute = {
	path: AppRoutePaths.DirectedLogin,
	canActivate: [IndexGuard, DirectedLoginGuard],
	loadChildren: () => import('@vlaio/e-loket/home').then((m) => m.HomeRoutes)
};

const APP_ROUTES: VlaioRoutes = [
	{
		path: '',
		canActivate: [LanguageGuard],
		pathMatch: 'full',
		component: BaseComponent
	},
	{
		path: 'deelnemen',
		redirectTo: '/nl/pagina?page=deelnemen',
		pathMatch: 'full'
	},
	{
		path: AppRoutePaths.Language,
		pathMatch: 'prefix',
		component: BaseComponent,
		canActivate: [SetLanguageGuard],
		canActivateChild: [LoginHintGuard],
		children: [
			HOME_PATH,
			DIRECTED_LOGIN_PATH,
			E_LOKET_FALLBACK_PATH,
			ELOKET_PATH,
			ABOUT_PATH,
			PARTNERS_PATH,
			PRODUCT_PATH,
			FAQ_PATH,
			PAGE_PATH,
			SINGLE_PAGE_PATH,
			INFO_PATH,
			REDIRECT_PATH,
			PWA_PATH,
			USER_STATUS_PATH,
			FALLBACK_PATH
		]
	}
];

/**
 * Upon navigation, the scrollPosition will be reset to the top. This does not
 * prevent explicitly setting a different scroll position onInit.
 */
export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(APP_ROUTES, {
	scrollPositionRestoration: 'top'
	// enableTracing: true
});
