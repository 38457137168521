<div>
	<div class="c-modal__header">
		<h3 class="c-modal__title">{{ i18nKeys.title }}</h3>
	</div>

	<div class="c-modal__body">
		<p>
			{{ i18nKeys.description }}
		</p>
	</div>

	<div class="c-modal__footer">
		<vlaio-button class="c-modal__action" [title]="i18nKeys.exit" (click)="closeModal.emit(modalOptions.Exit)">
			{{ i18nKeys.exit }}
		</vlaio-button>

		<vlaio-button
			class="c-modal__action"
			classes="c-button c-button--outline"
			[title]="i18nKeys.cancel"
			(click)="closeModal.emit(modalOptions.Cancel)"
		>
			{{ i18nKeys.cancel }}
		</vlaio-button>
	</div>
</div>
