@if (isOnline$ | async) { @if (loading) {
<vlaio-loader [label]="label"> </vlaio-loader>
} @else { @if (!error) {

<ng-content></ng-content>

} @else {

<vlaio-empty-search-result type="error" />

} } } @else {

<vlaio-disclaimer type="danger">
	<p>{{ offlineLabel | translate }}</p>
</vlaio-disclaimer>

}
