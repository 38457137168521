/**
 * The entity of the data passed to the toast component.
 */
export interface ToastEntity {
	showIcon?: boolean;
	text: string;
	type?: keyof typeof ToastEnum;
	duration?: number;
}

/**
 * The types of the toasts.
 */
export enum ToastEnum {
	Positive = 'positive',
	Negative = 'negative',
	Info = 'info',
	Warning = 'warning'
}
