import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { environment } from 'environments';

@Component({
	selector: 'vlaio-img',
	templateUrl: './image.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgOptimizedImage]
})
export class ImageComponent {
	public prefixedSrc = '';

	@HostBinding('class') @Input() public class: string;
	@Input() set src(value: string) {
		this.prefixedSrc = `${environment.assets.fullPath}${value}`;
	}
	@Input() public alt: string = '';
}
