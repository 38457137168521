import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { VlaioIconComponent, VlaioContentComponent, VlaioAddressComponent } from '@vlaio/shared/ui/common';

import { CompanyActivityEntity, CompanyAddressEntity } from '../../../data';
import { sharedCompanyI18nKeys } from '../../../i18n';
import { ActivityTableComponent } from '../activity-table/activity-table.component';

@Component({
	selector: 'vlaio-company-activity',
	templateUrl: './activity.component.html',
	styleUrl: './activity.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioIconComponent, VlaioContentComponent, VlaioAddressComponent, ActivityTableComponent, TranslateModule]
})
export class CompanyActivityComponent implements OnChanges {
	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof sharedCompanyI18nKeys.Companies = sharedCompanyI18nKeys.Companies;

	/**
	 * The title of the branch.
	 */
	@Input() public title: string;
	/**
	 * The name of the branch.
	 */
	@Input() public name: string;
	/**
	 * The address of the branch.
	 */
	@Input() public address: CompanyAddressEntity;
	/**
	 * Whether or not the activity should be open by default.
	 *
	 * Default value is `false`.
	 */
	@Input() public defaultIsOpenState: boolean = false;
	/**
	 * The loading state.
	 */
	@Input() public loading: boolean = false;
	/**
	 * The error state.
	 */
	@Input() public error: boolean = false;
	/**
	 * The activities of the current branch.
	 */
	@Input() public activities: CompanyActivityEntity[];
	/**
	 * Whether the branch is the enterprise or a subbranch.
	 *
	 * Default value is `false`.
	 */
	@Input() public isEnterprise: boolean = false;

	/**
	 * Requests the branch to load the activities and other details.
	 */
	@Output() public activitiesRequested = new EventEmitter<void>();

	/**
	 * The actual title that gets displayed. It is composed
	 * of the title and name input (if provided).
	 */
	public displayTitle: string = '';

	/**
	 * Fetches the activities if they do not exist yet.
	 */
	public toggleDetail() {
		if (!this.activities) {
			this.activitiesRequested.emit();
		}
	}

	/**
	 * Detect changes on the `name` or `title` input. With this check we
	 * can compose the title for the activity. If there is no name for a
	 * specific branch (vestiging), we only display the title without the
	 * name. Not all branches are provided with a name. - Kaat
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes['name'] || changes['title']) {
			if (changes['name']?.currentValue !== '' && this.name) {
				this.displayTitle = `${this.title} - ${this.name}`;
			} else {
				this.displayTitle = this.title;
			}
		}
	}
}
