@if (label) {
	<label class="c-input__label" [for]="id">
		{{ label }}
		@if (isOptional) {
			<small class="c-input__label__optional">
				{{ i18nKeys.FormLabels.OptionalMarker | translate }}
			</small>
		}
	</label>
}
@if (hint) {
	<div class="c-input__hint">{{ hint }}</div>
}

<ng-content></ng-content>

<div class="c-input__description">
	<ng-content select="[description]"></ng-content>
</div>
