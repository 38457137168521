<label [attr.for]="id">
	<input
		class="u-outline"
		type="radio"
		[name]="name"
		[id]="id"
		[title]="title || i18nKeys.Facet.Radio.Title | translate: { label }"
		[formControl]="form"
		[value]="value"
	/>

	<ng-content></ng-content>
</label>
