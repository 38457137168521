import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { finalize, tap } from 'rxjs/operators';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { AuthenticationFailedTypes, AuthenticationService } from '@vlaio/shared/authentication/auth';
import { VlaioFaIconComponent } from '@vlaio/shared/ui/common';

import { RedirectI18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-authentication-failed-page',
	templateUrl: './authentication-failed.component.html',
	styleUrls: ['./authentication-failed.component.scss'],
	standalone: true,
	imports: [CypressTagDirective, VlaioFaIconComponent, TranslateModule]
})
export class AuthenticationFailedPageComponent implements OnInit {
	public title: string;
	public text: string;
	public button: string;

	constructor(private readonly authService: AuthenticationService, private readonly router: Router) {}

	ngOnInit() {
		this.authService.authenticationFailed$
			.pipe(
				tap((failType: AuthenticationFailedTypes) => {
					switch (failType) {
						case AuthenticationFailedTypes.Invalid:
							this.title = RedirectI18nKeys.AuthenticationFailed.Invalid.Title;
							this.text = RedirectI18nKeys.AuthenticationFailed.Invalid.Text;
							this.button = RedirectI18nKeys.AuthenticationFailed.Invalid.Button;
							break;
						case AuthenticationFailedTypes.ThreshholdExceeded:
							this.title = RedirectI18nKeys.AuthenticationFailed.LoginThreshholdExceeded.Title;
							this.text = RedirectI18nKeys.AuthenticationFailed.LoginThreshholdExceeded.Text;
							this.button = RedirectI18nKeys.AuthenticationFailed.LoginThreshholdExceeded.Button;
							break;
						default:
							this.title = RedirectI18nKeys.AuthenticationFailed.Failed.Title;
							this.text = RedirectI18nKeys.AuthenticationFailed.Failed.Text;
							this.button = RedirectI18nKeys.AuthenticationFailed.Failed.Button;
					}
				})
			)
			.subscribe();
	}

	logOut() {
		this.authService
			.logout()
			.pipe(
				finalize(() => {
					this.router.navigate(['/nl']).then(() => {
						window.location.reload();
					});
				})
			)
			.subscribe();
	}
}
