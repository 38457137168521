<vlaio-button
	class="c-filter__button"
	buttonType="RegularOutline"
	#openFilter
	icon="filter"
	cypressTag="Offers.Facets.OpenFilter"
	aria-controls="offers-filter"
	[title]="i18nKeys.Facets.Actions.Filter | translate"
	[attr.aria-expanded]="isOpen"
	[fullWidth]="true"
	(handleClick)="toggleIsOpen()"
>
	{{ i18nKeys.Facets.Actions.Filter | translate }}
</vlaio-button>

<form class="c-filter__container u-flex u-cols" aria-describedby="offers-filter" [formGroup]="form">
	@if (enableClear) {
		<vlaio-button
			type="button"
			icon="close"
			buttonType="Link"
			customButtonClass="c-filter__filters__clear-btn"
			[title]="i18nKeys.Forms.Actions.ClearFilters.Title | translate"
			(handleClick)="clearFilters()"
		>
			{{ i18nKeys.Forms.Actions.ClearFilters.Text | translate }}
		</vlaio-button>
	}

	<div class="c-filter__filters" cypressTag="Filters.Container">
		<div class="u-flex u-flex--justify-end">
			<button
				class="c-filter__close u-outline"
				#closeFilter
				[title]="i18nKeys.Forms.Actions.CloseFilter | translate"
				(click)="toggleIsOpen()"
			>
				<span class="icon-close"></span>
			</button>
		</div>

		@if (isLoading) {
			<div class="loadingFilterContainer">
				<vlaio-content [loading]="isLoading" [label]="i18nKeys.Facets.Loading | translate">
					<ng-content></ng-content>
				</vlaio-content>
			</div>
		}
		@if (isEmpty) {
			<div class="c-search-results__emptystate">
				{{ i18nKeys.Facets.NoFilters | translate }}
			</div>
		}

		<ul class="u-reset-list c-filter__filters-list">
			@for (facet of facetsArray; track facet) {
				@if (facet?.items?.length > 0) {
					@if (!facet.isRefinable) {
						<li
							class="u-flex u-cols c-filter__item"
							cypressTag="Filters.Facets.ListBlock"
							[formArrayName]="facet.id"
						>
							<span class="c-filter__title">
								{{ facet.name }}
							</span>

							@if (facet.items; as items) {
								<ul class="u-reset-list" cypressTag="Filters.Facets.List">
									@for (
										control of formArrayMap[facet.id].controls
											| limitTo
												: (showAllFilters[facet.id]
														? items.length
														: filterLimitByFacet[facet.id]);
										track control;
										let i = $index
									) {
										<li class="u-list__item" cypressTag="Filters.Facets.ListItem">
											@if (items[i]; as item) {
												<vlaio-checkbox
													cypressTag="Filters.Facets.UnrefinableFacet"
													label="{{ item.name }} ({{ $any(item).amount }})"
													[formControl]="$any(control)"
													[id]="item.id"
													[value]="item.id"
													(changed)="changed(item.name, $event)"
												>
													<span>{{ item.name }}</span>
													&nbsp;<span class="c-filter__label-count"
														>({{ $any(item).amount }})</span
													>
												</vlaio-checkbox>
											}
										</li>
									}
									@if (items.length > showMoreLimit) {
										<li class="c-filter__more-refined-items u-outline">
											<vlaio-button
												cypressTag="Filters.ShowMore"
												buttonType="Link"
												[title]="
													(showAllFilters[facet.id]
														? i18nKeys.Facets.Actions.LessFilters
														: i18nKeys.Facets.Actions.MoreFilters
													) | translate
												"
												(handleClick)="showAllForFacet(facet.id)"
											>
												{{
													(showAllFilters[facet.id]
														? i18nKeys.Facets.Actions.LessFilters
														: i18nKeys.Facets.Actions.MoreFilters
													) | translate
												}}
											</vlaio-button>
										</li>
									}
								</ul>
							}
						</li>
					} @else {
						<li
							class="u-flex u-cols c-filter__item"
							cypressTag="Filters.Facets.ListBlock"
							[formArrayName]="facet.id"
						>
							<span class="c-filter__title">
								{{ facet.name }}
							</span>

							@for (subFacet of facet.items; track subFacet; let index = $index) {
								@if (sortedRefinements[facet.id][subFacet.id]; as items) {
									<ul
										class="u-reset-list c-filter__refined-list"
										checkboxGroup
										[class.has-margin-top]="index !== 0"
									>
										<li class="u-list__item c-filter_refine-item-title">
											<vlaio-checkbox
												#groupCheckbox
												cypressTag="Filters.Facets.RefinableFacet"
												[id]="subFacet.id"
												[label]="subFacet.name"
												(inputClicked)="changed(subFacet.name, $event)"
												(labelClicked)="toggleRefinementsOfSubFacet(facet.id, subFacet.id)"
											>
												{{ subFacet.name }}
											</vlaio-checkbox>
											<span
												class="icon u-clickable u-outline"
												cypressTag="Offers.Facets.Toggle"
												tabindex="0"
												[class.icon-arrow-down]="
													!refinementsOfSubFacetsShown[facet.id][subFacet.id]
												"
												[class.icon-arrow-up]="
													refinementsOfSubFacetsShown[facet.id][subFacet.id]
												"
												[title]="i18nKeys.Facets.Subnav | translate"
												(focusClick)="toggleRefinementsOfSubFacet(facet.id, subFacet.id)"
											>
											</span>
										</li>

										<div
											cypressTag="Offers.Facets.RefinedFacetsBlock"
											[hidden]="!refinementsOfSubFacetsShown[facet.id][subFacet.id]"
										>
											@if (refinementIndexMap[facet.id][subFacet.id]; as indexes) {
												@if (
													formArrayMap[facet.id].controls
														| slice: indexes.start : indexes.end;
													as subFacetItems
												) {
													@for (control of subFacetItems; track control; let i = $index) {
														<li
															class="u-list__item c-filter__refined-item"
															cypressTag="Offers.Facets.RefinedFacet"
															[style.display]="
																!(
																	showAllRefinements[facet.id][subFacet.id] ||
																	control.value ||
																	i < showMoreLimit
																)
																	? 'none'
																	: 'flex'
															"
														>
															@if (items[i]; as item) {
																<vlaio-checkbox
																	#individualCheckBox
																	cypressTag="Filters.Facets.UnrefinableFacet"
																	label="{{ item.name }} ({{ item.amount }})"
																	[formControl]="$any(control)"
																	[id]="item.id"
																	[value]="item.id"
																	(changed)="changed(item.name, $event)"
																>
																	<span>{{ item.name }}</span
																	>&nbsp;<span class="c-filter__label-count"
																		>({{ $any(item).amount }})</span
																	>
																</vlaio-checkbox>
															}
														</li>
													}
													@if (subFacetItems.length > showMoreLimit) {
														<li class="c-filter__more-refined-items">
															<vlaio-button
																cypressTag="Filters.ShowMore"
																buttonType="Link"
																[title]="
																	(showAllRefinements[facet.id][subFacet.id]
																		? i18nKeys.Facets.Actions.LessFilters
																		: i18nKeys.Facets.Actions.MoreFilters
																	) | translate
																"
																(handleClick)="
																	showAllForSubFacet(facet.id, subFacet.id)
																"
															>
																{{
																	(showAllRefinements[facet.id][subFacet.id]
																		? i18nKeys.Facets.Actions.LessFilters
																		: i18nKeys.Facets.Actions.MoreFilters
																	) | translate
																}}
															</vlaio-button>
														</li>
													}
												}
											}
										</div>
									</ul>
								}
							}
						</li>
					}
				}
			}
		</ul>
	</div>

	@if (isOpen) {
		<vlaio-button
			class="c-filter__results-button"
			cypressTag="Offers.Facets.ShowResults"
			[title]="i18nKeys.Facets.Actions.ShowResults | translate"
			(handleClick)="toggleIsOpen()"
		>
			{{ i18nKeys.Facets.Actions.ShowResults | translate }}
		</vlaio-button>
	}
</form>
