import { registerLocaleData, AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl-BE';
import { Component, OnInit, OnDestroy, Renderer2, AfterViewInit, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCookieService } from '@studiohyperdrive/ngx-cookies';
import { I18nLoadingService, I18nService } from '@studiohyperdrive/ngx-i18n';
import { NgxMediaQueryService } from '@studiohyperdrive/ngx-utils';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { differenceInSeconds, getUnixTime } from 'date-fns';
import { Observable, Subject, fromEvent, timer, of, combineLatest, from, BehaviorSubject } from 'rxjs';
import {
	catchError,
	distinctUntilChanged,
	filter,
	finalize,
	map,
	switchMap,
	take,
	takeUntil,
	takeWhile,
	tap
} from 'rxjs/operators';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { SpotlightService } from '@vlaio/e-loket/core';
import { FooterComponent } from '@vlaio/e-loket/footer';
import { AcmHeaderComponent } from '@vlaio/shared/authentication/acm';
import { AuthenticationFailedTypes, AuthenticationService } from '@vlaio/shared/authentication/auth';
import { savedKBOCookie } from '@vlaio/shared/cookies';
import {
	BrowserService,
	MetaService,
	ScriptsService,
	StatusService,
	VlaioHttpClientService,
	PwaService
} from '@vlaio/shared/core';
import { FeatureService, HasFeatureDirective } from '@vlaio/shared/features';
import { GTagService } from '@vlaio/shared/gtag';
import { LanguageService, LanguageSwitcherComponent } from '@vlaio/shared/i18n';
import { LoadingPageComponent } from '@vlaio/shared/loading';
import { PageService } from '@vlaio/shared/page';
import { AppRoutePaths, InfoPaths, PagesRoutePaths, RedirectsPaths } from '@vlaio/shared/route-paths';
import { Language, MediaQueryMax, MediaQueryMin, PageNavItemEntity, Pages } from '@vlaio/shared/types';
import {
	VlaioMenuComponent,
	VlaioModalComponent,
	VlaioSkipLinksComponent,
	VlaioInternalLinkComponent,
	VlaioMenuItemEntity,
	VlaioInternalTranslatedLinkComponent
} from '@vlaio/shared/ui/common';
import { VlaioActionModalComponent, ModalDialogService } from '@vlaio/shared/ui/modal';
import { getAuthFailType, UserEntity, UserService } from '@vlaio/shared/user';
import { environment } from '~environment';

import { I18nKeys } from './markers';

registerLocaleData(localeNl);

@Component({
	selector: 'vlaio-app',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	standalone: true,
	imports: [
		VlaioSkipLinksComponent,
		AcmHeaderComponent,
		HasFeatureDirective,
		LanguageSwitcherComponent,
		VlaioMenuComponent,
		RouterOutlet,
		FooterComponent,
		VlaioModalComponent,
		LoadingPageComponent,
		AsyncPipe,
		TranslateModule,
		CypressTagDirective,
		VlaioInternalLinkComponent
	]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	private readonly initialTranslationLoadSubject$ = new BehaviorSubject<boolean>(false);

	public readonly i18nKey = I18nKeys;
	public containerOffsetTop = 0;
	public header = null;
	public readonly language$: Observable<Language> = this.languageService.currentLanguage$;
	public readonly paths = { ...AppRoutePaths, ...PagesRoutePaths };

	public readonly footerNavItems$: ObservableArray<PageNavItemEntity> = this.pageService.getNavigationItems(
		Pages.Footer
	);
	public readonly isAuthenticated$: ObservableBoolean = this.userService.user$.pipe(map(Boolean));
	public isLoaded$: ObservableBoolean = this.initialTranslationLoadSubject$.asObservable();

	public isAvailable$: ObservableBoolean = this.statusService.status$;

	public menuItems$: ObservableArray<VlaioMenuItemEntity> = combineLatest([
		this.isAuthenticated$,
		this.featureService.hasFeature('Mandates'),
		this.featureService.hasFeature('Partners'),
		this.featureService.hasFeature('About'),
		this.languageService.currentLanguage$
	]).pipe(
		map(([isAuthenticated, hasMandates, hasPartners, hasAbout, language]) => {
			const result: VlaioMenuItemEntity[] = [
				isAuthenticated
					? {
							id: I18nKeys.PageTitles.MyELoket,
							routerLink: [language, this.paths.ELoket],
							cypressTag: 'Home.Nav.ELoket'
						}
					: {
							id: I18nKeys.PageTitles.Offers,
							routerLink: [language, this.paths.Offers],
							cypressTag: 'Home.Nav.Offers'
						}
			];

			// Iben: If the about flag is turned on, we add the additional menu link
			if (hasAbout) {
				result.push({
					id: I18nKeys.PageTitles.AboutUs,
					routerLink: [language, this.paths.About],
					cypressTag: 'Home.Nav.AboutUs'
				});
			}

			// Iben: If the partners flag is turned on, we add the additional menu link
			if (hasPartners) {
				result.push({
					id: I18nKeys.PageTitles.Partners,
					routerLink: [language, this.paths.Partners],
					cypressTag: 'Home.Nav.Partners'
				});
			}

			// Iben: If the mandates flag is turned on, we add the additional menu link
			if (hasMandates) {
				result.push({
					id: I18nKeys.PageTitles.RightsManagement,
					routerLink: [language, this.paths.Info, InfoPaths.RightsManagement],
					cypressTag: 'Home.Nav.Mandates'
				});
			}

			return result;
		})
	);

	private sessionRemaining: number;
	private onDestroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private readonly userService: UserService,
		private readonly authService: AuthenticationService,
		private readonly router: Router,
		private readonly renderer: Renderer2,
		private readonly pageService: PageService,
		private readonly route: ActivatedRoute,
		private readonly gtag: GTagService,
		private readonly statusService: StatusService,
		private readonly modalDialogService: ModalDialogService,
		private readonly i18nService: I18nService,
		private readonly browserService: BrowserService,
		private readonly scriptsService: ScriptsService,
		private readonly spotlightService: SpotlightService,
		private readonly pwaService: PwaService,
		private readonly metaService: MetaService,
		private readonly i18nLoadingService: I18nLoadingService,
		private readonly featureService: FeatureService,
		private readonly ngxCookieService: NgxCookieService,
		private readonly languageService: LanguageService,
		private readonly mediaService: NgxMediaQueryService,
		// Wouter: Only allow the use of these services in the constructor
		injector: Injector,
		httpClientService: VlaioHttpClientService
	) {
		// Iben: Set the current language in the i18nService
		this.languageService.currentLanguage$
			.pipe(
				switchMap((language) => {
					return this.i18nService.initI18n(language);
				})
			)
			.subscribe();

		// Iben: Set the multilingual mode
		// TODO: Iben: Remove this once the site is multilingual
		this.featureService
			.hasFeature('Multilingual')
			.pipe(
				tap((hasFeature) => {
					httpClientService.setIncludeLanguage(hasFeature);
				})
			)
			.subscribe();

		// Iben: Capture the pwa install prompt event
		this.pwaService.capturePwaInstallPromptEvent();

		// Iben: Attach external scripts to the dom so that they only get added when we're in the browser
		this.scriptsService.attachScriptsToDom(this.renderer);

		// Iben: Attach and initialize GTAG when we're in the browser
		this.ngxCookieService
			.hasAcceptedService('analytics', 'ga')
			.pipe(
				distinctUntilChanged(),
				tap((hasAccepted) => {
					//Iben: If Google Analytics was accepted, we add the script and initialize GTag
					if (hasAccepted) {
						this.scriptsService.attachGTAGScriptsToDom(this.renderer, () => {
							this.gtag.init();
						});
					} else {
						// Iben: If not, we remove the script and the corresponding cookies
						this.gtag.removeGA();
					}
				})
			)
			.subscribe();

		// This is the most important call. It fetches the user info from
		// the backend. This will trigger the user subscription in all
		// components. Only do interactions with the backend when a user's
		// status is know.
		this.userService
			.createUserSession()
			.pipe(
				// Iben: Handle the spotlight products if necessary (also include in the error flow in case of a 403)
				switchMap(() => this.spotlightService.handleSpotlightProduct()),
				catchError((error: HttpErrorResponse) => {
					if (error.status === 403) {
						return this.spotlightService.handleSpotlightProduct();
					}
				})
			)
			.subscribe();

		this.browserService.runInBrowser(({ browserWindow }) => {
			// Listen to message events. When the popup sends this message we need
			// to redirect to the given destination. this allows an admin user to
			// be redirected from withing the header popup window.
			fromEvent(browserWindow, 'message')
				.pipe(
					tap((event: MessageEvent) => {
						if (!!event.data.type && event.data.type == 'vlaio.postauth.redirect') {
							browserWindow.location.replace(event.data.destination);
						}
					}),
					takeUntil(this.onDestroy$)
				)
				.subscribe();
		});

		this.mediaService.registerMediaQueries(
			['toMobile', MediaQueryMax.Mobile],
			['atTablet', MediaQueryMin.Tablet],
			['toDesktop', MediaQueryMax.Desktop]
		);

		// Iben: Register the WebComponents for the NgxReplaceElementsPipe
		this.browserService.runInBrowser(() => {
			const linkWebComponent = createCustomElement(VlaioInternalTranslatedLinkComponent, { injector: injector });

			customElements.define('vlaio-internal-translated-link', linkWebComponent);
		});
	}

	/**
	 * Opens the cookies popup
	 */
	public openCookiesPopup(): void {
		this.ngxCookieService.showModal();
	}

	public ngOnInit(): void {
		// Iben: Make sure we handle the failed state of translations
		this.handleTranslationFailed();

		// Iben: Listen to the translations being loaded in and once they are all loaded in notify the loading state
		this.i18nLoadingService.translationsLoaded$
			.pipe(
				// Iben: Keep listening until all translations are loaded for the first time. This will make sure that the initial application loading state only shows once
				// and not when every lazy loaded module is loaded in, as that will be prevented by the guards
				takeWhile((value) => !value),
				// Iben: Let the subject know that all initial translations are loaded
				finalize(() => this.initialTranslationLoadSubject$.next(true))
			)
			.subscribe();

		// Iben: Redirect to the maintenance page if the status call fails
		combineLatest([this.isAvailable$, this.pwaService.isOnline$])
			.pipe(
				filter(([isAvailable]) => !isAvailable),
				tap(([, isOnline]) => {
					this.router.navigate([
						this.languageService.currentLanguage,
						AppRoutePaths.Redirects,
						isOnline ? RedirectsPaths.Maintenance : RedirectsPaths.Offline
					]);
				})
			)
			.subscribe();

		// Iben: Listen to the browser version to handle outdated browsers
		this.browserService.runInBrowser(() => {
			this.listenToBrowserVersion();
		});

		this.userService.userMetaData$
			.pipe(
				switchMap((meta) => {
					// Iben: Clear the sessionRemaining
					this.sessionRemaining = null;

					// Iben: Early exit if there's no meta data or if the session doesn't expire
					if (!meta || !meta.expiresAt) {
						return;
					}

					// Iben: Map to a timer to track every second
					return timer(0, 1000).pipe(
						switchMap(() => {
							// Iben: Calculate how many seconds we have left.
							const expiration = getUnixTime(Number(meta.expiresAt));
							this.sessionRemaining = differenceInSeconds(new Date(), expiration, {
								roundingMethod: 'round'
							});

							// Iben: If there's more than the time limit remaining, we continue
							if (this.sessionRemaining > 60) {
								return;
							}

							// Iben: If the session is over, logout the user
							if (this.sessionRemaining === 0) {
								// Iben: Navigate to the root
								this.router.navigate(['/']);

								// Iben: Remove the cookie
								this.ngxCookieService.removeCookie(savedKBOCookie);

								// Iben: Log out user
								return this.userService.logOut();
							}
						})
					);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		// Iben: Set the sas context based on the active kbo
		this.userService.user$
			.pipe(
				filter<UserEntity>((user) => Boolean(user && user.company)),
				switchMap((user) => this.userService.setSasContext(user.company.number)),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		this.browserService.runInBrowser(() => {
			// Iben: Subscribe to the router events
			this.router.events
				.pipe(
					tap(() => {
						this.metaService.addCanonicalLink();
					}),
					tap((event) => {
						if (event instanceof NavigationStart) {
							if (~event.url.indexOf('remoteAuthSuccess=true')) {
								this.authService.setAuthenticationCookie();
							}

							// Iben: Check if the login threshold is exceeded
							if (event.url.includes('loginThresholdExceeded=true')) {
								this.authService.setAuthenticationFailed(AuthenticationFailedTypes.ThresholdExceeded);
							}
						}

						if (event instanceof NavigationEnd) {
							this.browserService.scrollToPosition(0);
						}
					}),
					takeUntil(this.onDestroy$)
				)
				.subscribe();
		});

		// Iben: Check if the status is in maintenance mode
		this.statusService
			.getAppStatus((error: string) => {
				this.gtag.trackListedEvent('Status', 'StatusError', error);
			})
			.subscribe();

		// Iben: Track the update status of the application, if there are updates available, show a modal
		this.statusService.updateAvailable$
			.pipe(
				filter(Boolean),
				switchMap(() => this.handleServiceWorkerChanges()),
				takeUntil(this.onDestroy$)
			)
			.subscribe();

		// Iben: Do a location reload whenever there's an error event in the registered service worker
		this.statusService.serviceWorkerErrorEvent$
			.pipe(
				filter(Boolean),
				tap(() => {
					// Iben: Remove the latest cache to ensure that next time we get a version from the server
					this.browserService.runInBrowser(({ browserWindow }) => {
						browserWindow.caches.open('ngsw:/:db:control').then((cache) => cache.delete('/latest'));
					});
				})
			)
			.subscribe();

		// Kaat: Check query params for authentication success && retrieve value of Observable
		this.route.queryParams
			.pipe(
				distinctUntilChanged(),
				tap((queryParams) => {
					const failType = getAuthFailType(queryParams);

					if (failType) {
						this.authService.setAuthenticationFailed(failType);
						this.router.navigate([
							'/',
							this.languageService.currentLanguage,
							AppRoutePaths.Redirects,
							RedirectsPaths.AuthenticationFailed
						]);
					}
				})
			)
			.subscribe();
	}

	/**
	 * Show a modal when the service worker gets a new version of the application
	 */
	private handleServiceWorkerChanges(): Observable<void> {
		const translations = this.i18nKey.Maintenance.Update;

		// Iben: Fetch the translation strings
		return combineLatest([
			this.i18nService.getTranslationObservable(translations.Title),
			this.i18nService.getTranslationObservable(translations.Text),
			this.i18nService.getTranslationObservable(translations.ReadUpdates),
			this.i18nService.getTranslationObservable(translations.Continue)
		]).pipe(
			// Iben: Only take the first value
			take(1),
			// Iben: Switchmap the translations to the close event of the modal
			switchMap(([title, text, confirmLabel, cancelLabel]) => {
				// Iben: Set the details of the modal
				const modal = this.modalDialogService.openModal<VlaioActionModalComponent>(VlaioActionModalComponent);
				modal.component.title = title;
				modal.component.text = text;
				modal.component.confirmLabel = confirmLabel;
				modal.component.cancelLabel = cancelLabel;

				// Iben: Switchmap to the close event
				return modal.component.buttonClicked.pipe(
					switchMap((type) => {
						// Iben: If we confirm, we navigate to the What's New page, if not we remain where we are
						return type === 'confirm'
							? from(
									this.router.navigate(
										['/', this.languageService.currentLanguage, AppRoutePaths.About],
										{
											queryParams: { id: environment.whatsNewPageId }
										}
									)
								)
							: of(null);
					}),
					// Iben: Close the modal and reload the page
					tap(() => {
						this.modalDialogService.closeModal();
					}),
					// Iben: Switchmap to update service worker
					switchMap(() => this.statusService.updateApplication()),
					// Iben: In case the update failed, we do a hard reset
					tap(() => {
						location.reload();
					}),
					// Iben: Discard the return value of the observables
					map(() => undefined)
				);
			})
		);
	}

	/**
	 * Listen to the translation failed state and navigate to the no-resources page if necessary
	 */
	private handleTranslationFailed(): void {
		this.i18nLoadingService.translationsFailed$
			.pipe(
				filter(Boolean),
				tap(() => {
					this.router.navigate([`/${this.languageService.currentLanguage}/${RedirectsPaths.NoResources}`]);
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	/**
	 * Listen to the browser version so we can handle outdated browsers
	 */
	private listenToBrowserVersion() {
		// Iben: Once the translations are loaded, we setup the browser version listener
		this.initialTranslationLoadSubject$
			.asObservable()
			.pipe(
				filter(Boolean),
				take(1),
				tap(() => {
					// Iben: Listen to the browser version and show a popup if the browser is out of date
					this.browserService.handleBrowserVersion(
						{
							title: this.i18nService.getTranslation(I18nKeys.Browser.Title),
							update: this.i18nService.getTranslation(I18nKeys.Browser.Actions.Update),
							ignore: this.i18nService.getTranslation(I18nKeys.Browser.Actions.Ignore)
						},
						`${environment.domain}/${this.languageService.currentLanguage}/${AppRoutePaths.About}?id=Over e-loketondernemers.be&anchor=anchor_welke_browser_heb_je_nodig_voor_e-loketondernemers.be`
					);
				})
			)
			.subscribe();
	}

	ngAfterViewInit(): void {
		this.ngxCookieService.setupCookiesHandler(
			{
				necessary: {
					enabled: true,
					readOnly: true,
					services: {
						vlaio: {
							label: 'VLAIO Header',
							cookies: [
								{
									name: 'vlaio.authenticated'
								},

								{
									name: 'vlaio.savedKBO'
								}
							]
						}
					}
				},
				analytics: {
					enabled: false,
					services: {
						ga: {
							label: 'Google Analytics',
							cookies: [
								{
									name: /^(_ga|_gid)/
								}
							]
						}
					}
				},
				marketing: {
					enabled: false,
					services: {
						youtube: {
							label: 'YouTube'
						}
					}
				}
			},
			{
				default: this.languageService.currentLanguage || 'nl',
				translations: {
					nl: '../assets/cookies/i18n/nl.json'
				}
			}
		);
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
