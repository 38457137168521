<div class="header">
	<div class="title-wrapper">
		<h3 cypressTag="Shared.Ui.InfoWrapper.Title">
			<ng-content select="[title]" />
		</h3>

		@if (count) {
		<span class="count font-bold" cypressTag="Shared.Ui.InfoWrapper.Count">{{ count }}</span>
		}
	</div>

	<ng-content select="[link]" />
</div>

<ng-content select="[content]" />
