import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { map } from 'rxjs';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import { VlaioResult } from '@vlaio/shared/types';

import { VlaioZipCodeEntity, VlaioZipCodeResultEntity } from '../../interfaces';

@Injectable()
export class ZipCodeApiService {
	constructor(private readonly httpClient: VlaioHttpClientService) {}

	/**
	 * Get the zip codes for a given search string
	 *
	 * @param zoek - The provided search string
	 */
	public getZipCodes(zoek: string): ObservableArray<VlaioZipCodeEntity> {
		return this.httpClient
			.get<
				VlaioResult<VlaioZipCodeResultEntity, 'PostcodeVerzameling'>
			>(ELoketEndpoints.Company.ZipCodes(), { zoek }, true)
			.pipe(
				map(({ elementen }) => {
					return elementen.map((item) => {
						return {
							municipality: item.gemeente,
							code: item.postcode,
							id: `${item.gemeente}-${item.postcode}`
						};
					});
				})
			);
	}
}
