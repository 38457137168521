@if (amount === 0) { @if (!showNoResultsLabel) {

{{ i18nKey.MultipleResults | translate : { amount: amount } }}

} @else {

{{ i18nKey.NoResults | translate }}

} } @if (amount === 1) {

{{ i18nKey.SingleResult | translate : { amount: amount } }}

} @if (amount > 1) {

{{ i18nKey.MultipleResults | translate : { amount: amount } }}

}
