<nav>
	<ul>
		@for (item of navItems(); track item; let index = $index; let last = $last) {

		<ng-container *mediaQuery="mediaQueryMax.Tablet">
			@if(index < 3) {

			<li class="mobile-list">
				<ng-template
					[ngTemplateOutlet]="itemTmpl"
					[ngTemplateOutletContext]="{ $implicit: item, isMobile: true }"
				/>
			</li>

			} @else if (index === 3 && last) {

			<li class="mobile-list">
				<ng-template
					[ngTemplateOutlet]="itemTmpl"
					[ngTemplateOutletContext]="{ $implicit: item, isMobile: true }"
				/>
			</li>

			} @else if (index === 3) {

			<li class="mobile-list" vlaioClickAnywhere (handleClick)="handleMobileClickAnywhere($event)">
				<button class="flyout-toggle" (focusClick)="toggleFlyout()">
					<fa-icon icon="fa-bars-staggered" />
					<span *mediaQuery="mediaQueryMin.MobileSmall" class="label">{{ 'Meer' }}</span>
				</button>
			</li>

			}
		</ng-container>

		<li *mediaQuery="mediaQueryMin.Tablet" class="desktop-list">
			<ng-template
				[ngTemplateOutlet]="itemTmpl"
				[ngTemplateOutletContext]="{ $implicit: item, isMobile: false }"
			/>
		</li>

		}
		<ng-template
			*mediaQuery="mediaQueryMin.Tablet"
			[ngTemplateOutlet]="logoutTmpl"
			[ngTemplateOutletContext]="{ isMobile: false }"
		/>

		<li *mediaQuery="mediaQueryMax.Tablet" class="flyout-container" [ngClass]="{ 'flyout-open': flyoutIsOpen() }">
			<ul class="flyout">
				@for(item of navItems(); track item; let index = $index; let last = $last) { @if(index > 3) {

				<li class="mobile-list">
					<ng-template
						[ngTemplateOutlet]="itemTmpl"
						[ngTemplateOutletContext]="{ $implicit: item, isMobile: true }"
					/>
				</li>

				} }

				<ng-template [ngTemplateOutlet]="logoutTmpl" [ngTemplateOutletContext]="{ isMobile: true }" />
			</ul>
		</li>

		<button *mediaQuery="mediaQueryMin.Tablet" class="nav-toggle" (focusClick)="toggleSideBar()">
			<fa-icon [icon]="navIsOpen ? 'fa-chevron-left' : 'fa-chevron-right'" />
		</button>
	</ul>
</nav>

<ng-template #itemTmpl let-item let-isMobile="isMobile">
	<a
		class="nav-item"
		routerLinkActive="active"
		routerLinkActive="active-link font-bold"
		matTooltipPosition="right"
		matTooltipClass="dashboard-nav-tooltip"
		[routerLinkActiveOptions]="item.routerLinkActiveOptions"
		[routerLink]="item.routerLink"
		[matTooltip]="navIsOpen || isMobile ? null : (item.label | translate)"
		[cypressTag]="item?.cypressTag || null"
		[ngClass]="{ 'nav-open': navIsOpen }"
	>
		<fa-icon [icon]="item.icon" />
		<span *mediaQuery="mediaQueryMin.MobileSmall" class="label">
			{{ item.label | translate }}
		</span>
		@if(item.amount !== undefined) {

		<span class="count font-bold">{{ item.amount || 0 }}</span>

		}
	</a>
</ng-template>

<ng-template #logoutTmpl let-isMobile="isMobile">
	<li>
		<button
			class="nav-item color-red"
			matTooltipPosition="right"
			matTooltipClass="dashboard-nav-tooltip"
			[matTooltip]="navIsOpen || isMobile ? null : (i18nKeys.Menu.Logout | translate)"
			[ngClass]="{ 'nav-open': navIsOpen }"
			(focusClick)="logout()"
		>
			<fa-icon icon="fa-arrow-right-from-bracket" />
			<span *mediaQuery="mediaQueryMin.MobileSmall" class="label">
				{{ i18nKeys.Menu.Logout | translate }}
			</span>
		</button>
	</li>
</ng-template>
