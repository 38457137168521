<div class="c-eloket-nav__wrapper" [class.hover]="!navOpen && navHover" [class.open]="navOpen">
	<nav
		class="c-eloket-nav"
		vlaioClickAnywhere
		cypressTag="Dashboard.Navigation.Block"
		[ngClass]="navOpen || navHover ? 'c-eloket-nav__open' : 'c-eloket-nav__closed'"
		(handleClick)="onClickAnywhere($event)"
	>
		<ul (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
			<li
				class="c-eloket-nav__item"
				[routerLinkActive]="['is-active']"
				[routerLinkActiveOptions]="{ exact: true, queryParams: 'ignored' }"
			>
				<a
					cypressTag="Dashboard.Navigation.Link.Dashboard"
					[routerLink]="[paths.Dashboard]"
					[title]="i18nKeys.Dashboard | translate"
				>
					<span class="icon-dashboard"></span>
					<span [class]="navOpen || navHover ? 'label' : 'no-label'">{{
						i18nKeys.Dashboard | translate
					}}</span>
				</a>
			</li>

			<li
				*hasFeature="'Cases'"
				class="c-eloket-nav__item"
				[routerLinkActive]="['is-active']"
				[class.is-disabled]="!isCompany"
				[routerLinkActiveOptions]="{ exact: true, queryParams: 'ignored' }"
				[class.disabled-wrapper]="isCompany ? false : true"
			>
				<a
					cypressTag="Dashboard.Navigation.Link.Cases"
					[routerLink]="isCompany ? [paths.Cases] : []"
					[class.disabled]="isCompany ? false : true"
					[title]="i18nKeys.Cases | translate"
				>
					<span class="icon-books"></span>
					<span [class]="navOpen || navHover ? 'label' : 'no-label'">{{ i18nKeys.Cases | translate }}</span>
					@if (isCompany) {
						<vlaio-indicator
							[class]="navOpen || navHover ? 'u-flex' : 'u-is-hidden-on-tablet-and-up'"
							[amount]="amountOfCurrentCases || 0"
						>
						</vlaio-indicator>
					}
				</a>
			</li>

			<li
				*hasFeature="'Products'"
				class="c-eloket-nav__item"
				[routerLinkActive]="['is-active']"
				[routerLinkActiveOptions]="{ exact: false, queryParams: 'ignored' }"
			>
				<a
					cypressTag="Dashboard.Navigation.Link.Products"
					[routerLink]="[paths.Products]"
					[title]="i18nKeys.Products | translate"
				>
					<span class="icon-note c-eloket-nav__icon"></span>
					<span [class]="navOpen || navHover ? 'label' : 'no-label'">{{
						i18nKeys.Products | translate
					}}</span>
				</a>
			</li>

			<li class="mobile-only c-eloket-nav__item">
				<!-- TODO: Swap (click) with (focusClick) directive:
				Argument of type 'void | Event' is not assignable to parameter of type 'Event'. -->
				<button class="c-eloket-nav__more-btn" [title]="moreLabel | translate" (click)="handleToggle($event)">
					<span class="icon-more"></span>
					<span [class]="navOpen || navHover ? 'label' : 'no-label'">{{ moreLabel | translate }}</span>
				</button>
			</li>

			<!-- EBOX -->
			<!-- <li
					*ngIf="eBoxVisible"
					class="c-eloket-nav__item"
					[routerLinkActive]="['is-active']"
					[class.is-disabled]="!isCompany"
				>
					<a [routerLink]="isCompany ? [paths.EBox] : []" [title]="i18nKeys.EBox | translate">
						<span class="icon-ebox"></span>
						<span class="label">{{ i18nKeys.EBox | translate }}</span>
					</a>
				</li> -->

			<li
				*hasFeature="'MyOrganisation'"
				class="c-eloket-nav__item desktop-only"
				[routerLinkActive]="['is-active']"
				[class.is-disabled]="!isCompany"
				[routerLinkActiveOptions]="{ exact: true, queryParams: 'ignored' }"
				[class.disabled-wrapper]="isCompany ? false : true"
			>
				<a
					cypressTag="Dashboard.Navigation.Link.Company"
					[routerLink]="isCompany ? [paths.Company, paths.User] : []"
					[class.disabled]="isCompany ? false : true"
					[title]="i18nKeys.Company | translate"
				>
					<span class="icon-company"></span>
					@if (navOpen || navHover) {
						<span class="label">{{ i18nKeys.Company | translate }}</span>
					}
				</a>
			</li>

			<ng-container *hasFeature="'Sanctions'">
				<li
					*hasCompanyRole="['isLegalRepresentative', 'hasSanctionsAccess']; all: false"
					class="c-eloket-nav__item desktop-only"
					[routerLinkActive]="['is-active']"
					[class.is-disabled]="!isCompany"
					[routerLinkActiveOptions]="{ exact: true, queryParams: 'ignored' }"
					[class.disabled-wrapper]="isCompany ? false : true"
				>
					<a
						cypressTag="Dashboard.Navigation.Link.Sanctions"
						[routerLink]="isCompany ? [paths.Sanctions] : []"
						[class.disabled]="isCompany ? false : true"
						[title]="i18nKeys.Sanctions | translate"
					>
						<span class="icon-sanctions"></span>
						@if (navOpen || navHover) {
							<span class="label">{{ i18nKeys.Sanctions | translate }}</span>
						}
					</a>
				</li>
			</ng-container>

			<ng-container *hasFeature="'Permits'">
				<li
					class="c-eloket-nav__item desktop-only"
					[routerLinkActive]="['is-active']"
					[routerLinkActiveOptions]="{ exact: false, queryParams: 'ignored' }"
				>
					<a
						cypressTag="Dashboard.Navigation.Link.Permits"
						[routerLink]="[paths.Permits]"
						[title]="i18nKeys.Permits | translate"
					>
						<vlaio-icon icon="fas fa-vote-yea" />
						@if (navOpen || navHover) {
							<span class="label">{{ i18nKeys.Permits | translate }}</span>
						}
					</a>
				</li>
			</ng-container>

			<li
				*hasFeature="'SearchOrganisations'"
				class="c-eloket-nav__item desktop-only"
				[routerLinkActive]="['is-active']"
				[routerLinkActiveOptions]="{ exact: false, queryParams: 'ignored' }"
			>
				<a
					cypressTag="Dashboard.Navigation.Link.Search"
					[routerLink]="[paths.Company, paths.Search]"
					[title]="i18nKeys.Search | translate"
				>
					<span class="icon-search"></span>
					@if (navOpen || navHover) {
						<span class="label">{{ i18nKeys.Search | translate }}</span>
					}
				</a>
			</li>

			<ng-container *hasRole="'ECONOMIC_ACTOR'">
				<li
					*hasFeature="'Notifications'"
					class="c-eloket-nav__item desktop-only"
					[routerLinkActive]="['is-active']"
					[routerLinkActiveOptions]="{ exact: false, queryParams: 'ignored' }"
					[class.is-disabled]="!isCompany"
				>
					<a
						cypressTag="Dashboard.Navigation.Link.Inbox"
						[routerLink]="isCompany ? [paths.Inbox] : []"
						[title]="i18nKeys.Inbox | translate"
					>
						<vlaio-icon icon="fa-bell" iconType="fa-regular" />
						@if (navOpen || navHover) {
							<span class="label">{{ i18nKeys.Inbox | translate }}</span>
						}
					</a>
				</li>
			</ng-container>

			<ng-container *hasFeature="'Mandates'">
				<li
					*hasCompanyRole="['isMandateAdministrator', 'isLegalRepresentative']; all: false"
					class="c-eloket-nav__item desktop-only"
					[routerLinkActive]="['is-active']"
					[routerLinkActiveOptions]="{ exact: false, queryParams: 'ignored' }"
					[class.is-disabled]="!isCompany"
				>
					<a
						gtagClick
						gtagCategory="MandatesOverview"
						gtagType="Mandates"
						cypressTag="Dashboard.Navigation.Link.Mandates"
						[routerLink]="isCompany ? [paths.Mandates] : []"
						[title]="i18nKeys.Mandates | translate"
					>
						<span class="icon-layers"></span>
						@if (navOpen || navHover) {
							<span class="label">{{ i18nKeys.Mandates | translate }}</span>
						}
					</a>
				</li>
			</ng-container>
		</ul>

		<ul class="c-eloket-nav__show-more-menu has-sub-menu mobile-only" [@moreMenuState]="moreMenuState">
			<!-- EBOX -->
			<!-- <li
					*ngIf="eBoxVisible"
					class="c-eloket-nav__item"
					[routerLinkActive]="['is-active']"
					[class.is-disabled]="!isCompany"
				>
					<a [routerLink]="isCompany ? [paths.EBox] : []" [title]="i18nKeys.EBox | translate">
						<span class="icon-ebox"></span>
						<span class="label">{{ i18nKeys.EBox | translate }}</span>
					</a>
				</li> -->

			<li
				*hasFeature="'MyOrganisation'"
				class="c-eloket-nav__item"
				[routerLinkActive]="['is-active']"
				[class.is-disabled]="!isCompany"
				[class.disabled-wrapper]="!isCompany"
				[routerLinkActiveOptions]="{ exact: true, queryParams: 'ignored' }"
			>
				<a
					[routerLink]="isCompany ? [paths.Company, paths.User] : []"
					[class.disabled]="isCompany ? false : true"
					[title]="i18nKeys.Company | translate"
				>
					<span class="icon-company"></span>
					<span class="label">{{ i18nKeys.Company | translate }}</span>
				</a>
			</li>

			<ng-container *hasFeature="'Sanctions'">
				<li
					*hasCompanyRole="['isLegalRepresentative', 'hasSanctionsAccess']; all: false"
					class="c-eloket-nav__item"
					[routerLinkActive]="['is-active']"
					[class.is-disabled]="!isCompany"
					[class.disabled-wrapper]="!isCompany"
					[routerLinkActiveOptions]="{ exact: true, queryParams: 'ignored' }"
				>
					<a
						[routerLink]="isCompany ? [paths.Sanctions] : []"
						[class.disabled]="isCompany ? false : true"
						[title]="i18nKeys.Sanctions | translate"
					>
						<span class="icon-sanctions"></span>
						<span class="label">{{ i18nKeys.Sanctions | translate }}</span>
					</a>
				</li>
			</ng-container>

			<ng-container *hasFeature="'Permits'">
				<li
					[routerLinkActive]="['is-active']"
					[routerLinkActiveOptions]="{ exact: false, queryParams: 'ignored' }"
				>
					<a [routerLink]="[paths.Permits]" [title]="i18nKeys.Permits | translate">
						<vlaio-icon icon="fas fa-vote-yea" />
						<span class="label">{{ i18nKeys.Permits | translate }}</span>
					</a>
				</li>
			</ng-container>

			<li
				*hasFeature="'SearchOrganisations'"
				[routerLinkActive]="['is-active']"
				[routerLinkActiveOptions]="{ exact: false, queryParams: 'ignored' }"
			>
				<a [routerLink]="[paths.Company, paths.Search]" [title]="i18nKeys.Search | translate">
					<span class="icon-search"></span>
					<span class="label">{{ i18nKeys.Search | translate }}</span>
				</a>
			</li>

			<ng-container *hasRole="'ECONOMIC_ACTOR'">
				<ng-container
					*hasCompanyRole="
						['isLegalRepresentative', 'isMandateAdministrator', 'isMandateExecutor'];
						all: false
					"
				>
					<li
						*hasFeature="'Notifications'"
						[routerLinkActive]="['is-active']"
						[routerLinkActiveOptions]="{ exact: false, queryParams: 'ignored' }"
						[class.is-disabled]="!isCompany"
					>
						<a
							cypressTag="Dashboard.Navigation.Link.Inbox"
							[routerLink]="isCompany ? [paths.Inbox] : []"
							[title]="i18nKeys.Inbox | translate"
						>
							<vlaio-icon icon="fa-bell" iconType="fa-regular" />
							<span class="label">{{ i18nKeys.Inbox | translate }}</span>
						</a>
					</li>
				</ng-container>
			</ng-container>

			<li
				*hasFeature="'Mandates'"
				class="c-eloket-nav__item"
				[routerLinkActive]="['is-active']"
				[class.is-disabled]="!isCompany"
				[class.disabled-wrapper]="!isCompany"
				[routerLinkActiveOptions]="{ exact: true, queryParams: 'ignored' }"
			>
				<a
					[routerLink]="isCompany ? [paths.Mandates] : []"
					[class.disabled]="isCompany ? false : true"
					[title]="i18nKeys.Mandates | translate"
				>
					<span class="icon-layers"></span>
					<span class="label">{{ i18nKeys.Mandates | translate }}</span>
				</a>
			</li>
		</ul>
	</nav>

	<div
		class="c-eloket-nav__button"
		role="button"
		tabindex="0"
		[class.open]="navOpen"
		[class.hover]="!navOpen && navHover"
		(focusClick)="toggleNavigationBar.emit()"
	>
		<span [class]="navOpen ? 'icon-arrow-left' : 'icon-arrow-right'"> </span>
	</div>
</div>
