import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { PwaService, PlatformKeys } from '../../../data';

/**
 * Based upon `*ngIf`. See https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts
 */
@Directive({
	selector: '[isPlatform]',
	standalone: true
})
export class PlatformDirective {
	private thenTemplateRef: TemplateRef<any> | null = null;
	private thenViewRef: EmbeddedViewRef<any> | null = null;
	private elseTemplateRef: TemplateRef<any> | null = null;
	private elseViewRef: EmbeddedViewRef<any> | null = null;
	private isProvidedPlatform: boolean = false;

	constructor(
		private readonly pwaService: PwaService,
		templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
		this.thenTemplateRef = templateRef;
	}

	@Input()
	set isPlatform(platform: PlatformKeys) {
		this.isProvidedPlatform = this.pwaService.isPlatform(platform);
		this.updateView();
	}
	@Input()
	set isPlatformElse(ngTemplate: TemplateRef<any>) {
		this.elseTemplateRef = ngTemplate;
		this.elseViewRef = null;
		this.updateView();
	}

	private updateView() {
		if (this.isProvidedPlatform) {
			if (!this.thenViewRef) {
				this.viewContainer.clear();
				this.elseViewRef = null;
				if (this.thenTemplateRef) {
					this.thenViewRef = this.viewContainer.createEmbeddedView(this.thenTemplateRef);
				}
			}
		} else {
			if (!this.elseViewRef) {
				this.viewContainer.clear();
				this.thenViewRef = null;
				if (this.elseTemplateRef) {
					this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
				}
			}
		}
	}
}
