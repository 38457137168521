import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { VlaioFaIconComponent } from '../fa-icon/fa-icon.component';
import { IconType } from '../fa-icon/fa-icon.const';

import { TagType } from './tag.const';

@Component({
	selector: 'vlaio-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [VlaioFaIconComponent]
})
export class TagComponent {
	@HostBinding('class.c-vlaio-tag') private readonly hasTagClass: boolean = true;
	@HostBinding('class.c-vlaio-tag--light') private hasLightClass: boolean = false;
	@HostBinding('class.c-vlaio-tag--yellow') private hasYellowClass: boolean = false;
	@HostBinding('class.c-vlaio-tag--gray') private hasGrayClass: boolean = false;

	@HostBinding('class.c-vlaio-tag--blue') private hasBlueClass: boolean = false;

	/**
	 * The tagType attribute allows you to pass the specific type of tag you want to see.
	 *
	 * Options are `light` or `yellow`.
	 *
	 * Default: none
	 *
	 * @param type TagType
	 */
	@Input()
	public set tagType(type: `${TagType}`) {
		switch (type) {
			case TagType.light:
				this.hasLightClass = true;
				this.hasYellowClass = false;
				this.hasBlueClass = false;
				this.hasGrayClass = false;
				break;

			case TagType.yellow:
				this.hasLightClass = false;
				this.hasYellowClass = true;
				this.hasBlueClass = false;
				this.hasGrayClass = false;
				break;

			case TagType.blue:
				this.hasLightClass = false;
				this.hasYellowClass = false;
				this.hasBlueClass = true;
				this.hasGrayClass = false;
				break;
			case TagType.gray:
				this.hasLightClass = false;
				this.hasYellowClass = false;
				this.hasBlueClass = false;
				this.hasGrayClass = true;
				break;

			default:
				this.hasLightClass = false;
				this.hasYellowClass = false;
				this.hasBlueClass = true;
				break;
		}
	}

	/**
	 * The icon attribute allows you to pass a `fa-x` class to the fa-icon component.
	 *
	 * Default: undefined
	 */
	@Input() public icon: string;

	/**
	 * The iconType attribute allows you to select the icon type.
	 *
	 * Options are `fa-regular` or `fa-solid`, or you can use the IconType enum that comes with the fa-icon component.
	 *
	 * Default: `fa-solid`
	 */
	@Input() public iconType: `${IconType}` = IconType.solid;
}
