import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { dispatchDataToStore, StoreService } from '@studiohyperdrive/ngx-store';
import { ObservableBoolean, ObservableArray } from '@studiohyperdrive/rxjs-utils';

import { actions, selectors } from '../../forms-data.store';
import { ZipCodeEntity } from '../../interfaces';

import { ZipCodeApiService } from './zip-code.api.service';

@Injectable()
export class ZipCodeService extends StoreService {
	/**
	 * A list of searched zip codes
	 */
	public readonly zipCodes$: ObservableArray<ZipCodeEntity> = this.selectFromStore<ZipCodeEntity[]>(
		selectors.zipCodes
	);
	/**
	 * The loading state of the zip codes
	 */
	public readonly zipCodesLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.zipCodes);
	/**
	 * The error state of the zip codes
	 */
	public readonly zipCodesError$: ObservableBoolean = this.selectErrorFromStore(selectors.zipCodes);

	constructor(private readonly apiService: ZipCodeApiService, public readonly store: Store) {
		super(store);
	}

	/**
	 * Fetches and returns a list of zip codes
	 *
	 * @param searchQuery - The search query matching a zip code or municipality
	 */
	public searchZipCodes(searchQuery: string): ObservableArray<ZipCodeEntity> {
		return dispatchDataToStore(actions.zipCodes, this.apiService.getZipCodes(searchQuery), this.store);
	}
}
