import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'vlaio-banner',
	templateUrl: './banner.component.html',
	styleUrl: './banner.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class VlaioBannerComponent {
	@HostBinding('class.c-banner') public rootClass = true;
	// Iben: We use the style url here because the image location is changed during building
	@HostBinding('style.background-image') public readonly backgroundImage: string = 'url(hero-bg.jpg)';

	@Input() public title: string;
}
