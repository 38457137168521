import { Component, Input } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';

@Component({
	selector: 'vlaio-loader',
	templateUrl: './loader.component.html',
	standalone: true,
	imports: [CypressTagDirective]
})
export class LoaderComponent {
	@Input() label: string;
}
