<span class="c-filter__field" [class.c-checkbox__partial]="isPartialChecked">
	<input
		class="c-filter__input"
		#input
		type="checkbox"
		cypressTag="General.Input.Checkbox"
		[id]="id"
		[checked]="isChecked"
		[disabled]="disabled"
		[title]="title || i18nKeys.Facet.Checkbox.Title | translate: { label }"
		(keydown.enter)="enterPressed()"
		(click)="inputClicked.emit(input.checked)"
		(change)="onChange($any($event).target.checked)"
		(blur)="onTouched()"
	/>

	@if (!isPartialChecked) {
		<span class="c-filter__icon icon-checkmark"></span>
	}
</span>

<label
	class="c-filter__label"
	[attr.for]="(labelClicked | hasObservers) ? '' : id"
	[title]="label"
	(click)="labelClicked.emit()"
>
	<ng-content></ng-content>
</label>
