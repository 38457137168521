import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormAccessor, createAccessorProviders } from '@studiohyperdrive/ngx-forms';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { VlaioButtonComponent, VlaioCloseButtonComponent, VlaioDisclaimerComponent } from '@vlaio/shared/ui/common';
import { VlaioZipCodeInputComponent } from '@vlaio/shared/ui/forms';

import {
	CompanySearchEntity,
	CompanySearchFormEntity,
	PartialCompanySearchEntity,
	VlaioZipCodeEntity
} from '../../../data';
import { sharedCompanyI18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-search-company',
	templateUrl: './search-company.component.html',
	styleUrls: ['./search-company.component.scss'],
	providers: [createAccessorProviders(SearchCompanyComponent)],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ReactiveFormsModule,
		CypressTagDirective,
		VlaioZipCodeInputComponent,
		VlaioButtonComponent,
		VlaioCloseButtonComponent,
		VlaioDisclaimerComponent,
		TranslateModule,
		FocusClickDirective
	]
})
export class SearchCompanyComponent extends FormAccessor<CompanySearchEntity, FormGroup<CompanySearchFormEntity>> {
	public initForm(): FormGroup<CompanySearchFormEntity> {
		return new FormGroup({
			searchQuery: new FormControl<string>('', Validators.required),
			zipCode: new FormControl<VlaioZipCodeEntity>(null)
		});
	}

	/**
	 * Add margin top to the host.
	 */
	@HostBinding('class') private readonly rootClass = 'u-margin-top';

	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof sharedCompanyI18nKeys = sharedCompanyI18nKeys;
	/**
	 * Whether or not the user has searched yet.
	 */
	public hasSearched = false;
	/**
	 * The zipcodes that match the user query.
	 */
	@Input() public zipCodes: VlaioZipCodeEntity[] = [];
	/**
	 * Whether the zipcodes are being fetched.
	 */
	@Input() public zipCodesLoading: boolean = true;
	/**
	 * Emits when the `search` button is clicked.
	 */
	@Output() public readonly searchClicked: EventEmitter<PartialCompanySearchEntity> =
		new EventEmitter<PartialCompanySearchEntity>();
	/**
	 * Emits when the user searched for a zipcode.
	 */
	@Output() public readonly zipCodeSearched = new EventEmitter<string>();

	/**
	 * Clears the search field
	 */
	public clearField(): void {
		// Iben: reset both forms
		this.form.reset({});
		this.hasSearched = false;

		// Iben: Emit the cleared value
		this.searchClicked.emit(this.form.value);
	}

	/**
	 * Emit the value of the form if it's valid
	 */
	public searched(): void {
		// Iben: Mark the form as touched so we can see the error messages
		this.form.markAllAsTouched();
		this.hasSearched = true;

		// Iben: Only emit the form value if the form is valid
		if (this.form.valid) {
			this.searchClicked.emit(this.form.value);
		}
	}
}
