import { ZipCodeEntity, ZipCodeResultEntity } from '../interfaces';

export const ZipCodeEntityMock: ZipCodeEntity = {
	municipality: 'Ghent',
	code: 9000,
	id: 'Ghent-9000'
};

export const ZipCodeResultEntityMock: ZipCodeResultEntity = {
	postcode: 9000,
	gemeente: 'Ghent'
};
