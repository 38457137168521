import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableArray } from '@studiohyperdrive/rxjs-utils';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { VlaioHttpClientService } from '@vlaio/shared/core';
import { ELoketEndpoints } from '@vlaio/shared/endpoints';
import {
	FacetEntity,
	FacetFilter,
	FacetsResult,
	IndexedPagination,
	ProductEntity,
	ProductEntityResult,
	ProductsFilter,
	SortingEntity,
	SortingResultEntity,
	VlaioResult
} from '@vlaio/shared/types';
import { UserService } from '@vlaio/shared/user';
import {
	convertFacetFilterToFacetFilterResult,
	convertFacetResultToFacet,
	convertNextPage,
	convertPagination,
	convertVlaioResult
} from '@vlaio/shared/utils';

import { OfferedProductCollectionResult } from '../interfaces';
import { convertProductResultToProduct } from '../utils';

@Injectable()
export class ProductApiService {
	constructor(
		private readonly httpClient: VlaioHttpClientService,
		private readonly userService: UserService
	) {}

	/**
	 * Creates the query params based on the provided filters
	 *
	 *  @param filter: The selected filter, index and searchQuery to filter the products/facets
	 */
	private getQueryParams(filter: ProductsFilter): HttpParams {
		return new HttpParams({
			fromObject: {
				...(filter.filters && !isEmpty(filter.filters)
					? { filters: JSON.stringify(convertFacetFilterToFacetFilterResult(filter.filters)) }
					: {}),
				...(filter.searchQuery ? { zoek: filter.searchQuery } : {}),
				...(filter.pagination ? { limiet: filter.pagination.limit, offset: filter.pagination.offset } : {}),
				...(filter.index ? { index: filter.index } : {}),
				...(filter.sorting ? { sorteeroptie: filter.sorting } : {})
			}
		});
	}

	/**
	 * Fetches the facets from the API
	 *
	 * @param filters - The selected filters
	 */
	public getFacets(filters: FacetFilter, searchQuery: string): ObservableArray<FacetEntity> {
		return this.userService.user$.pipe(
			take(1),
			switchMap((user) => {
				const isAuthenticated = Boolean(user);

				const params = new HttpParams({
					fromObject: {
						...(!isEmpty(filters)
							? { filters: JSON.stringify(convertFacetFilterToFacetFilterResult(filters)) }
							: {}),
						...(searchQuery ? { zoek: searchQuery } : {})
					}
				});

				return this.httpClient.get<FacetsResult>(ELoketEndpoints.Products.Facets(), params, isAuthenticated);
			}),
			map(({ elementen }) => elementen.map((facet) => convertFacetResultToFacet(facet)))
		);
	}

	/**
	 * Get offered products from the API
	 *
	 * @param productsFilter: The selected filter, index and searchQuery to filter the products
	 */
	public getOfferedProducts(
		productsFilter: ProductsFilter = {}
	): Observable<{ products: ProductEntity[]; nextPage?: string; pagination?: IndexedPagination }> {
		return this.userService.user$.pipe(
			take(1),
			switchMap((user) => {
				const params = this.getQueryParams(productsFilter);

				return this.httpClient.get<OfferedProductCollectionResult>(
					ELoketEndpoints.Products.GetProducts(),
					params,
					Boolean(user)
				);
			}),
			map(({ inhoud, volgendePagina, paginatie }) => {
				return {
					products: inhoud.elementen.map((value) => convertProductResultToProduct(value.product)),
					nextPage: convertNextPage(volgendePagina),
					...(paginatie ? { pagination: convertPagination(paginatie) } : {})
				};
			})
		);
	}

	/**
	 * Fetch the spotlight products
	 */
	public getSpotlightProducts(): ObservableArray<ProductEntity> {
		return this.httpClient
			.get<
				VlaioResult<{ product: ProductEntityResult; annotaties: string[] }, 'ProductInDeKijker'>
			>(ELoketEndpoints.Products.Spotlight(), {}, true)
			.pipe(
				map(({ elementen }) =>
					elementen.map(({ product, annotaties }) => convertProductResultToProduct(product, annotaties))
				)
			);
	}

	/**
	 * Fetch specific product based on id
	 */
	public getProductDetail(id: string, withCredentials: boolean = false): Observable<ProductEntity> {
		return this.httpClient
			.get<ProductEntityResult>(ELoketEndpoints.Products.Detail(id), {}, withCredentials)
			.pipe(map((product) => convertProductResultToProduct(product)));
	}

	/**
	 * Fetch all the sorting options for products
	 */
	public getSortingOptions(): ObservableArray<SortingEntity> {
		return this.httpClient
			.get<VlaioResult<SortingResultEntity, 'SorteerOpties'>>(ELoketEndpoints.Products.Sorting())
			.pipe(
				convertVlaioResult<'SorteerOpties', SortingResultEntity, SortingEntity>((item) => {
					return {
						name: item.naam,
						id: item.id
					};
				}),
				catchError((err) => {
					// Iben: Log the error as a warning
					console.warn(err);

					// Iben: Return an empty array because not getting sorting options shouldn't break the frontend
					return of([]);
				})
			);
	}
}
