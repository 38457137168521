import { NgTemplateOutlet, AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, TemplateRef } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTooltipDirective } from '@studiohyperdrive/ngx-inform';
import { ObservableNumber } from '@studiohyperdrive/rxjs-utils';
import { map, shareReplay, takeUntil } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';
import { OnDestroyComponent } from '@vlaio/shared/types';

import { I18nKeys } from '../../i18n';
import { ProgressMeterItemEntity } from '../../types';

/**
 * Transform the provided `id` to a concatenated string to allow `index` extraction later on.
 *
 * The `id` is separated from the index by the string `"index"`. This is done to separate possible
 * numbers at the end of the `id` from the `index` number.
 */
function handleProgressMeterId(id: string): string {
	return 'id' + id + 'index';
}

@Component({
	selector: 'vlaio-progress-meter',
	templateUrl: './progress-meter.component.html',
	styleUrls: ['./progress-meter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CypressTagDirective,
		NgTemplateOutlet,
		ReactiveFormsModule,
		NgxTooltipDirective,
		AsyncPipe,
		DatePipe,
		TranslateModule
	]
})
export class VlaioProgressMeterComponent extends OnDestroyComponent {
	@HostBinding('class.c-progress-meter') private readonly rootClass = true;

	@ContentChild('title') public readonly titleTemplate: TemplateRef<unknown>;
	@ContentChild('subTitle') public readonly subTitleTemplate: TemplateRef<unknown>;
	@ContentChild('action') public readonly actionTemplate: TemplateRef<unknown>;

	/**
	 * Translation markers
	 */
	public readonly i18nKeys = I18nKeys;

	/**
	 * The form control to hold a concatenation of the `progressMeterId` and the index of the currently visible item.
	 *
	 * This is a concatenation because each progress meter input on a page needs to be unique.
	 */
	public readonly currentIndexControl = new FormControl<string>('');

	/**
	 * The index of the currently visible item
	 */
	public readonly currentIndex$: ObservableNumber = this.currentIndexControl.valueChanges.pipe(
		// Wouter: Extract the last number (the index) from the current control value
		map((indexControl) => parseInt(indexControl.match(/(\d+)/g).pop())),
		shareReplay()
	);

	/**
	 * The items used in the component
	 */
	public meterItems: ProgressMeterItemEntity[] = [];

	/**
	 * Whether dots have to show pointer cursor
	 */
	@HostBinding('class.c-non-clickable') @Input() public isNotClickable = false;

	/**
	 * An id so that multiple progress meters can be shown on a page
	 *
	 * It will be transformed into the string `id[id]index[index]`, where `[id]` is the provided `progressMeterId`.
	 */
	@Input({ required: true, transform: handleProgressMeterId }) public progressMeterId: string;

	/**
	 * The items that will be rendered on the progress meter
	 */
	@Input({ required: true }) public set items(items: ProgressMeterItemEntity[]) {
		// Iben: Set the current items
		this.meterItems = items || [];

		// Iben: Make the current item visible
		this.currentIndexControl.setValue(this.progressMeterId + items.findIndex((item) => item.isCurrent));
	}

	/**
	 * A format for the date of a progress meter item, default dd/MM/yyyy H:mm
	 */
	@Input() public dateFormat: string = 'dd/MM/yyyy H:mm';

	/**
	 * A cypress tag for the detail we see when clicking a progress button
	 */
	@Input() public detailCypressTag: CypressTagsPaths;

	/**
	 * A cypress tag for the date we see in a progress detail
	 */
	@Input() public dateCypressTag: CypressTagsPaths;

	/**
	 * A cypress tag for the progress button
	 */
	@Input() public progressButtonCypressTag: CypressTagsPaths;

	/**
	 * Prevents the user from executing the FormControl change
	 */
	@Input() public disableClickEvent: boolean = false;

	/**
	 * Disables the popup completely
	 */
	@Input() public disablePopup: boolean = false;

	constructor() {
		super();

		// Iben: We subscribe here in the constructor so that we can handle the late subscriber issue we have by updating the observable
		// before we subscribe in the component. This is a known issue and subscribing here and adding a shareReplay is a provided solution.
		this.currentIndex$.pipe(takeUntil(this.destroyed$)).subscribe();
	}
}
