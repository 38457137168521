import { marker } from '@ngneat/transloco-keys-manager/marker';

import { sharedI18nKeys } from '@vlaio/shared/i18n';
import { SharedPWAi18nKeys } from '@vlaio/shared/pwa';

export const RedirectI18nKeys = {
	...sharedI18nKeys,
	...SharedPWAi18nKeys,
	AuthenticationFailed: {
		Failed: {
			Title: marker('authentication-failed.failed.title'),
			Text: marker('authentication-failed.failed.text'),
			Button: marker('authentication-failed.failed.button')
		},
		LoginThreshholdExceeded: {
			Title: marker('authentication-failed.login-threshhold-exceeded.title'),
			Text: marker('authentication-failed.login-threshhold-exceeded.text'),
			Button: marker('authentication-failed.login-threshhold-exceeded.button')
		},
		Invalid: {
			Title: marker('authentication-failed.invalid.title'),
			Text: marker('authentication-failed.invalid.text'),
			Button: marker('authentication-failed.invalid.button')
		}
	},
	Maintenance: {
		MaintenanceMode: marker('maintenance.maintenance-mode'),
		Title: marker('maintenance.title'),
		Update: {
			Title: marker('maintenance.update.title'),
			Text: marker('maintenance.update.text'),
			Continue: marker('maintenance.update.continue'),
			ReadUpdates: marker('maintenance.update.read-updates')
		}
	},
	DesktopOnly: {
		Title: marker('desktop-only.title'),
		Text: marker('desktop-only.text'),
		Button: {
			Title: marker('desktop-only.button.title'),
			Text: marker('desktop-only.button.text')
		}
	}
};
