import { Component } from '@angular/core';
import { NgxTooltipAbstractComponent } from '@studiohyperdrive/ngx-inform';

@Component({
	selector: 'vlaio-tooltip',
	template: `<p [innerHtml]="text"></p>`,
	styleUrl: './tooltip.component.scss',
	standalone: true
})
export class VlaioTooltipComponent extends NgxTooltipAbstractComponent {}
