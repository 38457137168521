import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params, QueryParamsHandling } from '@angular/router';

import { VlaioInternalLinkComponent } from '../internal-link/internal-link.component';
import { VlaioTranslatedLinkAbstractComponent } from '../shared';

/**
 * This component is used as a WebComponent for when used in translation or other string values. Because of that, all inputs are lowercased
 *
 * IMPORTANT: This component should not be used in the application itself
 */
@Component({
	selector: 'vlaio-internal-translated-link',
	standalone: true,
	template: `<vlaio-internal-link
		[link]="link"
		[linkQueryParamsHandling]="link_query_params_handling"
		[linkStyle]="link_style"
		[title]="title"
		[linkQueryParams]="queryParams"
	>
		<ng-content></ng-content>
	</vlaio-internal-link>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VlaioInternalLinkComponent]
})
export class VlaioInternalTranslatedLinkComponent extends VlaioTranslatedLinkAbstractComponent {
	public queryParams: Params = undefined;

	/**
	 * The fragments (parameters) to be added to the URL query.
	 */
	@Input() public set link_query_params(value: string) {
		if (value) {
			this.queryParams = JSON.parse(value);
		}
	}

	/**
	 * How to handle query parameters in the URL.
	 *
	 * Default value is `merge`.
	 */
	@Input() public link_query_params_handling: QueryParamsHandling = 'merge';
}
