import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	QueryList,
	ViewChildren
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { filter, takeUntil, tap } from 'rxjs';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CallToActionClickedEvent, OnDestroyComponent, ProductEntity, RecommendationRating } from '@vlaio/shared/types';
import {
	VlaioContentComponent,
	VlaioButtonComponent,
	VlaioEventLinkComponent,
	VlaioEmptySearchResultComponent
} from '@vlaio/shared/ui/common';

import { I18nKeys } from '../../../i18n';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
/**
 * Represents a component that displays a list of products.
 */
@Component({
	selector: 'vlaio-product-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		VlaioContentComponent,
		VlaioButtonComponent,
		CypressTagDirective,
		TranslateModule,
		ProductDetailComponent,
		VlaioEventLinkComponent,
		VlaioEmptySearchResultComponent
	]
})
export class ProductListComponent extends OnDestroyComponent implements AfterViewInit {
	/**
	 * Binds the root class for the component.
	 */
	@HostBinding('class') private readonly rootClass = ' c-products__list';

	/**
	 * Query list of ProductDetailComponent instances.
	 */
	@ViewChildren('product', { read: ProductDetailComponent })
	private readonly productResults: QueryList<ProductDetailComponent>;

	/**
	 * The translation keys.
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * List of products to display.
	 */
	@Input() public products: ProductEntity[] = [];
	/**
	 * Whether the list items are being fetched.
	 */
	@Input() public loading: boolean = false;
	/**
	 * Whether there was an error fetching the list items.
	 */
	@Input() public error: boolean = false;
	/**
	 * Whether there is a next page of items to load.
	 */
	@Input() public hasNextPage: boolean = false;
	/**
	 * Whether more items are being loaded.
	 */
	@Input() public moreLoading: boolean = false;
	/**
	 * Recommendations for the products.
	 */
	@Input() public recommendations: Record<string, RecommendationRating> = {};
	/**
	 * Whether the list is empty after applying filters.
	 */
	@Input() public isEmptyAfterFilteredSearch: boolean = false;
	/**
	 * Index of the currently focussed item.
	 */
	@Input() public focussedOn: number;
	/**
	 * Event emitted when the "load more" button is clicked.
	 */
	@Output() public loadMoreClicked = new EventEmitter<void>();
	/**
	 * Event emitted when the "search without filters" button is clicked.
	 */
	@Output() public searchWithoutFiltersClicked = new EventEmitter<void>();

	/**
	 * Event emitted when a recommendation is rated.
	 */
	@Output() public recommendationRated = new EventEmitter<{ productId: string; rating: RecommendationRating }>();
	/**
	 * Emits the action config when the Call To Action of a product is clicked.
	 */
	@Output() public ctaClicked: EventEmitter<CallToActionClickedEvent> = new EventEmitter<CallToActionClickedEvent>();

	constructor(private readonly cdRef: ChangeDetectorRef) {
		super();
	}

	// Lifecycle methods
	// ==============================
	public ngAfterViewInit(): void {
		// Iben: Subscribe on the product results visible in the DOM so we can focus on the correct item after loading more items
		this.productResults.changes
			.pipe(
				// Iben: If there are no results or no element to focus on, we skip
				filter((results) => results?.length > 0 && this.focussedOn !== undefined),
				tap((results) => {
					// Iben: Get the required element
					const element = results.toArray()[this.focussedOn]?.elementRef?.nativeElement;

					// Iben: Early exit if the element is not found
					if (!element) {
						return;
					}

					// Iben: Get first focusable element and focus on that one
					element.querySelectorAll('button, [href], input, [tabindex="0"]')[0].focus();

					// Iben: Call a change detection cycle
					this.cdRef.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
