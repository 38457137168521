import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ImageComponent } from '../image/image.component';

@Component({
	selector: 'vlaio-empty-state',
	templateUrl: './empty-state.component.html',
	styleUrls: ['../../../../../styles/block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ImageComponent]
})
export class EmptyStateComponent {
	@Input() public image: string = 'img/dossiers-empty.svg';
}
