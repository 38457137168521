import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { AddressComponent } from '@vlaio/shared/ui/common';
import { clickOnTranslationLink } from '@vlaio/shared/utils';

import { CompanyEntity } from '../../../data';
import { sharedCompanyI18nKeys } from '../../../i18n';

@Component({
	selector: 'vlaio-company-info',
	templateUrl: './info.component.html',
	styleUrl: './info.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CypressTagDirective, AddressComponent, DatePipe, TranslateModule, SafeHtmlPipe]
})
export class CompanyInfoComponent {
	public readonly i18nKeys = sharedCompanyI18nKeys.Companies.Info;

	/**
	 * Whether the component should be full width.
	 *
	 * Default value is `false`.
	 */
	@HostBinding('class.is-full-width') @Input() fullWidth: boolean = false;

	/**
	 * Whether the user is the owner of the company.
	 *
	 * Default value is `false`.
	 */
	@Input() public isUserCompany: boolean = false;
	/**
	 * The company data.
	 */
	@Input() public company: CompanyEntity | Partial<CompanyEntity>;
	/**
	 * Whether the public data text has to be hidden.
	 *
	 * Default value is `false`.
	 */
	@Input() public hideText: boolean = false;
	/**
	 * The link to the cases page.
	 */
	@Input() public caseLink: string[] = [];
	/**
	 * The title to display.
	 *
	 * Default value is translation string.
	 */
	@Input() public title: string = this.i18nKeys.Public;
	/**
	 * Whether the title should be displayed.
	 *
	 * Default value is `true`.
	 */
	@Input() public showTitle: boolean = true;
	/**
	 * Whether the essentials of the company details should be shown.
	 *
	 * Default value is `false`.
	 */
	@Input() public showMinimalInfo: boolean = false;

	constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}

	/**
	 * Handle the click event on the offers page
	 *
	 * @param event - the click event for the util
	 */
	public navigateToCases(event: Event) {
		clickOnTranslationLink(event, () => {
			this.router.navigate(this.caseLink, { relativeTo: this.route.parent });
		});
	}
}
