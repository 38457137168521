import { AsyncPipe } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';

import { PwaService } from '@vlaio/shared/pwa';

import { I18nKeys } from '../../i18n';
import { VlaioDisclaimerComponent } from '../disclaimer/disclaimer.component';
import { EmptySearchResultComponent } from '../empty-search-result/empty-search-result.component';
import { PreventSpacingAt } from '../layout/layout.const';
import { LoaderComponent } from '../loader/loader.component';

@Component({
	selector: 'vlaio-content',
	templateUrl: './vlaio-content.component.html',
	standalone: true,
	imports: [LoaderComponent, VlaioDisclaimerComponent, AsyncPipe, TranslateModule, EmptySearchResultComponent]
})
export class VlaioContentComponent {
	@HostBinding('class.is-loading')
	/**
	 * The loading state of the content
	 */
	@Input({ required: true })
	public loading: boolean;
	/**
	 * Whether the browser is online
	 */
	public readonly isOnline$: ObservableBoolean = this.pwaService.isOnline$;

	/**
	 * The label we wish to show for the offline mode
	 */
	public readonly offlineLabel: string = I18nKeys.Offline;

	/**
	 * A label we wish to show when loading
	 */
	@Input() public label: string;

	/**
	 * The error state of the content
	 */
	@Input() public error: boolean = false;

	/**
	 * The style of the error. By default this is styled as a warning.
	 */
	@Input() public errorStyle: 'is-information' | 'is-warning' = 'is-warning';

	/**
	 * Whether we want to prevent padding around the error. By default this is false.
	 */
	@Input() public preventErrorSpacing: `${PreventSpacingAt}` = 'none';

	constructor(private readonly pwaService: PwaService) {}
}
