import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import { ObservableArray, ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { merge } from '@studiohyperdrive/utils';
import { Observable, combineLatest, map, of } from 'rxjs';

import { PermitsEntity, PermitsSummaryEntity, RenewablePermitsEntity } from '../interfaces';
import { actions, selectors } from '../permits.store';

import { PermitsApiService } from './permits.api.service';

@Injectable()
export class PermitsService extends StoreService {
	/**
	 * An array of permits associated with the company the user represents.
	 */
	public readonly permits$: ObservableArray<PermitsEntity> = this.selectFromStore(selectors.permits);
	/**
	 * Whether the permits are being fetched.
	 */
	public readonly loading$: ObservableBoolean = this.selectLoadingFromStore(selectors.permits);
	/**
	 * Whether the permits being fetched resulted in an error.
	 */
	public readonly error$: ObservableBoolean = this.selectErrorFromStore(selectors.permits);
	/**
	 * An array of permits associated with the company the user represents.
	 */
	public readonly permitsSummary$: Observable<PermitsSummaryEntity> = this.selectFromStore(selectors.summary);
	/**
	 * Whether the permits summary is being fetched.
	 */
	public readonly permitsSummaryLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.summary);
	/**
	 * Whether an error occurred while fetching the permits summary.
	 */
	public readonly permitsSummaryError$: ObservableBoolean = this.selectErrorFromStore(selectors.summary);
	/**
	 * An array of permits associated with the company the user represents.
	 */
	public readonly renewablePermits$: ObservableArray<RenewablePermitsEntity> = this.selectFromStore(
		selectors.renewablePermits
	);
	/**
	 * Whether the renewable permits are being fetched.
	 */
	public readonly renewablePermitsLoading$: ObservableBoolean = this.selectLoadingFromStore(
		selectors.renewablePermits
	);
	/**
	 * Whether an error occurred while fetching the renewable permits.
	 */
	public readonly renewablePermitsError$: ObservableBoolean = this.selectErrorFromStore(selectors.renewablePermits);

	constructor(
		public readonly store: Store,
		private readonly apiService: PermitsApiService
	) {
		super(store);
	}

	/**
	 * Fetches the permits from the API.
	 *
	 * @returns An observable of the fetched permits array.
	 */
	public getPermits(markRenewablePermits: boolean = false): ObservableArray<PermitsEntity> {
		return dispatchDataToStore(
			actions.permits,
			combineLatest([
				this.apiService.getPermits(),
				markRenewablePermits ? this.apiService.getRenewablePermits() : of([])
			]).pipe(
				map(([permits, renewablePermits]) => {
					const renewable = new Set(renewablePermits.map(({ reference }) => reference));

					// Iben: Add the isRenewable flag when needed
					return permits.map((permit) => {
						return merge(permit, ['isRenewable', renewable.has(permit.reference) || undefined]);
					});
				})
			),
			this.store
		);
	}

	/**
	 * Fetches the summary of the permits from the API.
	 *
	 * @returns An observable of the fetched summary.
	 */
	public getPermitsSummary(): Observable<PermitsSummaryEntity> {
		return dispatchDataToStore(actions.summary, this.apiService.getPermitsSummary(), this.store);
	}

	/**
	 * Fetches the permits that are eligible for a renewal.
	 *
	 * @returns An array of the permits that require renewal as an observable.
	 */
	public getRenewablePermits(): ObservableArray<RenewablePermitsEntity> {
		return dispatchDataToStore(actions.renewablePermits, this.apiService.getRenewablePermits(), this.store);
	}
}
