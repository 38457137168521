@if (isLoaded$ | async) {

<vlaio-skip-links />

<div class="top-bar">
	<vlaio-acm-header cypressTag="Header" />
	<language-switcher *hasFeature="'Multilingual'" />
</div>

<div class="c-eloket--main-content">
	<vlaio-menu [menuItems]="menuItems$ | async">
		<ng-template #itemTmpl let-item>
			{{ item | translate }}
		</ng-template>
	</vlaio-menu>
	<router-outlet id="main-content" tabindex="-1"></router-outlet>

	<!-- Needs a negative tabindex to be focusable via the <vlaio-skip-links> but not by tabbing -->
	<vlaio-footer
		class="u-outline-none"
		id="footer"
		tabindex="-1"
		[language]="language$ | async"
		[navItems]="footerNavItems$ | async"
		(cookiesClicked)="openCookiesPopup()"
	/>
</div>

@if (modalService.state.loginThresholdExceeded) {
<vlaio-modal
	title="Loginlimiet bereikt"
	[small]="false"
	(handleClose)="modalService.updateState('loginThresholdExceeded', false)"
>
	<ng-container modalBody>
		<p class="u-spacing-b">
			{{ i18nKey.BusyRetry | translate }}
		</p>
	</ng-container>
</vlaio-modal>
} } @else {

<vlaio-loading-page />

}
