import { NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'vlaio-tree-item',
	templateUrl: './tree-item.component.html',
	styleUrls: ['./tree-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass]
})
export class TreeItemComponent implements AfterViewInit {
	@ViewChild('details') details: ElementRef;
	public isOpen: boolean = false;

	@Input() public set open(isOpen: boolean) {
		this.isOpen = isOpen;

		this.handleExternalOpen(isOpen);
	}

	public toggleDetails(event: any) {
		// Iben: If the open attribute is on the details element, the element is closed due to open not having a value
		this.isOpen = !Boolean((event.attributes as NamedNodeMap).getNamedItem('open'));
	}

	/**
	 * Handle the isOpen flow if the component user opens the detail
	 *
	 * @param isOpen - Whether or not the item is open
	 */
	private handleExternalOpen(isOpen: boolean) {
		// Iben: Early exit if the details object doesn't exist yet
		if (!this.details) {
			return;
		}

		// Iben: Open or close the object based on the isOpen
		isOpen
			? this.details.nativeElement.setAttribute('open', 'true')
			: this.details.nativeElement.removeAttribute('open');
	}

	ngAfterViewInit() {
		// Iben: Handle the initial isOpen set
		this.handleExternalOpen(this.isOpen);
	}
}
