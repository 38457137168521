<h1 cypressTag="Shared.Tour.Title">
	{{ title }}

	<vlaio-icon
		icon="fa-times"
		cypressTag="Shared.Tour.Close"
		[attr.title]="i18nKeys.Tour.Close.Title | translate"
		(focusClick)="handleInteraction.emit('close')"
	/>
</h1>

<p cypressTag="Shared.Tour.Content">
	{{ content }}
</p>

<div class="buttons">
	<span class="progress" cypressTag="Shared.Tour.Progress">{{ currentStep + 1 }}/{{ amountOfSteps }}</span>

	@if (currentStep !== 0) {
		<button
			cypressTag="Shared.Tour.Previous"
			[title]="i18nKeys.Tour.Previous.Title | translate"
			(click)="handleInteraction.emit('back')"
		>
			{{ i18nKeys.Tour.Previous.Text | translate }}
		</button>
	}
	@if (currentStep + 1 < amountOfSteps) {
		<button
			cypressTag="Shared.Tour.Next"
			[title]="i18nKeys.Tour.Next.Title | translate"
			(click)="handleInteraction.emit('next')"
		>
			{{ i18nKeys.Tour.Next.Text | translate }}
		</button>
	}
	@if (currentStep + 1 === amountOfSteps) {
		<button
			cypressTag="Shared.Tour.Close"
			[title]="i18nKeys.Tour.Close.Title | translate"
			(click)="handleInteraction.emit('close')"
		>
			{{ i18nKeys.Tour.Close.Text | translate }}
		</button>
	}
</div>
