import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	HostBinding,
	Input,
	signal,
	TemplateRef,
	ViewChild,
	WritableSignal
} from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { MediaQueryMax, MediaQueryMin } from '@vlaio/shared/types';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';

import { VlaioMenuItemEntity } from '../../types';
import { VlaioHamburgerComponent } from '../hamburger/hamburger.component';

@Component({
	selector: 'vlaio-menu',
	templateUrl: 'menu.component.html',
	styleUrl: './menu.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		RouterLinkActive,
		RouterLink,
		NgTemplateOutlet,

		// Directives
		CypressTagDirective,
		MediaQueryDirective,
		CdkMenuTrigger,
		CdkMenu,
		CdkMenuItem,
		// Components
		VlaioHamburgerComponent
	]
})
export class VlaioMenuComponent {
	@HostBinding('class')
	@Input()
	public classes: string = '';
	/**
	 * An array of items we wish to display in the menu
	 */
	@Input() public menuItems: VlaioMenuItemEntity[] = [];

	@ViewChild('hamburger') public hamburger: VlaioHamburgerComponent;
	/**
	 * Prevent the menu from setting an active class on the currently active menu item.
	 *
	 * Default value is set to 'false'.
	 */
	@Input() public showActiveItem: boolean = false;
	/**
	 * Prevent the menu from collapsing on mobile devices.
	 *
	 * Default value is set to 'false'.
	 */
	@HostBinding('class.prevent-collapse')
	@Input()
	public preventCollapse: boolean = false;

	/**
	 * A template for content of the a tag
	 */
	@ContentChild('itemTmpl', { static: false })
	public itemTemplate: TemplateRef<any>;

	/**
	 * Th
	 */
	public mediaQueries = { MediaQueryMax, MediaQueryMin };
	public isMenuOpen: WritableSignal<boolean> = signal(false);

	/**
	 * Handles the menu change
	 *
	 * @param isOpen - A boolean value that indicates whether the menu is open
	 */
	public handleMenuChange(isOpen: boolean): void {
		this.isMenuOpen.update(() => isOpen);
	}
}
