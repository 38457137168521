import { CallToActionEntity, CallToActionResult } from '@vlaio/shared/types';

import { NotificationSeverity } from './notification-severity.enum';

/**
 * The default notification entity
 */
export interface Notification {
	title: string;
	message: string;
	severity: NotificationSeverity;
	link?: CallToActionEntity;
	id: string;
}

/**
 * The response from the notifications api.
 */
export interface NotificationResultEntity {
	naam: string;
	bericht: string;
	ernst: NotificationSeverity;
	link?: CallToActionResult;
	id: string;
}

/**
 * The response of the company specific
 */
export interface CompanyNotificationResultEntity {
	id: string;
	titel: string;
	datum: string;
	bestemmeling: string;
	sleutelWaardeParen: {
		elementen: CompanyNotificationKeyValueResultEntity[];
	};
	afzender: {
		korteNaam: string;
		naam: string;
		telefoonNummer?: string;
		website?: string;
	};
	isOngelezen: boolean;
}

export interface CompanyNotificationEntity extends Partial<CompanyNotificationKeyValueEntity> {
	id: string;
	title: string;
	fullDate: string;
	organizationNumber: string;
	provider: {
		short: string;
		name: string;
		phone?: string;
		link?: string;
	};
	isUnread: boolean;
}

export interface NotificationProviderResultEntity {
	korteNaam: string;
	naam: string;
	telefoonNummer?: string;
	website?: string;
}

export interface NotificationProviderEntity {
	short: string;
	name: string;
	phone?: string;
	url?: string;
}

/**
 * The respone of the API regarding the content of a notification.
 */
export interface CompanyNotificationKeyValueResultEntity {
	sleutel: NotificationResultKeys;
	waarde: string;
}

/**
 * The frontend-friendly entity to which the notification content gets mapped.
 */
export type CompanyNotificationKeyValueEntity = {
	[key in NotificationResultKeys]: string;
};

/**
 * The enum used by the company notification entity
 */
export enum NotificationResultKeys {
	Body = 'body',
	Titel = 'title',
	DocumentLinkUri = 'documentLink',
	ExterneLinkNaam = 'externalLink',
	NotificatieDatum = 'date',
	MerkNaam = 'brand'
}
