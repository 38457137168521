<button
	[disabled]="isDisabled"
	[class]="buttonClasses[buttonType] + ' u-outline ' + customButtonClass"
	[ngClass]="{ 'is-disabled': isDisabled }"
	[title]="title"
	[attr.type]="type"
	[cypressTag]="buttonCypressTag"
	(click)="handleButtonClick()"
>
	@if (icon) {
		<vlaio-icon [icon]="icon" />
	}

	<ng-content></ng-content>
</button>
