import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreService, dispatchDataToStore } from '@studiohyperdrive/ngx-store';
import {
	ObservableArray,
	ObservableBoolean,
	ObservableRecord,
	ObservableStringRecord,
	ObservableString
} from '@studiohyperdrive/rxjs-utils';
import { isEmpty } from 'lodash';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { FeatureService } from '@vlaio/shared/features';
import { UserEntity, UserService } from '@vlaio/shared/user';

import {
	CompanyActivitiesByBranchEntity,
	CompanyActivitiesByBranchRecord,
	CompanyAddressEntity,
	CompanyBranchEntity,
	CompanyEntity,
	PartialCompanySearchEntity
} from '../../data/interfaces';
import { actions, selectors } from '../company.store';
import { getCompanyAddressRecord } from '../utils';

import { CompanyApiService } from './company.api.service';

@Injectable()
export class CompanyService extends StoreService {
	/**
	 * The company of the current user
	 */
	public readonly userCompany$: Observable<CompanyEntity> = this.selectFromStore<CompanyEntity>(selectors.company);

	public readonly userCompanyBranches$: ObservableArray<CompanyBranchEntity> = this.userCompany$.pipe(
		filter<CompanyEntity>(Boolean),
		map((company) => company.branches)
	);
	/**
	 * A record of every company branch including the main location that exists of the address as
	 * value with the branch number as key.
	 */
	public readonly userCompanyBranchesByNumber$: ObservableRecord<CompanyAddressEntity> = this.userCompany$.pipe(
		filter<CompanyEntity>(Boolean),
		map(getCompanyAddressRecord)
	);
	/**
	 * The loading state of the current user company
	 */
	public readonly userCompanyLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.company);
	/**
	 * The error state of the current user company
	 */
	public readonly userCompanyError$: ObservableBoolean = this.selectErrorFromStore(selectors.company);

	/**
	 * A list of searched companies with all the data
	 */
	public readonly companies$: ObservableArray<CompanyEntity> = this.selectFromStore<CompanyEntity[]>(
		selectors.companies
	);
	/**
	 * The loading state of the searched companies
	 */
	public readonly companiesLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.companies);
	/**
	 * The error state of the searched companies
	 */
	public readonly companiesError$: ObservableBoolean = this.selectErrorFromStore(selectors.companies);

	/**
	 * A list of searched companies with all the data
	 */
	public readonly searchResults$: ObservableArray<Partial<CompanyEntity>> = this.selectFromStore<
		Partial<CompanyEntity>[]
	>(selectors.searchResults);
	/**
	 * The loading state of the searched companies
	 */
	public readonly searchResultsLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.searchResults);
	/**
	 * The error state of the searched companies
	 */
	public readonly searchResultsError$: ObservableBoolean = this.selectErrorFromStore(selectors.searchResults);

	/**
	 * A record of company details, saved by the company number
	 */
	public readonly companyDetailsRecord$: ObservableStringRecord<CompanyEntity> = this.selectFromStore<
		Record<string, CompanyEntity>
	>(selectors.companyDetails);

	/**
	 * The loading state of the details of companies
	 */
	public readonly companyDetailsRecordLoading$: ObservableBoolean = this.selectLoadingFromStore(
		selectors.companyDetails
	);
	/**
	 * The error state of the details of companies
	 */
	public readonly companyDetailsRecordError$: ObservableBoolean = this.selectErrorFromStore(selectors.companyDetails);
	/**
	 * A list of activities by branch
	 */
	public readonly activitiesByBranch$: ObservableArray<CompanyActivitiesByBranchEntity> = this.selectFromStore<
		CompanyActivitiesByBranchEntity[]
	>(selectors.activities);
	/**
	 * A record of activities by branch
	 */
	public readonly activitiesByBranchRecord$: Observable<CompanyActivitiesByBranchRecord> =
		this.activitiesByBranch$.pipe(
			// Iben: Map activities to record for easy access
			map((items) => {
				const result = {};

				items.forEach(({ branch, activities }) => {
					result[branch] = activities;
				});

				return result;
			})
		);
	/**
	 * The loading state of the activities
	 */
	public readonly activitiesByBranchLoading$: ObservableBoolean = this.selectLoadingFromStore(selectors.activities);
	/**
	 * The error state of the activities
	 */
	public readonly activitiesByBranchError$: ObservableBoolean = this.selectErrorFromStore(selectors.activities);

	/**
	 * The current selection of filters
	 */
	public readonly filters$: Observable<PartialCompanySearchEntity> = this.selectFromStore<PartialCompanySearchEntity>(
		selectors.filters
	);

	/**
	 * Id of the current company detail
	 */
	public readonly currentCompanyDetailId$: ObservableString = this.selectFromStore<string>(selectors.detail);

	/**
	 * The name of the company
	 */
	public readonly companyName$: ObservableString = this.userCompany$.pipe(map((company) => company?.names?.public));

	constructor(
		public readonly store: Store,
		private readonly apiService: CompanyApiService,
		private readonly userService: UserService,
		private readonly featureService: FeatureService
	) {
		super(store);
	}

	/**
	 * Fetches and returns the company of the current user
	 */
	public getUserCompany(): Observable<CompanyEntity> {
		return this.userService.user$.pipe(
			filter<UserEntity>((user) => Boolean(user && user.company)),
			switchMap(({ company }) =>
				dispatchDataToStore(actions.company, this.apiService.getCompany(company.number), this.store)
			)
		);
	}

	/**
	 * Fetches and returns a list of companies matching the provided search filters
	 *
	 * @param filters - The provided search filters
	 * @param showError - The provided search filters
	 */
	public searchCompanies(
		filters: PartialCompanySearchEntity,
		showError: boolean = false
	): ObservableArray<CompanyEntity> {
		return dispatchDataToStore(
			actions.searchResults,
			isEmpty(filters)
				? of([])
				: this.apiService.searchCompanies(filters).pipe(
						// Iben: Show the error when needed
						catchError((error) => {
							if (!showError) {
								return of([]);
							}

							throwError(error);
						})
					),
			this.store
		);
	}

	/**
	 * Set the current company filters
	 *
	 * @param payload
	 */
	public setCompanyFilters(payload: PartialCompanySearchEntity) {
		this.store.dispatch(actions.filters.set({ payload }));
	}

	/**
	 * Get the branch activities for a company's branch
	 *
	 * @param company - The number of the company
	 * @param branch - The number of the branch
	 */
	public getCompanyBranchActivities(company: string, branch: string) {
		return dispatchDataToStore(
			actions.activities,
			this.apiService.getCompanyBranchActivities(company, branch).pipe(
				map((activities) => {
					return { branch: `${company}-${branch}`, activities };
				})
			),
			this.store,
			'add'
		);
	}

	/**
	 * Get the full data of a company
	 *
	 * @param number - The number of the company
	 */
	public getCompany(number: string): Observable<CompanyEntity> {
		return this.companies$.pipe(
			switchMap((companies) => {
				// Iben: If there are no companies yet, fetch it right away
				if (!companies || companies.length === 0) {
					return this.fetchCompany(number);
				}

				// Iben: Search for the company in the list of companies
				const company = companies.find((item) => item.number === number);

				// Iben: If the company doesn't exist yet, fetch it from the backend
				return company ? of(company) : this.fetchCompany(number);
			})
		);
	}

	/**
	 * Set the id of the currently selected company
	 *
	 * @param id - Id the of the currently selected company
	 */
	public setCurrentCompanyId(id: string): ObservableString {
		return this.currentCompanyDetailId$.pipe(
			take(1),
			tap((current) => {
				// Iben: In case the ids are the same we reset
				const payload = current === id ? '' : id;
				this.store.dispatch(actions.detail.set({ payload }));
			})
		);
	}

	/**
	 * Clear the list of searched companies
	 */
	public clearSearchedCompanies() {
		this.store.dispatch(actions.searchResults.set({ payload: [] }));
	}

	/**
	 * Fetches and stores the company detail in a record for the mobile view
	 *
	 * @param number - The number of the company
	 */
	public getCompanyDetail(number: string): Observable<CompanyEntity> {
		// Iben: Reserve the requested company for later return
		let requestedCompany: CompanyEntity;

		// Iben: Check the current record to see if the company already exists
		return this.companyDetailsRecord$.pipe(
			take(1),
			switchMap((result) => {
				// Iben: Return the company in case it already exists
				if (result && result[number]) {
					return of(result[number]);
				}

				// Iben: Fetch the company if it does not exist yet
				return dispatchDataToStore(
					actions.companyDetails,
					this.getCompanyFromApi(number).pipe(
						map((company) => {
							requestedCompany = company;

							return {
								...result,
								[number]: company
							};
						})
					),
					this.store
				).pipe(map(() => requestedCompany));
			})
		);
	}

	/**
	 * Fetch the full data of a company from the api
	 *
	 * @param number - The number of the company
	 */
	private fetchCompany(number: string): Observable<CompanyEntity> {
		return dispatchDataToStore(actions.companies, this.getCompanyFromApi(number), this.store, 'add');
	}

	public addFakeBranch(fakeBranch: CompanyBranchEntity) {
		return dispatchDataToStore(
			actions.company,
			this.userCompany$.pipe(
				take(1),
				map((company) => {
					return {
						...company,
						branches: [...company.branches, fakeBranch]
					};
				})
			),
			this.store,
			'set'
		);
	}

	/**
	 * Fetches the company from the api, including the permits in case the permits feature flag is activated
	 *
	 * @param number - The number of the company
	 */
	private getCompanyFromApi(number: string): Observable<CompanyEntity> {
		// Iben: Check if the permits feature is active
		return this.featureService.hasFeature('Permits').pipe(
			switchMap((hasPermits) => {
				// Iben: If the permits are not active, return the company without the permits
				if (!hasPermits) {
					return this.apiService.getCompany(number);
				}

				// Iben: If the permits are active, fetch both the company and the permits
				return combineLatest([
					this.apiService.getCompany(number),
					// Iben: In case the permits would fail, we still continue as it should be non breaking
					this.apiService.getCompanyPermits(number).pipe(catchError(() => of([])))
				]).pipe(
					map(([company, permits]) => {
						return { ...company, permits };
					})
				);
			})
		);
	}
}
