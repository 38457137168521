import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { VlaioTreeItemComponent } from '@vlaio/shared/ui/common';
import { VlaioCheckBoxComponent } from '@vlaio/shared/ui/forms';

import { NaceBelEntity } from '../../../data';
import { isNacebelOpenPipe } from '../../pipes/is-nacebel-open.pipe';
import { NacebelTitleComponent } from '../nacebel-title/nacebel-title.component';

@Component({
	selector: 'vlaio-nacebel-tree-item',
	templateUrl: './nacebel-tree-item.component.html',
	styleUrls: ['./nacebel-tree-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		VlaioTreeItemComponent,
		NacebelTitleComponent,
		ReactiveFormsModule,
		VlaioCheckBoxComponent,
		isNacebelOpenPipe
	]
})
export class NacebelTreeItemComponent {
	@Input() codesForm: FormGroup | FormControl;
	@Input() codes: NaceBelEntity[] = [];
	@Input() isOpenSet: Set<string> = new Set();
	@Input() searchResults: Set<string>;
	@Input() filterNacebelCodes: boolean;
}
