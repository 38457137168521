import { Injectable } from '@angular/core';

import { AbstractLanguageProviderService } from '@vlaio/shared/core';
import { LanguageService } from '@vlaio/shared/i18n';
import { Language } from '@vlaio/shared/types';

@Injectable()
export class LanguageProviderService extends AbstractLanguageProviderService {
	constructor(private readonly languageService: LanguageService) {
		super();
	}

	public get currentLanguage(): Language {
		return this.languageService.currentLanguage as Language;
	}
}
