@for (item of values | keyvalue; track item) {

<input
	type="radio"
	cypressTag="General.Input.Radio"
	[name]="name"
	[id]="item.key"
	[value]="item.key"
	[checked]="item.key === defaultSelect ?? null"
	[cypressTag]="cypressTags[item.key]['input']"
	(change)="checkedValue.emit(item.key)"
/>
<!-- Wouter: move label below input to target in css when input is checked -->
<label
	class="u-outline"
	tabindex="0"
	[cypressTag]="$any(cypressTags[item.key]['label'])"
	[for]="item.key"
	[class.full-width]="fullWidth"
>
	{{ item.value }}
</label>

}
