import { KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';

@Component({
	selector: 'vlaio-radio-toggle',
	templateUrl: './radio-toggle.component.html',
	styleUrls: ['./radio-toggle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CypressTagDirective, KeyValuePipe]
})
export class RadioToggleComponent {
	/**
	 * The key value pair of the buttons.
	 * The key will be emitted and the value will be displayed.
	 */
	@Input({ required: true }) public values: Record<string, string>;
	/**
	 * The name of the group of these radio buttons. This has to be unique.
	 */
	@Input({ required: true }) public name: string;
	/**
	 * Pass the key of the item that needs to be selected by default.
	 */
	@Input() public defaultSelect: string;
	/**
	 * Allow the options to take the full available width.
	 */
	@Input() public fullWidth: boolean = false;
	/**
	 * The cypressTags to be added to the input fields.
	 */
	@Input() public cypressTags: Record<string, Record<string, CypressTagsPaths>>;

	/**
	 * Emits the key of the checked item.
	 *
	 * **Note:**
	 * Upon setting the defaultSelect, this will not emit. This will
	 * also not emit if the user interacts with an element that was already
	 * selected.
	 */
	@Output() public readonly checkedValue: EventEmitter<string> = new EventEmitter<string>();
}
