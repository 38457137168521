<!-- The input and label are in this order, so that the label color can be changed by selecting the input, then the label -->
<input
	class="c-input--as-textfield"
	type="date"
	[formControl]="form"
	[attr.id]="inputId"
	(blur)="onTouch()"
	(change)="onTouch()"
/>

<label [for]="inputId">{{ label }}</label>
