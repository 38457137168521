import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { tap } from 'rxjs/operators';

import { LanguageService } from '@vlaio/shared/i18n';

/**
 * Generates a guard that checks if an observable condition is met
 *
 * @param condition - The provided condition
 */
export const PreventNavigationGuard = (condition: ObservableBoolean): ObservableBoolean => {
	// Iben: Fetch all injectables
	const router: Router = inject(Router);
	const languageService: LanguageService = inject(LanguageService);

	// Iben: Return a CanNavigateFunction
	return condition.pipe(
		tap((isConditionMet) => {
			// Iben: If the provided condition is not met, redirect back to the home page
			if (!isConditionMet) {
				router.navigate([languageService.currentLanguage]);
			}
		})
	);
};
