import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition, MatTooltip } from '@angular/material/tooltip';

import { VlaioFaIconComponent } from '../fa-icon/fa-icon.component';

@Component({
	selector: 'vlaio-info',
	templateUrl: './info.component.html',
	styleUrls: ['./info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatTooltip, VlaioFaIconComponent]
})
export class InfoComponent {
	/**
	 * The description to display within the tooltip.
	 *
	 * If no description is provided, there will be no tooltip.
	 */
	@Input() public description: string;

	/**
	 * The position of the tooltip.
	 *
	 * Default value is `below`.
	 */
	@Input() public tooltipPosition: `${TooltipPosition}` = 'below';
}
