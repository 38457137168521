<div>
	<div class="c-modal__header">
		<h3 class="c-modal__title">{{ translations.title || 'titel' }}</h3>

		<button class="c-modal__close" (focusClick)="handleClose.emit()">
			<vlaio-icon icon="fa-close" />
		</button>
	</div>

	@if (hasCookieConsent) {
		<div class="c-modal__body">
			<div class="c-iframe">
				<iframe
					cypressTag="Shared.VideoModal.Video"
					style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0"
					allowfullscreen
					scrolling="no"
					allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
					[src]="videoData.video"
				></iframe>
			</div>
		</div>
	} @else {
		<div class="c-modal__body image-body">
			<img class="u-margin-bottom-sm" alt="" [src]="videoData.thumbnail" />

			<vlaio-disclaimer>
				<div [outerHTML]="translations.videoDescription"></div>

				<vlaio-external-link link="/nl/pagina?page=privacyverklaring" [title]="translations.readMoreLabel">
					{{ translations.readMoreLabel || 'Read more' }}
				</vlaio-external-link>
			</vlaio-disclaimer>
		</div>
	}
	@if (!hasCookieConsent) {
		<div class="c-modal__footer">
			<vlaio-button
				class="c-modal__action"
				cypressTag="Shared.VideoModal.AcceptCookies"
				[title]="translations.buttonTitle"
				(handleClick)="cookieAccepted.emit()"
			>
				{{ translations.buttonLabel }}
			</vlaio-button>
		</div>
	}
</div>
