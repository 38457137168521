import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MediaQueryMax } from '@vlaio/shared/types';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';

import { I18nKeys } from '../../i18n';
import { VlaioDisclaimerComponent } from '../disclaimer/disclaimer.component';

@Component({
	selector: 'vlaio-desktop-only-disclaimer',
	templateUrl: './desktop-only.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MediaQueryDirective, VlaioDisclaimerComponent, TranslateModule]
})
export class VlaioDesktopOnlyDisclaimerComponent {
	/**
	 * The translation keys of the component
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	/**
	 * Whether to add vertical margin to the disclaimer.
	 *
	 * The default value is `true`.
	 */
	@Input() public readonly verticalMargin: boolean = true;

	/**
	 * The mediaQuery we wish to match with
	 */
	public readonly mediaQuery: string = MediaQueryMax.Tablet;
}
