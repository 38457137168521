import {
	IPDCAddressCollectionEntity,
	IPDCContactCollectionEntityResult,
	IPDCContactEntityResult,
	IPDCProductEntity,
	IPDCProductEntityResult,
	IPDCTextCollectionEntity,
	IPDCTextCollectionEntityResult,
	IPDCTextEntityResult,
	ProductEntity,
	ProductEntityResult,
	ProductPartnerEntity,
	ProductPartnerResult
} from '@vlaio/shared/types';
import { convertToCTA, parseLocationString } from '@vlaio/shared/utils';

function convertProductPartnerResultToProductPartner(partnerResult: ProductPartnerResult[]): ProductPartnerEntity[] {
	return [...(partnerResult || [])].map((partner) => {
		return {
			name: partner.naam,
			url: partner.url
		};
	});
}

function convertIPDCAddressCollectionResultToIPDCTextCollection(
	addressCollectionResult: IPDCContactCollectionEntityResult
): IPDCAddressCollectionEntity {
	if (!addressCollectionResult) {
		return;
	}

	return {
		'@type': addressCollectionResult['@type'],
		elements: (addressCollectionResult.elementen || []).map((contactInfo: IPDCContactEntityResult) => {
			// Denis: parse the address string
			const address = parseLocationString(
				contactInfo.adres
					? {
							street: contactInfo.adres.straat,
							number: contactInfo.adres.nummer,
							zip: contactInfo.adres.postcode,
							municipality: contactInfo.adres.gemeente,
							box: contactInfo.adres.bus
						}
					: null
			);

			return {
				'@type': contactInfo['@type'],
				address,
				email: contactInfo.emailadres,
				hours: contactInfo.openingsuren,
				phone: contactInfo.telefoonnummer,
				website: {
					url: contactInfo.website,
					text: contactInfo.website
				}
			};
		})
	};
}

function convertIPDCTextCollectionResultToIPDCTextCollection(
	textCollectionResult: IPDCTextCollectionEntityResult
): IPDCTextCollectionEntity {
	if (!textCollectionResult) {
		return;
	}

	return {
		'@type': textCollectionResult['@type'],
		elements: (textCollectionResult.elementen || []).map((text: IPDCTextEntityResult) => ({
			'@type': text['@type'],
			text: text.tekst
		}))
	};
}

function convertIPDCProductResultToIPDCPRoduct(productResult: IPDCProductEntityResult): IPDCProductEntity {
	if (!productResult) {
		return;
	}

	return {
		'@type': productResult['@type'],
		productId: productResult.productID,
		regulations: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.regelgeving),
		exceptions: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.uitzonderingen),
		terms: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.voorwaarden),
		procedures: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.procedures),
		costs: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.kosten),
		financialBenifits: convertIPDCTextCollectionResultToIPDCTextCollection(productResult.financieleVoordelen),
		contactInfo: convertIPDCAddressCollectionResultToIPDCTextCollection(productResult.contactgegevens)
	};
}

export function convertProductResultToProduct(
	productResult: Partial<ProductEntityResult>,
	annotations?: string[]
): ProductEntity {
	// Iben: Handle possible corrupt data
	const partners = productResult.partners
		? Array.isArray(productResult.partners)
			? productResult.partners
			: [productResult.partners]
		: [];

	return {
		name: productResult.naam,
		partners: convertProductPartnerResultToProductPartner([...partners]),
		link: productResult.meerInfoLink,
		description: productResult.omschrijving,
		id: productResult.id,
		actions: [...(productResult.gebruikersacties?.elementen || [])].map((cta) => convertToCTA(cta)),
		...(annotations ? { annotations } : {}),
		infoLinks: (productResult.infoLinks?.elementen || []).map((item) => {
			return { name: item.naam, link: item.url };
		}),
		tags: (productResult.labels?.elementen || []).map((item) => {
			return {
				name: item.naam,
				description: item.omschrijving
			};
		}),
		theme: productResult.thema,
		ipdcProduct: convertIPDCProductResultToIPDCPRoduct(productResult.ipdcProduct)
	};
}
