import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { FocusClickDirective, LogPipe } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { MediaQueryMax, MediaQueryMin } from '@vlaio/shared/types';
import { VlaioIconComponent, VlaioInternalLinkComponent } from '@vlaio/shared/ui/common';
import { MediaQueryDirective } from '@vlaio/shared/ui/device';

import { AsideNavigationEntity } from '../../interfaces';

@Component({
	selector: 'vlaio-aside-navigation-block',
	templateUrl: './aside-navigation-block.component.html',
	styleUrls: ['./aside-navigation-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MediaQueryDirective,
		VlaioIconComponent,
		NgTemplateOutlet,
		CypressTagDirective,
		RouterLinkActive,
		RouterLink,
		FocusClickDirective,
		LogPipe,
		VlaioInternalLinkComponent
	]
})
export class VlaioAsideNavigationBlockComponent {
	/**
	 * The reference of the detail element
	 */
	@ViewChild('details') public details: ElementRef;

	/**
	 * The minimum media query object.
	 */
	public readonly mediaQueryMin: typeof MediaQueryMin = MediaQueryMin;
	/**
	 * The maximum media query object.
	 */
	public readonly mediaQueryMax: typeof MediaQueryMax = MediaQueryMax;
	/**
	 * Whether the detail is open.
	 */
	public isDetailOpen: boolean = false;

	/**
	 * The items to display in the navigation block;
	 */
	@Input({ required: true }) public items: AsideNavigationEntity[];
	/**
	 * The items to display in the navigation block;
	 */
	@Input({ required: true }) public summary: string;

	// Component methods
	// ==============================
	/**
	 * Add or remove the `open` property
	 *
	 * @param event The reference of the details tag
	 * @param clickEvent The bubbled click event of the summary. Only applicable on click, not
	 * on enter (hence the possible `void`)
	 */
	public handleClick(event: HTMLDetailsElement, clickEvent?: Event | void): void {
		if (clickEvent) {
			// Wouter: prevent bubbled click event from summary
			clickEvent.preventDefault();
		}

		// Wouter: If the open property does not exist, the details are closed.
		this.toggleOpenState(Boolean((event.attributes as NamedNodeMap).getNamedItem('open')));
	}

	/**
	 * Handle the isOpen flow if the component user opens the detail
	 *
	 * @param isOpen - Whether or not the item is currently open
	 */
	private toggleOpenState(isOpen: boolean) {
		// Iben: Early exit if the details object doesn't exist yet
		if (!this.details) {
			return;
		}

		this.isDetailOpen = !isOpen;

		// Wouter: if it is currently open, then we want to close it
		isOpen
			? this.details.nativeElement.removeAttribute('open')
			: this.details.nativeElement.setAttribute('open', 'true');
	}
}
