import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { CypressTagDirective } from '@vlaio/cypress/core';
import { CypressTagsPaths } from '@vlaio/cypress/shared';

@Component({
	selector: 'vlaio-detail-content',
	templateUrl: './detail-content.component.html',
	styleUrl: './detail-content.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CypressTagDirective]
})
export class VlaioDetailContentComponent {
	/**
	 * Whether we wish the title to be highlighted
	 */
	@HostBinding('class.highlight-title') @Input() public highlightTitle: boolean = false;

	/**
	 * The title of the detail
	 */
	@Input() public title: string;

	/**
	 * An optional cypress tag for the title
	 */
	@Input() public cypressTitleTag: CypressTagsPaths;
}
