import {
	Component,
	Input,
	forwardRef,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
	HostBinding,
	Output,
	EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HasObserversPipe } from '@studiohyperdrive/ngx-utils';

import { CypressTagDirective } from '@vlaio/cypress/core';

import { I18nKeys } from '../../../i18n';

const INPUT_CONTROL_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CheckBoxComponent), // tslint:disable-line:no-use-before-declare
	multi: true
};
@Component({
	selector: 'vlaio-checkbox',
	providers: [INPUT_CONTROL_ACCESSOR],
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CypressTagDirective, TranslateModule, HasObserversPipe]
})
export class CheckBoxComponent implements ControlValueAccessor {
	@HostBinding('class.c-filter__checkbox') public rootClass = true;
	@HostBinding('class.disabled') public disabled: boolean = false;

	/**
	 * Actual checkbox input (needed for the CheckboxGroupDirective)
	 */
	@ViewChild('input', { static: true }) public input: ElementRef;
	/**
	 * The unique id of the checkbox.
	 */
	@Input() public id: string;
	/**
	 * The value assigned to the checkbox.
	 */
	@Input() public value: any;
	/**
	 * The title of the checkbox.
	 * This property is superior to `label`.
	 *
	 * No default value. Fallback to `label` input.
	 */
	@Input() public title: string;
	/**
	 * The value that gets inserted in the title translation.
	 * This property is inferior to `title`.
	 *
	 * No default value.
	 */
	@Input() public label: string;

	/**
	 * Whether the value of the checkbox has changed
	 */
	@Output() public readonly changed = new EventEmitter<boolean>();

	/**
	 * Whether the label was clicked
	 */
	@Output() public readonly labelClicked = new EventEmitter<void>();

	/**
	 * Whether the input itself was clicked
	 */
	@Output() public readonly inputClicked = new EventEmitter<boolean>();

	/**
	 * Whether the input is checked
	 */
	public isChecked: boolean = false;

	/**
	 * Whether the input is partially checked
	 */
	public isPartialChecked: boolean = false;

	/**
	 * Translation keys
	 */
	public readonly i18nKeys: typeof I18nKeys = I18nKeys;

	private onTouch: Function = () => {}; // tslint:disable-line:no-empty
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private onModelChange: Function = (_: any) => {}; // tslint:disable-line:no-empty

	constructor(private readonly cdRef: ChangeDetectorRef) {}

	// Abstract methods
	// ==============================
	/**
	 * Patch the incoming value from the parent
	 */
	public writeValue(value: boolean): void {
		this.isChecked = value;
		this.cdRef.markForCheck();
	}

	/**
	 * Registers the onChange functionality
	 */
	public registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	/**
	 * Register the onTouch functionality
	 */
	public registerOnTouched(fn: Function): void {
		this.onTouch = fn;
	}
	/**
	 * Handle the set disabled from the parent
	 */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.cdRef.markForCheck();
	}

	// Component methods
	// ==============================
	/**
	 * Notify the parent of the change
	 */
	public onChange(value: boolean): void {
		// Iben: Set the internal checked value
		this.isChecked = value;

		// Iben: Notify the parent and mark the component as touched
		this.onModelChange(!this.value ? this.isChecked : this.isChecked ? this.value : null);
		this.onTouch();
		this.changed.emit(value);
	}

	/**
	 * Set the onTouch function
	 */
	public onTouched(): void {
		this.onTouch();
	}

	/**
	 * Mark the component as checked
	 */
	public markForCheck(): void {
		this.cdRef.markForCheck();
	}

	/**
	 * Handle the enter pressed on checkbox
	 */
	public enterPressed(): void {
		// Iben: Update the input checked state
		this.input.nativeElement.checked = !this.input.nativeElement.checked;

		// Iben: Notify the parent by emitting a change
		this.onChange(this.input.nativeElement.checked);

		// Iben: Mark the input as clicked
		this.inputClicked.emit(this.input.nativeElement.checked);
	}
}
