@if (form && codes) { @for (code of codes; track code) { @if ((filterNacebelCodes && searchResults.has('' + code.id)) ||
!filterNacebelCodes) {
<vlaio-tree-item [open]="'' + code.id | isNacebelOpen : isOpenSet">
	<vlaio-nacebel-title label [code]="code"> </vlaio-nacebel-title>

	<vlaio-nacebel-tree-item
		content
		[isOpenSet]="isOpenSet"
		[codes]="code.subcodes"
		[filterNacebelCodes]="filterNacebelCodes"
		[searchResults]="searchResults"
		[codesForm]="$any(form.get('' + code.id))"
	>
	</vlaio-nacebel-tree-item>
</vlaio-tree-item>
} } }
