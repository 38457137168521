import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition, MatTooltip } from '@angular/material/tooltip';

@Component({
	selector: 'vlaio-icon',
	template: ` <i
		matTooltipClass="c-mat-tooltip c-mat-icon-tooltip"
		[matTooltipPosition]="toolTipPosition"
		[matTooltipDisabled]="!toolTip"
		[matTooltip]="toolTip"
		[class]="icon"
	></i>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatTooltip]
})
export class VlaioIconComponent {
	@Input({ required: true }) public icon: string;
	@Input() public toolTip: string;
	@Input() public toolTipPosition: TooltipPosition = 'above';
}
