import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FocusClickDirective } from '@studiohyperdrive/ngx-utils';

import { VlaioLinkAbstractComponent } from '../shared';

/**
 * When clicked, this component emits an event. It does not navigate on its own, but is styled
 * in the same way the `VlaioInternalLinkComponent` is styled.
 */
@Component({
	selector: 'vlaio-event-link',
	template: `<a
		class="u-link__underline u-outline"
		[title]="title"
		[ngClass]="{ 'font-bold primary-color': linkStyle === 'styled' }"
		(focusClick)="linkClicked.emit()"
	>
		<ng-content></ng-content>
	</a>`,
	styleUrls: ['../shared/shared-link.style.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FocusClickDirective, NgClass]
})
export class VlaioEventLinkComponent extends VlaioLinkAbstractComponent {
	/**
	 * Emits when the link is clicked. The emitted value is the `linkId` or `null` if no `linkId` is provided.
	 */
	@Output() public linkClicked: EventEmitter<void> = new EventEmitter<void>();
}
