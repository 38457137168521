@for (annotation of action.annotations; track annotation) {
<vlaio-product-annotation [annotation]="annotation" />
} @switch (buttonActionType) {
<!-- Login -->

@case (ButtonActionTypes.Login) {
<vlaio-button
	classes="c-button c-product-cta c-button--outline c-button--dark"
	vlaioUserEIDLogin
	cypressTag="Offers.Product.Login"
	[productId]="productId"
	[title]="action.name"
	[fullWidth]="true"
>
	<span class="c-button__icon-before icon-inloggen"></span>
	<span class="c-button__label">
		{{ action.name }}
	</span>
</vlaio-button>
}

<!-- Request access eloket -->

@case (ButtonActionTypes.RequestAccessEloket) {
<vlaio-button
	class="c-button--icon-gap"
	classes="c-button c-product-cta c-button--orange"
	[title]="action.name"
	[fullWidth]="true"
	(handleClick)="ctaClicked.emit()"
>
	<vlaio-icon class="c-button-key-icon" icon="fa-solid fa-key" />
	<span class="c-button__label">
		{{ action.name }}
	</span>
</vlaio-button>
}

<!-- Request access -->

@case (ButtonActionTypes.RequestAccess) {
<vlaio-button
	class="c-button--icon-gap"
	classes="c-button c-product-cta c-button--orange"
	[title]="action.name"
	[fullWidth]="true"
	(handleClick)="ctaClicked.emit()"
>
	<vlaio-icon class="c-button-key-icon" icon="fa-solid fa-key" />
	<span class="c-button__label">
		{{ action.name }}
	</span>
</vlaio-button>
}

<!-- More info -->

@case (ButtonActionTypes.MoreInfo) {
<vlaio-button
	classes="c-button c-product-cta c-button--grey"
	[title]="action.name"
	[fullWidth]="true"
	(handleClick)="ctaClicked.emit()"
>
	<span class="c-button__icon-before icon-open-externally"></span>
	<span class="c-button__label">
		{{ action.name }}
	</span>
</vlaio-button>
}

<!-- Fallback (Start case) -->

@default {
<vlaio-button
	classes="c-button c-product-cta"
	[title]="action.name"
	[fullWidth]="true"
	(handleClick)="ctaClicked.emit()"
>
	<span class="c-button__icon-before icon-open-externally"></span>
	<span class="c-button__label">
		{{ action.name }}
	</span>
</vlaio-button>
} }
