@if (notifications$ | async; as notifications) {

<button
	class="c-notifications__trigger u-outline"
	aria-controls="notifications-toggle"
	[attr.aria-expanded]="isOpen"
	[title]="i18nKeys.Notifications.Title | translate"
	[attr.data-count]="notifications.length"
	(click)="toggleNotifications()"
>
	<span class="c-notifications__trigger-icon icon-notifications"></span>
</button>

<div class="c-notifications__container" aria-describedby="notifications-toggle">
	<ng-container *mediaQuery="mediaQueryMin.Desktop">
		@if (!useMobileUi || (useMobileUi && isOpen)) {
		<ng-template
			[ngTemplateOutlet]="notificationListTmpl"
			[ngTemplateOutletContext]="{ $implicit: notifications }"
		></ng-template>
		}
	</ng-container>

	<ng-container *mediaQuery="mediaQueryMax.Desktop">
		<ng-template
			[ngTemplateOutlet]="mobileNotificationTmpl"
			[ngTemplateOutletContext]="{ $implicit: notifications }"
		>
		</ng-template>
	</ng-container>
</div>

}

<!-- Templates -->
<ng-template #notificationListTmpl let-items>
	@for (notification of items; track trackById($index, notification)) {

	<ng-template
		[ngTemplateOutlet]="notificationTmpl"
		[ngTemplateOutletContext]="{ $implicit: notification }"
	></ng-template>

	}
</ng-template>

<ng-template #notificationTmpl let-item>
	<div class="c-notification" cypressTag="Home.Notification" [class.has-error]="item.hasError">
		<div class="c-notification-icon"></div>

		<p class="c-notification__title">
			{{ item.message }}
		</p>

		@if (item.link) {
		<p class="c-notification__link">
			<a
				class="u-outline font-bold"
				href="{{ item.link.link }}"
				tabindex="0"
				target="_blank"
				rel="noreferrer"
				[title]="item.link.name"
			>
				{{ item.link.name }}
			</a>
		</p>
		}
	</div>
</ng-template>

<ng-template #mobileNotificationTmpl let-notifications>
	<div *mediaQuery="mediaQueryMax.Desktop" class="c-notifications__container">
		<div class="c-notifications__content">
			<button class="c-notifications__close u-outline-sm" (click)="onClose()">
				<span class="c-notifications__close-icon icon-close"></span>
			</button>

			<div class="c-notifications__wrapper">
				<ng-template
					[ngTemplateOutlet]="notificationListTmpl"
					[ngTemplateOutletContext]="{ $implicit: notifications }"
				></ng-template>
			</div>
		</div>

		@if (isOpen) {
		<div class="c-notifications__overlay" [@fadeInOutAnimation]></div>
		}
	</div>
</ng-template>
