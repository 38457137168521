import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { HasFeaturePipe } from '@vlaio/shared/features';

@Component({
	selector: 'vlaio-product-tag',
	template: `<span
		matTooltipClass="c-mat-tooltip"
		matTooltipPosition="above"
		[matTooltipDisabled]="!('RecommendationReason' | hasFeature) || !description"
		[matTooltip]="description"
	>
		<ng-content></ng-content>
	</span>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatTooltip, HasFeaturePipe]
})
export class ProductTagComponent {
	@HostBinding('class') private readonly rootClass = 'c-product-tag';

	@Input() public description: string;
}
