import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultQueryParamsInterceptor implements HttpInterceptor {
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.requestIsEligibleForIntercept(req)) {
			return next.handle(req);
		}

		const defaultQueryParamsReq = req.clone({
			params: req.params.set('_format', 'json')
		});

		return next.handle(defaultQueryParamsReq);
	}

	private requestIsEligibleForIntercept(req: HttpRequest<any>): boolean {
		return req.method === 'GET' && req.url[0] !== '/'; // Skip local paths
	}
}
