import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AddressEntity } from '@vlaio/shared/types';

import { I18nKeys } from '../../i18n';

@Component({
	selector: 'vlaio-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule]
})
export class AddressComponent {
	/**
	 * Whether or not we want to render the address in a multi-liner or one-liner mode
	 */
	@Input() public format: 'multi-line' | 'one-line' = 'multi-line';
	/**
	 * The provided address
	 */
	@Input() public address: AddressEntity;
	/**
	 * Class for the address element
	 */
	@Input() public addressClass: string;

	public label = I18nKeys.Common.Empty.Address;
}
