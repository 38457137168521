<div class="c-container">
	<div class="c-sign-up__card l-grid">
		<div class="col-2-3">
			<div class="c-sign-up__container">
				<ng-container *isPlatform="'DESKTOP'">
					<h3 class="u-border-stripes" [innerHtml]="i18nKeys.Browser.Title | translate"></h3>

					<p [innerHtml]="i18nKeys.Browser.Description | translate"></p>
				</ng-container>

				<ng-container *isPlatform="'ANDROID'">
					<h3 class="u-border-stripes" [innerHtml]="i18nKeys.Android.Title | translate"></h3>

					<p
						[innerHtml]="
							(hasPwaInstallFunctionality
								? i18nKeys.Android.Description
								: i18nKeys.Android.NoChromeDescription
							) | translate
						"
					></p>
				</ng-container>
				<!--
				<ng-container *isPlatform="'IOS'">
					<h3 class="u-border-stripes" [innerHtml]="i18nKeys.Ios.Title | translate"></h3>

					<p [innerHtml]="i18nKeys.Ios.Description | translate"></p>
				</ng-container> -->
			</div>
		</div>

		<div class="col-1-3">
			<ng-container *isPlatform="'DESKTOP'">
				<qr-code
					size="200"
					errorCorrectionLevel="H"
					[value]="qrCodeUrl"
					[title]="i18nKeys.QrCode.Title | translate"
				></qr-code>
			</ng-container>

			<ng-container *isPlatform="'ANDROID'">
				@if (hasPwaInstallFunctionality) {
					<vlaio-button
						gtagClick
						gtagCategory="InstallPWA"
						gtagType="PWA"
						gtagLabel="Android"
						[title]="i18nKeys.Android.CTA | translate"
						(handleClick)="installApp()"
					>
						{{ i18nKeys.Android.CTA | translate }}
					</vlaio-button>
				}
			</ng-container>

			<!-- <ng-container *isPlatform="'IOS'">
				<button
					class="c-button"
					gtagClick
					gtagCategory="InstallPWAPage"
					gtagType="PWA"
					gtagLabel="iOs"
					[routerLink]="pageLink"
				>
					<span class="c-button__label">
						{{ i18nKeys.Ios.CTA | translate }}
					</span>
				</button>
			</ng-container> -->
		</div>
	</div>
</div>
